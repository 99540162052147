<template>
  <loading-container :loading="gettingQuote">
    <view-container normal>
      <flash />
      <help-banner />
      <div class="card banner-card">
        <order-banner :quote="quote" />
      </div>

      <async-form ref="form" class="form no-margin" @submit="onSubmit">
        <div v-if="quote.pastPaymentMethod" class="card banner-card grey">
          <div class="past-payment-method">
            <p class="sub-header no-margin">
              Past Payment Method:
              <span class="capitalize"
                ><strong>{{
                  quote.pastPaymentMethod.payment.cardBrand
                }}</strong></span
              >
              ending in
              <strong>{{ quote.pastPaymentMethod.payment.cardLast4 }}</strong>
            </p>
            <submit-button
              action="past-payment"
              class="form-button--beta"
              @click.prevent="onSubmitWithPastPayment"
            >
              Use this Payment Method
            </submit-button>
          </div>
        </div>

        <div class="card">
          <h1 class="header">Billing Details</h1>
          <br />
          <h2 class="sub-header">Billing Contact</h2>

          <div v-if="showPrefillOption">
            <input
              id="educationContact"
              v-model="matchEducationContact"
              type="checkbox"
              name="educationContact"
              class="contactCheckbox"
            />
            <label v-if="quote.newRenewal" for="educationContact"
              >Use information from previous purchase</label
            >
            <label v-else for="educationContact"
              >Same as Education Contact</label
            ><br />
          </div>
          <form-group class="input-30">
            <form-label for="firstName">First Name</form-label>
            <text-input
              id="firstName"
              v-model="billing.billingFirstName"
              rules="required"
              label="first name"
              autocomplete="given-name"
              @change="onContactChange"
            />
          </form-group>
          <form-group class="input-30">
            <form-label for="lastName">Last Name</form-label>
            <text-input
              id="lastName"
              v-model="billing.billingLastName"
              rules="required"
              label="last name"
              autocomplete="given-name"
              @change="onContactChange"
            />
          </form-group>
          <form-group class="input-30">
            <form-label for="phoneNumber">Phone Number</form-label>
            <text-input
              id="phoneNumber"
              v-model="billing.billingPhoneNumber"
              rules="required"
              label="phone number"
              autocomplete="phone-number"
              type="tel"
              @input="validatePhonenumber"
            />
          </form-group>
          <form-group class="input-50">
            <form-label for="email">Email Address</form-label>
            <text-input
              id="email"
              v-model="billing.billingEmail"
              rules="required|email"
              label="email address"
              type="email"
              autocomplete="email"
              @change="onContactChange"
            />
          </form-group>
          <br />
          <div><hr /></div>
          <h2 class="">Billing Address</h2>
          <div v-if="showPrefillOption">
            <input
              id="billingAddress"
              v-model="matchInstitutionAddress"
              type="checkbox"
              name="billingAddress"
              class="contactCheckbox"
            />
            <label v-if="quote.newRenewal" for="billingAddress"
              >Use information from previous purchase</label
            >
            <label v-else for="billingAddress"> Use institution address</label
            ><br />
          </div>
          <form-group class="input-30">
            <form-label for="country">Country</form-label>
            <select-field
              id="country"
              v-model="billing.billingCountry"
              rules="required"
              label="referral source"
            >
              <option
                v-for="option in countryOptions"
                :key="option.code"
                :value="option.code"
              >
                {{ option.name }}
              </option>
            </select-field>
          </form-group>
          <form-group class="input-50">
            <form-label for="billingAddress">Street Address</form-label>
            <text-input
              id="billingAddress"
              v-model="billing.billingAddress"
              rules="required"
              label="address"
              type="billingAddress"
              autocomplete="billingAddress"
              @change="onAddressChange"
            />
          </form-group>
          <form-group class="input-30">
            <form-label for="billingAddress2"
              >Department, Building, Unit, etc. (Optional)
            </form-label>
            <text-input
              id="billingAddress2"
              v-model="billing.billingAddress2"
              label="billing address 2"
              type="billingAddress2"
              autocomplete="billingAddress2"
              @change="onAddressChange"
            />
          </form-group>
          <form-group class="input-30">
            <form-label for="billingCity">City</form-label>
            <text-input
              id="billingCity"
              v-model="billing.billingCity"
              rules="required"
              label="city"
              type="billingCity"
              autocomplete="city"
              @change="onAddressChange"
            />
          </form-group>
          <form-group class="input-30">
            <form-label for="state">State/Province/Region</form-label>
            <select-field
              v-if="
                billing.billingCountry === 'US' ||
                billing.billingCountry === 'CA'
              "
              id="state"
              v-model="billing.billingState"
              rules="required"
              label="referral source"
              @update:modelValue="onAddressChange"
            >
              <option
                v-for="option in billing.billingCountry === 'US'
                  ? usStateOptions
                  : canProvinceOptions"
                :key="option[0]"
                :value="option[0]"
              >
                {{ option[1] }}
              </option>
              <option value="other">Other</option>
            </select-field>
            <text-input
              v-else
              id="state"
              v-model="billing.billingState"
              :rules="required"
              label="state"
              autocomplete="address-level1"
              @update:modelValue="onAddressChange"
            />
          </form-group>
          <form-group class="input-25">
            <form-label for="billingZip">Zip/Postal Code</form-label>
            <text-input
              id="billingZip"
              v-model="billing.billingZipCode"
              label="billing Zip"
              :rules="{
                required: true,
                postalCode: { country: billing.billingCountry }
              }"
              autocomplete="billing-zip"
              @change="onAddressChange"
            />
          </form-group>
          <div><hr /></div>
          <h2 class="">Payment Method</h2>
          <div class="switch-field" v-if="billing.billingCountry === 'US'">
            <input
              id="switch-one"
              v-model="payment.paymentMethod"
              type="radio"
              name="payment-method"
              value="creditCard"
              class="label-1"
              checked
            />
            <label class="switch-one" for="switch-one">Credit Card</label>
            <template v-if="!hidePurchaseOrder">
              <input
                id="switch-two"
                v-model="payment.paymentMethod"
                type="radio"
                name="payment-method"
                value="purchaseOrder"
                class="label-2"
              />
              <label class="switch-two" for="switch-two">Purchase Order</label>
            </template>
          </div>
          <form-group v-if="payment.paymentMethod === 'creditCard'">
            <form-label>Card Type</form-label>
            <div class="switch-field">
              <input
                id="switch-three"
                v-model="payment.cardType"
                type="radio"
                name="card-type"
                value="personal"
                class="label-1"
              />
              <label class="switch-one" for="switch-three">Personal</label>
              <input
                id="switch-four"
                v-model="payment.cardType"
                type="radio"
                name="card-type"
                value="institution"
                class="label-2"
              />
              <label class="switch-two" for="switch-four">Institution</label>
            </div>
          </form-group>
          <template v-if="canBeTaxExempt">
            <p v-if="quote.isTaxExempt" class="plain-text">
              You established tax exempt status in a previous purchase.
            </p>
            <form-group v-else class="input-checkbox">
              <checkbox
                v-model="payment.isTaxExempt"
                aria-label="Are you tax exempt?"
                class=""
              >
                Tax Exempt
              </checkbox>
            </form-group>
          </template>
          <div v-if="payment.paymentMethod == 'creditCard'">
            <form-group class="card-group">
              <form-label id="card-label">Card</form-label>
              <credit-card-input
                ref="card"
                aria-labelledby="card-label"
                label="card"
              />
            </form-group>
          </div>
          <div v-else>
            <form-group class="input-30">
              <form-label for="purchaseOrderNumber"
                >Purchase Order Number</form-label
              >
              <text-input
                id="purchaseOrderNumber"
                v-model="payment.purchaseOrderNumber"
                rules="required"
                label="purchase order number"
                autocomplete="purchase-order-number"
                :disabled="billing.billingCountry !== 'US'"
              />
            </form-group>
            <form-group class="input-75">
              <form-label for="purchaseOrderFile">
                Purchase Order File Attachment
              </form-label>
              <br />
              <input
                id="purchaseOrderFile"
                ref="fileInput"
                class="file-upload"
                :class="{ dragged: draggingPo }"
                type="file"
                name="purchaseOrderFile"
                accept="image/*, application/pdf"
                @change="fileChange"
                :disabled="billing.billingCountry !== 'US'"
              />
              <label
                for="purchaseOrderFile"
                @drop.prevent="onPoFileDropped"
                @dragover.prevent
                @dragenter="draggingPo = true"
                @dragleave="draggingPo = false"
              >
                <span
                  v-if="attachments.purchaseOrderFile.data"
                  class="file-upload-label-text"
                  >{{ attachments.purchaseOrderFile.data.name }}</span
                >
                <span v-else class="file-upload-label-text"
                  >Drag file here or click to upload</span
                >
              </label>
            </form-group>
          </div>
          <div><hr /></div>
          <form-group>
            <checkbox v-model="termsAccepted">
              On behalf of the school or institution identified above, I accept
              the
              <hyper-link :to="{ name: 'terms' }">Standard Terms</hyper-link>
              and certify that I have the authority to bind the school to the
              Standard Terms.</checkbox
            >

            <checkbox v-model="privacyAccepted">
              On behalf of the school or institution identified above, I have
              reviewed
              <hyper-link :to="{ name: 'privacy' }">
                Discovery Education’s Privacy Policy</hyper-link
              >
              and acknowledge Discovery Education will process personal
              information in accordance with this Privacy Policy.</checkbox
            >
          </form-group>
          <form-group class="button-group">
            <submit-button
              action="submit"
              :disabled="loading || !termsAccepted || !privacyAccepted"
              class="form-button--beta"
            >
              Review and Purchase
            </submit-button>
            <submit-button
              v-if="showQuoteButton"
              action="quote"
              :disabled="loading"
              class="form-button--alpha"
            >
              Send me a quote
            </submit-button>
          </form-group>
        </div>
        <div class="card bottom-card">
          <div>
            <hyper-link :to="{ name: 'w9' }">
              Where can I find a W-9 for Pivot Interactives?
            </hyper-link>
          </div>
          <div>
            <hyper-link :to="{ name: 'single_sole_source' }">
              Where can I find the Sole Source Vendor Certification for Pivot
              Interactives and Vernier?
            </hyper-link>
          </div>
        </div>
      </async-form>
    </view-container>
  </loading-container>
</template>

<script>
import OrderBanner from 'src/modules/purchases/components/OrderBanner'
import HelpBanner from 'src/modules/purchases/components/HelpBanner'
import CreditCardInput from 'src/shared/components/CreditCardInput'
import {
  getPurchaseData,
  canProvinceOptions,
  usStateOptions,
  countryOptions,
  mergePurchaseData
} from 'src/shared/utils/purchases'
import Flash from 'src/shared/components/Flash'
import client from 'src/shared/api-client.ts'

export default {
  name: 'BillingView',
  components: { OrderBanner, CreditCardInput, HelpBanner, Flash },
  data() {
    return {
      gettingQuote: true,
      quote: {},
      billing: {
        billingFirstName: '',
        billingLastName: '',
        billingPhoneNumber: '',
        billingEmail: '',
        billingCountry: 'US',
        billingAddress: '',
        billingAddress2: '',
        billingCity: '',
        billingState: '',
        billingZipCode: ''
      },
      payment: {
        paymentMethod: 'creditCard',
        cardType: 'personal',
        isTaxExempt: false,
        taxExemptFile: '',
        purchaseOrderNumber: '',
        purchaseOrderFile: ''
      },
      attachments: {
        // property names should match corresponding PAYMENT property name
        purchaseOrderFile: { type: '', data: null },
        taxExemptFile: { type: '', data: null }
      },
      canProvinceOptions,
      usStateOptions,
      countryOptions,
      matchEducationContact: false,
      matchInstitutionAddress: false,
      taxExemptFilename: null,
      purchaseOrderFilename: null,
      draggingTaxExempt: false,
      draggingPo: false,
      termsAccepted: false,
      privacyAccepted: false
    }
  },
  beforeRouteEnter(to, from, next) {
    const { id, purchaseType } = getPurchaseData()

    if (!id) {
      switch (purchaseType) {
        case 'license':
        default:
          return next({
            name: 'new_license_session',
            params: { licenseType: 'institution' }
          })
      }
    }

    next()
  },
  computed: {
    isInstitutionLicense() {
      return this.quote.licenseType === 'institution'
    },
    purchaseOrderRequired() {
      return this.payment.paymentMethod === 'purchaseOrder'
    },
    showPrefillOption() {
      return this.quote.purchaseType === 'license'
    },
    taxExemptRequired() {
      return this.canBeTaxExempt && this.payment.isTaxExempt
    },
    showQuoteButton() {
      return (
        this.quote.licenseType === 'institution' &&
        this.quote.currentSeats === 0
      )
    },
    hidePurchaseOrder() {
      return this.quote.purchaseType === 'bookstore-codes'
    },
    canBeTaxExempt() {
      return (
        (this.payment.paymentMethod === 'purchaseOrder' ||
          this.payment.cardType === 'institution') &&
        ['US', 'CA'].includes(this.billing.billingCountry)
      )
    }
  },
  methods: {
    onSubmitWithPastPayment() {
      this.$refs.form.submit(true)
    },
    async onSubmit({ action, done }) {
      try {
        if (action === 'past-payment') {
          const cleanBilling = Object.fromEntries(
            Object.entries(this.quote.pastPaymentMethod.billing).filter(
              ([_, v]) => v != null
            )
          )
          await client.purchases.setQuotePaymentMethod({
            id: this.quote.id,
            paymentMethodId: this.quote.pastPaymentMethod.paymentMethodId,
            ...cleanBilling,
            billingPhoneNumber:
              this.quote.pastPaymentMethod.billing.billingPhone,
            billingInstitution: this.quote.institution || 'N/A'
          })

          mergePurchaseData({
            billing: {
              email: this.quote.pastPaymentMethod.email
            }
          })

          if (this.quote.pastPaymentMethod.taxExempt) {
            this.SET_PURCHASE_TAX_RATE(0)
          }

          // return done(true)
          setTimeout(() => {
            this.$router.push({ name: 'purchase_order_summary' })
            done(true)
          }, 1000)
          return
        }

        if (!this.purchaseOrderRequired) {
          this.payment.purchaseOrderFile = ''
          this.attachments.purchaseOrderFile = ''
        }

        if (
          this.purchaseOrderRequired &&
          !this.attachments.purchaseOrderFile.data
        ) {
          this.$error(
            'Purchase Order File is required if Purchase Order Number is provided.'
          )
          return done(false)
        }

        // upload any attached files (PO or Tax Exemption) to S3...
        const filenames = Object.keys(this.attachments).filter(
          k => this.attachments[k] && this.attachments[k].data
        )
        for (const name of filenames) {
          const { data, type } = this.attachments[name]
          const fileName = name || data.name
          const { url } = await client.purchases.upload(
            {
              fileName,
              id: this.quote.id,
              type: type.split('/').pop()
            },
            data
          )
          this.payment[name] = url
        }

        mergePurchaseData({
          billing: {
            email: this.billing.billingEmail
          }
        })

        if (this.payment.paymentMethod === 'creditCard') {
          const paymentMethod = await this.$refs.card.createPaymentMethod({
            billing_details: {
              name: `${this.billing.billingFirstName} ${this.billing.billingLastName}`,
              email: this.billing.billingEmail,
              phone: this.billing.billingPhoneNumber,
              address: {
                country: this.billing.billingCountry,
                line1: this.billing.billingAddress,
                line2: this.billing.billingAddress2,
                city: this.billing.billingCity,
                state: this.billing.billingState,
                postal_code: this.billing.billingZipCode
              }
            }
          })

          await client.purchases.setQuotePaymentMethod({
            id: this.quote.id,
            paymentMethodId: paymentMethod.id,
            ...this.billing,
            billingName: `${this.billing.billingFirstName} ${this.billing.billingLastName}`,
            billingInstitution: this.quote.institution || 'N/A',
            ...this.payment
          })
        } else {
          await client.purchases.setQuotePurchaseOrder({
            id: this.quote.id,
            ...this.billing,
            billingName: `${this.billing.billingFirstName} ${this.billing.billingLastName}`,
            billingInstitution: this.quote.institution || 'N/A',
            ...this.payment
          })
        }

        if (this.payment.isTaxExempt) {
          this.$router.push({ name: 'tax_exemption' })
          done(true)
        } else {
          setTimeout(() => {
            this.$router.push({ name: 'purchase_order_summary' })
            done(true)
          }, 1000)
        }
      } catch (error) {
        done(false)
        throw error
      }
    },
    splitName(fullName) {
      const splitFullName = fullName.split(' ')
      const firstName = splitFullName
        .slice(0, splitFullName.length - 1)
        .join(' ')
      const lastName = splitFullName[splitFullName.length - 1]
      return { firstName, lastName }
    },
    onContactChange: function () {
      if (
        this.billing.billingFirstName !== this.quote.contactFirstName ||
        this.billing.billingLastName !== this.quote.contactLastName ||
        this.billing.billingEmail !== this.quote.contactEmail
      ) {
        this.matchEducationContact = false
      }
    },
    onAddressChange: function () {
      if (
        this.billing.billingCountry !== this.quote.institutionCountry ||
        this.billing.billingAddress !== this.quote.institutionAddress ||
        this.billing.billingAddress2 !== this.quote.institutionAddress2 ||
        this.billing.billingCity !== this.quote.institutionCity ||
        this.billing.billingState !== this.quote.institutionState ||
        this.billing.billingZipCode !== this.quote.institutionZipCode
      ) {
        this.matchInstitutionAddress = false
      }
    },
    validatePhonenumber() {
      const x = this.billing.billingPhoneNumber
        .replace(/\D/g, '')
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
      this.billing.billingPhoneNumber = !x[2]
        ? x[1]
        : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '')
    },
    fileChange(event) {
      // expects NAME of <input type="file" name="..."> to match ATTACHMENTS property name
      const file = event.target.files[0]

      const name = event.target.name
      const { type } = file
      this.attachments[name] = { data: file, type }
    },
    onTaxExemptFileDropped(event) {
      const file = event.dataTransfer.files[0]
      this.attachments.taxExemptFile = { data: file, type: file.type }
      this.draggingTaxExempt = false
    },
    onPoFileDropped(event) {
      const file = event.dataTransfer.files[0]
      this.attachments.purchaseOrderFile = { data: file, type: file.type }
      this.draggingPo = false
    }
  },
  async mounted() {
    this.gettingQuote = true

    const { id } = getPurchaseData()

    const { quote } = await client.purchases.getQuote({ id })

    this.quote = quote

    this.gettingQuote = false
  },
  watch: {
    matchEducationContact: function (val) {
      if (val) {
        this.billing.billingFirstName = this.quote.contactFirstName
        this.billing.billingLastName = this.quote.contactLastName
        this.billing.billingEmail = this.quote.contactEmail
      }
    },
    matchInstitutionAddress: function (val) {
      if (val) {
        this.billing.billingCountry = this.quote.institutionCountry
        this.billing.billingAddress = this.quote.institutionAddress
        this.billing.billingAddress2 = this.quote.institutionAddress2
        this.billing.billingCity = this.quote.institutionCity
        this.billing.billingState = this.quote.institutionState
        this.billing.billingZipCode = this.quote.institutionZipCode
      }
    },
    'billing.billingCountry': function (val) {
      if (val !== 'US') {
        this.payment.paymentMethod = 'creditCard'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.card {
  border-radius: 6px;
  box-shadow: 0px 1px 3px 0px rgba(61, 44, 96, 0.2);
  padding: 60px 64px;
}
.banner-card {
  padding: 16px 64px;
}
.bottom-card {
  padding: 16px 64px;
}
.payment-view {
  background-color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 48px 64px;
  width: 928px;
}
.button-group {
  display: flex;
  flex-direction: row-reverse;
}
.header {
  font-size: 30px;
  line-height: 45px;
}
.sub-header {
  font-size: 20px;
  line-height: 30px;
}
.form-input {
  position: static;
  background-color: grey;
}
.input-75 {
  width: 75%;
}
.input-50 {
  width: 50%;
}
.input-30 {
  width: 33%;
}
.input-25 {
  width: 25%;
}
.input-checkbox {
  font-size: 16px;
}
.switch-field {
  display: flex;
  margin-bottom: 24px;
  overflow: hidden;
}
.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}
.switch-field label {
  background-color: #ffffff;
  color: #3c393a;
  font-size: 20px;
  font-weight: normal;
  line-height: 150%;
  text-align: center;
  padding: 8px 16px;
  margin-right: -1px;
  border: 1px solid #d4d3d3;
  &:hover {
    cursor: pointer;
  }
}
.switch-one {
  border-radius: 3px 0px 0px 3px;
}
.switch-two {
  border-radius: 0px 3px 3px 0px;
}
.switch-field input:checked + label {
  background-color: #3d2c60;
  box-shadow: none;
  border: 1px solid #3d2c60;
  color: #ffffff;
}
.label-1 {
  border-radius: 4px 0 0 4px;
}
.label-2 {
  border-radius: 0 4px 4px 0;
}
.file-upload {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.file-upload + label {
  width: 800px;
  height: 51px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  line-height: 19px;
  color: #221f20;
  background: #eeeded;
  display: inline-block;
  padding-top: 16px;
  &:hover {
    cursor: pointer;
  }
}
.file-upload.dragged + label {
  box-shadow: inset 0 0 10px #000000;
}
.card-group {
  width: 60%;
}
.file-upload-label-text {
  pointer-events: none;
}
.capitalize {
  text-transform: capitalize;
}
.past-payment-method {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.no-margin {
  margin: 0;
}
.grey {
  background-color: #eee;
}
.plain-text {
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.008em;
  text-align: left;
}
.contactCheckbox {
  margin-right: 8px;
}
</style>
