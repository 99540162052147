<template>
  <UIForm ref="form" @submit="saveChanges">
    <UICard collapsible>
      <div class="flex items-baseline">
        <UICardHeading>Total Possible Points:</UICardHeading>
        <UINumberInput
          v-if="values.overrideTotalPointValue"
          name="totalPointValueOverride"
          class="w-10 ml-2"
          rules="required"
        />
        <div v-else class="ml-2 h-8 flex items-center">
          {{
            values.ignoreInstructorGradedPoints
              ? activityInfo.autogradePoints
              : activity.totalPointValue
          }}
        </div>
        <div class="ml-4 text-ui-gray-600">
          <template v-if="isSubmitting">
            <UIIcon icon="arrows-rotate" />
            Saving...
          </template>
          <template v-else>
            <UIIcon icon="check" />
            Saved
          </template>
        </div>
      </div>
      <UICardContent :unmount="false">
        <div class="flex items-end mt-4">
          <div class="flex-grow flex gap-4 mb-2">
            <div>
              <div>Autograded</div>
              <div>
                {{ activityInfo.autogradePoints }} Points ({{
                  activityInfo.autogradeQuestions
                }}
                Questions)
              </div>
            </div>
            <div>
              <div>Instructor Graded</div>
              <div>
                {{ activityInfo.instructorPoints }} Points ({{
                  activityInfo.instructorQuestions
                }}
                Questions)
              </div>
            </div>
          </div>
          <div>
            <UISwitch name="overrideTotalPointValue" class="mb-2">
              Override Point Total
            </UISwitch>
            <UISwitch
              v-if="ignoreInstructorGradeEnabled"
              name="ignoreInstructorGradedPoints"
              class="mb-2"
            >
              Ignore Instructor Graded Points
            </UISwitch>
          </div>
        </div>
      </UICardContent>
    </UICard>
  </UIForm>
</template>

<script setup lang="ts">
import {
  UIIcon,
  UICard,
  UICardContent,
  UICardHeading,
  UISwitch,
  UINumberInput,
  UIForm
} from '@pi/design'
import { debounce } from 'lodash'
import client from 'src/shared/api-client'
import { useForm } from 'vee-validate'
import { computed, onBeforeUnmount, ref, watch } from 'vue'
import { useFeatureFlag } from 'src/shared/hooks/feature-flags'

interface Assignment {
  id: string
  totalPointValueOverride?: number
  ignoreInstructorGradedPoints: boolean
}

interface ActivityComponent {
  componentType: string
  isRandomized?: boolean
  autograde?: boolean
  pointValue?: number
  leftContent?: ActivityComponent[]
  rightContent?: ActivityComponent[]
}

interface Activity {
  id: string
  totalPointValue: number
  sections: {
    components: ActivityComponent[]
  }[]
}

const ignoreInstructorGradeEnabled = useFeatureFlag('ignore_instructor_grade')

const emit = defineEmits<{ updated: [] }>()
const props = defineProps<{ activity: Activity; assignment: Assignment }>()

const { handleSubmit, values, isSubmitting, setValues } = useForm({
  initialValues: {
    overrideTotalPointValue:
      typeof props.assignment.totalPointValueOverride === 'number',
    totalPointValueOverride: props.assignment.totalPointValueOverride,
    ignoreInstructorGradedPoints: props.assignment.ignoreInstructorGradedPoints
  },
  keepValuesOnUnmount: true
})

watch(
  () => values.overrideTotalPointValue,
  override => {
    if (!override) {
      setValues({
        totalPointValueOverride: undefined
      })
    } else if (typeof values.totalPointValueOverride !== 'number') {
      setValues({
        totalPointValueOverride: values.ignoreInstructorGradedPoints
          ? activityInfo.value.autogradePoints
          : props.activity.totalPointValue
      })
    }
  }
)

const saveChanges = handleSubmit(async values => {
  await client.assignments.update({
    assignmentId: props.assignment.id,
    totalPointValueOverride: values.totalPointValueOverride ?? null,
    ignoreInstructorGradedPoints: values.ignoreInstructorGradedPoints
  })

  emit('updated')
})

const form = ref<typeof UIForm>()
const autosave = debounce(() => {
  form.value?.requestSubmit()
}, 1500)
watch(values, () => autosave(), { deep: true })
onBeforeUnmount(() => autosave.flush())

const activityInfo = computed(() => {
  const components = (props.activity.sections ?? [])
    .flatMap(section => section.components)
    .flatMap(component =>
      component.componentType === 'SplitView'
        ? [
            ...(component?.leftContent ?? []),
            ...(component?.rightContent ?? [])
          ]
        : component
    )

  let instructorPoints = 0
  let instructorQuestions = 0
  let autogradePoints = 0
  let autogradeQuestions = 0

  for (const component of components) {
    if (component.autograde) {
      autogradePoints += component.pointValue ?? 0
      autogradeQuestions += 1
    } else if (component.componentType.includes('Question')) {
      instructorPoints += component.pointValue ?? 0
      instructorQuestions += 1
    }
  }

  return {
    instructorPoints,
    instructorQuestions,
    autogradePoints,
    autogradeQuestions
  }
})
</script>
