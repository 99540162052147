<template>
  <UICard collapsible>
    <UICardHeading>Settings</UICardHeading>
    <UICardContent>
      <div class="flex flex-col md:flex-row md:gap-4 lg:gap-8 pt-2 pb-4">
        <div class="md:w-0 flex-1 mb-8 md:mb-0">
          <div class="mb-8">
            <div class="font-bold mb-1 text-lg">Instant Feedback</div>
            <UISwitch
              name="settings.noHints"
              class="mb-2"
              :controlled="false"
              :modelValue="!settings.noHints"
              @update:modelValue="
                (showHints: boolean) => (settings.noHints = !showHints)
              "
            >
              Show Hints
            </UISwitch>
            <div class="flex items-baseline mb-2">
              <UILabel for="studentFeedbackTiming"
                >Show Feedback to Students</UILabel
              >
              <UISelectInput
                id="studentFeedbackTiming"
                class="ml-4 w-72"
                name="settings.studentFeedbackTiming"
                label=""
                v-model="settings.studentFeedbackTiming"
                :options="[
                  {
                    label: 'After Submitting Each Question',
                    value:
                      AssignmentStudentFeedbackTimingValue.AfterQuestionSubmit
                  },
                  {
                    label: 'After Submitting the Assignment',
                    value:
                      AssignmentStudentFeedbackTimingValue.AfterAssignmentSubmit
                  }
                ]"
              >
                <option
                  :value="
                    AssignmentStudentFeedbackTimingValue.AfterQuestionSubmit
                  "
                >
                  After Submitting Each Question
                </option>
                <option
                  :value="
                    AssignmentStudentFeedbackTimingValue.AfterAssignmentSubmit
                  "
                >
                  After Submitting the Assignment
                </option>
              </UISelectInput>
            </div>
            <p>
              Right/wrong indicators, scores, and comments will be shown to the
              student after
              {{
                settings.studentFeedbackTiming ===
                AssignmentStudentFeedbackTimingValue.AfterQuestionSubmit
                  ? 'each question is submitted'
                  : 'the entire assignment is submitted'
              }}. Autograding will occur after
              {{
                settings.studentFeedbackTiming ===
                AssignmentStudentFeedbackTimingValue.AfterQuestionSubmit
                  ? 'each question is submitted'
                  : 'the entire assignment is submitted'
              }}.
            </p>
          </div>
          <div>
            <div class="font-bold mb-1 text-lg">Question Overrides</div>
            <UISwitch
              name="settings.attemptsOverride"
              class="mb-2"
              :controlled="false"
              :modelValue="!!settings.attemptsOverride"
              @update:modelValue="
                (override: boolean) =>
                  (settings.attemptsOverride = override ? {} : undefined)
              "
              :disabled="overrideAttemptsDisabled"
            >
              Override Attempts
            </UISwitch>
            <template
              v-if="settings.attemptsOverride && !overrideAttemptsDisabled"
            >
              <div class="flex items-baseline mb-3">
                <UINumberInput
                  id="overrideAttemptsDragDrop"
                  class="w-10 flex-shrink-0"
                  v-model="settings.attemptsOverride.dragDrop"
                  name="settings.overrideAttempts.dragDrop"
                />
                <label for="overrideAttemptsDragDrop" class="font-normal ml-2">
                  Total number of attempts for all
                  <strong>drag &amp; drop</strong> questions
                </label>
              </div>
              <div class="flex items-baseline mb-3">
                <UINumberInput
                  id="overrideAttemptsChoice"
                  class="w-10 flex-shrink-0"
                  v-model="settings.attemptsOverride.multipleChoice"
                  name="settings.overrideAttempts.multipleChoice"
                />
                <label for="overrideAttemptsChoice" class="font-normal ml-2">
                  Total number of attempts for all
                  <strong>multiple choice</strong> questions
                </label>
              </div>
              <div class="flex items-baseline">
                <UINumberInput
                  id="overrideAttemptsNumerical"
                  class="w-10 flex-shrink-0"
                  v-model="settings.attemptsOverride.numerical"
                  name="settings.overrideAttempts.numerical"
                />
                <label for="overrideAttemptsNumerical" class="font-normal ml-2">
                  Total number of attempts for all
                  <strong>numerical</strong> questions
                </label>
              </div>
            </template>
            <p v-else>
              <template v-if="overrideAttemptsDisabled">
                Unless the feedback is shown after submitting each question, the
                activity does not support custom attempt limits
              </template>
              <template v-else>
                Use question attempt limits provided by the activity.
              </template>
            </p>
          </div>
        </div>
        <div class="md:w-0 flex-1">
          <div
            v-if="activities.length === 1 || ignoreInstructorGradeEnabled"
            class="mb-8"
          >
            <div class="font-bold mb-1 text-lg">Scoring</div>
            <template v-if="activities.length === 1">
              <div class="flex items-baseline">
                <UILabel>Total Point Value:</UILabel>
                <UINumberInput
                  v-if="overrideTotalPointValue"
                  name="settings.totalPointValueOverride"
                  class="w-10 ml-2"
                  v-model="settings.totalPointValueOverride"
                />
                <div v-else class="ml-2 h-8 flex items-center">
                  {{
                    settings.ignoreInstructorGradedPoints
                      ? firstActivityInfo.autogradePoints
                      : activities[0].totalPointValue
                  }}
                </div>
              </div>
              <div class="flex gap-4 mb-2">
                <div>
                  <div>Autograded</div>
                  <div>
                    {{ firstActivityInfo.autogradePoints }} Points ({{
                      firstActivityInfo.autogradeQuestions
                    }}
                    Questions)
                  </div>
                </div>

                <div>
                  <div>Instructor Graded</div>
                  <div>
                    {{ firstActivityInfo.instructorPoints }} Points ({{
                      firstActivityInfo.instructorQuestions
                    }}
                    Questions)
                  </div>
                </div>
              </div>
              <UISwitch
                name="settings.totalPointValueOverride"
                class="mb-2"
                :controlled="false"
                v-model="overrideTotalPointValue"
              >
                Override Point Total
              </UISwitch>
            </template>
            <template v-if="ignoreInstructorGradeEnabled">
              <UISwitch
                name="settings.ignoreInstructorGradedPoints"
                class="mb-2"
                :controlled="false"
                v-model="settings.ignoreInstructorGradedPoints"
              >
                Ignore Instructor Graded Points
              </UISwitch>
              <p v-if="settings.ignoreInstructorGradedPoints">
                Instructor graded questions will not be included the total point
                value of the assignment, nor will manually given scores on those
                questions count towards students' assignment scores.
              </p>
              <p v-else>
                You may need to grade a few questions for the student to get
                full credit.
              </p>
            </template>
          </div>
          <div>
            <div class="font-bold mb-1 text-lg">Randomization</div>
            <UISwitch
              name="settings.disableRandomization"
              class="mb-2"
              :controlled="false"
              :disabled="editing"
              :modelValue="!settings.disableRandomization"
              @update:modelValue="
                (isRandomized: boolean) =>
                  (settings.disableRandomization = !isRandomized)
              "
            >
              Randomize Questions
            </UISwitch>
            <p v-if="settings.disableRandomization">
              All students will be given the same questions and will have the
              same correct answers.
            </p>
            <p v-else>
              Questions with randomized answers will have answers which are
              different for each student.
            </p>
          </div>
        </div>
      </div>
    </UICardContent>
  </UICard>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref, watch } from 'vue'
import {
  UILabel,
  UICard,
  UICardContent,
  UICardHeading,
  UINumberInput,
  UISelectInput,
  UISwitch
} from '@pi/design'
import { AssignmentStudentFeedbackTimingValue } from '@pi/types'
import { useFeatureFlag } from 'src/shared/hooks/feature-flags'

interface Settings {
  attemptsOverride?: {
    dragDrop?: number
    numerical?: number
    multipleChoice?: number
  }
  noHints: boolean
  studentFeedbackTiming: AssignmentStudentFeedbackTimingValue
  disableRandomization: boolean
  totalPointValueOverride?: number
  ignoreInstructorGradedPoints: boolean
}

interface ActivityComponent {
  componentType: string
  isRandomized?: boolean
  autograde?: boolean
  pointValue?: number
  leftContent?: ActivityComponent[]
  rightContent?: ActivityComponent[]
}

interface Activity {
  id: string
  totalPointValue: number
  sections: {
    components: ActivityComponent[]
  }[]
}

const ignoreInstructorGradeEnabled = useFeatureFlag('ignore_instructor_grade')

const props = withDefaults(
  defineProps<{ activities: Activity[]; editing?: boolean }>(),
  { editing: false }
)
const settings = defineModel<Settings>({ required: true })

const overrideTotalPointValue = ref(false)
onBeforeMount(() => {
  overrideTotalPointValue.value =
    typeof settings.value.totalPointValueOverride === 'number'
})
watch(overrideTotalPointValue, override => {
  if (!override) {
    delete settings.value.totalPointValueOverride
  } else if (typeof settings.value.totalPointValueOverride !== 'number') {
    settings.value.totalPointValueOverride = settings.value
      .ignoreInstructorGradedPoints
      ? firstActivityInfo.value.autogradePoints
      : props.activities[0].totalPointValue
  }
})

const randomizedActivityCount = computed(
  () =>
    props.activities.filter(activity => {
      const components = (activity.sections ?? [])
        .flatMap(section => section.components)
        .flatMap(component =>
          component.componentType === 'SplitView'
            ? [
                ...(component?.leftContent ?? []),
                ...(component?.rightContent ?? [])
              ]
            : component
        )

      return components.some(component => component.isRandomized)
    }).length
)

const firstActivityInfo = computed(() => {
  const components = (props.activities[0]?.sections ?? [])
    .flatMap(section => section.components)
    .flatMap(component =>
      component.componentType === 'SplitView'
        ? [
            ...(component?.leftContent ?? []),
            ...(component?.rightContent ?? [])
          ]
        : component
    )

  let instructorPoints = 0
  let instructorQuestions = 0
  let autogradePoints = 0
  let autogradeQuestions = 0
  let randomizedQuestions = 0

  for (const component of components) {
    if (component.autograde) {
      autogradePoints += component.pointValue ?? 0
      autogradeQuestions += 1
    } else if (component.componentType.includes('Question')) {
      instructorPoints += component.pointValue ?? 0
      instructorQuestions += 1
    }
    if (component.isRandomized) {
      randomizedQuestions += 1
    }
  }

  return {
    instructorPoints,
    instructorQuestions,
    autogradePoints,
    autogradeQuestions,
    randomizedQuestions
  }
})

const overrideAttemptsDisabled = computed(
  () =>
    settings.value.studentFeedbackTiming !==
    AssignmentStudentFeedbackTimingValue.AfterQuestionSubmit
)
</script>
