<template>
  <div class="curve-fit-detail">
    <p>
      <latex-block
        v-if="vColumn.name.parser === 'latex'"
        :latex="vColumn.name.formula"
      />
      <template v-else>{{ vColumn.name }}</template>
      Curve:
    </p>
    <span class="sr-only">
      <p v-for="(line, index) in fit.description" :key="index">{{ line }}</p>
    </span>
    <p ref="formula" aria-hidden="true"></p>
  </div>
</template>

<script>
import katex from 'katex'

import {
  columnDisplayName,
  columnDisplayVariable,
  columnDisplayUnits
} from 'src/shared/components/grid-graph/utilities'

export default {
  name: 'DataGraphCurveFitDetail',
  props: {
    fit: Object,
    hColumn: Object,
    vColumn: Object
  },
  methods: {
    render() {
      const xVar = columnDisplayVariable(this.hColumn, 'x')
      const yVar = columnDisplayVariable(this.vColumn, 'y')
      const xUnits = columnDisplayUnits(this.hColumn, 'x')
      const yUnits = columnDisplayUnits(this.vColumn, 'y')
      katex.render(
        this.fit.formula
          .replace(/e(?:\+|(-))(\d+)/g, '\\times 10^{$1$2}')
          .replace(
            /xvar/g,
            typeof xVar === 'object' ? xVar.formula : `\\verb!${xVar}!`
          )
          .replace(
            /yvar/g,
            typeof yVar === 'object' ? yVar.formula : `\\verb!${yVar}!`
          )
          .replace(
            /xunits/g,
            typeof xUnits === 'object' ? xUnits.formula : `\\verb!${xUnits}!`
          )
          .replace(
            /yunits/g,
            typeof yUnits === 'object' ? yUnits.formula : `\\verb!${yUnits}!`
          ),
        this.$refs.formula,
        {
          throwOnError: false
        }
      )
    },
    columnDisplayName
  },
  mounted() {
    this.render()
  },
  watch: {
    fit() {
      this.render()
    },
    vColumn: {
      deep: true,
      handler() {
        this.render()
      }
    },
    hColumn: {
      deep: true,
      handler() {
        this.render()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.curve-fit-detail {
  padding: 0 14px;
  margin-bottom: 14px;
  border-right: 1px solid $dark-grey;

  &:first-child {
    border-left: 1px solid $dark-grey;
  }

  p {
    &:first-child {
      font-size: 14px;
      /* margin-bottom: 0; */
      /* border-bottom: 1px solid $dark-grey; */
    }

    &:last-child {
      font-size: 13px;
      margin-bottom: 0;
    }
  }
}
</style>
