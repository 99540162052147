<template>
  <collapse-panel
    :title="section.name"
    :collapsed="collapsedByDefault"
    show-on-print
    class="activity-section-panel"
  >
    <template v-if="section.isAvailable || override">
      <div class="activity-section__summary">
        <playback
          v-if="hasSummary"
          :element="$refs.summary?.$el"
          class="playback-btn__summary"
        />

        <editor-content
          v-if="hasSummary"
          :text="section.summary"
          :variable-context="variableContext"
          ref="summary"
          class="activity-section__summary-text"
          :view-as-student="viewAsStudent"
        />
      </div>
      <slot />
    </template>
    <template v-else>
      Please complete and submit the previous section before continuing to this
      one.
    </template>
  </collapse-panel>
</template>

<script>
import * as Vue from 'vue'
import { hasContent } from 'src/shared/components/editor/utils.js'

export default {
  name: 'ActivitySection',
  inject: ['inherited'],
  provide() {
    return {
      inherited: Vue.computed(() => ({
        ...this.inherited,
        section: this.section
      }))
    }
  },
  props: {
    section: {
      type: Object,
      required: true
    },
    openByDefault: {
      type: Boolean,
      default: false
    },
    override: {
      type: Boolean,
      default: false
    },
    variableContext: {
      type: Object,
      required: true
    },
    viewAsStudent: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasSummary() {
      return !!this.section.summary && hasContent(this.section.summary)
    },
    collapsedByDefault() {
      if (this.openByDefault) {
        return false
      }

      return this.section.index !== 0
    }
  }
}
</script>

<style lang="scss" scoped>
.activity-section-panel {
  margin-top: 22px;
}

.playback-btn {
  &__title {
    font-size: 20px;
    display: inline-block;
  }

  &__summary {
    display: inline-block;
    float: left;
    margin-top: 5px;
  }
}

.activity-section__summary {
  display: flex;
}
.activity-section__summary-text {
  display: inline-block;
  flex: 1 1 0%;
  width: 98%;
  padding-left: 10px;
}

@media screen and (max-width: 1024px) {
  .summary {
    width: 95%;
  }
}
</style>
