<template>
  <div
    class="settings-panel"
    :class="{ 'settings-panel--open': showing }"
    aria-label="Graph Settings Panel"
  >
    <collapse-provider
      :collapsed="collapsedSections.settings"
      v-slot="{ collapsed }"
      @change="newState => doToggle('settings', newState)"
    >
      <collapse-toggle
        class="collapse__header"
        :class="{ 'collapse__header--collapsed': collapsed }"
      >
        Graph Settings <collapse-icon class="collapse-icon" />
      </collapse-toggle>
      <collapse-content
        class="collapse-content"
        :class="{ 'collapse-content--collapsed': collapsed }"
      >
        <div class="panel-content">
          <form-group>
            <form-label :for="`title-${settingsId}`">Graph Title</form-label>
            <text-input
              data-focus
              :id="`title-${settingsId}`"
              v-model="title"
              @change="changeTitle"
            />
          </form-group>
          <form-group>
            <form-label :for="`graphType-${settingsId}`">Graph Type</form-label>
            <select-field
              :id="`graphType-${settingsId}`"
              v-model="graphType"
              @update:modelValue="value => changeGraphType(value)"
            >
              <option v-if="settings.graphTypes?.lineGraphs" value="line">
                Line Graph
              </option>
              <option v-if="settings.graphTypes?.barGraphs" value="bar">
                Bar Graph
              </option>
              <option v-if="settings.graphTypes?.scatterGraphs" value="scatter">
                Scatter Graph
              </option>
            </select-field>
          </form-group>
        </div>
      </collapse-content>
    </collapse-provider>
    <collapse-provider
      v-if="hasCurveFits"
      :collapsed="collapsedSections.curveFits"
      v-slot="{ collapsed }"
      @change="newState => doToggle('curveFits', newState)"
    >
      <collapse-toggle
        class="collapse__header"
        :class="{ 'collapse__header--collapsed': collapsed }"
      >
        Curve Fits <collapse-icon class="collapse-icon" />
      </collapse-toggle>
      <collapse-content
        class="collapse-content"
        :class="{ 'collapse-content--collapsed': collapsed }"
      >
        <div class="panel-content">
          <div class="copy">
            <p v-if="!curveFitDisabled">
              Select a curve fit for to apply to a plotted column.
            </p>
            <p v-else>
              Curve fits are disabled when plotting a text column on the
              horizontal axis.
            </p>
          </div>
          <form-group
            v-for="(selection, selectionIndex) in validVColumns"
            :key="selectionIndex"
          >
            <form-label>
              <latex-block
                v-if="columns[selection.data].name.parser === 'latex'"
                :latex="columns[selection.data].name.formula"
              />
              <template v-else>{{
                columns[selection.data]?.name ?? 'Name'
              }}</template>
            </form-label>
            <select-field
              v-model="selection.curve"
              :aria-describedby="`select curve fit for plotted column ${columnDisplayName(
                columns[selection.data]
              )}`"
              :disabled="curveFitDisabled"
              :data-focus="curveFitDisabled ? null : true"
              @update:modelValue="() => updateCurveFits()"
            >
              <option value="none">No Curve Fit</option>
              <option
                value="proportional"
                v-if="settings.curveFitTypes.proportional"
              >
                Proportional: y = Ax
              </option>
              <option value="linear" v-if="settings.curveFitTypes.linear">
                Linear: y = Ax + B
              </option>
              <option value="square" v-if="settings.curveFitTypes.squareLaw">
                Square Law: y = Ax&sup2;
              </option>
              <option
                value="squareroot"
                v-if="settings.curveFitTypes.squareRoot"
              >
                Square Root: y = A&radic;x
              </option>
              <option value="quadratic" v-if="settings.curveFitTypes.quadratic">
                Quadratic: y = Ax&sup2; + Bx + C
              </option>
              <option
                value="exponential"
                v-if="settings.curveFitTypes.exponential"
              >
                Exponential: y = A e^(Bx);
              </option>
              <option
                value="logarithmic"
                v-if="settings.curveFitTypes.logarithmic"
              >
                Logarithmic: y = A + Bln(x)
              </option>
              <option value="inverse" v-if="settings.curveFitTypes.inverse">
                Inverse: y = A / x
              </option>
              <option
                value="inverse-square"
                v-if="settings.curveFitTypes.inverseSquare"
              >
                Inverse Square: y = A / x&sup2;
              </option>
            </select-field>
          </form-group>
        </div>
      </collapse-content>
    </collapse-provider>
    <collapse-provider
      :collapsed="collapsedSections.horizontalAxis"
      v-slot="{ collapsed }"
      @change="newState => doToggle('horizontalAxis', newState)"
    >
      <collapse-toggle
        class="collapse__header"
        :class="{ 'collapse__header--collapsed': collapsed }"
      >
        Horizontal Axis <collapse-icon class="collapse-icon" />
      </collapse-toggle>
      <collapse-content
        class="collapse-content"
        :class="{ 'collapse-content--collapsed': collapsed }"
      >
        <div class="panel-content">
          <form-group>
            <form-label :for="`dataColumnHAxis-${settingsId}`"
              >Data Column</form-label
            >
            <select-field
              v-model="hSelection.data"
              :id="`dataColumnHAxis-${settingsId}`"
              @update:modelValue="
                value => updateAxis('horizontal', 'data', value)
              "
            >
              <option :value="-1">Select Data Column</option>
              <option
                v-for="(column, columnIndex) in columns"
                :key="columnIndex"
                :value="columnIndex"
              >
                {{ toPlotlyString(columnDisplayName(column)) }}
              </option>
            </select-field>
          </form-group>

          <form-group v-if="settings.allowUncertainty">
            <form-label :for="`uncertaintyHAxis-${settingsId}`"
              >Uncertainty Column</form-label
            >
            <select-field
              v-model="hSelection.uncertainty"
              :id="`uncertaintyHAxis-${settingsId}`"
              :disabled="
                hSelection.data < 0 || columns[hSelection.data]?.allowText
              "
              @update:modelValue="
                value => updateAxis('horizontal', 'uncertainty', value)
              "
            >
              <option :value="-1">No Uncertainty</option>
              <option
                v-for="(column, columnIndex) in columns"
                :key="columnIndex"
                :value="columnIndex"
              >
                {{ columnDisplayName(column) }}
              </option>
            </select-field>
          </form-group>
          <form-group>
            <form-label
              aria-label="Horizontal Axis Title"
              :for="`hAxisTitle-${settingsId}`"
              >Axis Title</form-label
            >
            <text-input
              :id="`hAxisTitle-${settingsId}`"
              v-model="hAxisTitle"
              :placeholder="defaultHorizontalAxisTitle"
              @update:modelValue="
                value => updateAxis('horizontal', 'title', value)
              "
            />
          </form-group>
        </div>
      </collapse-content>
    </collapse-provider>
    <collapse-provider
      :collapsed="collapsedSections.verticalAxis"
      v-slot="{ collapsed }"
      @change="newState => doToggle('verticalAxis', newState)"
    >
      <collapse-toggle
        class="collapse__header"
        :class="{ 'collapse__header--collapsed': collapsed }"
      >
        Vertical Axis <collapse-icon class="collapse-icon" />
      </collapse-toggle>
      <collapse-content
        class="collapse-content"
        :class="{ 'collapse-content--collapsed': collapsed }"
      >
        <div class="panel-content">
          <div class="scrollable">
            <div
              v-for="(selection, selectionIndex) in vSelections"
              :key="selectionIndex"
              class="axis-row"
            >
              <form-group>
                <form-label :for="`dataColumnVertical-${selectionIndex}`"
                  >Data Column {{ selectionIndex + 1 }}</form-label
                >
                <select-field
                  :id="`dataColumnVertical-${selectionIndex}`"
                  v-model="selection.data"
                  :data-focus="selectionIndex === 0 ? true : null"
                  @update:modelValue="
                    value =>
                      updateAxis('vertical', 'data', value, selectionIndex)
                  "
                >
                  <option :value="-1">Select Data Column</option>
                  <option
                    v-for="(column, columnIndex) in columns"
                    :key="columnIndex"
                    :value="columnIndex"
                    :disabled="column.allowText || null"
                  >
                    {{ toPlotlyString(columnDisplayName(column)) }}
                  </option>
                </select-field>
              </form-group>
              <form-group v-if="settings.allowUncertainty">
                <form-label :for="`uncertaintyVAxis-${selectionIndex}`"
                  >Uncertainty Column</form-label
                >
                <select-field
                  v-model="selection.uncertainty"
                  id="`uncertaintyVAxis-${selectionIndex}`"
                  :disabled="
                    selection.data < 0 || columns[selection.data]?.allowText
                  "
                  @update:modelValue="
                    value =>
                      updateAxis(
                        'vertical',
                        'uncertainty',
                        value,
                        selectionIndex
                      )
                  "
                >
                  <option :value="-1">No Uncertainty</option>
                  <option
                    v-for="(column, columnIndex) in columns"
                    :key="columnIndex"
                    :value="columnIndex"
                    :disabled="column.allowText || null"
                  >
                    {{ columnDisplayName(column) }}
                  </option>
                </select-field>
              </form-group>
            </div>
          </div>
          <div class="vaxis-actions">
            <form-button
              link
              v-if="vSelections.length > 1"
              class="row-action"
              href="#"
              @click.prevent="removeVerticalColumns"
              >Remove Columns</form-button
            >
            <form-button
              link
              v-if="vSelections.length < 5"
              class="row-action"
              href="#"
              @click.prevent="addVerticalColumns"
              >Add Columns</form-button
            >
          </div>
          <form-group>
            <form-label
              aria-label="Vertical Axis Title"
              :for="`vAxisTitle-${settingsId}`"
              >Axis Title</form-label
            >
            <text-input
              :id="`vAxisTitle-${settingsId}`"
              v-model="vAxisTitle"
              :placeholder="defaultVerticalAxisTitle"
              @update:modelValue="
                value => updateAxis('vertical', 'title', value)
              "
            />
          </form-group>
        </div>
      </collapse-content>
    </collapse-provider>
    <collapse-provider
      :collapsed="collapsedSections.viewOptions"
      v-slot="{ collapsed }"
      @change="newState => doToggle('viewOptions', newState)"
    >
      <collapse-toggle
        class="collapse__header"
        :class="{ 'collapse__header--collapsed': collapsed }"
      >
        View Options <collapse-icon class="collapse-icon" />
      </collapse-toggle>
      <collapse-content
        class="collapse-content"
        :class="{ 'collapse-content--collapsed': collapsed }"
      >
        <div class="panel-content">
          <form-group>
            <checkbox
              :modelValue="graphDetails.includeOrigin"
              @update:modelValue="toggleIncludeOrigin"
            >
              Include Origin
            </checkbox>
            <checkbox
              :modelValue="graphDetails.showLegend"
              @update:modelValue="toggleShowLegend"
            >
              Show Legend
            </checkbox>
            <form-button link @click="autoscale" class="autoscale-btn">
              Autoscale View
            </form-button>
          </form-group>
        </div>
      </collapse-content>
    </collapse-provider>

    <div class="panel-footer">
      <form-button link destructive @click="resetGraph" class="reset-btn"
        >Reset Graph</form-button
      >
      <form-button
        v-if="canDelete"
        link
        destructive
        @click="() => emit('delete')"
        >Delete Graph</form-button
      >
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed } from 'vue'
import {
  columnDisplayName,
  toPlotlyString,
  defaultGraphVAxisColumns
} from 'src/shared/components/grid-graph/utilities'
import * as Y from 'yjs'
const emit = defineEmits(['update', 'reset', 'clear:openPanel', 'delete'])
const props = defineProps({
  showing: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    required: false
  },
  settings: {
    type: Object,
    required: true
  },
  openPanel: {
    type: String,
    required: false
  },
  curveFitDisabled: {
    type: Boolean,
    default: true
  },
  columns: {
    type: Array,
    required: true
  },
  graphDetails: {
    type: Object,
    required: true
  },
  graphMap: {
    type: Y.Map,
    required: true
  },
  canDelete: {
    type: Boolean,
    default: false
  }
})

const settingsId = (Math.random() + 1).toString(36).substring(7)

const collapsedSections = ref({
  settings: false,
  curveFits: true,
  horizontalAxis: true,
  verticalAxis: true,
  viewOptions: true
})
const graphType = ref(props.graphDetails.type)
const title = ref(props.title)
const hSelection = ref({ ...props.graphDetails.hColumns })
const vSelections = ref(
  props.graphDetails.vColumns.map(selection => ({ ...selection }))
)
const hAxisTitle = ref(props.graphMap.get('hAxisTitle'))
const vAxisTitle = ref(props.graphMap.get('vAxisTitle'))
const validVColumns = computed(() =>
  props.graphDetails.vColumns.filter(column => column.data > -1)
)

const defaultVerticalAxisTitle = computed(() =>
  vSelections.value
    .map(({ data }) => {
      if (data >= 0) {
        const column = props.columns[data]

        return `${column.name.formula ?? column.name}${
          column.units ? ` (${column.units.formula ?? column.units})` : ''
        }`
      }
    })
    .filter(x => !!x)
    .join(', ')
)

const hasCurveFits = computed(() =>
  props.settings.curveFitTypes
    ? Object.keys(props.settings.curveFitTypes).filter(
        key => props.settings.curveFitTypes[key]
      ).length
    : false
)

const defaultHorizontalAxisTitle = computed(() => {
  if (hSelection.value.data >= 0) {
    const column = props.columns[hSelection.value.data]

    return `${column.name.formula ?? column.name}${
      column.units ? ` (${column.units.formula ?? column.units})` : ''
    }`
  }
  return ''
})

const resetGraph = () => {
  hSelection.value = {
    data: -1,
    uncertainty: -1
  }
  vSelections.value = [
    {
      data: -1,
      uncertainty: -1,
      curve: 'none'
    }
  ]
  emit('reset')
}
const doToggle = (key, newState) => {
  if (!newState) {
    //turn others off.
    collapsedSections.value = {
      settings: true,
      curveFits: true,
      horizontalAxis: true,
      verticalAxis: true,
      viewOptions: true,
      [key]: newState
    }
  } else {
    collapsedSections.value = {
      ...collapsedSections.value,
      [key]: newState
    }
  }
}

watch(props, () => {
  if (props.openPanel && props.openPanel !== '') {
    doToggle(props.openPanel, false)
    emit('clear:openPanel')
  }
})

const changeGraphType = value => {
  props.graphMap.doc.transact(() => {
    props.graphMap.set('type', value)
    if (value === 'bar') {
      props.graphMap
        .get('vColumns')
        .forEach(column => column.set('curve', 'none'))
    }
  })
}

const changeTitle = () => {
  props.graphMap.set('title', title.value)
}

const addVerticalColumns = () => {
  vSelections.value = [...vSelections.value, { ...defaultGraphVAxisColumns }]
}
const removeVerticalColumns = () => {
  vSelections.value = [...vSelections.value].splice(-1, 1)
}

const updateAxis = (dir, prop, value, index) => {
  if (dir === 'horizontal') {
    const obj = { ...hSelection.value, [prop]: value }
    props.graphMap.doc.transact(() => {
      props.graphMap.set('hAxisTitle', hAxisTitle.value)
      const hColumns = new Y.Map()
      hColumns.set('data', obj.data)
      hColumns.set('uncertainty', obj.uncertainty)
      props.graphMap.set('hColumns', hColumns)

      emit('update')
    })
  } else {
    props.graphMap.doc.transact(() => {
      props.graphMap.set('vAxisTitle', vAxisTitle.value)
      props.graphMap.set(
        'vColumns',
        Y.Array.from(
          vSelections.value.map(column => {
            const map = new Y.Map()
            map.set('data', column.data ?? -1)
            map.set('uncertainty', column.uncertainty ?? -1)
            map.set('curve', column.curve ?? 'none')
            return map
          })
        )
      )

      emit('update')
    })
  }
}
const updateCurveFits = () => {
  props.graphMap.doc.transact(() => {
    props.graphMap.set(
      'vColumns',
      Y.Array.from(
        props.graphDetails.vColumns.map(column => {
          const map = new Y.Map()
          map.set('data', column.data ?? -1)
          map.set('uncertainty', column.uncertainty ?? -1)
          map.set('curve', column.curve ?? 'none')
          return map
        })
      )
    )
  })
}
const autoscale = () => {
  props.graphMap.set('xAxisRange', [null, null])
  props.graphMap.set('yAxisRange', [null, null])
}

const toggleIncludeOrigin = () => {
  props.graphMap.set('includeOrigin', !props.graphDetails.includeOrigin)
}
const toggleShowLegend = () => {
  props.graphMap.set('showLegend', !props.graphDetails.showLegend)
}
</script>

<style lang="scss" scoped>
$solid-gray: #c3c3c3;
.settings-panel {
  position: absolute;
  visibility: hidden;
  right: -300px;
  top: 0;
  height: 100%;
  width: 300px;
  background: #fff;
  border: 1px solid $solid-gray;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  z-index: 10;
  transition: right 0.5s ease-out;
  &--open {
    visibility: visible;
    right: 0;
  }

  .form-group {
    margin-left: 10px;
    margin-right: 10px;
  }

  .copy {
    padding-left: 10px;
    padding-right: 10px;
  }

  .panel-content {
    height: 380px;
  }

  .panel-footer {
    background-color: #fff;
    bottom: 0;
    position: absolute;
    width: 100%;
    border-top: 1px solid $solid-gray;
    display: flex;
    justify-content: space-between;
    border-bottom-right-radius: 7px;
  }
}

.collapse-content {
  border-bottom: 1px solid $solid-gray;
  &--collapsed {
    border-bottom: none;
  }
}
.collapse__header {
  font-size: 16px;
  text-decoration: none;
  padding: 10px 8px;
  line-height: 18px;

  font-weight: 700;
  .collapse-icon {
    float: right;
    padding-top: 2px;
  }
  &--collapsed {
    border-bottom: 1px solid $solid-gray;
  }
}

.scrollable {
  height: 200px;
  overflow-y: scroll;
}

.vaxis-actions {
  display: flex;
  justify-content: flex-end;
  padding: 10px;

  .row-action {
    margin-left: 10px;
  }
}
</style>
