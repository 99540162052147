<template>
  <div
    ref="popoverAnchorRef"
    @mouseover="openPopover"
    @mouseout="closePopover"
    @focusin="openPopover"
    @focusout="closePopover"
  >
    <base-popover
      :anchor-ref="popoverAnchorRef"
      :visible="popoverVisible"
      placement="top"
      >This cell is pre-populated and cannot be edited
    </base-popover>
    <span>{{ valueForDisplay }}</span>
  </div>
</template>
<script setup lang="ts">
import {
  replaceVariablesWithNames,
  evaluateExpression,
  type VariableConfig,
  formatExpressionResult
} from '@pi/shared/variables'
import { computed, ref } from 'vue'

interface VariableContextConfig {
  variables: VariableConfig[]
  hasStudentResponse?: boolean
}

interface Props {
  expression: string
  readOnly: boolean
  variableContext?: VariableContextConfig
}
const popoverAnchorRef = ref()
const popoverVisible = ref<boolean>(false)
const props = defineProps<Props>()

const closePopover = () => {
  popoverVisible.value = false
}
const openPopover = () => {
  popoverVisible.value = true
}

const valueForDisplay = computed<string>(() => {
  return props.readOnly
    ? formatExpressionResult(
        evaluateExpression(
          props.expression,
          props.variableContext?.variables ?? []
        )
      )
    : replaceVariablesWithNames(
        props.expression,
        props.variableContext?.variables ?? []
      )
})
</script>
