<template>
  <list :items="assignments">
    <sortable-list-column
      v-slot="assignment"
      title="Name"
      :sort="sort.name"
      @change="sort => handleSort('name', sort)"
    >
      <router-link
        v-if="klass.status === 'active' && assignment.status !== 'not-open'"
        :to="{
          name: 'student_response',
          params: { id: assignment._id },
          query: {
            groups: assignment.assignedTo === 'groups'
          }
        }"
      >
        {{ assignment.name }}
      </router-link>
      <span v-else> {{ assignment.name }}</span>
    </sortable-list-column>
    <sortable-list-column
      v-slot="{ startDate }"
      title="Start Date"
      :sort="sort.startDate"
      @change="sort => handleSort('startDate', sort)"
    >
      <span v-if="startDate">
        {{ $format.date(startDate, 'MM/dd/yyyy h:mm a') }}
      </span>
      <span v-else> - </span>
    </sortable-list-column>
    <sortable-list-column
      v-slot="{ endDate, extensionEndDate }"
      title="Due Date"
      :sort="sort.endDate"
      @change="sort => handleSort('endDate', sort)"
    >
      <span v-if="endDate">
        {{
          $format.date(dueDate(endDate, extensionEndDate), 'MM/dd/yyyy h:mm a')
        }}
      </span>
      <span v-else> - </span>
    </sortable-list-column>
    <sortable-list-column
      v-slot="{ status }"
      title="Status"
      :sort="sort.status"
      @change="sort => handleSort('status', sort)"
    >
      <span
        :class="{
          'highlight-status': status === 'feedback'
        }"
      >
        {{ formatStatus(status) }}
      </span>
    </sortable-list-column>
    <sortable-list-column
      v-slot="assignment"
      title="Score"
      :sort="sort.score"
      @change="sort => handleSort('score', sort)"
    >
      {{ formatScore(assignment) }}
    </sortable-list-column>
    <list-column v-slot="assignment" title="Actions">
      <template
        v-if="klass.status === 'active' && assignment.status !== 'not-open'"
      >
        <router-link
          :to="{
            name: 'student_response',
            params: { id: assignment._id },
            query: {
              groups: assignment.assignedTo === 'groups'
            }
          }"
        >
          View
        </router-link>
      </template>
    </list-column>
  </list>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'ClassAssignmentsList',
  props: {
    classId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      sort: {
        status: 'asc'
      }
    }
  },
  async created() {
    await this.getAssignments('status', 'asc')
  },
  computed: {
    ...mapState({
      classes: state => state.classes.classes
    }),
    klass() {
      return this.classes.find(({ _id }) => _id === this.classId)
    },
    assignments() {
      return (this.klass?.assignments ?? []).filter(
        assignment => !assignment.isDeleted && !assignment.isArchived
      )
    }
  },
  methods: {
    ...mapActions(['loadClassAssignments']),
    async handleSort(field, direction) {
      if (direction) {
        this.sort = { [field]: direction }
        await this.getAssignments(field, direction)
      } else {
        this.sort = {}
        await this.getAssignments()
      }
    },
    async getAssignments(field, direction) {
      try {
        await this.loadClassAssignments({
          classId: this.classId,
          field,
          direction
        })
      } catch (error) {
        // Ignore 403 errors and just show an empty list.
        if (error.status !== 403) throw error
      }
    },
    formatStatus(status) {
      switch (status) {
        case 'in-progress':
          return 'In Progress'
        case 'opened':
          return 'Opened'
        case 'not-started':
          return 'Not Started'
        case 'graded':
          return 'Graded'
        case 'submitted':
          return 'Submitted'
        case 'not-open':
          return 'Not Open'
        default:
          return 'N/A'
      }
    },
    formatScore(assignment) {
      if (
        assignment.status === 'graded' ||
        (assignment.status === 'submitted' &&
          assignment.studentFeedbackTiming === 'after-assignment-submit')
      ) {
        return `${(assignment.totalScore * 100).toFixed(2)}%`
      } else {
        return '-'
      }
    },
    dueDate(endDate, extensionEndDate) {
      if (!endDate && extensionEndDate) {
        return extensionEndDate
      }
      if (extensionEndDate && new Date(extensionEndDate) > new Date(endDate)) {
        return extensionEndDate
      } else {
        return endDate
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.highlight-status {
  color: $color-error;
  font-weight: bold;
}
</style>
