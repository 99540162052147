var Qu = Object.defineProperty;
var Ku = (e, t, a) => t in e ? Qu(e, t, { enumerable: !0, configurable: !0, writable: !0, value: a }) : e[t] = a;
var fe = (e, t, a) => (Ku(e, typeof t != "symbol" ? t + "" : t, a), a);
import { defineComponent as _e, openBlock as M, createElementBlock as Y, renderSlot as ie, ref as z, computed as $, watch as ht, h as ea, toRef as tt, useAttrs as zo, mergeProps as Ue, withDirectives as ka, createElementVNode as le, isRef as ia, unref as p, vModelDynamic as Gu, createBlock as Me, createCommentVNode as G, vModelText as Vo, normalizeClass as ge, normalizeStyle as Ge, toDisplayString as Ne, vModelRadio as Ju, inject as $t, nextTick as je, watchEffect as ta, onMounted as He, cloneVNode as Zu, Fragment as Ae, provide as aa, onUnmounted as Bt, toRaw as Nt, withCtx as pe, createVNode as Ee, withModifiers as za, watchPostEffect as ec, createTextVNode as ut, Teleport as Yo, Transition as Ca, renderList as ze, reactive as mn, useSlots as ja, createSlots as bt, normalizeProps as mt, guardReactiveProps as At, resolveDynamicComponent as Mr, render as $i, onBeforeUpdate as tc, vShow as sr, withKeys as ur, getCurrentScope as ac, onScopeDispose as nc, resolveComponent as zl, toHandlers as Bo } from "vue";
import { useIsSubmitting as rc, useField as Ua, ErrorMessage as lc, useForm as ic } from "vee-validate";
const oc = { class: "tw-reset font-bold" }, fh = /* @__PURE__ */ _e({
  name: "UILabel",
  __name: "UILabel",
  setup(e) {
    return (t, a) => (M(), Y("label", oc, [
      ie(t.$slots, "default")
    ]));
  }
}), vh = /* @__PURE__ */ _e({
  __name: "UIForm",
  setup(e, { expose: t }) {
    const a = rc(), n = (i) => {
      a.value && (i.preventDefault(), i.stopImmediatePropagation());
    }, r = z();
    return t({
      requestSubmit() {
        var i;
        (i = r.value) == null || i.requestSubmit();
      }
    }), (i, l) => (M(), Y("form", {
      ref_key: "root",
      ref: r,
      class: "tw-reset",
      novalidate: "",
      onSubmit: n
    }, [
      ie(i.$slots, "default")
    ], 544));
  }
});
function Ii(e, t) {
  var a = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var n = Object.getOwnPropertySymbols(e);
    t && (n = n.filter(function(r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), a.push.apply(a, n);
  }
  return a;
}
function me(e) {
  for (var t = 1; t < arguments.length; t++) {
    var a = arguments[t] != null ? arguments[t] : {};
    t % 2 ? Ii(Object(a), !0).forEach(function(n) {
      ft(e, n, a[n]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(a)) : Ii(Object(a)).forEach(function(n) {
      Object.defineProperty(e, n, Object.getOwnPropertyDescriptor(a, n));
    });
  }
  return e;
}
function fr(e) {
  "@babel/helpers - typeof";
  return fr = typeof Symbol == "function" && typeof Symbol.iterator == "symbol" ? function(t) {
    return typeof t;
  } : function(t) {
    return t && typeof Symbol == "function" && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
  }, fr(e);
}
function sc(e, t) {
  if (!(e instanceof t))
    throw new TypeError("Cannot call a class as a function");
}
function Ei(e, t) {
  for (var a = 0; a < t.length; a++) {
    var n = t[a];
    n.enumerable = n.enumerable || !1, n.configurable = !0, "value" in n && (n.writable = !0), Object.defineProperty(e, n.key, n);
  }
}
function uc(e, t, a) {
  return t && Ei(e.prototype, t), a && Ei(e, a), Object.defineProperty(e, "prototype", {
    writable: !1
  }), e;
}
function ft(e, t, a) {
  return t in e ? Object.defineProperty(e, t, {
    value: a,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[t] = a, e;
}
function Vl(e, t) {
  return dc(e) || vc(e, t) || jo(e, t) || pc();
}
function Ln(e) {
  return cc(e) || fc(e) || jo(e) || mc();
}
function cc(e) {
  if (Array.isArray(e))
    return hl(e);
}
function dc(e) {
  if (Array.isArray(e))
    return e;
}
function fc(e) {
  if (typeof Symbol < "u" && e[Symbol.iterator] != null || e["@@iterator"] != null)
    return Array.from(e);
}
function vc(e, t) {
  var a = e == null ? null : typeof Symbol < "u" && e[Symbol.iterator] || e["@@iterator"];
  if (a != null) {
    var n = [], r = !0, i = !1, l, o;
    try {
      for (a = a.call(e); !(r = (l = a.next()).done) && (n.push(l.value), !(t && n.length === t)); r = !0)
        ;
    } catch (s) {
      i = !0, o = s;
    } finally {
      try {
        !r && a.return != null && a.return();
      } finally {
        if (i)
          throw o;
      }
    }
    return n;
  }
}
function jo(e, t) {
  if (e) {
    if (typeof e == "string")
      return hl(e, t);
    var a = Object.prototype.toString.call(e).slice(8, -1);
    if (a === "Object" && e.constructor && (a = e.constructor.name), a === "Map" || a === "Set")
      return Array.from(e);
    if (a === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(a))
      return hl(e, t);
  }
}
function hl(e, t) {
  (t == null || t > e.length) && (t = e.length);
  for (var a = 0, n = new Array(t); a < t; a++)
    n[a] = e[a];
  return n;
}
function mc() {
  throw new TypeError(`Invalid attempt to spread non-iterable instance.
In order to be iterable, non-array objects must have a [Symbol.iterator]() method.`);
}
function pc() {
  throw new TypeError(`Invalid attempt to destructure non-iterable instance.
In order to be iterable, non-array objects must have a [Symbol.iterator]() method.`);
}
var Ri = function() {
}, Yl = {}, Uo = {}, Wo = null, qo = {
  mark: Ri,
  measure: Ri
};
try {
  typeof window < "u" && (Yl = window), typeof document < "u" && (Uo = document), typeof MutationObserver < "u" && (Wo = MutationObserver), typeof performance < "u" && (qo = performance);
} catch {
}
var hc = Yl.navigator || {}, Ni = hc.userAgent, Li = Ni === void 0 ? "" : Ni, Ma = Yl, Qe = Uo, Hi = Wo, qn = qo;
Ma.document;
var va = !!Qe.documentElement && !!Qe.head && typeof Qe.addEventListener == "function" && typeof Qe.createElement == "function", Xo = ~Li.indexOf("MSIE") || ~Li.indexOf("Trident/"), Xn, Qn, Kn, Gn, Jn, oa = "___FONT_AWESOME___", yl = 16, Qo = "fa", Ko = "svg-inline--fa", Va = "data-fa-i2svg", gl = "data-fa-pseudo-element", yc = "data-fa-pseudo-element-pending", Bl = "data-prefix", jl = "data-icon", Fi = "fontawesome-i2svg", gc = "async", bc = ["HTML", "HEAD", "STYLE", "SCRIPT"], Go = function() {
  try {
    return process.env.NODE_ENV === "production";
  } catch {
    return !1;
  }
}(), Xe = "classic", et = "sharp", Ul = [Xe, et];
function Hn(e) {
  return new Proxy(e, {
    get: function(a, n) {
      return n in a ? a[n] : a[Xe];
    }
  });
}
var Tn = Hn((Xn = {}, ft(Xn, Xe, {
  fa: "solid",
  fas: "solid",
  "fa-solid": "solid",
  far: "regular",
  "fa-regular": "regular",
  fal: "light",
  "fa-light": "light",
  fat: "thin",
  "fa-thin": "thin",
  fad: "duotone",
  "fa-duotone": "duotone",
  fab: "brands",
  "fa-brands": "brands",
  fak: "kit",
  fakd: "kit",
  "fa-kit": "kit",
  "fa-kit-duotone": "kit"
}), ft(Xn, et, {
  fa: "solid",
  fass: "solid",
  "fa-solid": "solid",
  fasr: "regular",
  "fa-regular": "regular",
  fasl: "light",
  "fa-light": "light",
  fast: "thin",
  "fa-thin": "thin"
}), Xn)), On = Hn((Qn = {}, ft(Qn, Xe, {
  solid: "fas",
  regular: "far",
  light: "fal",
  thin: "fat",
  duotone: "fad",
  brands: "fab",
  kit: "fak"
}), ft(Qn, et, {
  solid: "fass",
  regular: "fasr",
  light: "fasl",
  thin: "fast"
}), Qn)), Sn = Hn((Kn = {}, ft(Kn, Xe, {
  fab: "fa-brands",
  fad: "fa-duotone",
  fak: "fa-kit",
  fal: "fa-light",
  far: "fa-regular",
  fas: "fa-solid",
  fat: "fa-thin"
}), ft(Kn, et, {
  fass: "fa-solid",
  fasr: "fa-regular",
  fasl: "fa-light",
  fast: "fa-thin"
}), Kn)), wc = Hn((Gn = {}, ft(Gn, Xe, {
  "fa-brands": "fab",
  "fa-duotone": "fad",
  "fa-kit": "fak",
  "fa-light": "fal",
  "fa-regular": "far",
  "fa-solid": "fas",
  "fa-thin": "fat"
}), ft(Gn, et, {
  "fa-solid": "fass",
  "fa-regular": "fasr",
  "fa-light": "fasl",
  "fa-thin": "fast"
}), Gn)), kc = /fa(s|r|l|t|d|b|k|ss|sr|sl|st)?[\-\ ]/, Jo = "fa-layers-text", xc = /Font ?Awesome ?([56 ]*)(Solid|Regular|Light|Thin|Duotone|Brands|Free|Pro|Sharp|Kit)?.*/i, _c = Hn((Jn = {}, ft(Jn, Xe, {
  900: "fas",
  400: "far",
  normal: "far",
  300: "fal",
  100: "fat"
}), ft(Jn, et, {
  900: "fass",
  400: "fasr",
  300: "fasl",
  100: "fast"
}), Jn)), Zo = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], Mc = Zo.concat([11, 12, 13, 14, 15, 16, 17, 18, 19, 20]), Pc = ["class", "data-prefix", "data-icon", "data-fa-transform", "data-fa-mask"], Ra = {
  GROUP: "duotone-group",
  SWAP_OPACITY: "swap-opacity",
  PRIMARY: "primary",
  SECONDARY: "secondary"
}, Cn = /* @__PURE__ */ new Set();
Object.keys(On[Xe]).map(Cn.add.bind(Cn));
Object.keys(On[et]).map(Cn.add.bind(Cn));
var Ac = [].concat(Ul, Ln(Cn), ["2xs", "xs", "sm", "lg", "xl", "2xl", "beat", "border", "fade", "beat-fade", "bounce", "flip-both", "flip-horizontal", "flip-vertical", "flip", "fw", "inverse", "layers-counter", "layers-text", "layers", "li", "pull-left", "pull-right", "pulse", "rotate-180", "rotate-270", "rotate-90", "rotate-by", "shake", "spin-pulse", "spin-reverse", "spin", "stack-1x", "stack-2x", "stack", "ul", Ra.GROUP, Ra.SWAP_OPACITY, Ra.PRIMARY, Ra.SECONDARY]).concat(Zo.map(function(e) {
  return "".concat(e, "x");
})).concat(Mc.map(function(e) {
  return "w-".concat(e);
})), xn = Ma.FontAwesomeConfig || {};
function Tc(e) {
  var t = Qe.querySelector("script[" + e + "]");
  if (t)
    return t.getAttribute(e);
}
function Oc(e) {
  return e === "" ? !0 : e === "false" ? !1 : e === "true" ? !0 : e;
}
if (Qe && typeof Qe.querySelector == "function") {
  var Sc = [["data-family-prefix", "familyPrefix"], ["data-css-prefix", "cssPrefix"], ["data-family-default", "familyDefault"], ["data-style-default", "styleDefault"], ["data-replacement-class", "replacementClass"], ["data-auto-replace-svg", "autoReplaceSvg"], ["data-auto-add-css", "autoAddCss"], ["data-auto-a11y", "autoA11y"], ["data-search-pseudo-elements", "searchPseudoElements"], ["data-observe-mutations", "observeMutations"], ["data-mutate-approach", "mutateApproach"], ["data-keep-original-source", "keepOriginalSource"], ["data-measure-performance", "measurePerformance"], ["data-show-missing-icons", "showMissingIcons"]];
  Sc.forEach(function(e) {
    var t = Vl(e, 2), a = t[0], n = t[1], r = Oc(Tc(a));
    r != null && (xn[n] = r);
  });
}
var es = {
  styleDefault: "solid",
  familyDefault: "classic",
  cssPrefix: Qo,
  replacementClass: Ko,
  autoReplaceSvg: !0,
  autoAddCss: !0,
  autoA11y: !0,
  searchPseudoElements: !1,
  observeMutations: !0,
  mutateApproach: "async",
  keepOriginalSource: !0,
  measurePerformance: !1,
  showMissingIcons: !0
};
xn.familyPrefix && (xn.cssPrefix = xn.familyPrefix);
var rn = me(me({}, es), xn);
rn.autoReplaceSvg || (rn.observeMutations = !1);
var he = {};
Object.keys(es).forEach(function(e) {
  Object.defineProperty(he, e, {
    enumerable: !0,
    set: function(a) {
      rn[e] = a, _n.forEach(function(n) {
        return n(he);
      });
    },
    get: function() {
      return rn[e];
    }
  });
});
Object.defineProperty(he, "familyPrefix", {
  enumerable: !0,
  set: function(t) {
    rn.cssPrefix = t, _n.forEach(function(a) {
      return a(he);
    });
  },
  get: function() {
    return rn.cssPrefix;
  }
});
Ma.FontAwesomeConfig = he;
var _n = [];
function Cc(e) {
  return _n.push(e), function() {
    _n.splice(_n.indexOf(e), 1);
  };
}
var ha = yl, Gt = {
  size: 16,
  x: 0,
  y: 0,
  rotate: 0,
  flipX: !1,
  flipY: !1
};
function Dc(e) {
  if (!(!e || !va)) {
    var t = Qe.createElement("style");
    t.setAttribute("type", "text/css"), t.innerHTML = e;
    for (var a = Qe.head.childNodes, n = null, r = a.length - 1; r > -1; r--) {
      var i = a[r], l = (i.tagName || "").toUpperCase();
      ["STYLE", "LINK"].indexOf(l) > -1 && (n = i);
    }
    return Qe.head.insertBefore(t, n), e;
  }
}
var $c = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
function Dn() {
  for (var e = 12, t = ""; e-- > 0; )
    t += $c[Math.random() * 62 | 0];
  return t;
}
function pn(e) {
  for (var t = [], a = (e || []).length >>> 0; a--; )
    t[a] = e[a];
  return t;
}
function Wl(e) {
  return e.classList ? pn(e.classList) : (e.getAttribute("class") || "").split(" ").filter(function(t) {
    return t;
  });
}
function ts(e) {
  return "".concat(e).replace(/&/g, "&amp;").replace(/"/g, "&quot;").replace(/'/g, "&#39;").replace(/</g, "&lt;").replace(/>/g, "&gt;");
}
function Ic(e) {
  return Object.keys(e || {}).reduce(function(t, a) {
    return t + "".concat(a, '="').concat(ts(e[a]), '" ');
  }, "").trim();
}
function Pr(e) {
  return Object.keys(e || {}).reduce(function(t, a) {
    return t + "".concat(a, ": ").concat(e[a].trim(), ";");
  }, "");
}
function ql(e) {
  return e.size !== Gt.size || e.x !== Gt.x || e.y !== Gt.y || e.rotate !== Gt.rotate || e.flipX || e.flipY;
}
function Ec(e) {
  var t = e.transform, a = e.containerWidth, n = e.iconWidth, r = {
    transform: "translate(".concat(a / 2, " 256)")
  }, i = "translate(".concat(t.x * 32, ", ").concat(t.y * 32, ") "), l = "scale(".concat(t.size / 16 * (t.flipX ? -1 : 1), ", ").concat(t.size / 16 * (t.flipY ? -1 : 1), ") "), o = "rotate(".concat(t.rotate, " 0 0)"), s = {
    transform: "".concat(i, " ").concat(l, " ").concat(o)
  }, d = {
    transform: "translate(".concat(n / 2 * -1, " -256)")
  };
  return {
    outer: r,
    inner: s,
    path: d
  };
}
function Rc(e) {
  var t = e.transform, a = e.width, n = a === void 0 ? yl : a, r = e.height, i = r === void 0 ? yl : r, l = e.startCentered, o = l === void 0 ? !1 : l, s = "";
  return o && Xo ? s += "translate(".concat(t.x / ha - n / 2, "em, ").concat(t.y / ha - i / 2, "em) ") : o ? s += "translate(calc(-50% + ".concat(t.x / ha, "em), calc(-50% + ").concat(t.y / ha, "em)) ") : s += "translate(".concat(t.x / ha, "em, ").concat(t.y / ha, "em) "), s += "scale(".concat(t.size / ha * (t.flipX ? -1 : 1), ", ").concat(t.size / ha * (t.flipY ? -1 : 1), ") "), s += "rotate(".concat(t.rotate, "deg) "), s;
}
var Nc = `:root, :host {
  --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Solid";
  --fa-font-regular: normal 400 1em/1 "Font Awesome 6 Regular";
  --fa-font-light: normal 300 1em/1 "Font Awesome 6 Light";
  --fa-font-thin: normal 100 1em/1 "Font Awesome 6 Thin";
  --fa-font-duotone: normal 900 1em/1 "Font Awesome 6 Duotone";
  --fa-font-sharp-solid: normal 900 1em/1 "Font Awesome 6 Sharp";
  --fa-font-sharp-regular: normal 400 1em/1 "Font Awesome 6 Sharp";
  --fa-font-sharp-light: normal 300 1em/1 "Font Awesome 6 Sharp";
  --fa-font-sharp-thin: normal 100 1em/1 "Font Awesome 6 Sharp";
  --fa-font-brands: normal 400 1em/1 "Font Awesome 6 Brands";
}

svg:not(:root).svg-inline--fa, svg:not(:host).svg-inline--fa {
  overflow: visible;
  box-sizing: content-box;
}

.svg-inline--fa {
  display: var(--fa-display, inline-block);
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
.svg-inline--fa.fa-2xs {
  vertical-align: 0.1em;
}
.svg-inline--fa.fa-xs {
  vertical-align: 0em;
}
.svg-inline--fa.fa-sm {
  vertical-align: -0.0714285705em;
}
.svg-inline--fa.fa-lg {
  vertical-align: -0.2em;
}
.svg-inline--fa.fa-xl {
  vertical-align: -0.25em;
}
.svg-inline--fa.fa-2xl {
  vertical-align: -0.3125em;
}
.svg-inline--fa.fa-pull-left {
  margin-right: var(--fa-pull-margin, 0.3em);
  width: auto;
}
.svg-inline--fa.fa-pull-right {
  margin-left: var(--fa-pull-margin, 0.3em);
  width: auto;
}
.svg-inline--fa.fa-li {
  width: var(--fa-li-width, 2em);
  top: 0.25em;
}
.svg-inline--fa.fa-fw {
  width: var(--fa-fw-width, 1.25em);
}

.fa-layers svg.svg-inline--fa {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
}

.fa-layers-counter, .fa-layers-text {
  display: inline-block;
  position: absolute;
  text-align: center;
}

.fa-layers {
  display: inline-block;
  height: 1em;
  position: relative;
  text-align: center;
  vertical-align: -0.125em;
  width: 1em;
}
.fa-layers svg.svg-inline--fa {
  -webkit-transform-origin: center center;
          transform-origin: center center;
}

.fa-layers-text {
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transform-origin: center center;
          transform-origin: center center;
}

.fa-layers-counter {
  background-color: var(--fa-counter-background-color, #ff253a);
  border-radius: var(--fa-counter-border-radius, 1em);
  box-sizing: border-box;
  color: var(--fa-inverse, #fff);
  line-height: var(--fa-counter-line-height, 1);
  max-width: var(--fa-counter-max-width, 5em);
  min-width: var(--fa-counter-min-width, 1.5em);
  overflow: hidden;
  padding: var(--fa-counter-padding, 0.25em 0.5em);
  right: var(--fa-right, 0);
  text-overflow: ellipsis;
  top: var(--fa-top, 0);
  -webkit-transform: scale(var(--fa-counter-scale, 0.25));
          transform: scale(var(--fa-counter-scale, 0.25));
  -webkit-transform-origin: top right;
          transform-origin: top right;
}

.fa-layers-bottom-right {
  bottom: var(--fa-bottom, 0);
  right: var(--fa-right, 0);
  top: auto;
  -webkit-transform: scale(var(--fa-layers-scale, 0.25));
          transform: scale(var(--fa-layers-scale, 0.25));
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
}

.fa-layers-bottom-left {
  bottom: var(--fa-bottom, 0);
  left: var(--fa-left, 0);
  right: auto;
  top: auto;
  -webkit-transform: scale(var(--fa-layers-scale, 0.25));
          transform: scale(var(--fa-layers-scale, 0.25));
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
}

.fa-layers-top-right {
  top: var(--fa-top, 0);
  right: var(--fa-right, 0);
  -webkit-transform: scale(var(--fa-layers-scale, 0.25));
          transform: scale(var(--fa-layers-scale, 0.25));
  -webkit-transform-origin: top right;
          transform-origin: top right;
}

.fa-layers-top-left {
  left: var(--fa-left, 0);
  right: auto;
  top: var(--fa-top, 0);
  -webkit-transform: scale(var(--fa-layers-scale, 0.25));
          transform: scale(var(--fa-layers-scale, 0.25));
  -webkit-transform-origin: top left;
          transform-origin: top left;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-2xs {
  font-size: 0.625em;
  line-height: 0.1em;
  vertical-align: 0.225em;
}

.fa-xs {
  font-size: 0.75em;
  line-height: 0.0833333337em;
  vertical-align: 0.125em;
}

.fa-sm {
  font-size: 0.875em;
  line-height: 0.0714285718em;
  vertical-align: 0.0535714295em;
}

.fa-lg {
  font-size: 1.25em;
  line-height: 0.05em;
  vertical-align: -0.075em;
}

.fa-xl {
  font-size: 1.5em;
  line-height: 0.0416666682em;
  vertical-align: -0.125em;
}

.fa-2xl {
  font-size: 2em;
  line-height: 0.03125em;
  vertical-align: -0.1875em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  list-style-type: none;
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0;
}
.fa-ul > li {
  position: relative;
}

.fa-li {
  left: calc(var(--fa-li-width, 2em) * -1);
  position: absolute;
  text-align: center;
  width: var(--fa-li-width, 2em);
  line-height: inherit;
}

.fa-border {
  border-color: var(--fa-border-color, #eee);
  border-radius: var(--fa-border-radius, 0.1em);
  border-style: var(--fa-border-style, solid);
  border-width: var(--fa-border-width, 0.08em);
  padding: var(--fa-border-padding, 0.2em 0.25em 0.15em);
}

.fa-pull-left {
  float: left;
  margin-right: var(--fa-pull-margin, 0.3em);
}

.fa-pull-right {
  float: right;
  margin-left: var(--fa-pull-margin, 0.3em);
}

.fa-beat {
  -webkit-animation-name: fa-beat;
          animation-name: fa-beat;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
          animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-bounce {
  -webkit-animation-name: fa-bounce;
          animation-name: fa-bounce;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1));
          animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1));
}

.fa-fade {
  -webkit-animation-name: fa-fade;
          animation-name: fa-fade;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
          animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
}

.fa-beat-fade {
  -webkit-animation-name: fa-beat-fade;
          animation-name: fa-beat-fade;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
          animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
}

.fa-flip {
  -webkit-animation-name: fa-flip;
          animation-name: fa-flip;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
          animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-shake {
  -webkit-animation-name: fa-shake;
          animation-name: fa-shake;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
          animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin {
  -webkit-animation-name: fa-spin;
          animation-name: fa-spin;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 2s);
          animation-duration: var(--fa-animation-duration, 2s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
          animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin-reverse {
  --fa-animation-direction: reverse;
}

.fa-pulse,
.fa-spin-pulse {
  -webkit-animation-name: fa-spin;
          animation-name: fa-spin;
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, steps(8));
          animation-timing-function: var(--fa-animation-timing, steps(8));
}

@media (prefers-reduced-motion: reduce) {
  .fa-beat,
.fa-bounce,
.fa-fade,
.fa-beat-fade,
.fa-flip,
.fa-pulse,
.fa-shake,
.fa-spin,
.fa-spin-pulse {
    -webkit-animation-delay: -1ms;
            animation-delay: -1ms;
    -webkit-animation-duration: 1ms;
            animation-duration: 1ms;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-transition-delay: 0s;
            transition-delay: 0s;
    -webkit-transition-duration: 0s;
            transition-duration: 0s;
  }
}
@-webkit-keyframes fa-beat {
  0%, 90% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  45% {
    -webkit-transform: scale(var(--fa-beat-scale, 1.25));
            transform: scale(var(--fa-beat-scale, 1.25));
  }
}
@keyframes fa-beat {
  0%, 90% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  45% {
    -webkit-transform: scale(var(--fa-beat-scale, 1.25));
            transform: scale(var(--fa-beat-scale, 1.25));
  }
}
@-webkit-keyframes fa-bounce {
  0% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0);
  }
  10% {
    -webkit-transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
            transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
  }
  30% {
    -webkit-transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
            transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
  }
  50% {
    -webkit-transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
            transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
  }
  57% {
    -webkit-transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em));
            transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em));
  }
  64% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0);
  }
  100% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0);
  }
}
@keyframes fa-bounce {
  0% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0);
  }
  10% {
    -webkit-transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
            transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
  }
  30% {
    -webkit-transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
            transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
  }
  50% {
    -webkit-transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
            transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
  }
  57% {
    -webkit-transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em));
            transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em));
  }
  64% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0);
  }
  100% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0);
  }
}
@-webkit-keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, 0.4);
  }
}
@keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, 0.4);
  }
}
@-webkit-keyframes fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, 0.4);
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(var(--fa-beat-fade-scale, 1.125));
            transform: scale(var(--fa-beat-fade-scale, 1.125));
  }
}
@keyframes fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, 0.4);
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(var(--fa-beat-fade-scale, 1.125));
            transform: scale(var(--fa-beat-fade-scale, 1.125));
  }
}
@-webkit-keyframes fa-flip {
  50% {
    -webkit-transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
            transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
  }
}
@keyframes fa-flip {
  50% {
    -webkit-transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
            transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
  }
}
@-webkit-keyframes fa-shake {
  0% {
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg);
  }
  4% {
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg);
  }
  8%, 24% {
    -webkit-transform: rotate(-18deg);
            transform: rotate(-18deg);
  }
  12%, 28% {
    -webkit-transform: rotate(18deg);
            transform: rotate(18deg);
  }
  16% {
    -webkit-transform: rotate(-22deg);
            transform: rotate(-22deg);
  }
  20% {
    -webkit-transform: rotate(22deg);
            transform: rotate(22deg);
  }
  32% {
    -webkit-transform: rotate(-12deg);
            transform: rotate(-12deg);
  }
  36% {
    -webkit-transform: rotate(12deg);
            transform: rotate(12deg);
  }
  40%, 100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
@keyframes fa-shake {
  0% {
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg);
  }
  4% {
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg);
  }
  8%, 24% {
    -webkit-transform: rotate(-18deg);
            transform: rotate(-18deg);
  }
  12%, 28% {
    -webkit-transform: rotate(18deg);
            transform: rotate(18deg);
  }
  16% {
    -webkit-transform: rotate(-22deg);
            transform: rotate(-22deg);
  }
  20% {
    -webkit-transform: rotate(22deg);
            transform: rotate(22deg);
  }
  32% {
    -webkit-transform: rotate(-12deg);
            transform: rotate(-12deg);
  }
  36% {
    -webkit-transform: rotate(12deg);
            transform: rotate(12deg);
  }
  40%, 100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.fa-rotate-90 {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.fa-rotate-180 {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.fa-rotate-270 {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}

.fa-flip-horizontal {
  -webkit-transform: scale(-1, 1);
          transform: scale(-1, 1);
}

.fa-flip-vertical {
  -webkit-transform: scale(1, -1);
          transform: scale(1, -1);
}

.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  -webkit-transform: scale(-1, -1);
          transform: scale(-1, -1);
}

.fa-rotate-by {
  -webkit-transform: rotate(var(--fa-rotate-angle, none));
          transform: rotate(var(--fa-rotate-angle, none));
}

.fa-stack {
  display: inline-block;
  vertical-align: middle;
  height: 2em;
  position: relative;
  width: 2.5em;
}

.fa-stack-1x,
.fa-stack-2x {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  z-index: var(--fa-stack-z-index, auto);
}

.svg-inline--fa.fa-stack-1x {
  height: 1em;
  width: 1.25em;
}
.svg-inline--fa.fa-stack-2x {
  height: 2em;
  width: 2.5em;
}

.fa-inverse {
  color: var(--fa-inverse, #fff);
}

.sr-only,
.fa-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.sr-only-focusable:not(:focus),
.fa-sr-only-focusable:not(:focus) {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.svg-inline--fa .fa-primary {
  fill: var(--fa-primary-color, currentColor);
  opacity: var(--fa-primary-opacity, 1);
}

.svg-inline--fa .fa-secondary {
  fill: var(--fa-secondary-color, currentColor);
  opacity: var(--fa-secondary-opacity, 0.4);
}

.svg-inline--fa.fa-swap-opacity .fa-primary {
  opacity: var(--fa-secondary-opacity, 0.4);
}

.svg-inline--fa.fa-swap-opacity .fa-secondary {
  opacity: var(--fa-primary-opacity, 1);
}

.svg-inline--fa mask .fa-primary,
.svg-inline--fa mask .fa-secondary {
  fill: black;
}

.fad.fa-inverse,
.fa-duotone.fa-inverse {
  color: var(--fa-inverse, #fff);
}`;
function as() {
  var e = Qo, t = Ko, a = he.cssPrefix, n = he.replacementClass, r = Nc;
  if (a !== e || n !== t) {
    var i = new RegExp("\\.".concat(e, "\\-"), "g"), l = new RegExp("\\--".concat(e, "\\-"), "g"), o = new RegExp("\\.".concat(t), "g");
    r = r.replace(i, ".".concat(a, "-")).replace(l, "--".concat(a, "-")).replace(o, ".".concat(n));
  }
  return r;
}
var zi = !1;
function Gr() {
  he.autoAddCss && !zi && (Dc(as()), zi = !0);
}
var Lc = {
  mixout: function() {
    return {
      dom: {
        css: as,
        insertCss: Gr
      }
    };
  },
  hooks: function() {
    return {
      beforeDOMElementCreation: function() {
        Gr();
      },
      beforeI2svg: function() {
        Gr();
      }
    };
  }
}, sa = Ma || {};
sa[oa] || (sa[oa] = {});
sa[oa].styles || (sa[oa].styles = {});
sa[oa].hooks || (sa[oa].hooks = {});
sa[oa].shims || (sa[oa].shims = []);
var zt = sa[oa], ns = [], Hc = function e() {
  Qe.removeEventListener("DOMContentLoaded", e), vr = 1, ns.map(function(t) {
    return t();
  });
}, vr = !1;
va && (vr = (Qe.documentElement.doScroll ? /^loaded|^c/ : /^loaded|^i|^c/).test(Qe.readyState), vr || Qe.addEventListener("DOMContentLoaded", Hc));
function Fc(e) {
  va && (vr ? setTimeout(e, 0) : ns.push(e));
}
function Fn(e) {
  var t = e.tag, a = e.attributes, n = a === void 0 ? {} : a, r = e.children, i = r === void 0 ? [] : r;
  return typeof e == "string" ? ts(e) : "<".concat(t, " ").concat(Ic(n), ">").concat(i.map(Fn).join(""), "</").concat(t, ">");
}
function Vi(e, t, a) {
  if (e && e[t] && e[t][a])
    return {
      prefix: t,
      iconName: a,
      icon: e[t][a]
    };
}
var zc = function(t, a) {
  return function(n, r, i, l) {
    return t.call(a, n, r, i, l);
  };
}, Jr = function(t, a, n, r) {
  var i = Object.keys(t), l = i.length, o = r !== void 0 ? zc(a, r) : a, s, d, u;
  for (n === void 0 ? (s = 1, u = t[i[0]]) : (s = 0, u = n); s < l; s++)
    d = i[s], u = o(u, t[d], d, t);
  return u;
};
function Vc(e) {
  for (var t = [], a = 0, n = e.length; a < n; ) {
    var r = e.charCodeAt(a++);
    if (r >= 55296 && r <= 56319 && a < n) {
      var i = e.charCodeAt(a++);
      (i & 64512) == 56320 ? t.push(((r & 1023) << 10) + (i & 1023) + 65536) : (t.push(r), a--);
    } else
      t.push(r);
  }
  return t;
}
function bl(e) {
  var t = Vc(e);
  return t.length === 1 ? t[0].toString(16) : null;
}
function Yc(e, t) {
  var a = e.length, n = e.charCodeAt(t), r;
  return n >= 55296 && n <= 56319 && a > t + 1 && (r = e.charCodeAt(t + 1), r >= 56320 && r <= 57343) ? (n - 55296) * 1024 + r - 56320 + 65536 : n;
}
function Yi(e) {
  return Object.keys(e).reduce(function(t, a) {
    var n = e[a], r = !!n.icon;
    return r ? t[n.iconName] = n.icon : t[a] = n, t;
  }, {});
}
function wl(e, t) {
  var a = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, n = a.skipHooks, r = n === void 0 ? !1 : n, i = Yi(t);
  typeof zt.hooks.addPack == "function" && !r ? zt.hooks.addPack(e, Yi(t)) : zt.styles[e] = me(me({}, zt.styles[e] || {}), i), e === "fas" && wl("fa", t);
}
var Zn, er, tr, Ga = zt.styles, Bc = zt.shims, jc = (Zn = {}, ft(Zn, Xe, Object.values(Sn[Xe])), ft(Zn, et, Object.values(Sn[et])), Zn), Xl = null, rs = {}, ls = {}, is = {}, os = {}, ss = {}, Uc = (er = {}, ft(er, Xe, Object.keys(Tn[Xe])), ft(er, et, Object.keys(Tn[et])), er);
function Wc(e) {
  return ~Ac.indexOf(e);
}
function qc(e, t) {
  var a = t.split("-"), n = a[0], r = a.slice(1).join("-");
  return n === e && r !== "" && !Wc(r) ? r : null;
}
var us = function() {
  var t = function(i) {
    return Jr(Ga, function(l, o, s) {
      return l[s] = Jr(o, i, {}), l;
    }, {});
  };
  rs = t(function(r, i, l) {
    if (i[3] && (r[i[3]] = l), i[2]) {
      var o = i[2].filter(function(s) {
        return typeof s == "number";
      });
      o.forEach(function(s) {
        r[s.toString(16)] = l;
      });
    }
    return r;
  }), ls = t(function(r, i, l) {
    if (r[l] = l, i[2]) {
      var o = i[2].filter(function(s) {
        return typeof s == "string";
      });
      o.forEach(function(s) {
        r[s] = l;
      });
    }
    return r;
  }), ss = t(function(r, i, l) {
    var o = i[2];
    return r[l] = l, o.forEach(function(s) {
      r[s] = l;
    }), r;
  });
  var a = "far" in Ga || he.autoFetchSvg, n = Jr(Bc, function(r, i) {
    var l = i[0], o = i[1], s = i[2];
    return o === "far" && !a && (o = "fas"), typeof l == "string" && (r.names[l] = {
      prefix: o,
      iconName: s
    }), typeof l == "number" && (r.unicodes[l.toString(16)] = {
      prefix: o,
      iconName: s
    }), r;
  }, {
    names: {},
    unicodes: {}
  });
  is = n.names, os = n.unicodes, Xl = Ar(he.styleDefault, {
    family: he.familyDefault
  });
};
Cc(function(e) {
  Xl = Ar(e.styleDefault, {
    family: he.familyDefault
  });
});
us();
function Ql(e, t) {
  return (rs[e] || {})[t];
}
function Xc(e, t) {
  return (ls[e] || {})[t];
}
function Na(e, t) {
  return (ss[e] || {})[t];
}
function cs(e) {
  return is[e] || {
    prefix: null,
    iconName: null
  };
}
function Qc(e) {
  var t = os[e], a = Ql("fas", e);
  return t || (a ? {
    prefix: "fas",
    iconName: a
  } : null) || {
    prefix: null,
    iconName: null
  };
}
function Pa() {
  return Xl;
}
var Kl = function() {
  return {
    prefix: null,
    iconName: null,
    rest: []
  };
};
function Ar(e) {
  var t = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, a = t.family, n = a === void 0 ? Xe : a, r = Tn[n][e], i = On[n][e] || On[n][r], l = e in zt.styles ? e : null;
  return i || l || null;
}
var Bi = (tr = {}, ft(tr, Xe, Object.keys(Sn[Xe])), ft(tr, et, Object.keys(Sn[et])), tr);
function Tr(e) {
  var t, a = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, n = a.skipLookups, r = n === void 0 ? !1 : n, i = (t = {}, ft(t, Xe, "".concat(he.cssPrefix, "-").concat(Xe)), ft(t, et, "".concat(he.cssPrefix, "-").concat(et)), t), l = null, o = Xe;
  (e.includes(i[Xe]) || e.some(function(d) {
    return Bi[Xe].includes(d);
  })) && (o = Xe), (e.includes(i[et]) || e.some(function(d) {
    return Bi[et].includes(d);
  })) && (o = et);
  var s = e.reduce(function(d, u) {
    var c = qc(he.cssPrefix, u);
    if (Ga[u] ? (u = jc[o].includes(u) ? wc[o][u] : u, l = u, d.prefix = u) : Uc[o].indexOf(u) > -1 ? (l = u, d.prefix = Ar(u, {
      family: o
    })) : c ? d.iconName = c : u !== he.replacementClass && u !== i[Xe] && u !== i[et] && d.rest.push(u), !r && d.prefix && d.iconName) {
      var f = l === "fa" ? cs(d.iconName) : {}, v = Na(d.prefix, d.iconName);
      f.prefix && (l = null), d.iconName = f.iconName || v || d.iconName, d.prefix = f.prefix || d.prefix, d.prefix === "far" && !Ga.far && Ga.fas && !he.autoFetchSvg && (d.prefix = "fas");
    }
    return d;
  }, Kl());
  return (e.includes("fa-brands") || e.includes("fab")) && (s.prefix = "fab"), (e.includes("fa-duotone") || e.includes("fad")) && (s.prefix = "fad"), !s.prefix && o === et && (Ga.fass || he.autoFetchSvg) && (s.prefix = "fass", s.iconName = Na(s.prefix, s.iconName) || s.iconName), (s.prefix === "fa" || l === "fa") && (s.prefix = Pa() || "fas"), s;
}
var Kc = /* @__PURE__ */ function() {
  function e() {
    sc(this, e), this.definitions = {};
  }
  return uc(e, [{
    key: "add",
    value: function() {
      for (var a = this, n = arguments.length, r = new Array(n), i = 0; i < n; i++)
        r[i] = arguments[i];
      var l = r.reduce(this._pullDefinitions, {});
      Object.keys(l).forEach(function(o) {
        a.definitions[o] = me(me({}, a.definitions[o] || {}), l[o]), wl(o, l[o]);
        var s = Sn[Xe][o];
        s && wl(s, l[o]), us();
      });
    }
  }, {
    key: "reset",
    value: function() {
      this.definitions = {};
    }
  }, {
    key: "_pullDefinitions",
    value: function(a, n) {
      var r = n.prefix && n.iconName && n.icon ? {
        0: n
      } : n;
      return Object.keys(r).map(function(i) {
        var l = r[i], o = l.prefix, s = l.iconName, d = l.icon, u = d[2];
        a[o] || (a[o] = {}), u.length > 0 && u.forEach(function(c) {
          typeof c == "string" && (a[o][c] = d);
        }), a[o][s] = d;
      }), a;
    }
  }]), e;
}(), ji = [], Ja = {}, tn = {}, Gc = Object.keys(tn);
function Jc(e, t) {
  var a = t.mixoutsTo;
  return ji = e, Ja = {}, Object.keys(tn).forEach(function(n) {
    Gc.indexOf(n) === -1 && delete tn[n];
  }), ji.forEach(function(n) {
    var r = n.mixout ? n.mixout() : {};
    if (Object.keys(r).forEach(function(l) {
      typeof r[l] == "function" && (a[l] = r[l]), fr(r[l]) === "object" && Object.keys(r[l]).forEach(function(o) {
        a[l] || (a[l] = {}), a[l][o] = r[l][o];
      });
    }), n.hooks) {
      var i = n.hooks();
      Object.keys(i).forEach(function(l) {
        Ja[l] || (Ja[l] = []), Ja[l].push(i[l]);
      });
    }
    n.provides && n.provides(tn);
  }), a;
}
function kl(e, t) {
  for (var a = arguments.length, n = new Array(a > 2 ? a - 2 : 0), r = 2; r < a; r++)
    n[r - 2] = arguments[r];
  var i = Ja[e] || [];
  return i.forEach(function(l) {
    t = l.apply(null, [t].concat(n));
  }), t;
}
function Ya(e) {
  for (var t = arguments.length, a = new Array(t > 1 ? t - 1 : 0), n = 1; n < t; n++)
    a[n - 1] = arguments[n];
  var r = Ja[e] || [];
  r.forEach(function(i) {
    i.apply(null, a);
  });
}
function ua() {
  var e = arguments[0], t = Array.prototype.slice.call(arguments, 1);
  return tn[e] ? tn[e].apply(null, t) : void 0;
}
function xl(e) {
  e.prefix === "fa" && (e.prefix = "fas");
  var t = e.iconName, a = e.prefix || Pa();
  if (t)
    return t = Na(a, t) || t, Vi(ds.definitions, a, t) || Vi(zt.styles, a, t);
}
var ds = new Kc(), Zc = function() {
  he.autoReplaceSvg = !1, he.observeMutations = !1, Ya("noAuto");
}, ed = {
  i2svg: function() {
    var t = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    return va ? (Ya("beforeI2svg", t), ua("pseudoElements2svg", t), ua("i2svg", t)) : Promise.reject("Operation requires a DOM of some kind.");
  },
  watch: function() {
    var t = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {}, a = t.autoReplaceSvgRoot;
    he.autoReplaceSvg === !1 && (he.autoReplaceSvg = !0), he.observeMutations = !0, Fc(function() {
      ad({
        autoReplaceSvgRoot: a
      }), Ya("watch", t);
    });
  }
}, td = {
  icon: function(t) {
    if (t === null)
      return null;
    if (fr(t) === "object" && t.prefix && t.iconName)
      return {
        prefix: t.prefix,
        iconName: Na(t.prefix, t.iconName) || t.iconName
      };
    if (Array.isArray(t) && t.length === 2) {
      var a = t[1].indexOf("fa-") === 0 ? t[1].slice(3) : t[1], n = Ar(t[0]);
      return {
        prefix: n,
        iconName: Na(n, a) || a
      };
    }
    if (typeof t == "string" && (t.indexOf("".concat(he.cssPrefix, "-")) > -1 || t.match(kc))) {
      var r = Tr(t.split(" "), {
        skipLookups: !0
      });
      return {
        prefix: r.prefix || Pa(),
        iconName: Na(r.prefix, r.iconName) || r.iconName
      };
    }
    if (typeof t == "string") {
      var i = Pa();
      return {
        prefix: i,
        iconName: Na(i, t) || t
      };
    }
  }
}, Ct = {
  noAuto: Zc,
  config: he,
  dom: ed,
  parse: td,
  library: ds,
  findIconDefinition: xl,
  toHtml: Fn
}, ad = function() {
  var t = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {}, a = t.autoReplaceSvgRoot, n = a === void 0 ? Qe : a;
  (Object.keys(zt.styles).length > 0 || he.autoFetchSvg) && va && he.autoReplaceSvg && Ct.dom.i2svg({
    node: n
  });
};
function Or(e, t) {
  return Object.defineProperty(e, "abstract", {
    get: t
  }), Object.defineProperty(e, "html", {
    get: function() {
      return e.abstract.map(function(n) {
        return Fn(n);
      });
    }
  }), Object.defineProperty(e, "node", {
    get: function() {
      if (va) {
        var n = Qe.createElement("div");
        return n.innerHTML = e.html, n.children;
      }
    }
  }), e;
}
function nd(e) {
  var t = e.children, a = e.main, n = e.mask, r = e.attributes, i = e.styles, l = e.transform;
  if (ql(l) && a.found && !n.found) {
    var o = a.width, s = a.height, d = {
      x: o / s / 2,
      y: 0.5
    };
    r.style = Pr(me(me({}, i), {}, {
      "transform-origin": "".concat(d.x + l.x / 16, "em ").concat(d.y + l.y / 16, "em")
    }));
  }
  return [{
    tag: "svg",
    attributes: r,
    children: t
  }];
}
function rd(e) {
  var t = e.prefix, a = e.iconName, n = e.children, r = e.attributes, i = e.symbol, l = i === !0 ? "".concat(t, "-").concat(he.cssPrefix, "-").concat(a) : i;
  return [{
    tag: "svg",
    attributes: {
      style: "display: none;"
    },
    children: [{
      tag: "symbol",
      attributes: me(me({}, r), {}, {
        id: l
      }),
      children: n
    }]
  }];
}
function Gl(e) {
  var t = e.icons, a = t.main, n = t.mask, r = e.prefix, i = e.iconName, l = e.transform, o = e.symbol, s = e.title, d = e.maskId, u = e.titleId, c = e.extra, f = e.watchable, v = f === void 0 ? !1 : f, g = n.found ? n : a, y = g.width, P = g.height, b = r === "fak", w = [he.replacementClass, i ? "".concat(he.cssPrefix, "-").concat(i) : ""].filter(function(L) {
    return c.classes.indexOf(L) === -1;
  }).filter(function(L) {
    return L !== "" || !!L;
  }).concat(c.classes).join(" "), h = {
    children: [],
    attributes: me(me({}, c.attributes), {}, {
      "data-prefix": r,
      "data-icon": i,
      class: w,
      role: c.attributes.role || "img",
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 ".concat(y, " ").concat(P)
    })
  }, _ = b && !~c.classes.indexOf("fa-fw") ? {
    width: "".concat(y / P * 16 * 0.0625, "em")
  } : {};
  v && (h.attributes[Va] = ""), s && (h.children.push({
    tag: "title",
    attributes: {
      id: h.attributes["aria-labelledby"] || "title-".concat(u || Dn())
    },
    children: [s]
  }), delete h.attributes.title);
  var C = me(me({}, h), {}, {
    prefix: r,
    iconName: i,
    main: a,
    mask: n,
    maskId: d,
    transform: l,
    symbol: o,
    styles: me(me({}, _), c.styles)
  }), A = n.found && a.found ? ua("generateAbstractMask", C) || {
    children: [],
    attributes: {}
  } : ua("generateAbstractIcon", C) || {
    children: [],
    attributes: {}
  }, I = A.children, V = A.attributes;
  return C.children = I, C.attributes = V, o ? rd(C) : nd(C);
}
function Ui(e) {
  var t = e.content, a = e.width, n = e.height, r = e.transform, i = e.title, l = e.extra, o = e.watchable, s = o === void 0 ? !1 : o, d = me(me(me({}, l.attributes), i ? {
    title: i
  } : {}), {}, {
    class: l.classes.join(" ")
  });
  s && (d[Va] = "");
  var u = me({}, l.styles);
  ql(r) && (u.transform = Rc({
    transform: r,
    startCentered: !0,
    width: a,
    height: n
  }), u["-webkit-transform"] = u.transform);
  var c = Pr(u);
  c.length > 0 && (d.style = c);
  var f = [];
  return f.push({
    tag: "span",
    attributes: d,
    children: [t]
  }), i && f.push({
    tag: "span",
    attributes: {
      class: "sr-only"
    },
    children: [i]
  }), f;
}
function ld(e) {
  var t = e.content, a = e.title, n = e.extra, r = me(me(me({}, n.attributes), a ? {
    title: a
  } : {}), {}, {
    class: n.classes.join(" ")
  }), i = Pr(n.styles);
  i.length > 0 && (r.style = i);
  var l = [];
  return l.push({
    tag: "span",
    attributes: r,
    children: [t]
  }), a && l.push({
    tag: "span",
    attributes: {
      class: "sr-only"
    },
    children: [a]
  }), l;
}
var Zr = zt.styles;
function _l(e) {
  var t = e[0], a = e[1], n = e.slice(4), r = Vl(n, 1), i = r[0], l = null;
  return Array.isArray(i) ? l = {
    tag: "g",
    attributes: {
      class: "".concat(he.cssPrefix, "-").concat(Ra.GROUP)
    },
    children: [{
      tag: "path",
      attributes: {
        class: "".concat(he.cssPrefix, "-").concat(Ra.SECONDARY),
        fill: "currentColor",
        d: i[0]
      }
    }, {
      tag: "path",
      attributes: {
        class: "".concat(he.cssPrefix, "-").concat(Ra.PRIMARY),
        fill: "currentColor",
        d: i[1]
      }
    }]
  } : l = {
    tag: "path",
    attributes: {
      fill: "currentColor",
      d: i
    }
  }, {
    found: !0,
    width: t,
    height: a,
    icon: l
  };
}
var id = {
  found: !1,
  width: 512,
  height: 512
};
function od(e, t) {
  !Go && !he.showMissingIcons && e && console.error('Icon with name "'.concat(e, '" and prefix "').concat(t, '" is missing.'));
}
function Ml(e, t) {
  var a = t;
  return t === "fa" && he.styleDefault !== null && (t = Pa()), new Promise(function(n, r) {
    if (ua("missingIconAbstract"), a === "fa") {
      var i = cs(e) || {};
      e = i.iconName || e, t = i.prefix || t;
    }
    if (e && t && Zr[t] && Zr[t][e]) {
      var l = Zr[t][e];
      return n(_l(l));
    }
    od(e, t), n(me(me({}, id), {}, {
      icon: he.showMissingIcons && e ? ua("missingIconAbstract") || {} : {}
    }));
  });
}
var Wi = function() {
}, Pl = he.measurePerformance && qn && qn.mark && qn.measure ? qn : {
  mark: Wi,
  measure: Wi
}, kn = 'FA "6.5.1"', sd = function(t) {
  return Pl.mark("".concat(kn, " ").concat(t, " begins")), function() {
    return fs(t);
  };
}, fs = function(t) {
  Pl.mark("".concat(kn, " ").concat(t, " ends")), Pl.measure("".concat(kn, " ").concat(t), "".concat(kn, " ").concat(t, " begins"), "".concat(kn, " ").concat(t, " ends"));
}, Jl = {
  begin: sd,
  end: fs
}, cr = function() {
};
function qi(e) {
  var t = e.getAttribute ? e.getAttribute(Va) : null;
  return typeof t == "string";
}
function ud(e) {
  var t = e.getAttribute ? e.getAttribute(Bl) : null, a = e.getAttribute ? e.getAttribute(jl) : null;
  return t && a;
}
function cd(e) {
  return e && e.classList && e.classList.contains && e.classList.contains(he.replacementClass);
}
function dd() {
  if (he.autoReplaceSvg === !0)
    return dr.replace;
  var e = dr[he.autoReplaceSvg];
  return e || dr.replace;
}
function fd(e) {
  return Qe.createElementNS("http://www.w3.org/2000/svg", e);
}
function vd(e) {
  return Qe.createElement(e);
}
function vs(e) {
  var t = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, a = t.ceFn, n = a === void 0 ? e.tag === "svg" ? fd : vd : a;
  if (typeof e == "string")
    return Qe.createTextNode(e);
  var r = n(e.tag);
  Object.keys(e.attributes || []).forEach(function(l) {
    r.setAttribute(l, e.attributes[l]);
  });
  var i = e.children || [];
  return i.forEach(function(l) {
    r.appendChild(vs(l, {
      ceFn: n
    }));
  }), r;
}
function md(e) {
  var t = " ".concat(e.outerHTML, " ");
  return t = "".concat(t, "Font Awesome fontawesome.com "), t;
}
var dr = {
  replace: function(t) {
    var a = t[0];
    if (a.parentNode)
      if (t[1].forEach(function(r) {
        a.parentNode.insertBefore(vs(r), a);
      }), a.getAttribute(Va) === null && he.keepOriginalSource) {
        var n = Qe.createComment(md(a));
        a.parentNode.replaceChild(n, a);
      } else
        a.remove();
  },
  nest: function(t) {
    var a = t[0], n = t[1];
    if (~Wl(a).indexOf(he.replacementClass))
      return dr.replace(t);
    var r = new RegExp("".concat(he.cssPrefix, "-.*"));
    if (delete n[0].attributes.id, n[0].attributes.class) {
      var i = n[0].attributes.class.split(" ").reduce(function(o, s) {
        return s === he.replacementClass || s.match(r) ? o.toSvg.push(s) : o.toNode.push(s), o;
      }, {
        toNode: [],
        toSvg: []
      });
      n[0].attributes.class = i.toSvg.join(" "), i.toNode.length === 0 ? a.removeAttribute("class") : a.setAttribute("class", i.toNode.join(" "));
    }
    var l = n.map(function(o) {
      return Fn(o);
    }).join(`
`);
    a.setAttribute(Va, ""), a.innerHTML = l;
  }
};
function Xi(e) {
  e();
}
function ms(e, t) {
  var a = typeof t == "function" ? t : cr;
  if (e.length === 0)
    a();
  else {
    var n = Xi;
    he.mutateApproach === gc && (n = Ma.requestAnimationFrame || Xi), n(function() {
      var r = dd(), i = Jl.begin("mutate");
      e.map(r), i(), a();
    });
  }
}
var Zl = !1;
function ps() {
  Zl = !0;
}
function Al() {
  Zl = !1;
}
var mr = null;
function Qi(e) {
  if (Hi && he.observeMutations) {
    var t = e.treeCallback, a = t === void 0 ? cr : t, n = e.nodeCallback, r = n === void 0 ? cr : n, i = e.pseudoElementsCallback, l = i === void 0 ? cr : i, o = e.observeMutationsRoot, s = o === void 0 ? Qe : o;
    mr = new Hi(function(d) {
      if (!Zl) {
        var u = Pa();
        pn(d).forEach(function(c) {
          if (c.type === "childList" && c.addedNodes.length > 0 && !qi(c.addedNodes[0]) && (he.searchPseudoElements && l(c.target), a(c.target)), c.type === "attributes" && c.target.parentNode && he.searchPseudoElements && l(c.target.parentNode), c.type === "attributes" && qi(c.target) && ~Pc.indexOf(c.attributeName))
            if (c.attributeName === "class" && ud(c.target)) {
              var f = Tr(Wl(c.target)), v = f.prefix, g = f.iconName;
              c.target.setAttribute(Bl, v || u), g && c.target.setAttribute(jl, g);
            } else
              cd(c.target) && r(c.target);
        });
      }
    }), va && mr.observe(s, {
      childList: !0,
      attributes: !0,
      characterData: !0,
      subtree: !0
    });
  }
}
function pd() {
  mr && mr.disconnect();
}
function hd(e) {
  var t = e.getAttribute("style"), a = [];
  return t && (a = t.split(";").reduce(function(n, r) {
    var i = r.split(":"), l = i[0], o = i.slice(1);
    return l && o.length > 0 && (n[l] = o.join(":").trim()), n;
  }, {})), a;
}
function yd(e) {
  var t = e.getAttribute("data-prefix"), a = e.getAttribute("data-icon"), n = e.innerText !== void 0 ? e.innerText.trim() : "", r = Tr(Wl(e));
  return r.prefix || (r.prefix = Pa()), t && a && (r.prefix = t, r.iconName = a), r.iconName && r.prefix || (r.prefix && n.length > 0 && (r.iconName = Xc(r.prefix, e.innerText) || Ql(r.prefix, bl(e.innerText))), !r.iconName && he.autoFetchSvg && e.firstChild && e.firstChild.nodeType === Node.TEXT_NODE && (r.iconName = e.firstChild.data)), r;
}
function gd(e) {
  var t = pn(e.attributes).reduce(function(r, i) {
    return r.name !== "class" && r.name !== "style" && (r[i.name] = i.value), r;
  }, {}), a = e.getAttribute("title"), n = e.getAttribute("data-fa-title-id");
  return he.autoA11y && (a ? t["aria-labelledby"] = "".concat(he.replacementClass, "-title-").concat(n || Dn()) : (t["aria-hidden"] = "true", t.focusable = "false")), t;
}
function bd() {
  return {
    iconName: null,
    title: null,
    titleId: null,
    prefix: null,
    transform: Gt,
    symbol: !1,
    mask: {
      iconName: null,
      prefix: null,
      rest: []
    },
    maskId: null,
    extra: {
      classes: [],
      styles: {},
      attributes: {}
    }
  };
}
function Ki(e) {
  var t = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {
    styleParser: !0
  }, a = yd(e), n = a.iconName, r = a.prefix, i = a.rest, l = gd(e), o = kl("parseNodeAttributes", {}, e), s = t.styleParser ? hd(e) : [];
  return me({
    iconName: n,
    title: e.getAttribute("title"),
    titleId: e.getAttribute("data-fa-title-id"),
    prefix: r,
    transform: Gt,
    mask: {
      iconName: null,
      prefix: null,
      rest: []
    },
    maskId: null,
    symbol: !1,
    extra: {
      classes: i,
      styles: s,
      attributes: l
    }
  }, o);
}
var wd = zt.styles;
function hs(e) {
  var t = he.autoReplaceSvg === "nest" ? Ki(e, {
    styleParser: !1
  }) : Ki(e);
  return ~t.extra.classes.indexOf(Jo) ? ua("generateLayersText", e, t) : ua("generateSvgReplacementMutation", e, t);
}
var Aa = /* @__PURE__ */ new Set();
Ul.map(function(e) {
  Aa.add("fa-".concat(e));
});
Object.keys(Tn[Xe]).map(Aa.add.bind(Aa));
Object.keys(Tn[et]).map(Aa.add.bind(Aa));
Aa = Ln(Aa);
function Gi(e) {
  var t = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : null;
  if (!va)
    return Promise.resolve();
  var a = Qe.documentElement.classList, n = function(c) {
    return a.add("".concat(Fi, "-").concat(c));
  }, r = function(c) {
    return a.remove("".concat(Fi, "-").concat(c));
  }, i = he.autoFetchSvg ? Aa : Ul.map(function(u) {
    return "fa-".concat(u);
  }).concat(Object.keys(wd));
  i.includes("fa") || i.push("fa");
  var l = [".".concat(Jo, ":not([").concat(Va, "])")].concat(i.map(function(u) {
    return ".".concat(u, ":not([").concat(Va, "])");
  })).join(", ");
  if (l.length === 0)
    return Promise.resolve();
  var o = [];
  try {
    o = pn(e.querySelectorAll(l));
  } catch {
  }
  if (o.length > 0)
    n("pending"), r("complete");
  else
    return Promise.resolve();
  var s = Jl.begin("onTree"), d = o.reduce(function(u, c) {
    try {
      var f = hs(c);
      f && u.push(f);
    } catch (v) {
      Go || v.name === "MissingIcon" && console.error(v);
    }
    return u;
  }, []);
  return new Promise(function(u, c) {
    Promise.all(d).then(function(f) {
      ms(f, function() {
        n("active"), n("complete"), r("pending"), typeof t == "function" && t(), s(), u();
      });
    }).catch(function(f) {
      s(), c(f);
    });
  });
}
function kd(e) {
  var t = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : null;
  hs(e).then(function(a) {
    a && ms([a], t);
  });
}
function xd(e) {
  return function(t) {
    var a = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, n = (t || {}).icon ? t : xl(t || {}), r = a.mask;
    return r && (r = (r || {}).icon ? r : xl(r || {})), e(n, me(me({}, a), {}, {
      mask: r
    }));
  };
}
var _d = function(t) {
  var a = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, n = a.transform, r = n === void 0 ? Gt : n, i = a.symbol, l = i === void 0 ? !1 : i, o = a.mask, s = o === void 0 ? null : o, d = a.maskId, u = d === void 0 ? null : d, c = a.title, f = c === void 0 ? null : c, v = a.titleId, g = v === void 0 ? null : v, y = a.classes, P = y === void 0 ? [] : y, b = a.attributes, w = b === void 0 ? {} : b, h = a.styles, _ = h === void 0 ? {} : h;
  if (t) {
    var C = t.prefix, A = t.iconName, I = t.icon;
    return Or(me({
      type: "icon"
    }, t), function() {
      return Ya("beforeDOMElementCreation", {
        iconDefinition: t,
        params: a
      }), he.autoA11y && (f ? w["aria-labelledby"] = "".concat(he.replacementClass, "-title-").concat(g || Dn()) : (w["aria-hidden"] = "true", w.focusable = "false")), Gl({
        icons: {
          main: _l(I),
          mask: s ? _l(s.icon) : {
            found: !1,
            width: null,
            height: null,
            icon: {}
          }
        },
        prefix: C,
        iconName: A,
        transform: me(me({}, Gt), r),
        symbol: l,
        title: f,
        maskId: u,
        titleId: g,
        extra: {
          attributes: w,
          styles: _,
          classes: P
        }
      });
    });
  }
}, Md = {
  mixout: function() {
    return {
      icon: xd(_d)
    };
  },
  hooks: function() {
    return {
      mutationObserverCallbacks: function(a) {
        return a.treeCallback = Gi, a.nodeCallback = kd, a;
      }
    };
  },
  provides: function(t) {
    t.i2svg = function(a) {
      var n = a.node, r = n === void 0 ? Qe : n, i = a.callback, l = i === void 0 ? function() {
      } : i;
      return Gi(r, l);
    }, t.generateSvgReplacementMutation = function(a, n) {
      var r = n.iconName, i = n.title, l = n.titleId, o = n.prefix, s = n.transform, d = n.symbol, u = n.mask, c = n.maskId, f = n.extra;
      return new Promise(function(v, g) {
        Promise.all([Ml(r, o), u.iconName ? Ml(u.iconName, u.prefix) : Promise.resolve({
          found: !1,
          width: 512,
          height: 512,
          icon: {}
        })]).then(function(y) {
          var P = Vl(y, 2), b = P[0], w = P[1];
          v([a, Gl({
            icons: {
              main: b,
              mask: w
            },
            prefix: o,
            iconName: r,
            transform: s,
            symbol: d,
            maskId: c,
            title: i,
            titleId: l,
            extra: f,
            watchable: !0
          })]);
        }).catch(g);
      });
    }, t.generateAbstractIcon = function(a) {
      var n = a.children, r = a.attributes, i = a.main, l = a.transform, o = a.styles, s = Pr(o);
      s.length > 0 && (r.style = s);
      var d;
      return ql(l) && (d = ua("generateAbstractTransformGrouping", {
        main: i,
        transform: l,
        containerWidth: i.width,
        iconWidth: i.width
      })), n.push(d || i.icon), {
        children: n,
        attributes: r
      };
    };
  }
}, Pd = {
  mixout: function() {
    return {
      layer: function(a) {
        var n = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, r = n.classes, i = r === void 0 ? [] : r;
        return Or({
          type: "layer"
        }, function() {
          Ya("beforeDOMElementCreation", {
            assembler: a,
            params: n
          });
          var l = [];
          return a(function(o) {
            Array.isArray(o) ? o.map(function(s) {
              l = l.concat(s.abstract);
            }) : l = l.concat(o.abstract);
          }), [{
            tag: "span",
            attributes: {
              class: ["".concat(he.cssPrefix, "-layers")].concat(Ln(i)).join(" ")
            },
            children: l
          }];
        });
      }
    };
  }
}, Ad = {
  mixout: function() {
    return {
      counter: function(a) {
        var n = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, r = n.title, i = r === void 0 ? null : r, l = n.classes, o = l === void 0 ? [] : l, s = n.attributes, d = s === void 0 ? {} : s, u = n.styles, c = u === void 0 ? {} : u;
        return Or({
          type: "counter",
          content: a
        }, function() {
          return Ya("beforeDOMElementCreation", {
            content: a,
            params: n
          }), ld({
            content: a.toString(),
            title: i,
            extra: {
              attributes: d,
              styles: c,
              classes: ["".concat(he.cssPrefix, "-layers-counter")].concat(Ln(o))
            }
          });
        });
      }
    };
  }
}, Td = {
  mixout: function() {
    return {
      text: function(a) {
        var n = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, r = n.transform, i = r === void 0 ? Gt : r, l = n.title, o = l === void 0 ? null : l, s = n.classes, d = s === void 0 ? [] : s, u = n.attributes, c = u === void 0 ? {} : u, f = n.styles, v = f === void 0 ? {} : f;
        return Or({
          type: "text",
          content: a
        }, function() {
          return Ya("beforeDOMElementCreation", {
            content: a,
            params: n
          }), Ui({
            content: a,
            transform: me(me({}, Gt), i),
            title: o,
            extra: {
              attributes: c,
              styles: v,
              classes: ["".concat(he.cssPrefix, "-layers-text")].concat(Ln(d))
            }
          });
        });
      }
    };
  },
  provides: function(t) {
    t.generateLayersText = function(a, n) {
      var r = n.title, i = n.transform, l = n.extra, o = null, s = null;
      if (Xo) {
        var d = parseInt(getComputedStyle(a).fontSize, 10), u = a.getBoundingClientRect();
        o = u.width / d, s = u.height / d;
      }
      return he.autoA11y && !r && (l.attributes["aria-hidden"] = "true"), Promise.resolve([a, Ui({
        content: a.innerHTML,
        width: o,
        height: s,
        transform: i,
        title: r,
        extra: l,
        watchable: !0
      })]);
    };
  }
}, Od = new RegExp('"', "ug"), Ji = [1105920, 1112319];
function Sd(e) {
  var t = e.replace(Od, ""), a = Yc(t, 0), n = a >= Ji[0] && a <= Ji[1], r = t.length === 2 ? t[0] === t[1] : !1;
  return {
    value: bl(r ? t[0] : t),
    isSecondary: n || r
  };
}
function Zi(e, t) {
  var a = "".concat(yc).concat(t.replace(":", "-"));
  return new Promise(function(n, r) {
    if (e.getAttribute(a) !== null)
      return n();
    var i = pn(e.children), l = i.filter(function(I) {
      return I.getAttribute(gl) === t;
    })[0], o = Ma.getComputedStyle(e, t), s = o.getPropertyValue("font-family").match(xc), d = o.getPropertyValue("font-weight"), u = o.getPropertyValue("content");
    if (l && !s)
      return e.removeChild(l), n();
    if (s && u !== "none" && u !== "") {
      var c = o.getPropertyValue("content"), f = ~["Sharp"].indexOf(s[2]) ? et : Xe, v = ~["Solid", "Regular", "Light", "Thin", "Duotone", "Brands", "Kit"].indexOf(s[2]) ? On[f][s[2].toLowerCase()] : _c[f][d], g = Sd(c), y = g.value, P = g.isSecondary, b = s[0].startsWith("FontAwesome"), w = Ql(v, y), h = w;
      if (b) {
        var _ = Qc(y);
        _.iconName && _.prefix && (w = _.iconName, v = _.prefix);
      }
      if (w && !P && (!l || l.getAttribute(Bl) !== v || l.getAttribute(jl) !== h)) {
        e.setAttribute(a, h), l && e.removeChild(l);
        var C = bd(), A = C.extra;
        A.attributes[gl] = t, Ml(w, v).then(function(I) {
          var V = Gl(me(me({}, C), {}, {
            icons: {
              main: I,
              mask: Kl()
            },
            prefix: v,
            iconName: h,
            extra: A,
            watchable: !0
          })), L = Qe.createElementNS("http://www.w3.org/2000/svg", "svg");
          t === "::before" ? e.insertBefore(L, e.firstChild) : e.appendChild(L), L.outerHTML = V.map(function(F) {
            return Fn(F);
          }).join(`
`), e.removeAttribute(a), n();
        }).catch(r);
      } else
        n();
    } else
      n();
  });
}
function Cd(e) {
  return Promise.all([Zi(e, "::before"), Zi(e, "::after")]);
}
function Dd(e) {
  return e.parentNode !== document.head && !~bc.indexOf(e.tagName.toUpperCase()) && !e.getAttribute(gl) && (!e.parentNode || e.parentNode.tagName !== "svg");
}
function eo(e) {
  if (va)
    return new Promise(function(t, a) {
      var n = pn(e.querySelectorAll("*")).filter(Dd).map(Cd), r = Jl.begin("searchPseudoElements");
      ps(), Promise.all(n).then(function() {
        r(), Al(), t();
      }).catch(function() {
        r(), Al(), a();
      });
    });
}
var $d = {
  hooks: function() {
    return {
      mutationObserverCallbacks: function(a) {
        return a.pseudoElementsCallback = eo, a;
      }
    };
  },
  provides: function(t) {
    t.pseudoElements2svg = function(a) {
      var n = a.node, r = n === void 0 ? Qe : n;
      he.searchPseudoElements && eo(r);
    };
  }
}, to = !1, Id = {
  mixout: function() {
    return {
      dom: {
        unwatch: function() {
          ps(), to = !0;
        }
      }
    };
  },
  hooks: function() {
    return {
      bootstrap: function() {
        Qi(kl("mutationObserverCallbacks", {}));
      },
      noAuto: function() {
        pd();
      },
      watch: function(a) {
        var n = a.observeMutationsRoot;
        to ? Al() : Qi(kl("mutationObserverCallbacks", {
          observeMutationsRoot: n
        }));
      }
    };
  }
}, ao = function(t) {
  var a = {
    size: 16,
    x: 0,
    y: 0,
    flipX: !1,
    flipY: !1,
    rotate: 0
  };
  return t.toLowerCase().split(" ").reduce(function(n, r) {
    var i = r.toLowerCase().split("-"), l = i[0], o = i.slice(1).join("-");
    if (l && o === "h")
      return n.flipX = !0, n;
    if (l && o === "v")
      return n.flipY = !0, n;
    if (o = parseFloat(o), isNaN(o))
      return n;
    switch (l) {
      case "grow":
        n.size = n.size + o;
        break;
      case "shrink":
        n.size = n.size - o;
        break;
      case "left":
        n.x = n.x - o;
        break;
      case "right":
        n.x = n.x + o;
        break;
      case "up":
        n.y = n.y - o;
        break;
      case "down":
        n.y = n.y + o;
        break;
      case "rotate":
        n.rotate = n.rotate + o;
        break;
    }
    return n;
  }, a);
}, Ed = {
  mixout: function() {
    return {
      parse: {
        transform: function(a) {
          return ao(a);
        }
      }
    };
  },
  hooks: function() {
    return {
      parseNodeAttributes: function(a, n) {
        var r = n.getAttribute("data-fa-transform");
        return r && (a.transform = ao(r)), a;
      }
    };
  },
  provides: function(t) {
    t.generateAbstractTransformGrouping = function(a) {
      var n = a.main, r = a.transform, i = a.containerWidth, l = a.iconWidth, o = {
        transform: "translate(".concat(i / 2, " 256)")
      }, s = "translate(".concat(r.x * 32, ", ").concat(r.y * 32, ") "), d = "scale(".concat(r.size / 16 * (r.flipX ? -1 : 1), ", ").concat(r.size / 16 * (r.flipY ? -1 : 1), ") "), u = "rotate(".concat(r.rotate, " 0 0)"), c = {
        transform: "".concat(s, " ").concat(d, " ").concat(u)
      }, f = {
        transform: "translate(".concat(l / 2 * -1, " -256)")
      }, v = {
        outer: o,
        inner: c,
        path: f
      };
      return {
        tag: "g",
        attributes: me({}, v.outer),
        children: [{
          tag: "g",
          attributes: me({}, v.inner),
          children: [{
            tag: n.icon.tag,
            children: n.icon.children,
            attributes: me(me({}, n.icon.attributes), v.path)
          }]
        }]
      };
    };
  }
}, el = {
  x: 0,
  y: 0,
  width: "100%",
  height: "100%"
};
function no(e) {
  var t = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : !0;
  return e.attributes && (e.attributes.fill || t) && (e.attributes.fill = "black"), e;
}
function Rd(e) {
  return e.tag === "g" ? e.children : [e];
}
var Nd = {
  hooks: function() {
    return {
      parseNodeAttributes: function(a, n) {
        var r = n.getAttribute("data-fa-mask"), i = r ? Tr(r.split(" ").map(function(l) {
          return l.trim();
        })) : Kl();
        return i.prefix || (i.prefix = Pa()), a.mask = i, a.maskId = n.getAttribute("data-fa-mask-id"), a;
      }
    };
  },
  provides: function(t) {
    t.generateAbstractMask = function(a) {
      var n = a.children, r = a.attributes, i = a.main, l = a.mask, o = a.maskId, s = a.transform, d = i.width, u = i.icon, c = l.width, f = l.icon, v = Ec({
        transform: s,
        containerWidth: c,
        iconWidth: d
      }), g = {
        tag: "rect",
        attributes: me(me({}, el), {}, {
          fill: "white"
        })
      }, y = u.children ? {
        children: u.children.map(no)
      } : {}, P = {
        tag: "g",
        attributes: me({}, v.inner),
        children: [no(me({
          tag: u.tag,
          attributes: me(me({}, u.attributes), v.path)
        }, y))]
      }, b = {
        tag: "g",
        attributes: me({}, v.outer),
        children: [P]
      }, w = "mask-".concat(o || Dn()), h = "clip-".concat(o || Dn()), _ = {
        tag: "mask",
        attributes: me(me({}, el), {}, {
          id: w,
          maskUnits: "userSpaceOnUse",
          maskContentUnits: "userSpaceOnUse"
        }),
        children: [g, b]
      }, C = {
        tag: "defs",
        children: [{
          tag: "clipPath",
          attributes: {
            id: h
          },
          children: Rd(f)
        }, _]
      };
      return n.push(C, {
        tag: "rect",
        attributes: me({
          fill: "currentColor",
          "clip-path": "url(#".concat(h, ")"),
          mask: "url(#".concat(w, ")")
        }, el)
      }), {
        children: n,
        attributes: r
      };
    };
  }
}, Ld = {
  provides: function(t) {
    var a = !1;
    Ma.matchMedia && (a = Ma.matchMedia("(prefers-reduced-motion: reduce)").matches), t.missingIconAbstract = function() {
      var n = [], r = {
        fill: "currentColor"
      }, i = {
        attributeType: "XML",
        repeatCount: "indefinite",
        dur: "2s"
      };
      n.push({
        tag: "path",
        attributes: me(me({}, r), {}, {
          d: "M156.5,447.7l-12.6,29.5c-18.7-9.5-35.9-21.2-51.5-34.9l22.7-22.7C127.6,430.5,141.5,440,156.5,447.7z M40.6,272H8.5 c1.4,21.2,5.4,41.7,11.7,61.1L50,321.2C45.1,305.5,41.8,289,40.6,272z M40.6,240c1.4-18.8,5.2-37,11.1-54.1l-29.5-12.6 C14.7,194.3,10,216.7,8.5,240H40.6z M64.3,156.5c7.8-14.9,17.2-28.8,28.1-41.5L69.7,92.3c-13.7,15.6-25.5,32.8-34.9,51.5 L64.3,156.5z M397,419.6c-13.9,12-29.4,22.3-46.1,30.4l11.9,29.8c20.7-9.9,39.8-22.6,56.9-37.6L397,419.6z M115,92.4 c13.9-12,29.4-22.3,46.1-30.4l-11.9-29.8c-20.7,9.9-39.8,22.6-56.8,37.6L115,92.4z M447.7,355.5c-7.8,14.9-17.2,28.8-28.1,41.5 l22.7,22.7c13.7-15.6,25.5-32.9,34.9-51.5L447.7,355.5z M471.4,272c-1.4,18.8-5.2,37-11.1,54.1l29.5,12.6 c7.5-21.1,12.2-43.5,13.6-66.8H471.4z M321.2,462c-15.7,5-32.2,8.2-49.2,9.4v32.1c21.2-1.4,41.7-5.4,61.1-11.7L321.2,462z M240,471.4c-18.8-1.4-37-5.2-54.1-11.1l-12.6,29.5c21.1,7.5,43.5,12.2,66.8,13.6V471.4z M462,190.8c5,15.7,8.2,32.2,9.4,49.2h32.1 c-1.4-21.2-5.4-41.7-11.7-61.1L462,190.8z M92.4,397c-12-13.9-22.3-29.4-30.4-46.1l-29.8,11.9c9.9,20.7,22.6,39.8,37.6,56.9 L92.4,397z M272,40.6c18.8,1.4,36.9,5.2,54.1,11.1l12.6-29.5C317.7,14.7,295.3,10,272,8.5V40.6z M190.8,50 c15.7-5,32.2-8.2,49.2-9.4V8.5c-21.2,1.4-41.7,5.4-61.1,11.7L190.8,50z M442.3,92.3L419.6,115c12,13.9,22.3,29.4,30.5,46.1 l29.8-11.9C470,128.5,457.3,109.4,442.3,92.3z M397,92.4l22.7-22.7c-15.6-13.7-32.8-25.5-51.5-34.9l-12.6,29.5 C370.4,72.1,384.4,81.5,397,92.4z"
        })
      });
      var l = me(me({}, i), {}, {
        attributeName: "opacity"
      }), o = {
        tag: "circle",
        attributes: me(me({}, r), {}, {
          cx: "256",
          cy: "364",
          r: "28"
        }),
        children: []
      };
      return a || o.children.push({
        tag: "animate",
        attributes: me(me({}, i), {}, {
          attributeName: "r",
          values: "28;14;28;28;14;28;"
        })
      }, {
        tag: "animate",
        attributes: me(me({}, l), {}, {
          values: "1;0;1;1;0;1;"
        })
      }), n.push(o), n.push({
        tag: "path",
        attributes: me(me({}, r), {}, {
          opacity: "1",
          d: "M263.7,312h-16c-6.6,0-12-5.4-12-12c0-71,77.4-63.9,77.4-107.8c0-20-17.8-40.2-57.4-40.2c-29.1,0-44.3,9.6-59.2,28.7 c-3.9,5-11.1,6-16.2,2.4l-13.1-9.2c-5.6-3.9-6.9-11.8-2.6-17.2c21.2-27.2,46.4-44.7,91.2-44.7c52.3,0,97.4,29.8,97.4,80.2 c0,67.6-77.4,63.5-77.4,107.8C275.7,306.6,270.3,312,263.7,312z"
        }),
        children: a ? [] : [{
          tag: "animate",
          attributes: me(me({}, l), {}, {
            values: "1;0;0;0;0;1;"
          })
        }]
      }), a || n.push({
        tag: "path",
        attributes: me(me({}, r), {}, {
          opacity: "0",
          d: "M232.5,134.5l7,168c0.3,6.4,5.6,11.5,12,11.5h9c6.4,0,11.7-5.1,12-11.5l7-168c0.3-6.8-5.2-12.5-12-12.5h-23 C237.7,122,232.2,127.7,232.5,134.5z"
        }),
        children: [{
          tag: "animate",
          attributes: me(me({}, l), {}, {
            values: "0;0;1;1;0;0;"
          })
        }]
      }), {
        tag: "g",
        attributes: {
          class: "missing"
        },
        children: n
      };
    };
  }
}, Hd = {
  hooks: function() {
    return {
      parseNodeAttributes: function(a, n) {
        var r = n.getAttribute("data-fa-symbol"), i = r === null ? !1 : r === "" ? !0 : r;
        return a.symbol = i, a;
      }
    };
  }
}, Fd = [Lc, Md, Pd, Ad, Td, $d, Id, Ed, Nd, Ld, Hd];
Jc(Fd, {
  mixoutsTo: Ct
});
Ct.noAuto;
var ys = Ct.config, zd = Ct.library;
Ct.dom;
var pr = Ct.parse;
Ct.findIconDefinition;
Ct.toHtml;
var Vd = Ct.icon;
Ct.layer;
var Yd = Ct.text;
Ct.counter;
var Bd = {
  prefix: "fas",
  iconName: "sort-down",
  icon: [320, 512, ["sort-desc"], "f0dd", "M182.6 470.6c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128z"]
}, jd = {
  prefix: "fas",
  iconName: "pencil",
  icon: [512, 512, [9999, 61504, "pencil-alt"], "f303", "M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"]
}, Ud = {
  prefix: "fas",
  iconName: "caret-right",
  icon: [256, 512, [], "f0da", "M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z"]
}, Wd = {
  prefix: "fas",
  iconName: "table",
  icon: [512, 512, [], "f0ce", "M64 256V160H224v96H64zm0 64H224v96H64V320zm224 96V320H448v96H288zM448 256H288V160H448v96zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z"]
}, qd = {
  prefix: "fas",
  iconName: "bars",
  icon: [448, 512, ["navicon"], "f0c9", "M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"]
}, Xd = {
  prefix: "fas",
  iconName: "list",
  icon: [512, 512, ["list-squares"], "f03a", "M40 48C26.7 48 16 58.7 16 72v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V72c0-13.3-10.7-24-24-24H40zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zM16 232v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V232c0-13.3-10.7-24-24-24H40c-13.3 0-24 10.7-24 24zM40 368c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V392c0-13.3-10.7-24-24-24H40z"]
}, Qd = {
  prefix: "fas",
  iconName: "eye-slash",
  icon: [640, 512, [], "f070", "M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z"]
}, Kd = {
  prefix: "fas",
  iconName: "chevron-up",
  icon: [512, 512, [], "f077", "M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"]
}, Gd = {
  prefix: "fas",
  iconName: "angle-right",
  icon: [320, 512, [8250], "f105", "M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"]
}, Jd = {
  prefix: "fas",
  iconName: "bullseye",
  icon: [512, 512, [], "f140", "M448 256A192 192 0 1 0 64 256a192 192 0 1 0 384 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 80a80 80 0 1 0 0-160 80 80 0 1 0 0 160zm0-224a144 144 0 1 1 0 288 144 144 0 1 1 0-288zM224 256a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"]
}, Zd = {
  prefix: "fas",
  iconName: "bullhorn",
  icon: [512, 512, [128226, 128363], "f0a1", "M480 32c0-12.9-7.8-24.6-19.8-29.6s-25.7-2.2-34.9 6.9L381.7 53c-48 48-113.1 75-181 75H192 160 64c-35.3 0-64 28.7-64 64v96c0 35.3 28.7 64 64 64l0 128c0 17.7 14.3 32 32 32h64c17.7 0 32-14.3 32-32V352l8.7 0c67.9 0 133 27 181 75l43.6 43.6c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V300.4c18.6-8.8 32-32.5 32-60.4s-13.4-51.6-32-60.4V32zm-64 76.7V240 371.3C357.2 317.8 280.5 288 200.7 288H192V192h8.7c79.8 0 156.5-29.8 215.3-83.3z"]
}, ef = {
  prefix: "fas",
  iconName: "ban",
  icon: [512, 512, [128683, "cancel"], "f05e", "M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"]
}, tf = {
  prefix: "fas",
  iconName: "shuffle",
  icon: [512, 512, [128256, "random"], "f074", "M403.8 34.4c12-5 25.7-2.2 34.9 6.9l64 64c6 6 9.4 14.1 9.4 22.6s-3.4 16.6-9.4 22.6l-64 64c-9.2 9.2-22.9 11.9-34.9 6.9s-19.8-16.6-19.8-29.6V160H352c-10.1 0-19.6 4.7-25.6 12.8L284 229.3 244 176l31.2-41.6C293.3 110.2 321.8 96 352 96h32V64c0-12.9 7.8-24.6 19.8-29.6zM164 282.7L204 336l-31.2 41.6C154.7 401.8 126.2 416 96 416H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H96c10.1 0 19.6-4.7 25.6-12.8L164 282.7zm274.6 188c-9.2 9.2-22.9 11.9-34.9 6.9s-19.8-16.6-19.8-29.6V416H352c-30.2 0-58.7-14.2-76.8-38.4L121.6 172.8c-6-8.1-15.5-12.8-25.6-12.8H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H96c30.2 0 58.7 14.2 76.8 38.4L326.4 339.2c6 8.1 15.5 12.8 25.6 12.8h32V320c0-12.9 7.8-24.6 19.8-29.6s25.7-2.2 34.9 6.9l64 64c6 6 9.4 14.1 9.4 22.6s-3.4 16.6-9.4 22.6l-64 64z"]
}, af = {
  prefix: "fas",
  iconName: "arrow-right-to-bracket",
  icon: [512, 512, ["sign-in"], "f090", "M352 96l64 0c17.7 0 32 14.3 32 32l0 256c0 17.7-14.3 32-32 32l-64 0c-17.7 0-32 14.3-32 32s14.3 32 32 32l64 0c53 0 96-43 96-96l0-256c0-53-43-96-96-96l-64 0c-17.7 0-32 14.3-32 32s14.3 32 32 32zm-9.4 182.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L242.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"]
}, nf = {
  prefix: "fas",
  iconName: "hourglass-start",
  icon: [384, 512, ["hourglass-1"], "f251", "M32 0C14.3 0 0 14.3 0 32S14.3 64 32 64V75c0 42.4 16.9 83.1 46.9 113.1L146.7 256 78.9 323.9C48.9 353.9 32 394.6 32 437v11c-17.7 0-32 14.3-32 32s14.3 32 32 32H64 320h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V437c0-42.4-16.9-83.1-46.9-113.1L237.3 256l67.9-67.9c30-30 46.9-70.7 46.9-113.1V64c17.7 0 32-14.3 32-32s-14.3-32-32-32H320 64 32zM288 437v11H96V437c0-25.5 10.1-49.9 28.1-67.9L192 301.3l67.9 67.9c18 18 28.1 42.4 28.1 67.9z"]
}, rf = {
  prefix: "fas",
  iconName: "right-to-bracket",
  icon: [512, 512, ["sign-in-alt"], "f2f6", "M217.9 105.9L340.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L217.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1L32 320c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM352 416l64 0c17.7 0 32-14.3 32-32l0-256c0-17.7-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32s14.3-32 32-32l64 0c53 0 96 43 96 96l0 256c0 53-43 96-96 96l-64 0c-17.7 0-32-14.3-32-32s14.3-32 32-32z"]
}, lf = {
  prefix: "fas",
  iconName: "clipboard",
  icon: [384, 512, [128203], "f328", "M192 0c-41.8 0-77.4 26.7-90.5 64H64C28.7 64 0 92.7 0 128V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H282.5C269.4 26.7 233.8 0 192 0zm0 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM112 192H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z"]
}, of = {
  prefix: "fas",
  iconName: "underline",
  icon: [448, 512, [], "f0cd", "M16 64c0-17.7 14.3-32 32-32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H128V224c0 53 43 96 96 96s96-43 96-96V96H304c-17.7 0-32-14.3-32-32s14.3-32 32-32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H384V224c0 88.4-71.6 160-160 160s-160-71.6-160-160V96H48C30.3 96 16 81.7 16 64zM0 448c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32z"]
}, sf = {
  prefix: "fas",
  iconName: "bold",
  icon: [384, 512, [], "f032", "M0 64C0 46.3 14.3 32 32 32H80 96 224c70.7 0 128 57.3 128 128c0 31.3-11.3 60.1-30 82.3c37.1 22.4 62 63.1 62 109.7c0 70.7-57.3 128-128 128H96 80 32c-17.7 0-32-14.3-32-32s14.3-32 32-32H48V256 96H32C14.3 96 0 81.7 0 64zM224 224c35.3 0 64-28.7 64-64s-28.7-64-64-64H112V224H224zM112 288V416H256c35.3 0 64-28.7 64-64s-28.7-64-64-64H224 112z"]
}, uf = {
  prefix: "fas",
  iconName: "image",
  icon: [512, 512, [], "f03e", "M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"]
}, cf = {
  prefix: "fas",
  iconName: "caret-up",
  icon: [320, 512, [], "f0d8", "M182.6 137.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8H288c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z"]
}, df = {
  prefix: "fas",
  iconName: "rotate-left",
  icon: [512, 512, ["rotate-back", "rotate-backward", "undo-alt"], "f2ea", "M48.5 224H40c-13.3 0-24-10.7-24-24V72c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2L98.6 96.6c87.6-86.5 228.7-86.2 315.8 1c87.5 87.5 87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3c-62.2-62.2-162.7-62.5-225.3-1L185 183c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8H48.5z"]
}, ff = {
  prefix: "fas",
  iconName: "circle-check",
  icon: [512, 512, [61533, "check-circle"], "f058", "M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"]
}, vf = {
  prefix: "fas",
  iconName: "pause",
  icon: [320, 512, [9208], "f04c", "M48 64C21.5 64 0 85.5 0 112V400c0 26.5 21.5 48 48 48H80c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H48zm192 0c-26.5 0-48 21.5-48 48V400c0 26.5 21.5 48 48 48h32c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H240z"]
}, mf = {
  prefix: "fas",
  iconName: "subscript",
  icon: [512, 512, [], "f12c", "M32 64C14.3 64 0 78.3 0 96s14.3 32 32 32H47.3l89.6 128L47.3 384H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H64c10.4 0 20.2-5.1 26.2-13.6L176 311.8l85.8 122.6c6 8.6 15.8 13.6 26.2 13.6h32c17.7 0 32-14.3 32-32s-14.3-32-32-32H304.7L215.1 256l89.6-128H320c17.7 0 32-14.3 32-32s-14.3-32-32-32H288c-10.4 0-20.2 5.1-26.2 13.6L176 200.2 90.2 77.6C84.2 69.1 74.4 64 64 64H32zM480 320c0-11.1-5.7-21.4-15.2-27.2s-21.2-6.4-31.1-1.4l-32 16c-15.8 7.9-22.2 27.1-14.3 42.9C393 361.5 404.3 368 416 368v80c-17.7 0-32 14.3-32 32s14.3 32 32 32h32 32c17.7 0 32-14.3 32-32s-14.3-32-32-32V320z"]
}, pf = {
  prefix: "fas",
  iconName: "arrows-rotate",
  icon: [512, 512, [128472, "refresh", "sync"], "f021", "M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H352c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V80c0-17.7-14.3-32-32-32s-32 14.3-32 32v35.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V432c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H160c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z"]
}, hf = {
  prefix: "fas",
  iconName: "italic",
  icon: [384, 512, [], "f033", "M128 64c0-17.7 14.3-32 32-32H352c17.7 0 32 14.3 32 32s-14.3 32-32 32H293.3L160 416h64c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H90.7L224 96H160c-17.7 0-32-14.3-32-32z"]
}, yf = {
  prefix: "fas",
  iconName: "list-ol",
  icon: [512, 512, ["list-1-2", "list-numeric"], "f0cb", "M24 56c0-13.3 10.7-24 24-24H80c13.3 0 24 10.7 24 24V176h16c13.3 0 24 10.7 24 24s-10.7 24-24 24H40c-13.3 0-24-10.7-24-24s10.7-24 24-24H56V80H48C34.7 80 24 69.3 24 56zM86.7 341.2c-6.5-7.4-18.3-6.9-24 1.2L51.5 357.9c-7.7 10.8-22.7 13.3-33.5 5.6s-13.3-22.7-5.6-33.5l11.1-15.6c23.7-33.2 72.3-35.6 99.2-4.9c21.3 24.4 20.8 60.9-1.1 84.7L86.8 432H120c13.3 0 24 10.7 24 24s-10.7 24-24 24H32c-9.5 0-18.2-5.6-22-14.4s-2.1-18.9 4.3-25.9l72-78c5.3-5.8 5.4-14.6 .3-20.5zM224 64H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H224c-17.7 0-32-14.3-32-32s14.3-32 32-32zm0 160H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H224c-17.7 0-32-14.3-32-32s14.3-32 32-32zm0 160H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H224c-17.7 0-32-14.3-32-32s14.3-32 32-32z"]
}, gf = {
  prefix: "fas",
  iconName: "vector-square",
  icon: [448, 512, [], "f5cb", "M368 80h32v32H368V80zM352 32c-17.7 0-32 14.3-32 32H128c0-17.7-14.3-32-32-32H32C14.3 32 0 46.3 0 64v64c0 17.7 14.3 32 32 32V352c-17.7 0-32 14.3-32 32v64c0 17.7 14.3 32 32 32H96c17.7 0 32-14.3 32-32H320c0 17.7 14.3 32 32 32h64c17.7 0 32-14.3 32-32V384c0-17.7-14.3-32-32-32V160c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32H352zM96 160c17.7 0 32-14.3 32-32H320c0 17.7 14.3 32 32 32V352c-17.7 0-32 14.3-32 32H128c0-17.7-14.3-32-32-32V160zM48 400H80v32H48V400zm320 32V400h32v32H368zM48 112V80H80v32H48z"]
}, bf = {
  prefix: "fas",
  iconName: "filter",
  icon: [512, 512, [], "f0b0", "M3.9 54.9C10.5 40.9 24.5 32 40 32H472c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5L320 320.9V448c0 12.1-6.8 23.2-17.7 28.6s-23.8 4.3-33.5-3l-64-48c-8.1-6-12.8-15.5-12.8-25.6V320.9L9 97.3C-.7 85.4-2.8 68.8 3.9 54.9z"]
}, wf = {
  prefix: "fas",
  iconName: "question",
  icon: [320, 512, [10067, 10068, 61736], "3f", "M80 160c0-35.3 28.7-64 64-64h32c35.3 0 64 28.7 64 64v3.6c0 21.8-11.1 42.1-29.4 53.8l-42.2 27.1c-25.2 16.2-40.4 44.1-40.4 74V320c0 17.7 14.3 32 32 32s32-14.3 32-32v-1.4c0-8.2 4.2-15.8 11-20.2l42.2-27.1c36.6-23.6 58.8-64.1 58.8-107.7V160c0-70.7-57.3-128-128-128H144C73.3 32 16 89.3 16 160c0 17.7 14.3 32 32 32s32-14.3 32-32zm80 320a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"]
}, kf = {
  prefix: "fas",
  iconName: "arrow-up-right-from-square",
  icon: [512, 512, ["external-link"], "f08e", "M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z"]
}, xf = kf, _f = {
  prefix: "fas",
  iconName: "arrow-right",
  icon: [448, 512, [8594], "f061", "M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"]
}, Mf = {
  prefix: "fas",
  iconName: "volume-high",
  icon: [640, 512, [128266, "volume-up"], "f028", "M533.6 32.5C598.5 85.2 640 165.8 640 256s-41.5 170.7-106.4 223.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C557.5 398.2 592 331.2 592 256s-34.5-142.2-88.7-186.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM473.1 107c43.2 35.2 70.9 88.9 70.9 149s-27.7 113.8-70.9 149c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C475.3 341.3 496 301.1 496 256s-20.7-85.3-53.2-111.8c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zm-60.5 74.5C434.1 199.1 448 225.9 448 256s-13.9 56.9-35.4 74.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C393.1 284.4 400 271 400 256s-6.9-28.4-17.7-37.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM301.1 34.8C312.6 40 320 51.4 320 64V448c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L131.8 352H64c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h67.8L266.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3z"]
}, Pf = {
  prefix: "fas",
  iconName: "circle-question",
  icon: [512, 512, [62108, "question-circle"], "f059", "M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM169.8 165.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"]
}, Af = Pf, Tf = {
  prefix: "fas",
  iconName: "eye",
  icon: [576, 512, [128065], "f06e", "M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"]
}, Of = {
  prefix: "fas",
  iconName: "sort-up",
  icon: [320, 512, ["sort-asc"], "f0de", "M182.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8H288c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z"]
}, Sf = {
  prefix: "fas",
  iconName: "trash",
  icon: [448, 512, [], "f1f8", "M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"]
}, Cf = {
  prefix: "fas",
  iconName: "arrow-left",
  icon: [448, 512, [8592], "f060", "M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"]
}, Df = {
  prefix: "fas",
  iconName: "align-left",
  icon: [448, 512, [], "f036", "M288 64c0 17.7-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32H256c17.7 0 32 14.3 32 32zm0 256c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H256c17.7 0 32 14.3 32 32zM0 192c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 448c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"]
}, $f = {
  prefix: "fas",
  iconName: "file-pdf",
  icon: [512, 512, [], "f1c1", "M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 144-208 0c-35.3 0-64 28.7-64 64l0 144-48 0c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z"]
}, If = {
  prefix: "fas",
  iconName: "table-cells-large",
  icon: [512, 512, ["th-large"], "f009", "M448 96V224H288V96H448zm0 192V416H288V288H448zM224 224H64V96H224V224zM64 288H224V416H64V288zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z"]
}, Ef = {
  prefix: "fas",
  iconName: "indent",
  icon: [448, 512, [], "f03c", "M0 64C0 46.3 14.3 32 32 32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64zM192 192c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H224c-17.7 0-32-14.3-32-32zm32 96H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H224c-17.7 0-32-14.3-32-32s14.3-32 32-32zM0 448c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM127.8 268.6L25.8 347.9C15.3 356.1 0 348.6 0 335.3V176.7c0-13.3 15.3-20.8 25.8-12.6l101.9 79.3c8.2 6.4 8.2 18.9 0 25.3z"]
}, Rf = {
  prefix: "fas",
  iconName: "circle-info",
  icon: [512, 512, ["info-circle"], "f05a", "M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"]
}, Nf = {
  prefix: "fas",
  iconName: "gear",
  icon: [512, 512, [9881, "cog"], "f013", "M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"]
}, Lf = {
  prefix: "fas",
  iconName: "grip-vertical",
  icon: [320, 512, [], "f58e", "M40 352l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40zm192 0l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40zM40 320c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0zM232 192l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40zM40 160c-22.1 0-40-17.9-40-40L0 72C0 49.9 17.9 32 40 32l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0zM232 32l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40z"]
}, Hf = {
  prefix: "fas",
  iconName: "square-root-variable",
  icon: [576, 512, ["square-root-alt"], "f698", "M282.6 78.1c8-27.3 33-46.1 61.4-46.1H544c17.7 0 32 14.3 32 32s-14.3 32-32 32H344L238.7 457c-3.6 12.3-14.1 21.2-26.8 22.8s-25.1-4.6-31.5-15.6L77.6 288H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H77.6c22.8 0 43.8 12.1 55.3 31.8l65.2 111.8L282.6 78.1zM393.4 233.4c12.5-12.5 32.8-12.5 45.3 0L480 274.7l41.4-41.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3L525.3 320l41.4 41.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L480 365.3l-41.4 41.4c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L434.7 320l-41.4-41.4c-12.5-12.5-12.5-32.8 0-45.3z"]
}, Ff = {
  prefix: "fas",
  iconName: "caret-down",
  icon: [320, 512, [], "f0d7", "M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"]
}, zf = {
  prefix: "fas",
  iconName: "ellipsis-vertical",
  icon: [128, 512, ["ellipsis-v"], "f142", "M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z"]
}, Vf = zf, Yf = {
  prefix: "fas",
  iconName: "align-center",
  icon: [448, 512, [], "f037", "M352 64c0-17.7-14.3-32-32-32H128c-17.7 0-32 14.3-32 32s14.3 32 32 32H320c17.7 0 32-14.3 32-32zm96 128c0-17.7-14.3-32-32-32H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H416c17.7 0 32-14.3 32-32zM0 448c0 17.7 14.3 32 32 32H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H32c-17.7 0-32 14.3-32 32zM352 320c0-17.7-14.3-32-32-32H128c-17.7 0-32 14.3-32 32s14.3 32 32 32H320c17.7 0 32-14.3 32-32z"]
}, Bf = {
  prefix: "fas",
  iconName: "outdent",
  icon: [448, 512, ["dedent"], "f03b", "M0 64C0 46.3 14.3 32 32 32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64zM192 192c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H224c-17.7 0-32-14.3-32-32zm32 96H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H224c-17.7 0-32-14.3-32-32s14.3-32 32-32zM0 448c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM.2 268.6c-8.2-6.4-8.2-18.9 0-25.3l101.9-79.3c10.5-8.2 25.8-.7 25.8 12.6V335.3c0 13.3-15.3 20.8-25.8 12.6L.2 268.6z"]
}, jf = {
  prefix: "fas",
  iconName: "angle-down",
  icon: [448, 512, [8964], "f107", "M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"]
}, Uf = {
  prefix: "fas",
  iconName: "ellipsis",
  icon: [448, 512, ["ellipsis-h"], "f141", "M8 256a56 56 0 1 1 112 0A56 56 0 1 1 8 256zm160 0a56 56 0 1 1 112 0 56 56 0 1 1 -112 0zm216-56a56 56 0 1 1 0 112 56 56 0 1 1 0-112z"]
}, Wf = Uf, qf = {
  prefix: "fas",
  iconName: "superscript",
  icon: [512, 512, [], "f12b", "M480 32c0-11.1-5.7-21.4-15.2-27.2s-21.2-6.4-31.1-1.4l-32 16c-15.8 7.9-22.2 27.1-14.3 42.9C393 73.5 404.3 80 416 80v80c-17.7 0-32 14.3-32 32s14.3 32 32 32h32 32c17.7 0 32-14.3 32-32s-14.3-32-32-32V32zM32 64C14.3 64 0 78.3 0 96s14.3 32 32 32H47.3l89.6 128L47.3 384H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H64c10.4 0 20.2-5.1 26.2-13.6L176 311.8l85.8 122.6c6 8.6 15.8 13.6 26.2 13.6h32c17.7 0 32-14.3 32-32s-14.3-32-32-32H304.7L215.1 256l89.6-128H320c17.7 0 32-14.3 32-32s-14.3-32-32-32H288c-10.4 0-20.2 5.1-26.2 13.6L176 200.2 90.2 77.6C84.2 69.1 74.4 64 64 64H32z"]
}, Xf = {
  prefix: "fas",
  iconName: "link",
  icon: [640, 512, [128279, "chain"], "f0c1", "M579.8 267.7c56.5-56.5 56.5-148 0-204.5c-50-50-128.8-56.5-186.3-15.4l-1.6 1.1c-14.4 10.3-17.7 30.3-7.4 44.6s30.3 17.7 44.6 7.4l1.6-1.1c32.1-22.9 76-19.3 103.8 8.6c31.5 31.5 31.5 82.5 0 114L422.3 334.8c-31.5 31.5-82.5 31.5-114 0c-27.9-27.9-31.5-71.8-8.6-103.8l1.1-1.6c10.3-14.4 6.9-34.4-7.4-44.6s-34.4-6.9-44.6 7.4l-1.1 1.6C206.5 251.2 213 330 263 380c56.5 56.5 148 56.5 204.5 0L579.8 267.7zM60.2 244.3c-56.5 56.5-56.5 148 0 204.5c50 50 128.8 56.5 186.3 15.4l1.6-1.1c14.4-10.3 17.7-30.3 7.4-44.6s-30.3-17.7-44.6-7.4l-1.6 1.1c-32.1 22.9-76 19.3-103.8-8.6C74 372 74 321 105.5 289.5L217.7 177.2c31.5-31.5 82.5-31.5 114 0c27.9 27.9 31.5 71.8 8.6 103.9l-1.1 1.6c-10.3 14.4-6.9 34.4 7.4 44.6s34.4 6.9 44.6-7.4l1.1-1.6C433.5 260.8 427 182 377 132c-56.5-56.5-148-56.5-204.5 0L60.2 244.3z"]
}, Qf = {
  prefix: "fas",
  iconName: "play",
  icon: [384, 512, [9654], "f04b", "M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"]
}, gs = {
  prefix: "fas",
  iconName: "magnifying-glass",
  icon: [512, 512, [128269, "search"], "f002", "M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"]
}, Kf = gs, Gf = {
  prefix: "fas",
  iconName: "chevron-down",
  icon: [512, 512, [], "f078", "M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"]
}, Jf = {
  prefix: "fas",
  iconName: "list-ul",
  icon: [512, 512, ["list-dots"], "f0ca", "M64 144a48 48 0 1 0 0-96 48 48 0 1 0 0 96zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zM64 464a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm48-208a48 48 0 1 0 -96 0 48 48 0 1 0 96 0z"]
}, Zf = {
  prefix: "fas",
  iconName: "copy",
  icon: [448, 512, [], "f0c5", "M208 0H332.1c12.7 0 24.9 5.1 33.9 14.1l67.9 67.9c9 9 14.1 21.2 14.1 33.9V336c0 26.5-21.5 48-48 48H208c-26.5 0-48-21.5-48-48V48c0-26.5 21.5-48 48-48zM48 128h80v64H64V448H256V416h64v48c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V176c0-26.5 21.5-48 48-48z"]
}, e1 = {
  prefix: "fas",
  iconName: "grip",
  icon: [448, 512, ["grip-horizontal"], "f58d", "M128 136c0-22.1-17.9-40-40-40L40 96C17.9 96 0 113.9 0 136l0 48c0 22.1 17.9 40 40 40H88c22.1 0 40-17.9 40-40l0-48zm0 192c0-22.1-17.9-40-40-40H40c-22.1 0-40 17.9-40 40l0 48c0 22.1 17.9 40 40 40H88c22.1 0 40-17.9 40-40V328zm32-192v48c0 22.1 17.9 40 40 40h48c22.1 0 40-17.9 40-40V136c0-22.1-17.9-40-40-40l-48 0c-22.1 0-40 17.9-40 40zM288 328c0-22.1-17.9-40-40-40H200c-22.1 0-40 17.9-40 40l0 48c0 22.1 17.9 40 40 40h48c22.1 0 40-17.9 40-40V328zm32-192v48c0 22.1 17.9 40 40 40h48c22.1 0 40-17.9 40-40V136c0-22.1-17.9-40-40-40l-48 0c-22.1 0-40 17.9-40 40zM448 328c0-22.1-17.9-40-40-40H360c-22.1 0-40 17.9-40 40v48c0 22.1 17.9 40 40 40h48c22.1 0 40-17.9 40-40V328z"]
}, t1 = {
  prefix: "fas",
  iconName: "plus",
  icon: [448, 512, [10133, 61543, "add"], "2b", "M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"]
}, bs = {
  prefix: "fas",
  iconName: "xmark",
  icon: [384, 512, [128473, 10005, 10006, 10060, 215, "close", "multiply", "remove", "times"], "f00d", "M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"]
}, a1 = bs, n1 = {
  prefix: "fas",
  iconName: "arrows-up-down-left-right",
  icon: [512, 512, ["arrows"], "f047", "M278.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-64 64c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l9.4-9.4V224H109.3l9.4-9.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-64 64c-12.5 12.5-12.5 32.8 0 45.3l64 64c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-9.4-9.4H224V402.7l-9.4-9.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l64 64c12.5 12.5 32.8 12.5 45.3 0l64-64c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-9.4 9.4V288H402.7l-9.4 9.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l64-64c12.5-12.5 12.5-32.8 0-45.3l-64-64c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l9.4 9.4H288V109.3l9.4 9.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-64-64z"]
}, r1 = {
  prefix: "fas",
  iconName: "chevron-left",
  icon: [320, 512, [9001], "f053", "M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"]
}, l1 = {
  prefix: "fas",
  iconName: "chevron-right",
  icon: [320, 512, [9002], "f054", "M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"]
}, i1 = {
  prefix: "fas",
  iconName: "calendar",
  icon: [448, 512, [128197, 128198], "f133", "M96 32V64H48C21.5 64 0 85.5 0 112v48H448V112c0-26.5-21.5-48-48-48H352V32c0-17.7-14.3-32-32-32s-32 14.3-32 32V64H160V32c0-17.7-14.3-32-32-32S96 14.3 96 32zM448 192H0V464c0 26.5 21.5 48 48 48H400c26.5 0 48-21.5 48-48V192z"]
}, o1 = {
  prefix: "fas",
  iconName: "link-slash",
  icon: [640, 512, ["chain-broken", "chain-slash", "unlink"], "f127", "M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L489.3 358.2l90.5-90.5c56.5-56.5 56.5-148 0-204.5c-50-50-128.8-56.5-186.3-15.4l-1.6 1.1c-14.4 10.3-17.7 30.3-7.4 44.6s30.3 17.7 44.6 7.4l1.6-1.1c32.1-22.9 76-19.3 103.8 8.6c31.5 31.5 31.5 82.5 0 114l-96 96-31.9-25C430.9 239.6 420.1 175.1 377 132c-52.2-52.3-134.5-56.2-191.3-11.7L38.8 5.1zM239 162c30.1-14.9 67.7-9.9 92.8 15.3c20 20 27.5 48.3 21.7 74.5L239 162zM116.6 187.9L60.2 244.3c-56.5 56.5-56.5 148 0 204.5c50 50 128.8 56.5 186.3 15.4l1.6-1.1c14.4-10.3 17.7-30.3 7.4-44.6s-30.3-17.7-44.6-7.4l-1.6 1.1c-32.1 22.9-76 19.3-103.8-8.6C74 372 74 321 105.5 289.5l61.8-61.8-50.6-39.9zM220.9 270c-2.1 39.8 12.2 80.1 42.2 110c38.9 38.9 94.4 51 143.6 36.3L220.9 270z"]
}, s1 = o1, u1 = {
  prefix: "fas",
  iconName: "user-plus",
  icon: [640, 512, [], "f234", "M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM504 312V248H440c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V136c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H552v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"]
}, c1 = {
  prefix: "fas",
  iconName: "check",
  icon: [448, 512, [10003, 10004], "f00c", "M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"]
}, d1 = {
  prefix: "fas",
  iconName: "triangle-exclamation",
  icon: [512, 512, [9888, "exclamation-triangle", "warning"], "f071", "M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"]
}, f1 = {
  prefix: "fas",
  iconName: "align-right",
  icon: [448, 512, [], "f038", "M448 64c0 17.7-14.3 32-32 32H192c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32zm0 256c0 17.7-14.3 32-32 32H192c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32zM0 192c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 448c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"]
}, v1 = {
  prefix: "fas",
  iconName: "exclamation",
  icon: [64, 512, [10069, 10071, 61738], "21", "M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64V320c0 17.7 14.3 32 32 32s32-14.3 32-32V64zM32 480a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"]
}, m1 = {
  prefix: "fas",
  iconName: "dollar-sign",
  icon: [320, 512, [128178, 61781, "dollar", "usd"], "24", "M160 0c17.7 0 32 14.3 32 32V67.7c1.6 .2 3.1 .4 4.7 .7c.4 .1 .7 .1 1.1 .2l48 8.8c17.4 3.2 28.9 19.9 25.7 37.2s-19.9 28.9-37.2 25.7l-47.5-8.7c-31.3-4.6-58.9-1.5-78.3 6.2s-27.2 18.3-29 28.1c-2 10.7-.5 16.7 1.2 20.4c1.8 3.9 5.5 8.3 12.8 13.2c16.3 10.7 41.3 17.7 73.7 26.3l2.9 .8c28.6 7.6 63.6 16.8 89.6 33.8c14.2 9.3 27.6 21.9 35.9 39.5c8.5 17.9 10.3 37.9 6.4 59.2c-6.9 38-33.1 63.4-65.6 76.7c-13.7 5.6-28.6 9.2-44.4 11V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V445.1c-.4-.1-.9-.1-1.3-.2l-.2 0 0 0c-24.4-3.8-64.5-14.3-91.5-26.3c-16.1-7.2-23.4-26.1-16.2-42.2s26.1-23.4 42.2-16.2c20.9 9.3 55.3 18.5 75.2 21.6c31.9 4.7 58.2 2 76-5.3c16.9-6.9 24.6-16.9 26.8-28.9c1.9-10.6 .4-16.7-1.3-20.4c-1.9-4-5.6-8.4-13-13.3c-16.4-10.7-41.5-17.7-74-26.3l-2.8-.7 0 0C119.4 279.3 84.4 270 58.4 253c-14.2-9.3-27.5-22-35.8-39.6c-8.4-17.9-10.1-37.9-6.1-59.2C23.7 116 52.3 91.2 84.8 78.3c13.3-5.3 27.9-8.9 43.2-11V32c0-17.7 14.3-32 32-32z"]
}, p1 = m1, h1 = {
  prefix: "far",
  iconName: "lightbulb",
  icon: [384, 512, [128161], "f0eb", "M297.2 248.9C311.6 228.3 320 203.2 320 176c0-70.7-57.3-128-128-128S64 105.3 64 176c0 27.2 8.4 52.3 22.8 72.9c3.7 5.3 8.1 11.3 12.8 17.7l0 0c12.9 17.7 28.3 38.9 39.8 59.8c10.4 19 15.7 38.8 18.3 57.5H109c-2.2-12-5.9-23.7-11.8-34.5c-9.9-18-22.2-34.9-34.5-51.8l0 0 0 0c-5.2-7.1-10.4-14.2-15.4-21.4C27.6 247.9 16 213.3 16 176C16 78.8 94.8 0 192 0s176 78.8 176 176c0 37.3-11.6 71.9-31.4 100.3c-5 7.2-10.2 14.3-15.4 21.4l0 0 0 0c-12.3 16.8-24.6 33.7-34.5 51.8c-5.9 10.8-9.6 22.5-11.8 34.5H226.4c2.6-18.7 7.9-38.6 18.3-57.5c11.5-20.9 26.9-42.1 39.8-59.8l0 0 0 0 0 0c4.7-6.4 9-12.4 12.7-17.7zM192 128c-26.5 0-48 21.5-48 48c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-44.2 35.8-80 80-80c8.8 0 16 7.2 16 16s-7.2 16-16 16zm0 384c-44.2 0-80-35.8-80-80V416H272v16c0 44.2-35.8 80-80 80z"]
};
function ro(e, t) {
  var a = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var n = Object.getOwnPropertySymbols(e);
    t && (n = n.filter(function(r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), a.push.apply(a, n);
  }
  return a;
}
function Lt(e) {
  for (var t = 1; t < arguments.length; t++) {
    var a = arguments[t] != null ? arguments[t] : {};
    t % 2 ? ro(Object(a), !0).forEach(function(n) {
      Pt(e, n, a[n]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(a)) : ro(Object(a)).forEach(function(n) {
      Object.defineProperty(e, n, Object.getOwnPropertyDescriptor(a, n));
    });
  }
  return e;
}
function hr(e) {
  "@babel/helpers - typeof";
  return hr = typeof Symbol == "function" && typeof Symbol.iterator == "symbol" ? function(t) {
    return typeof t;
  } : function(t) {
    return t && typeof Symbol == "function" && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
  }, hr(e);
}
function Pt(e, t, a) {
  return t = M1(t), t in e ? Object.defineProperty(e, t, {
    value: a,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[t] = a, e;
}
function y1(e, t) {
  if (e == null)
    return {};
  var a = {}, n = Object.keys(e), r, i;
  for (i = 0; i < n.length; i++)
    r = n[i], !(t.indexOf(r) >= 0) && (a[r] = e[r]);
  return a;
}
function g1(e, t) {
  if (e == null)
    return {};
  var a = y1(e, t), n, r;
  if (Object.getOwnPropertySymbols) {
    var i = Object.getOwnPropertySymbols(e);
    for (r = 0; r < i.length; r++)
      n = i[r], !(t.indexOf(n) >= 0) && Object.prototype.propertyIsEnumerable.call(e, n) && (a[n] = e[n]);
  }
  return a;
}
function Tl(e) {
  return b1(e) || w1(e) || k1(e) || x1();
}
function b1(e) {
  if (Array.isArray(e))
    return Ol(e);
}
function w1(e) {
  if (typeof Symbol < "u" && e[Symbol.iterator] != null || e["@@iterator"] != null)
    return Array.from(e);
}
function k1(e, t) {
  if (e) {
    if (typeof e == "string")
      return Ol(e, t);
    var a = Object.prototype.toString.call(e).slice(8, -1);
    if (a === "Object" && e.constructor && (a = e.constructor.name), a === "Map" || a === "Set")
      return Array.from(e);
    if (a === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(a))
      return Ol(e, t);
  }
}
function Ol(e, t) {
  (t == null || t > e.length) && (t = e.length);
  for (var a = 0, n = new Array(t); a < t; a++)
    n[a] = e[a];
  return n;
}
function x1() {
  throw new TypeError(`Invalid attempt to spread non-iterable instance.
In order to be iterable, non-array objects must have a [Symbol.iterator]() method.`);
}
function _1(e, t) {
  if (typeof e != "object" || e === null)
    return e;
  var a = e[Symbol.toPrimitive];
  if (a !== void 0) {
    var n = a.call(e, t || "default");
    if (typeof n != "object")
      return n;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (t === "string" ? String : Number)(e);
}
function M1(e) {
  var t = _1(e, "string");
  return typeof t == "symbol" ? t : String(t);
}
var P1 = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {}, ws = { exports: {} };
(function(e) {
  (function(t) {
    var a = function(b, w, h) {
      if (!d(w) || c(w) || f(w) || v(w) || s(w))
        return w;
      var _, C = 0, A = 0;
      if (u(w))
        for (_ = [], A = w.length; C < A; C++)
          _.push(a(b, w[C], h));
      else {
        _ = {};
        for (var I in w)
          Object.prototype.hasOwnProperty.call(w, I) && (_[b(I, h)] = a(b, w[I], h));
      }
      return _;
    }, n = function(b, w) {
      w = w || {};
      var h = w.separator || "_", _ = w.split || /(?=[A-Z])/;
      return b.split(_).join(h);
    }, r = function(b) {
      return g(b) ? b : (b = b.replace(/[\-_\s]+(.)?/g, function(w, h) {
        return h ? h.toUpperCase() : "";
      }), b.substr(0, 1).toLowerCase() + b.substr(1));
    }, i = function(b) {
      var w = r(b);
      return w.substr(0, 1).toUpperCase() + w.substr(1);
    }, l = function(b, w) {
      return n(b, w).toLowerCase();
    }, o = Object.prototype.toString, s = function(b) {
      return typeof b == "function";
    }, d = function(b) {
      return b === Object(b);
    }, u = function(b) {
      return o.call(b) == "[object Array]";
    }, c = function(b) {
      return o.call(b) == "[object Date]";
    }, f = function(b) {
      return o.call(b) == "[object RegExp]";
    }, v = function(b) {
      return o.call(b) == "[object Boolean]";
    }, g = function(b) {
      return b = b - 0, b === b;
    }, y = function(b, w) {
      var h = w && "process" in w ? w.process : w;
      return typeof h != "function" ? b : function(_, C) {
        return h(_, b, C);
      };
    }, P = {
      camelize: r,
      decamelize: l,
      pascalize: i,
      depascalize: l,
      camelizeKeys: function(b, w) {
        return a(y(r, w), b);
      },
      decamelizeKeys: function(b, w) {
        return a(y(l, w), b, w);
      },
      pascalizeKeys: function(b, w) {
        return a(y(i, w), b);
      },
      depascalizeKeys: function() {
        return this.decamelizeKeys.apply(this, arguments);
      }
    };
    e.exports ? e.exports = P : t.humps = P;
  })(P1);
})(ws);
var A1 = ws.exports, T1 = ["class", "style"];
function O1(e) {
  return e.split(";").map(function(t) {
    return t.trim();
  }).filter(function(t) {
    return t;
  }).reduce(function(t, a) {
    var n = a.indexOf(":"), r = A1.camelize(a.slice(0, n)), i = a.slice(n + 1).trim();
    return t[r] = i, t;
  }, {});
}
function S1(e) {
  return e.split(/\s+/).reduce(function(t, a) {
    return t[a] = !0, t;
  }, {});
}
function ei(e) {
  var t = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, a = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {};
  if (typeof e == "string")
    return e;
  var n = (e.children || []).map(function(s) {
    return ei(s);
  }), r = Object.keys(e.attributes || {}).reduce(function(s, d) {
    var u = e.attributes[d];
    switch (d) {
      case "class":
        s.class = S1(u);
        break;
      case "style":
        s.style = O1(u);
        break;
      default:
        s.attrs[d] = u;
    }
    return s;
  }, {
    attrs: {},
    class: {},
    style: {}
  });
  a.class;
  var i = a.style, l = i === void 0 ? {} : i, o = g1(a, T1);
  return ea(e.tag, Lt(Lt(Lt({}, t), {}, {
    class: r.class,
    style: Lt(Lt({}, r.style), l)
  }, r.attrs), o), n);
}
var ks = !1;
try {
  ks = process.env.NODE_ENV === "production";
} catch {
}
function C1() {
  if (!ks && console && typeof console.error == "function") {
    var e;
    (e = console).error.apply(e, arguments);
  }
}
function Mn(e, t) {
  return Array.isArray(t) && t.length > 0 || !Array.isArray(t) && t ? Pt({}, e, t) : {};
}
function D1(e) {
  var t, a = (t = {
    "fa-spin": e.spin,
    "fa-pulse": e.pulse,
    "fa-fw": e.fixedWidth,
    "fa-border": e.border,
    "fa-li": e.listItem,
    "fa-inverse": e.inverse,
    "fa-flip": e.flip === !0,
    "fa-flip-horizontal": e.flip === "horizontal" || e.flip === "both",
    "fa-flip-vertical": e.flip === "vertical" || e.flip === "both"
  }, Pt(t, "fa-".concat(e.size), e.size !== null), Pt(t, "fa-rotate-".concat(e.rotation), e.rotation !== null), Pt(t, "fa-pull-".concat(e.pull), e.pull !== null), Pt(t, "fa-swap-opacity", e.swapOpacity), Pt(t, "fa-bounce", e.bounce), Pt(t, "fa-shake", e.shake), Pt(t, "fa-beat", e.beat), Pt(t, "fa-fade", e.fade), Pt(t, "fa-beat-fade", e.beatFade), Pt(t, "fa-flash", e.flash), Pt(t, "fa-spin-pulse", e.spinPulse), Pt(t, "fa-spin-reverse", e.spinReverse), t);
  return Object.keys(a).map(function(n) {
    return a[n] ? n : null;
  }).filter(function(n) {
    return n;
  });
}
function lo(e) {
  if (e && hr(e) === "object" && e.prefix && e.iconName && e.icon)
    return e;
  if (pr.icon)
    return pr.icon(e);
  if (e === null)
    return null;
  if (hr(e) === "object" && e.prefix && e.iconName)
    return e;
  if (Array.isArray(e) && e.length === 2)
    return {
      prefix: e[0],
      iconName: e[1]
    };
  if (typeof e == "string")
    return {
      prefix: "fas",
      iconName: e
    };
}
var ca = _e({
  name: "FontAwesomeIcon",
  props: {
    border: {
      type: Boolean,
      default: !1
    },
    fixedWidth: {
      type: Boolean,
      default: !1
    },
    flip: {
      type: [Boolean, String],
      default: !1,
      validator: function(t) {
        return [!0, !1, "horizontal", "vertical", "both"].indexOf(t) > -1;
      }
    },
    icon: {
      type: [Object, Array, String],
      required: !0
    },
    mask: {
      type: [Object, Array, String],
      default: null
    },
    maskId: {
      type: String,
      default: null
    },
    listItem: {
      type: Boolean,
      default: !1
    },
    pull: {
      type: String,
      default: null,
      validator: function(t) {
        return ["right", "left"].indexOf(t) > -1;
      }
    },
    pulse: {
      type: Boolean,
      default: !1
    },
    rotation: {
      type: [String, Number],
      default: null,
      validator: function(t) {
        return [90, 180, 270].indexOf(Number.parseInt(t, 10)) > -1;
      }
    },
    swapOpacity: {
      type: Boolean,
      default: !1
    },
    size: {
      type: String,
      default: null,
      validator: function(t) {
        return ["2xs", "xs", "sm", "lg", "xl", "2xl", "1x", "2x", "3x", "4x", "5x", "6x", "7x", "8x", "9x", "10x"].indexOf(t) > -1;
      }
    },
    spin: {
      type: Boolean,
      default: !1
    },
    transform: {
      type: [String, Object],
      default: null
    },
    symbol: {
      type: [Boolean, String],
      default: !1
    },
    title: {
      type: String,
      default: null
    },
    titleId: {
      type: String,
      default: null
    },
    inverse: {
      type: Boolean,
      default: !1
    },
    bounce: {
      type: Boolean,
      default: !1
    },
    shake: {
      type: Boolean,
      default: !1
    },
    beat: {
      type: Boolean,
      default: !1
    },
    fade: {
      type: Boolean,
      default: !1
    },
    beatFade: {
      type: Boolean,
      default: !1
    },
    flash: {
      type: Boolean,
      default: !1
    },
    spinPulse: {
      type: Boolean,
      default: !1
    },
    spinReverse: {
      type: Boolean,
      default: !1
    }
  },
  setup: function(t, a) {
    var n = a.attrs, r = $(function() {
      return lo(t.icon);
    }), i = $(function() {
      return Mn("classes", D1(t));
    }), l = $(function() {
      return Mn("transform", typeof t.transform == "string" ? pr.transform(t.transform) : t.transform);
    }), o = $(function() {
      return Mn("mask", lo(t.mask));
    }), s = $(function() {
      return Vd(r.value, Lt(Lt(Lt(Lt({}, i.value), l.value), o.value), {}, {
        symbol: t.symbol,
        title: t.title,
        titleId: t.titleId,
        maskId: t.maskId
      }));
    });
    ht(s, function(u) {
      if (!u)
        return C1("Could not find one or more icon(s)", r.value, o.value);
    }, {
      immediate: !0
    });
    var d = $(function() {
      return s.value ? ei(s.value.abstract[0], {}, n) : null;
    });
    return function() {
      return d.value;
    };
  }
}), mh = _e({
  name: "FontAwesomeLayers",
  props: {
    fixedWidth: {
      type: Boolean,
      default: !1
    }
  },
  setup: function(t, a) {
    var n = a.slots, r = ys.familyPrefix, i = $(function() {
      return ["".concat(r, "-layers")].concat(Tl(t.fixedWidth ? ["".concat(r, "-fw")] : []));
    });
    return function() {
      return ea("div", {
        class: i.value
      }, n.default ? n.default() : []);
    };
  }
});
_e({
  name: "FontAwesomeLayersText",
  props: {
    value: {
      type: [String, Number],
      default: ""
    },
    transform: {
      type: [String, Object],
      default: null
    },
    counter: {
      type: Boolean,
      default: !1
    },
    position: {
      type: String,
      default: null,
      validator: function(t) {
        return ["bottom-left", "bottom-right", "top-left", "top-right"].indexOf(t) > -1;
      }
    }
  },
  setup: function(t, a) {
    var n = a.attrs, r = ys.familyPrefix, i = $(function() {
      return Mn("classes", [].concat(Tl(t.counter ? ["".concat(r, "-layers-counter")] : []), Tl(t.position ? ["".concat(r, "-layers-").concat(t.position)] : [])));
    }), l = $(function() {
      return Mn("transform", typeof t.transform == "string" ? pr.transform(t.transform) : t.transform);
    }), o = $(function() {
      var d = Yd(t.value.toString(), Lt(Lt({}, l.value), i.value)), u = d.abstract;
      return t.counter && (u[0].attributes.class = u[0].attributes.class.replace("fa-layers-text", "")), u[0];
    }), s = $(function() {
      return ei(o.value, {}, n);
    });
    return function() {
      return s.value;
    };
  }
});
zd.add(
  h1,
  Yf,
  Df,
  f1,
  jf,
  Gd,
  Cf,
  _f,
  pf,
  n1,
  ef,
  qd,
  sf,
  Zd,
  Jd,
  i1,
  Ff,
  Ud,
  cf,
  c1,
  Gf,
  r1,
  l1,
  Kd,
  ff,
  Rf,
  lf,
  Zf,
  p1,
  Wf,
  Vf,
  xf,
  v1,
  Tf,
  Qd,
  $f,
  bf,
  Nf,
  e1,
  Lf,
  nf,
  uf,
  Ef,
  hf,
  Xf,
  Xd,
  yf,
  Jf,
  gs,
  Bf,
  vf,
  jd,
  Qf,
  t1,
  Af,
  wf,
  Kf,
  Bd,
  Of,
  Hf,
  mf,
  qf,
  Wd,
  If,
  a1,
  Sf,
  d1,
  of,
  s1,
  Mf,
  u1,
  bs,
  rf,
  df,
  af,
  gf,
  tf
);
const $1 = ["type"], ph = /* @__PURE__ */ _e({
  inheritAttrs: !1,
  __name: "UITextInput",
  props: {
    type: { default: "text" },
    name: {},
    modelValue: {},
    label: {},
    rules: {},
    icon: {},
    controlled: { type: Boolean, default: !0 }
  },
  setup(e) {
    const t = e, { value: a, errorMessage: n } = Ua(
      tt(t, "name"),
      tt(t, "rules"),
      {
        label: tt(t, "label"),
        syncVModel: !0,
        controlled: t.controlled
      }
    ), r = zo(), i = $(() => {
      const { class: d, style: u } = r;
      return { class: d, style: u };
    }), l = $(() => {
      const { class: d, style: u, ...c } = r;
      return c;
    }), o = z(!1), s = (d) => {
      o.value = d.getModifierState && d.getModifierState("CapsLock");
    };
    return (d, u) => d.type !== "multiline" ? (M(), Y("div", Ue({
      key: 0,
      class: "relative"
    }, i.value), [
      ka(le("input", Ue(l.value, {
        onKeydown: s,
        "onUpdate:modelValue": u[0] || (u[0] = (c) => ia(a) ? a.value = c : null),
        type: d.type,
        class: [
          "tw-reset w-full px-2 h-8 border rounded border-ui-gray-400 focus:shadow-glow focus:outline-none placeholder:text-ui-gray-400 disabled:text-ui-gray-300 disabled:bg-ui-gray-100",
          { "pl-8": d.icon },
          {
            "border-warning-orange-600 text-warning-orange-600 italic": p(n)
          },
          {
            "pl-6": o.value && d.type === "password"
          }
        ]
      }), null, 16, $1), [
        [Gu, p(a)]
      ]),
      d.icon ? (M(), Me(p(ca), {
        key: 0,
        icon: d.icon,
        class: "absolute left-2 top-1/2 transform -translate-y-1/2 text-ui-gray-800 disabled:text-ui-gray-300"
      }, null, 8, ["icon"])) : G("", !0),
      o.value && d.type === "password" ? (M(), Me(p(ca), {
        key: 1,
        title: "Caps Lock is on",
        icon: "arrow-up-from-bracket",
        class: "absolute left-2 top-1/2 transform -translate-y-1/2 border-warning-orange-600 disabled:text-ui-gray-300"
      })) : G("", !0)
    ], 16)) : ka((M(), Y("textarea", Ue({ key: 1 }, p(r), {
      "onUpdate:modelValue": u[1] || (u[1] = (c) => ia(a) ? a.value = c : null),
      class: [
        "tw-reset px-2 border rounded border-ui-gray-400 focus:shadow-glow focus:outline-none placeholder:text-ui-gray-400 disabled:text-ui-gray-300 disabled:bg-ui-gray-100",
        {
          "border-warning-orange-600 text-warning-orange-600 italic": p(n)
        }
      ]
    }), null, 16)), [
      [Vo, p(a)]
    ]);
  }
}), hh = /* @__PURE__ */ _e({
  __name: "UIFieldError",
  props: {
    name: {}
  },
  setup(e) {
    return (t, a) => (M(), Me(p(lc), {
      name: t.name,
      class: "tw-reset text-sm text-warning-orange-600"
    }, null, 8, ["name"]));
  }
}), I1 = { class: "flex h-6 items-center" }, E1 = ["id", "name", "disabled", "aria-describedby"], R1 = {
  key: 1,
  class: "w-3.5 h-0.5 bg-pivot-teal-700 rounded-xl"
}, N1 = { className: "ml-3 leading-6" }, L1 = ["htmlFor"], yh = /* @__PURE__ */ _e({
  __name: "UICheckbox",
  props: {
    name: {},
    modelValue: {},
    label: {},
    rules: {},
    value: { default: "true" },
    indeterminate: { type: Boolean },
    class: {},
    style: { type: [Boolean, null, String, Object, Array] },
    disabled: { type: Boolean, default: !1 }
  },
  setup(e) {
    const t = e, { checked: a, handleChange: n } = Ua(() => t.name, t.rules, {
      type: "checkbox",
      checkedValue: t.value,
      label: t.label,
      syncVModel: !0
    });
    return (r, i) => (M(), Y("div", {
      class: ge(["relative flex items-start", t.class]),
      style: Ge(r.style)
    }, [
      le("div", I1, [
        le("input", Ue(r.$attrs, {
          id: r.name,
          name: r.name,
          disabled: r.disabled,
          type: "checkbox",
          "aria-describedby": r.label,
          class: "absolute opacity-0 w-6 h-6",
          onClick: i[0] || (i[0] = (l) => p(n)(r.value))
        }), null, 16, E1),
        le("span", {
          class: ge(["w-6 h-6 rounded border border-ui-gray-400 bg-white flex items-center justify-center hidden-print", {
            "bg-ui-gray-100": r.disabled
          }])
        }, [
          p(a) ? (M(), Me(p(ca), {
            key: 0,
            icon: "check",
            class: "text-pivot-teal-700 block",
            size: "lg"
          })) : r.indeterminate ? (M(), Y("div", R1)) : G("", !0)
        ], 2)
      ]),
      le("div", N1, [
        le("label", {
          htmlFor: r.name,
          class: ge(["text-base", {
            "text-ui-gray-800": !p(a),
            "text-pivot-teal-700 font-bold": p(a),
            "text-ui-gray-300": r.disabled
          }])
        }, Ne(r.label), 11, L1)
      ])
    ], 6));
  }
}), H1 = ["htmlFor"], F1 = { class: "flex h-6 items-center" }, z1 = ["id", "name", "disabled", "aria-describedby", "value"], V1 = { className: "ml-3" }, gh = /* @__PURE__ */ _e({
  __name: "UIRadioButton",
  props: {
    name: {},
    modelValue: {},
    label: {},
    rules: {},
    value: { default: "true" },
    class: {},
    style: { type: [Boolean, null, String, Object, Array] },
    disabled: { type: Boolean, default: !1 }
  },
  emits: ["update:modelValue"],
  setup(e, { emit: t }) {
    const a = e, n = t, {
      checked: r,
      handleChange: i,
      value: l
    } = Ua(() => a.name, a.rules, {
      type: "radio",
      checkedValue: a.value,
      uncheckedValue: "",
      label: a.label,
      syncVModel: !0
    }), o = () => {
      let s = a.value;
      l.value === s && (s = ""), i(s), n("update:modelValue", s);
    };
    return (s, d) => (M(), Y("div", {
      class: ge(["relative flex items-start", a.class]),
      style: Ge(s.style)
    }, [
      le("label", {
        htmlFor: s.value,
        class: "flex"
      }, [
        le("div", F1, [
          ka(le("input", Ue(s.$attrs, {
            id: s.value,
            name: s.name,
            disabled: s.disabled,
            type: "radio",
            "aria-describedby": s.label,
            value: s.value,
            "onUpdate:modelValue": d[0] || (d[0] = (u) => ia(l) ? l.value = u : null),
            class: "absolute opacity-0",
            onClick: o
          }), null, 16, z1), [
            [Ju, p(l)]
          ]),
          le("span", {
            class: ge(["w-6 h-6 rounded-full border border-ui-gray-400 flex items-center justify-center hidden-print", {
              "bg-ui-gray-100": s.disabled,
              "bg-white": !s.disabled
            }])
          }, [
            p(r) ? (M(), Me(p(ca), {
              key: 0,
              icon: "circle",
              class: "text-pivot-teal-700 block",
              size: "xs"
            })) : G("", !0)
          ], 2)
        ]),
        le("div", V1, [
          le("span", {
            class: ge(["text-base leading-6", {
              "text-ui-gray-800": !p(r) && !s.disabled,
              "text-pivot-teal-700 font-bold": p(r),
              "text-ui-gray-300": s.disabled
            }])
          }, Ne(s.label), 3)
        ])
      ], 8, H1)
    ], 6));
  }
});
function xs(e, t, a) {
  let n = z(a == null ? void 0 : a.value), r = $(() => e.value !== void 0);
  return [$(() => r.value ? e.value : n.value), function(i) {
    return r.value || (n.value = i), t == null ? void 0 : t(i);
  }];
}
let Y1 = Symbol("headlessui.useid"), B1 = 0;
function jt() {
  return $t(Y1, () => `${++B1}`)();
}
function Se(e) {
  var t;
  if (e == null || e.value == null)
    return null;
  let a = (t = e.value.$el) != null ? t : e.value;
  return a instanceof Node ? a : null;
}
function _t(e, t, ...a) {
  if (e in t) {
    let r = t[e];
    return typeof r == "function" ? r(...a) : r;
  }
  let n = new Error(`Tried to handle "${e}" but there is no handler defined. Only defined handlers are: ${Object.keys(t).map((r) => `"${r}"`).join(", ")}.`);
  throw Error.captureStackTrace && Error.captureStackTrace(n, _t), n;
}
var j1 = Object.defineProperty, U1 = (e, t, a) => t in e ? j1(e, t, { enumerable: !0, configurable: !0, writable: !0, value: a }) : e[t] = a, io = (e, t, a) => (U1(e, typeof t != "symbol" ? t + "" : t, a), a);
let W1 = class {
  constructor() {
    io(this, "current", this.detect()), io(this, "currentId", 0);
  }
  set(t) {
    this.current !== t && (this.currentId = 0, this.current = t);
  }
  reset() {
    this.set(this.detect());
  }
  nextId() {
    return ++this.currentId;
  }
  get isServer() {
    return this.current === "server";
  }
  get isClient() {
    return this.current === "client";
  }
  detect() {
    return typeof window > "u" || typeof document > "u" ? "server" : "client";
  }
}, Sr = new W1();
function ti(e) {
  if (Sr.isServer)
    return null;
  if (e instanceof Node)
    return e.ownerDocument;
  if (e != null && e.hasOwnProperty("value")) {
    let t = Se(e);
    if (t)
      return t.ownerDocument;
  }
  return document;
}
let Sl = ["[contentEditable=true]", "[tabindex]", "a[href]", "area[href]", "button:not([disabled])", "iframe", "input:not([disabled])", "select:not([disabled])", "textarea:not([disabled])"].map((e) => `${e}:not([tabindex='-1'])`).join(",");
var Cl = ((e) => (e[e.First = 1] = "First", e[e.Previous = 2] = "Previous", e[e.Next = 4] = "Next", e[e.Last = 8] = "Last", e[e.WrapAround = 16] = "WrapAround", e[e.NoScroll = 32] = "NoScroll", e))(Cl || {}), q1 = ((e) => (e[e.Error = 0] = "Error", e[e.Overflow = 1] = "Overflow", e[e.Success = 2] = "Success", e[e.Underflow = 3] = "Underflow", e))(q1 || {}), X1 = ((e) => (e[e.Previous = -1] = "Previous", e[e.Next = 1] = "Next", e))(X1 || {});
function _s(e = document.body) {
  return e == null ? [] : Array.from(e.querySelectorAll(Sl)).sort((t, a) => Math.sign((t.tabIndex || Number.MAX_SAFE_INTEGER) - (a.tabIndex || Number.MAX_SAFE_INTEGER)));
}
var Cr = ((e) => (e[e.Strict = 0] = "Strict", e[e.Loose = 1] = "Loose", e))(Cr || {});
function Dr(e, t = 0) {
  var a;
  return e === ((a = ti(e)) == null ? void 0 : a.body) ? !1 : _t(t, { 0() {
    return e.matches(Sl);
  }, 1() {
    let n = e;
    for (; n !== null; ) {
      if (n.matches(Sl))
        return !0;
      n = n.parentElement;
    }
    return !1;
  } });
}
function Ms(e) {
  let t = ti(e);
  je(() => {
    t && !Dr(t.activeElement, 0) && K1(e);
  });
}
var Q1 = ((e) => (e[e.Keyboard = 0] = "Keyboard", e[e.Mouse = 1] = "Mouse", e))(Q1 || {});
typeof window < "u" && typeof document < "u" && (document.addEventListener("keydown", (e) => {
  e.metaKey || e.altKey || e.ctrlKey || (document.documentElement.dataset.headlessuiFocusVisible = "");
}, !0), document.addEventListener("click", (e) => {
  e.detail === 1 ? delete document.documentElement.dataset.headlessuiFocusVisible : e.detail === 0 && (document.documentElement.dataset.headlessuiFocusVisible = "");
}, !0));
function K1(e) {
  e == null || e.focus({ preventScroll: !0 });
}
let G1 = ["textarea", "input"].join(",");
function J1(e) {
  var t, a;
  return (a = (t = e == null ? void 0 : e.matches) == null ? void 0 : t.call(e, G1)) != null ? a : !1;
}
function ai(e, t = (a) => a) {
  return e.slice().sort((a, n) => {
    let r = t(a), i = t(n);
    if (r === null || i === null)
      return 0;
    let l = r.compareDocumentPosition(i);
    return l & Node.DOCUMENT_POSITION_FOLLOWING ? -1 : l & Node.DOCUMENT_POSITION_PRECEDING ? 1 : 0;
  });
}
function Z1(e, t) {
  return e0(_s(), t, { relativeTo: e });
}
function e0(e, t, { sorted: a = !0, relativeTo: n = null, skipElements: r = [] } = {}) {
  var i;
  let l = (i = Array.isArray(e) ? e.length > 0 ? e[0].ownerDocument : document : e == null ? void 0 : e.ownerDocument) != null ? i : document, o = Array.isArray(e) ? a ? ai(e) : e : _s(e);
  r.length > 0 && o.length > 1 && (o = o.filter((g) => !r.includes(g))), n = n ?? l.activeElement;
  let s = (() => {
    if (t & 5)
      return 1;
    if (t & 10)
      return -1;
    throw new Error("Missing Focus.First, Focus.Previous, Focus.Next or Focus.Last");
  })(), d = (() => {
    if (t & 1)
      return 0;
    if (t & 2)
      return Math.max(0, o.indexOf(n)) - 1;
    if (t & 4)
      return Math.max(0, o.indexOf(n)) + 1;
    if (t & 8)
      return o.length - 1;
    throw new Error("Missing Focus.First, Focus.Previous, Focus.Next or Focus.Last");
  })(), u = t & 32 ? { preventScroll: !0 } : {}, c = 0, f = o.length, v;
  do {
    if (c >= f || c + f <= 0)
      return 0;
    let g = d + c;
    if (t & 16)
      g = (g + f) % f;
    else {
      if (g < 0)
        return 3;
      if (g >= f)
        return 1;
    }
    v = o[g], v == null || v.focus(u), c += s;
  } while (v !== l.activeElement);
  return t & 6 && J1(v) && v.select(), 2;
}
function t0() {
  return /iPhone/gi.test(window.navigator.platform) || /Mac/gi.test(window.navigator.platform) && window.navigator.maxTouchPoints > 0;
}
function a0() {
  return /Android/gi.test(window.navigator.userAgent);
}
function n0() {
  return t0() || a0();
}
function ar(e, t, a) {
  Sr.isServer || ta((n) => {
    document.addEventListener(e, t, a), n(() => document.removeEventListener(e, t, a));
  });
}
function r0(e, t, a) {
  Sr.isServer || ta((n) => {
    window.addEventListener(e, t, a), n(() => window.removeEventListener(e, t, a));
  });
}
function Ps(e, t, a = $(() => !0)) {
  function n(i, l) {
    if (!a.value || i.defaultPrevented)
      return;
    let o = l(i);
    if (o === null || !o.getRootNode().contains(o))
      return;
    let s = function d(u) {
      return typeof u == "function" ? d(u()) : Array.isArray(u) || u instanceof Set ? u : [u];
    }(e);
    for (let d of s) {
      if (d === null)
        continue;
      let u = d instanceof HTMLElement ? d : Se(d);
      if (u != null && u.contains(o) || i.composed && i.composedPath().includes(u))
        return;
    }
    return !Dr(o, Cr.Loose) && o.tabIndex !== -1 && i.preventDefault(), t(i, o);
  }
  let r = z(null);
  ar("pointerdown", (i) => {
    var l, o;
    a.value && (r.value = ((o = (l = i.composedPath) == null ? void 0 : l.call(i)) == null ? void 0 : o[0]) || i.target);
  }, !0), ar("mousedown", (i) => {
    var l, o;
    a.value && (r.value = ((o = (l = i.composedPath) == null ? void 0 : l.call(i)) == null ? void 0 : o[0]) || i.target);
  }, !0), ar("click", (i) => {
    n0() || r.value && (n(i, () => r.value), r.value = null);
  }, !0), ar("touchend", (i) => n(i, () => i.target instanceof HTMLElement ? i.target : null), !0), r0("blur", (i) => n(i, () => window.document.activeElement instanceof HTMLIFrameElement ? window.document.activeElement : null), !0);
}
function oo(e, t) {
  if (e)
    return e;
  let a = t ?? "button";
  if (typeof a == "string" && a.toLowerCase() === "button")
    return "button";
}
function ni(e, t) {
  let a = z(oo(e.value.type, e.value.as));
  return He(() => {
    a.value = oo(e.value.type, e.value.as);
  }), ta(() => {
    var n;
    a.value || Se(t) && Se(t) instanceof HTMLButtonElement && !((n = Se(t)) != null && n.hasAttribute("type")) && (a.value = "button");
  }), a;
}
function so(e) {
  return [e.screenX, e.screenY];
}
function As() {
  let e = z([-1, -1]);
  return { wasMoved(t) {
    let a = so(t);
    return e.value[0] === a[0] && e.value[1] === a[1] ? !1 : (e.value = a, !0);
  }, update(t) {
    e.value = so(t);
  } };
}
function l0({ container: e, accept: t, walk: a, enabled: n }) {
  ta(() => {
    let r = e.value;
    if (!r || n !== void 0 && !n.value)
      return;
    let i = ti(e);
    if (!i)
      return;
    let l = Object.assign((s) => t(s), { acceptNode: t }), o = i.createTreeWalker(r, NodeFilter.SHOW_ELEMENT, l, !1);
    for (; o.nextNode(); )
      a(o.currentNode);
  });
}
var ln = ((e) => (e[e.None = 0] = "None", e[e.RenderStrategy = 1] = "RenderStrategy", e[e.Static = 2] = "Static", e))(ln || {}), ba = ((e) => (e[e.Unmount = 0] = "Unmount", e[e.Hidden = 1] = "Hidden", e))(ba || {});
function wt({ visible: e = !0, features: t = 0, ourProps: a, theirProps: n, ...r }) {
  var i;
  let l = Os(n, a), o = Object.assign(r, { props: l });
  if (e || t & 2 && l.static)
    return tl(o);
  if (t & 1) {
    let s = (i = l.unmount) == null || i ? 0 : 1;
    return _t(s, { 0() {
      return null;
    }, 1() {
      return tl({ ...r, props: { ...l, hidden: !0, style: { display: "none" } } });
    } });
  }
  return tl(o);
}
function tl({ props: e, attrs: t, slots: a, slot: n, name: r }) {
  var i, l;
  let { as: o, ...s } = $r(e, ["unmount", "static"]), d = (i = a.default) == null ? void 0 : i.call(a, n), u = {};
  if (n) {
    let c = !1, f = [];
    for (let [v, g] of Object.entries(n))
      typeof g == "boolean" && (c = !0), g === !0 && f.push(v);
    c && (u["data-headlessui-state"] = f.join(" "));
  }
  if (o === "template") {
    if (d = Ts(d ?? []), Object.keys(s).length > 0 || Object.keys(t).length > 0) {
      let [c, ...f] = d ?? [];
      if (!i0(c) || f.length > 0)
        throw new Error(['Passing props on "template"!', "", `The current component <${r} /> is rendering a "template".`, "However we need to passthrough the following props:", Object.keys(s).concat(Object.keys(t)).map((y) => y.trim()).filter((y, P, b) => b.indexOf(y) === P).sort((y, P) => y.localeCompare(P)).map((y) => `  - ${y}`).join(`
`), "", "You can apply a few solutions:", ['Add an `as="..."` prop, to ensure that we render an actual element instead of a "template".', "Render a single element as the child so that we can forward the props onto that element."].map((y) => `  - ${y}`).join(`
`)].join(`
`));
      let v = Os((l = c.props) != null ? l : {}, s, u), g = Zu(c, v, !0);
      for (let y in v)
        y.startsWith("on") && (g.props || (g.props = {}), g.props[y] = v[y]);
      return g;
    }
    return Array.isArray(d) && d.length === 1 ? d[0] : d;
  }
  return ea(o, Object.assign({}, s, u), { default: () => d });
}
function Ts(e) {
  return e.flatMap((t) => t.type === Ae ? Ts(t.children) : [t]);
}
function Os(...e) {
  if (e.length === 0)
    return {};
  if (e.length === 1)
    return e[0];
  let t = {}, a = {};
  for (let n of e)
    for (let r in n)
      r.startsWith("on") && typeof n[r] == "function" ? (a[r] != null || (a[r] = []), a[r].push(n[r])) : t[r] = n[r];
  if (t.disabled || t["aria-disabled"])
    return Object.assign(t, Object.fromEntries(Object.keys(a).map((n) => [n, void 0])));
  for (let n in a)
    Object.assign(t, { [n](r, ...i) {
      let l = a[n];
      for (let o of l) {
        if (r instanceof Event && r.defaultPrevented)
          return;
        o(r, ...i);
      }
    } });
  return t;
}
function Ss(e) {
  let t = Object.assign({}, e);
  for (let a in t)
    t[a] === void 0 && delete t[a];
  return t;
}
function $r(e, t = []) {
  let a = Object.assign({}, e);
  for (let n of t)
    n in a && delete a[n];
  return a;
}
function i0(e) {
  return e == null ? !1 : typeof e.type == "string" || typeof e.type == "object" || typeof e.type == "function";
}
var ri = ((e) => (e[e.None = 1] = "None", e[e.Focusable = 2] = "Focusable", e[e.Hidden = 4] = "Hidden", e))(ri || {});
let Cs = _e({ name: "Hidden", props: { as: { type: [Object, String], default: "div" }, features: { type: Number, default: 1 } }, setup(e, { slots: t, attrs: a }) {
  return () => {
    var n;
    let { features: r, ...i } = e, l = { "aria-hidden": (r & 2) === 2 ? !0 : (n = i["aria-hidden"]) != null ? n : void 0, style: { position: "fixed", top: 1, left: 1, width: 1, height: 0, padding: 0, margin: -1, overflow: "hidden", clip: "rect(0, 0, 0, 0)", whiteSpace: "nowrap", borderWidth: "0", ...(r & 4) === 4 && (r & 2) !== 2 && { display: "none" } } };
    return wt({ ourProps: l, theirProps: i, slot: {}, attrs: a, slots: t, name: "Hidden" });
  };
} }), Ds = Symbol("Context");
var pt = ((e) => (e[e.Open = 1] = "Open", e[e.Closed = 2] = "Closed", e[e.Closing = 4] = "Closing", e[e.Opening = 8] = "Opening", e))(pt || {});
function o0() {
  return Ir() !== null;
}
function Ir() {
  return $t(Ds, null);
}
function li(e) {
  aa(Ds, e);
}
var Ie = ((e) => (e.Space = " ", e.Enter = "Enter", e.Escape = "Escape", e.Backspace = "Backspace", e.Delete = "Delete", e.ArrowLeft = "ArrowLeft", e.ArrowUp = "ArrowUp", e.ArrowRight = "ArrowRight", e.ArrowDown = "ArrowDown", e.Home = "Home", e.End = "End", e.PageUp = "PageUp", e.PageDown = "PageDown", e.Tab = "Tab", e))(Ie || {});
function s0(e) {
  throw new Error("Unexpected object: " + e);
}
var Ye = ((e) => (e[e.First = 0] = "First", e[e.Previous = 1] = "Previous", e[e.Next = 2] = "Next", e[e.Last = 3] = "Last", e[e.Specific = 4] = "Specific", e[e.Nothing = 5] = "Nothing", e))(Ye || {});
function $s(e, t) {
  let a = t.resolveItems();
  if (a.length <= 0)
    return null;
  let n = t.resolveActiveIndex(), r = n ?? -1;
  switch (e.focus) {
    case 0: {
      for (let i = 0; i < a.length; ++i)
        if (!t.resolveDisabled(a[i], i, a))
          return i;
      return n;
    }
    case 1: {
      r === -1 && (r = a.length);
      for (let i = r - 1; i >= 0; --i)
        if (!t.resolveDisabled(a[i], i, a))
          return i;
      return n;
    }
    case 2: {
      for (let i = r + 1; i < a.length; ++i)
        if (!t.resolveDisabled(a[i], i, a))
          return i;
      return n;
    }
    case 3: {
      for (let i = a.length - 1; i >= 0; --i)
        if (!t.resolveDisabled(a[i], i, a))
          return i;
      return n;
    }
    case 4: {
      for (let i = 0; i < a.length; ++i)
        if (t.resolveId(a[i], i, a) === e.id)
          return i;
      return n;
    }
    case 5:
      return null;
    default:
      s0(e);
  }
}
function u0(e) {
  typeof queueMicrotask == "function" ? queueMicrotask(e) : Promise.resolve().then(e).catch((t) => setTimeout(() => {
    throw t;
  }));
}
function ii() {
  let e = [], t = { addEventListener(a, n, r, i) {
    return a.addEventListener(n, r, i), t.add(() => a.removeEventListener(n, r, i));
  }, requestAnimationFrame(...a) {
    let n = requestAnimationFrame(...a);
    t.add(() => cancelAnimationFrame(n));
  }, nextFrame(...a) {
    t.requestAnimationFrame(() => {
      t.requestAnimationFrame(...a);
    });
  }, setTimeout(...a) {
    let n = setTimeout(...a);
    t.add(() => clearTimeout(n));
  }, microTask(...a) {
    let n = { current: !0 };
    return u0(() => {
      n.current && a[0]();
    }), t.add(() => {
      n.current = !1;
    });
  }, style(a, n, r) {
    let i = a.style.getPropertyValue(n);
    return Object.assign(a.style, { [n]: r }), this.add(() => {
      Object.assign(a.style, { [n]: i });
    });
  }, group(a) {
    let n = ii();
    return a(n), this.add(() => n.dispose());
  }, add(a) {
    return e.push(a), () => {
      let n = e.indexOf(a);
      if (n >= 0)
        for (let r of e.splice(n, 1))
          r();
    };
  }, dispose() {
    for (let a of e.splice(0))
      a();
  } };
  return t;
}
function Is(e = {}, t = null, a = []) {
  for (let [n, r] of Object.entries(e))
    Rs(a, Es(t, n), r);
  return a;
}
function Es(e, t) {
  return e ? e + "[" + t + "]" : t;
}
function Rs(e, t, a) {
  if (Array.isArray(a))
    for (let [n, r] of a.entries())
      Rs(e, Es(t, n.toString()), r);
  else
    a instanceof Date ? e.push([t, a.toISOString()]) : typeof a == "boolean" ? e.push([t, a ? "1" : "0"]) : typeof a == "string" ? e.push([t, a]) : typeof a == "number" ? e.push([t, `${a}`]) : a == null ? e.push([t, ""]) : Is(a, t, e);
}
function c0(e) {
  var t, a;
  let n = (t = e == null ? void 0 : e.form) != null ? t : e.closest("form");
  if (n) {
    for (let r of n.elements)
      if (r !== e && (r.tagName === "INPUT" && r.type === "submit" || r.tagName === "BUTTON" && r.type === "submit" || r.nodeName === "INPUT" && r.type === "image")) {
        r.click();
        return;
      }
    (a = n.requestSubmit) == null || a.call(n);
  }
}
let Ns = Symbol("DescriptionContext");
function d0() {
  let e = $t(Ns, null);
  if (e === null)
    throw new Error("Missing parent");
  return e;
}
function f0({ slot: e = z({}), name: t = "Description", props: a = {} } = {}) {
  let n = z([]);
  function r(i) {
    return n.value.push(i), () => {
      let l = n.value.indexOf(i);
      l !== -1 && n.value.splice(l, 1);
    };
  }
  return aa(Ns, { register: r, slot: e, name: t, props: a }), $(() => n.value.length > 0 ? n.value.join(" ") : void 0);
}
_e({ name: "Description", props: { as: { type: [Object, String], default: "p" }, id: { type: String, default: null } }, setup(e, { attrs: t, slots: a }) {
  var n;
  let r = (n = e.id) != null ? n : `headlessui-description-${jt()}`, i = d0();
  return He(() => Bt(i.register(r))), () => {
    let { name: l = "Description", slot: o = z({}), props: s = {} } = i, { ...d } = e, u = { ...Object.entries(s).reduce((c, [f, v]) => Object.assign(c, { [f]: p(v) }), {}), id: r };
    return wt({ ourProps: u, theirProps: d, slot: o.value, attrs: t, slots: a, name: l });
  };
} });
let uo = /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g;
function co(e) {
  var t, a;
  let n = (t = e.innerText) != null ? t : "", r = e.cloneNode(!0);
  if (!(r instanceof HTMLElement))
    return n;
  let i = !1;
  for (let o of r.querySelectorAll('[hidden],[aria-hidden],[role="img"]'))
    o.remove(), i = !0;
  let l = i ? (a = r.innerText) != null ? a : "" : n;
  return uo.test(l) && (l = l.replace(uo, "")), l;
}
function v0(e) {
  let t = e.getAttribute("aria-label");
  if (typeof t == "string")
    return t.trim();
  let a = e.getAttribute("aria-labelledby");
  if (a) {
    let n = a.split(" ").map((r) => {
      let i = document.getElementById(r);
      if (i) {
        let l = i.getAttribute("aria-label");
        return typeof l == "string" ? l.trim() : co(i).trim();
      }
      return null;
    }).filter(Boolean);
    if (n.length > 0)
      return n.join(", ");
  }
  return co(e).trim();
}
function Ls(e) {
  let t = z(""), a = z("");
  return () => {
    let n = Se(e);
    if (!n)
      return "";
    let r = n.innerText;
    if (t.value === r)
      return a.value;
    let i = v0(n).trim().toLowerCase();
    return t.value = r, a.value = i, i;
  };
}
function m0(e, t) {
  return e === t;
}
var p0 = ((e) => (e[e.Open = 0] = "Open", e[e.Closed = 1] = "Closed", e))(p0 || {}), h0 = ((e) => (e[e.Single = 0] = "Single", e[e.Multi = 1] = "Multi", e))(h0 || {}), y0 = ((e) => (e[e.Pointer = 0] = "Pointer", e[e.Other = 1] = "Other", e))(y0 || {});
function g0(e) {
  requestAnimationFrame(() => requestAnimationFrame(e));
}
let Hs = Symbol("ListboxContext");
function zn(e) {
  let t = $t(Hs, null);
  if (t === null) {
    let a = new Error(`<${e} /> is missing a parent <Listbox /> component.`);
    throw Error.captureStackTrace && Error.captureStackTrace(a, zn), a;
  }
  return t;
}
let b0 = _e({ name: "Listbox", emits: { "update:modelValue": (e) => !0 }, props: { as: { type: [Object, String], default: "template" }, disabled: { type: [Boolean], default: !1 }, by: { type: [String, Function], default: () => m0 }, horizontal: { type: [Boolean], default: !1 }, modelValue: { type: [Object, String, Number, Boolean], default: void 0 }, defaultValue: { type: [Object, String, Number, Boolean], default: void 0 }, form: { type: String, optional: !0 }, name: { type: String, optional: !0 }, multiple: { type: [Boolean], default: !1 } }, inheritAttrs: !1, setup(e, { slots: t, attrs: a, emit: n }) {
  let r = z(1), i = z(null), l = z(null), o = z(null), s = z([]), d = z(""), u = z(null), c = z(1);
  function f(h = (_) => _) {
    let _ = u.value !== null ? s.value[u.value] : null, C = ai(h(s.value.slice()), (I) => Se(I.dataRef.domRef)), A = _ ? C.indexOf(_) : null;
    return A === -1 && (A = null), { options: C, activeOptionIndex: A };
  }
  let v = $(() => e.multiple ? 1 : 0), [g, y] = xs($(() => e.modelValue), (h) => n("update:modelValue", h), $(() => e.defaultValue)), P = $(() => g.value === void 0 ? _t(v.value, { 1: [], 0: void 0 }) : g.value), b = { listboxState: r, value: P, mode: v, compare(h, _) {
    if (typeof e.by == "string") {
      let C = e.by;
      return (h == null ? void 0 : h[C]) === (_ == null ? void 0 : _[C]);
    }
    return e.by(h, _);
  }, orientation: $(() => e.horizontal ? "horizontal" : "vertical"), labelRef: i, buttonRef: l, optionsRef: o, disabled: $(() => e.disabled), options: s, searchQuery: d, activeOptionIndex: u, activationTrigger: c, closeListbox() {
    e.disabled || r.value !== 1 && (r.value = 1, u.value = null);
  }, openListbox() {
    e.disabled || r.value !== 0 && (r.value = 0);
  }, goToOption(h, _, C) {
    if (e.disabled || r.value === 1)
      return;
    let A = f(), I = $s(h === Ye.Specific ? { focus: Ye.Specific, id: _ } : { focus: h }, { resolveItems: () => A.options, resolveActiveIndex: () => A.activeOptionIndex, resolveId: (V) => V.id, resolveDisabled: (V) => V.dataRef.disabled });
    d.value = "", u.value = I, c.value = C ?? 1, s.value = A.options;
  }, search(h) {
    if (e.disabled || r.value === 1)
      return;
    let _ = d.value !== "" ? 0 : 1;
    d.value += h.toLowerCase();
    let C = (u.value !== null ? s.value.slice(u.value + _).concat(s.value.slice(0, u.value + _)) : s.value).find((I) => I.dataRef.textValue.startsWith(d.value) && !I.dataRef.disabled), A = C ? s.value.indexOf(C) : -1;
    A === -1 || A === u.value || (u.value = A, c.value = 1);
  }, clearSearch() {
    e.disabled || r.value !== 1 && d.value !== "" && (d.value = "");
  }, registerOption(h, _) {
    let C = f((A) => [...A, { id: h, dataRef: _ }]);
    s.value = C.options, u.value = C.activeOptionIndex;
  }, unregisterOption(h) {
    let _ = f((C) => {
      let A = C.findIndex((I) => I.id === h);
      return A !== -1 && C.splice(A, 1), C;
    });
    s.value = _.options, u.value = _.activeOptionIndex, c.value = 1;
  }, theirOnChange(h) {
    e.disabled || y(h);
  }, select(h) {
    e.disabled || y(_t(v.value, { 0: () => h, 1: () => {
      let _ = Nt(b.value.value).slice(), C = Nt(h), A = _.findIndex((I) => b.compare(C, Nt(I)));
      return A === -1 ? _.push(C) : _.splice(A, 1), _;
    } }));
  } };
  Ps([l, o], (h, _) => {
    var C;
    b.closeListbox(), Dr(_, Cr.Loose) || (h.preventDefault(), (C = Se(l)) == null || C.focus());
  }, $(() => r.value === 0)), aa(Hs, b), li($(() => _t(r.value, { 0: pt.Open, 1: pt.Closed })));
  let w = $(() => {
    var h;
    return (h = Se(l)) == null ? void 0 : h.closest("form");
  });
  return He(() => {
    ht([w], () => {
      if (!w.value || e.defaultValue === void 0)
        return;
      function h() {
        b.theirOnChange(e.defaultValue);
      }
      return w.value.addEventListener("reset", h), () => {
        var _;
        (_ = w.value) == null || _.removeEventListener("reset", h);
      };
    }, { immediate: !0 });
  }), () => {
    let { name: h, modelValue: _, disabled: C, form: A, ...I } = e, V = { open: r.value === 0, disabled: C, value: P.value };
    return ea(Ae, [...h != null && P.value != null ? Is({ [h]: P.value }).map(([L, F]) => ea(Cs, Ss({ features: ri.Hidden, key: L, as: "input", type: "hidden", hidden: !0, readOnly: !0, form: A, name: L, value: F }))) : [], wt({ ourProps: {}, theirProps: { ...a, ...$r(I, ["defaultValue", "onUpdate:modelValue", "horizontal", "multiple", "by"]) }, slot: V, slots: t, attrs: a, name: "Listbox" })]);
  };
} });
_e({ name: "ListboxLabel", props: { as: { type: [Object, String], default: "label" }, id: { type: String, default: null } }, setup(e, { attrs: t, slots: a }) {
  var n;
  let r = (n = e.id) != null ? n : `headlessui-listbox-label-${jt()}`, i = zn("ListboxLabel");
  function l() {
    var o;
    (o = Se(i.buttonRef)) == null || o.focus({ preventScroll: !0 });
  }
  return () => {
    let o = { open: i.listboxState.value === 0, disabled: i.disabled.value }, { ...s } = e, d = { id: r, ref: i.labelRef, onClick: l };
    return wt({ ourProps: d, theirProps: s, slot: o, attrs: t, slots: a, name: "ListboxLabel" });
  };
} });
let w0 = _e({ name: "ListboxButton", props: { as: { type: [Object, String], default: "button" }, id: { type: String, default: null } }, setup(e, { attrs: t, slots: a, expose: n }) {
  var r;
  let i = (r = e.id) != null ? r : `headlessui-listbox-button-${jt()}`, l = zn("ListboxButton");
  n({ el: l.buttonRef, $el: l.buttonRef });
  function o(c) {
    switch (c.key) {
      case Ie.Space:
      case Ie.Enter:
      case Ie.ArrowDown:
        c.preventDefault(), l.openListbox(), je(() => {
          var f;
          (f = Se(l.optionsRef)) == null || f.focus({ preventScroll: !0 }), l.value.value || l.goToOption(Ye.First);
        });
        break;
      case Ie.ArrowUp:
        c.preventDefault(), l.openListbox(), je(() => {
          var f;
          (f = Se(l.optionsRef)) == null || f.focus({ preventScroll: !0 }), l.value.value || l.goToOption(Ye.Last);
        });
        break;
    }
  }
  function s(c) {
    switch (c.key) {
      case Ie.Space:
        c.preventDefault();
        break;
    }
  }
  function d(c) {
    l.disabled.value || (l.listboxState.value === 0 ? (l.closeListbox(), je(() => {
      var f;
      return (f = Se(l.buttonRef)) == null ? void 0 : f.focus({ preventScroll: !0 });
    })) : (c.preventDefault(), l.openListbox(), g0(() => {
      var f;
      return (f = Se(l.optionsRef)) == null ? void 0 : f.focus({ preventScroll: !0 });
    })));
  }
  let u = ni($(() => ({ as: e.as, type: t.type })), l.buttonRef);
  return () => {
    var c, f;
    let v = { open: l.listboxState.value === 0, disabled: l.disabled.value, value: l.value.value }, { ...g } = e, y = { ref: l.buttonRef, id: i, type: u.value, "aria-haspopup": "listbox", "aria-controls": (c = Se(l.optionsRef)) == null ? void 0 : c.id, "aria-expanded": l.listboxState.value === 0, "aria-labelledby": l.labelRef.value ? [(f = Se(l.labelRef)) == null ? void 0 : f.id, i].join(" ") : void 0, disabled: l.disabled.value === !0 ? !0 : void 0, onKeydown: o, onKeyup: s, onClick: d };
    return wt({ ourProps: y, theirProps: g, slot: v, attrs: t, slots: a, name: "ListboxButton" });
  };
} }), k0 = _e({ name: "ListboxOptions", props: { as: { type: [Object, String], default: "ul" }, static: { type: Boolean, default: !1 }, unmount: { type: Boolean, default: !0 }, id: { type: String, default: null } }, setup(e, { attrs: t, slots: a, expose: n }) {
  var r;
  let i = (r = e.id) != null ? r : `headlessui-listbox-options-${jt()}`, l = zn("ListboxOptions"), o = z(null);
  n({ el: l.optionsRef, $el: l.optionsRef });
  function s(c) {
    switch (o.value && clearTimeout(o.value), c.key) {
      case Ie.Space:
        if (l.searchQuery.value !== "")
          return c.preventDefault(), c.stopPropagation(), l.search(c.key);
      case Ie.Enter:
        if (c.preventDefault(), c.stopPropagation(), l.activeOptionIndex.value !== null) {
          let f = l.options.value[l.activeOptionIndex.value];
          l.select(f.dataRef.value);
        }
        l.mode.value === 0 && (l.closeListbox(), je(() => {
          var f;
          return (f = Se(l.buttonRef)) == null ? void 0 : f.focus({ preventScroll: !0 });
        }));
        break;
      case _t(l.orientation.value, { vertical: Ie.ArrowDown, horizontal: Ie.ArrowRight }):
        return c.preventDefault(), c.stopPropagation(), l.goToOption(Ye.Next);
      case _t(l.orientation.value, { vertical: Ie.ArrowUp, horizontal: Ie.ArrowLeft }):
        return c.preventDefault(), c.stopPropagation(), l.goToOption(Ye.Previous);
      case Ie.Home:
      case Ie.PageUp:
        return c.preventDefault(), c.stopPropagation(), l.goToOption(Ye.First);
      case Ie.End:
      case Ie.PageDown:
        return c.preventDefault(), c.stopPropagation(), l.goToOption(Ye.Last);
      case Ie.Escape:
        c.preventDefault(), c.stopPropagation(), l.closeListbox(), je(() => {
          var f;
          return (f = Se(l.buttonRef)) == null ? void 0 : f.focus({ preventScroll: !0 });
        });
        break;
      case Ie.Tab:
        c.preventDefault(), c.stopPropagation();
        break;
      default:
        c.key.length === 1 && (l.search(c.key), o.value = setTimeout(() => l.clearSearch(), 350));
        break;
    }
  }
  let d = Ir(), u = $(() => d !== null ? (d.value & pt.Open) === pt.Open : l.listboxState.value === 0);
  return () => {
    var c, f;
    let v = { open: l.listboxState.value === 0 }, { ...g } = e, y = { "aria-activedescendant": l.activeOptionIndex.value === null || (c = l.options.value[l.activeOptionIndex.value]) == null ? void 0 : c.id, "aria-multiselectable": l.mode.value === 1 ? !0 : void 0, "aria-labelledby": (f = Se(l.buttonRef)) == null ? void 0 : f.id, "aria-orientation": l.orientation.value, id: i, onKeydown: s, role: "listbox", tabIndex: 0, ref: l.optionsRef };
    return wt({ ourProps: y, theirProps: g, slot: v, attrs: t, slots: a, features: ln.RenderStrategy | ln.Static, visible: u.value, name: "ListboxOptions" });
  };
} }), x0 = _e({ name: "ListboxOption", props: { as: { type: [Object, String], default: "li" }, value: { type: [Object, String, Number, Boolean] }, disabled: { type: Boolean, default: !1 }, id: { type: String, default: null } }, setup(e, { slots: t, attrs: a, expose: n }) {
  var r;
  let i = (r = e.id) != null ? r : `headlessui-listbox-option-${jt()}`, l = zn("ListboxOption"), o = z(null);
  n({ el: o, $el: o });
  let s = $(() => l.activeOptionIndex.value !== null ? l.options.value[l.activeOptionIndex.value].id === i : !1), d = $(() => _t(l.mode.value, { 0: () => l.compare(Nt(l.value.value), Nt(e.value)), 1: () => Nt(l.value.value).some((h) => l.compare(Nt(h), Nt(e.value))) })), u = $(() => _t(l.mode.value, { 1: () => {
    var h;
    let _ = Nt(l.value.value);
    return ((h = l.options.value.find((C) => _.some((A) => l.compare(Nt(A), Nt(C.dataRef.value))))) == null ? void 0 : h.id) === i;
  }, 0: () => d.value })), c = Ls(o), f = $(() => ({ disabled: e.disabled, value: e.value, get textValue() {
    return c();
  }, domRef: o }));
  He(() => l.registerOption(i, f)), Bt(() => l.unregisterOption(i)), He(() => {
    ht([l.listboxState, d], () => {
      l.listboxState.value === 0 && d.value && _t(l.mode.value, { 1: () => {
        u.value && l.goToOption(Ye.Specific, i);
      }, 0: () => {
        l.goToOption(Ye.Specific, i);
      } });
    }, { immediate: !0 });
  }), ta(() => {
    l.listboxState.value === 0 && s.value && l.activationTrigger.value !== 0 && je(() => {
      var h, _;
      return (_ = (h = Se(o)) == null ? void 0 : h.scrollIntoView) == null ? void 0 : _.call(h, { block: "nearest" });
    });
  });
  function v(h) {
    if (e.disabled)
      return h.preventDefault();
    l.select(e.value), l.mode.value === 0 && (l.closeListbox(), je(() => {
      var _;
      return (_ = Se(l.buttonRef)) == null ? void 0 : _.focus({ preventScroll: !0 });
    }));
  }
  function g() {
    if (e.disabled)
      return l.goToOption(Ye.Nothing);
    l.goToOption(Ye.Specific, i);
  }
  let y = As();
  function P(h) {
    y.update(h);
  }
  function b(h) {
    y.wasMoved(h) && (e.disabled || s.value || l.goToOption(Ye.Specific, i, 0));
  }
  function w(h) {
    y.wasMoved(h) && (e.disabled || s.value && l.goToOption(Ye.Nothing));
  }
  return () => {
    let { disabled: h } = e, _ = { active: s.value, selected: d.value, disabled: h }, { value: C, disabled: A, ...I } = e, V = { id: i, ref: o, role: "option", tabIndex: h === !0 ? void 0 : -1, "aria-disabled": h === !0 ? !0 : void 0, "aria-selected": d.value, disabled: void 0, onClick: v, onFocus: g, onPointerenter: P, onMouseenter: P, onPointermove: b, onMousemove: b, onPointerleave: w, onMouseleave: w };
    return wt({ ourProps: V, theirProps: I, slot: _, attrs: a, slots: t, name: "ListboxOption" });
  };
} });
var _0 = ((e) => (e[e.Open = 0] = "Open", e[e.Closed = 1] = "Closed", e))(_0 || {}), M0 = ((e) => (e[e.Pointer = 0] = "Pointer", e[e.Other = 1] = "Other", e))(M0 || {});
function P0(e) {
  requestAnimationFrame(() => requestAnimationFrame(e));
}
let Fs = Symbol("MenuContext");
function Er(e) {
  let t = $t(Fs, null);
  if (t === null) {
    let a = new Error(`<${e} /> is missing a parent <Menu /> component.`);
    throw Error.captureStackTrace && Error.captureStackTrace(a, Er), a;
  }
  return t;
}
let A0 = _e({ name: "Menu", props: { as: { type: [Object, String], default: "template" } }, setup(e, { slots: t, attrs: a }) {
  let n = z(1), r = z(null), i = z(null), l = z([]), o = z(""), s = z(null), d = z(1);
  function u(f = (v) => v) {
    let v = s.value !== null ? l.value[s.value] : null, g = ai(f(l.value.slice()), (P) => Se(P.dataRef.domRef)), y = v ? g.indexOf(v) : null;
    return y === -1 && (y = null), { items: g, activeItemIndex: y };
  }
  let c = { menuState: n, buttonRef: r, itemsRef: i, items: l, searchQuery: o, activeItemIndex: s, activationTrigger: d, closeMenu: () => {
    n.value = 1, s.value = null;
  }, openMenu: () => n.value = 0, goToItem(f, v, g) {
    let y = u(), P = $s(f === Ye.Specific ? { focus: Ye.Specific, id: v } : { focus: f }, { resolveItems: () => y.items, resolveActiveIndex: () => y.activeItemIndex, resolveId: (b) => b.id, resolveDisabled: (b) => b.dataRef.disabled });
    o.value = "", s.value = P, d.value = g ?? 1, l.value = y.items;
  }, search(f) {
    let v = o.value !== "" ? 0 : 1;
    o.value += f.toLowerCase();
    let g = (s.value !== null ? l.value.slice(s.value + v).concat(l.value.slice(0, s.value + v)) : l.value).find((P) => P.dataRef.textValue.startsWith(o.value) && !P.dataRef.disabled), y = g ? l.value.indexOf(g) : -1;
    y === -1 || y === s.value || (s.value = y, d.value = 1);
  }, clearSearch() {
    o.value = "";
  }, registerItem(f, v) {
    let g = u((y) => [...y, { id: f, dataRef: v }]);
    l.value = g.items, s.value = g.activeItemIndex, d.value = 1;
  }, unregisterItem(f) {
    let v = u((g) => {
      let y = g.findIndex((P) => P.id === f);
      return y !== -1 && g.splice(y, 1), g;
    });
    l.value = v.items, s.value = v.activeItemIndex, d.value = 1;
  } };
  return Ps([r, i], (f, v) => {
    var g;
    c.closeMenu(), Dr(v, Cr.Loose) || (f.preventDefault(), (g = Se(r)) == null || g.focus());
  }, $(() => n.value === 0)), aa(Fs, c), li($(() => _t(n.value, { 0: pt.Open, 1: pt.Closed }))), () => {
    let f = { open: n.value === 0, close: c.closeMenu };
    return wt({ ourProps: {}, theirProps: e, slot: f, slots: t, attrs: a, name: "Menu" });
  };
} }), T0 = _e({ name: "MenuButton", props: { disabled: { type: Boolean, default: !1 }, as: { type: [Object, String], default: "button" }, id: { type: String, default: null } }, setup(e, { attrs: t, slots: a, expose: n }) {
  var r;
  let i = (r = e.id) != null ? r : `headlessui-menu-button-${jt()}`, l = Er("MenuButton");
  n({ el: l.buttonRef, $el: l.buttonRef });
  function o(c) {
    switch (c.key) {
      case Ie.Space:
      case Ie.Enter:
      case Ie.ArrowDown:
        c.preventDefault(), c.stopPropagation(), l.openMenu(), je(() => {
          var f;
          (f = Se(l.itemsRef)) == null || f.focus({ preventScroll: !0 }), l.goToItem(Ye.First);
        });
        break;
      case Ie.ArrowUp:
        c.preventDefault(), c.stopPropagation(), l.openMenu(), je(() => {
          var f;
          (f = Se(l.itemsRef)) == null || f.focus({ preventScroll: !0 }), l.goToItem(Ye.Last);
        });
        break;
    }
  }
  function s(c) {
    switch (c.key) {
      case Ie.Space:
        c.preventDefault();
        break;
    }
  }
  function d(c) {
    e.disabled || (l.menuState.value === 0 ? (l.closeMenu(), je(() => {
      var f;
      return (f = Se(l.buttonRef)) == null ? void 0 : f.focus({ preventScroll: !0 });
    })) : (c.preventDefault(), l.openMenu(), P0(() => {
      var f;
      return (f = Se(l.itemsRef)) == null ? void 0 : f.focus({ preventScroll: !0 });
    })));
  }
  let u = ni($(() => ({ as: e.as, type: t.type })), l.buttonRef);
  return () => {
    var c;
    let f = { open: l.menuState.value === 0 }, { ...v } = e, g = { ref: l.buttonRef, id: i, type: u.value, "aria-haspopup": "menu", "aria-controls": (c = Se(l.itemsRef)) == null ? void 0 : c.id, "aria-expanded": l.menuState.value === 0, onKeydown: o, onKeyup: s, onClick: d };
    return wt({ ourProps: g, theirProps: v, slot: f, attrs: t, slots: a, name: "MenuButton" });
  };
} }), O0 = _e({ name: "MenuItems", props: { as: { type: [Object, String], default: "div" }, static: { type: Boolean, default: !1 }, unmount: { type: Boolean, default: !0 }, id: { type: String, default: null } }, setup(e, { attrs: t, slots: a, expose: n }) {
  var r;
  let i = (r = e.id) != null ? r : `headlessui-menu-items-${jt()}`, l = Er("MenuItems"), o = z(null);
  n({ el: l.itemsRef, $el: l.itemsRef }), l0({ container: $(() => Se(l.itemsRef)), enabled: $(() => l.menuState.value === 0), accept(f) {
    return f.getAttribute("role") === "menuitem" ? NodeFilter.FILTER_REJECT : f.hasAttribute("role") ? NodeFilter.FILTER_SKIP : NodeFilter.FILTER_ACCEPT;
  }, walk(f) {
    f.setAttribute("role", "none");
  } });
  function s(f) {
    var v;
    switch (o.value && clearTimeout(o.value), f.key) {
      case Ie.Space:
        if (l.searchQuery.value !== "")
          return f.preventDefault(), f.stopPropagation(), l.search(f.key);
      case Ie.Enter:
        if (f.preventDefault(), f.stopPropagation(), l.activeItemIndex.value !== null) {
          let g = l.items.value[l.activeItemIndex.value];
          (v = Se(g.dataRef.domRef)) == null || v.click();
        }
        l.closeMenu(), Ms(Se(l.buttonRef));
        break;
      case Ie.ArrowDown:
        return f.preventDefault(), f.stopPropagation(), l.goToItem(Ye.Next);
      case Ie.ArrowUp:
        return f.preventDefault(), f.stopPropagation(), l.goToItem(Ye.Previous);
      case Ie.Home:
      case Ie.PageUp:
        return f.preventDefault(), f.stopPropagation(), l.goToItem(Ye.First);
      case Ie.End:
      case Ie.PageDown:
        return f.preventDefault(), f.stopPropagation(), l.goToItem(Ye.Last);
      case Ie.Escape:
        f.preventDefault(), f.stopPropagation(), l.closeMenu(), je(() => {
          var g;
          return (g = Se(l.buttonRef)) == null ? void 0 : g.focus({ preventScroll: !0 });
        });
        break;
      case Ie.Tab:
        f.preventDefault(), f.stopPropagation(), l.closeMenu(), je(() => Z1(Se(l.buttonRef), f.shiftKey ? Cl.Previous : Cl.Next));
        break;
      default:
        f.key.length === 1 && (l.search(f.key), o.value = setTimeout(() => l.clearSearch(), 350));
        break;
    }
  }
  function d(f) {
    switch (f.key) {
      case Ie.Space:
        f.preventDefault();
        break;
    }
  }
  let u = Ir(), c = $(() => u !== null ? (u.value & pt.Open) === pt.Open : l.menuState.value === 0);
  return () => {
    var f, v;
    let g = { open: l.menuState.value === 0 }, { ...y } = e, P = { "aria-activedescendant": l.activeItemIndex.value === null || (f = l.items.value[l.activeItemIndex.value]) == null ? void 0 : f.id, "aria-labelledby": (v = Se(l.buttonRef)) == null ? void 0 : v.id, id: i, onKeydown: s, onKeyup: d, role: "menu", tabIndex: 0, ref: l.itemsRef };
    return wt({ ourProps: P, theirProps: y, slot: g, attrs: t, slots: a, features: ln.RenderStrategy | ln.Static, visible: c.value, name: "MenuItems" });
  };
} }), S0 = _e({ name: "MenuItem", inheritAttrs: !1, props: { as: { type: [Object, String], default: "template" }, disabled: { type: Boolean, default: !1 }, id: { type: String, default: null } }, setup(e, { slots: t, attrs: a, expose: n }) {
  var r;
  let i = (r = e.id) != null ? r : `headlessui-menu-item-${jt()}`, l = Er("MenuItem"), o = z(null);
  n({ el: o, $el: o });
  let s = $(() => l.activeItemIndex.value !== null ? l.items.value[l.activeItemIndex.value].id === i : !1), d = Ls(o), u = $(() => ({ disabled: e.disabled, get textValue() {
    return d();
  }, domRef: o }));
  He(() => l.registerItem(i, u)), Bt(() => l.unregisterItem(i)), ta(() => {
    l.menuState.value === 0 && s.value && l.activationTrigger.value !== 0 && je(() => {
      var b, w;
      return (w = (b = Se(o)) == null ? void 0 : b.scrollIntoView) == null ? void 0 : w.call(b, { block: "nearest" });
    });
  });
  function c(b) {
    if (e.disabled)
      return b.preventDefault();
    l.closeMenu(), Ms(Se(l.buttonRef));
  }
  function f() {
    if (e.disabled)
      return l.goToItem(Ye.Nothing);
    l.goToItem(Ye.Specific, i);
  }
  let v = As();
  function g(b) {
    v.update(b);
  }
  function y(b) {
    v.wasMoved(b) && (e.disabled || s.value || l.goToItem(Ye.Specific, i, 0));
  }
  function P(b) {
    v.wasMoved(b) && (e.disabled || s.value && l.goToItem(Ye.Nothing));
  }
  return () => {
    let { disabled: b } = e, w = { active: s.value, disabled: b, close: l.closeMenu }, { ...h } = e;
    return wt({ ourProps: { id: i, ref: o, role: "menuitem", tabIndex: b === !0 ? void 0 : -1, "aria-disabled": b === !0 ? !0 : void 0, disabled: void 0, onClick: c, onFocus: f, onPointerenter: g, onMouseenter: g, onPointermove: y, onMousemove: y, onPointerleave: P, onMouseleave: P }, theirProps: { ...a, ...h }, slot: w, attrs: a, slots: t, name: "MenuItem" });
  };
} }), zs = Symbol("LabelContext");
function Vs() {
  let e = $t(zs, null);
  if (e === null) {
    let t = new Error("You used a <Label /> component, but it is not inside a parent.");
    throw Error.captureStackTrace && Error.captureStackTrace(t, Vs), t;
  }
  return e;
}
function C0({ slot: e = {}, name: t = "Label", props: a = {} } = {}) {
  let n = z([]);
  function r(i) {
    return n.value.push(i), () => {
      let l = n.value.indexOf(i);
      l !== -1 && n.value.splice(l, 1);
    };
  }
  return aa(zs, { register: r, slot: e, name: t, props: a }), $(() => n.value.length > 0 ? n.value.join(" ") : void 0);
}
let D0 = _e({ name: "Label", props: { as: { type: [Object, String], default: "label" }, passive: { type: [Boolean], default: !1 }, id: { type: String, default: null } }, setup(e, { slots: t, attrs: a }) {
  var n;
  let r = (n = e.id) != null ? n : `headlessui-label-${jt()}`, i = Vs();
  return He(() => Bt(i.register(r))), () => {
    let { name: l = "Label", slot: o = {}, props: s = {} } = i, { passive: d, ...u } = e, c = { ...Object.entries(s).reduce((f, [v, g]) => Object.assign(f, { [v]: p(g) }), {}), id: r };
    return d && (delete c.onClick, delete c.htmlFor, delete u.onClick), wt({ ourProps: c, theirProps: u, slot: o, attrs: a, slots: t, name: l });
  };
} }), Ys = Symbol("GroupContext"), $0 = _e({ name: "SwitchGroup", props: { as: { type: [Object, String], default: "template" } }, setup(e, { slots: t, attrs: a }) {
  let n = z(null), r = C0({ name: "SwitchLabel", props: { htmlFor: $(() => {
    var l;
    return (l = n.value) == null ? void 0 : l.id;
  }), onClick(l) {
    n.value && (l.currentTarget.tagName === "LABEL" && l.preventDefault(), n.value.click(), n.value.focus({ preventScroll: !0 }));
  } } }), i = f0({ name: "SwitchDescription" });
  return aa(Ys, { switchRef: n, labelledby: r, describedby: i }), () => wt({ theirProps: e, ourProps: {}, slot: {}, slots: t, attrs: a, name: "SwitchGroup" });
} }), I0 = _e({ name: "Switch", emits: { "update:modelValue": (e) => !0 }, props: { as: { type: [Object, String], default: "button" }, modelValue: { type: Boolean, default: void 0 }, defaultChecked: { type: Boolean, optional: !0 }, form: { type: String, optional: !0 }, name: { type: String, optional: !0 }, value: { type: String, optional: !0 }, id: { type: String, default: null } }, inheritAttrs: !1, setup(e, { emit: t, attrs: a, slots: n, expose: r }) {
  var i;
  let l = (i = e.id) != null ? i : `headlessui-switch-${jt()}`, o = $t(Ys, null), [s, d] = xs($(() => e.modelValue), (w) => t("update:modelValue", w), $(() => e.defaultChecked));
  function u() {
    d(!s.value);
  }
  let c = z(null), f = o === null ? c : o.switchRef, v = ni($(() => ({ as: e.as, type: a.type })), f);
  r({ el: f, $el: f });
  function g(w) {
    w.preventDefault(), u();
  }
  function y(w) {
    w.key === Ie.Space ? (w.preventDefault(), u()) : w.key === Ie.Enter && c0(w.currentTarget);
  }
  function P(w) {
    w.preventDefault();
  }
  let b = $(() => {
    var w, h;
    return (h = (w = Se(f)) == null ? void 0 : w.closest) == null ? void 0 : h.call(w, "form");
  });
  return He(() => {
    ht([b], () => {
      if (!b.value || e.defaultChecked === void 0)
        return;
      function w() {
        d(e.defaultChecked);
      }
      return b.value.addEventListener("reset", w), () => {
        var h;
        (h = b.value) == null || h.removeEventListener("reset", w);
      };
    }, { immediate: !0 });
  }), () => {
    let { name: w, value: h, form: _, ...C } = e, A = { checked: s.value }, I = { id: l, ref: f, role: "switch", type: v.value, tabIndex: 0, "aria-checked": s.value, "aria-labelledby": o == null ? void 0 : o.labelledby.value, "aria-describedby": o == null ? void 0 : o.describedby.value, onClick: g, onKeyup: y, onKeypress: P };
    return ea(Ae, [w != null && s.value != null ? ea(Cs, Ss({ features: ri.Hidden, as: "input", type: "checkbox", hidden: !0, readOnly: !0, checked: s.value, form: _, name: w, value: h })) : null, wt({ ourProps: I, theirProps: { ...a, ...$r(C, ["modelValue", "defaultChecked"]) }, slot: A, attrs: a, slots: n, name: "Switch" })]);
  };
} }), E0 = D0;
function R0(e) {
  let t = { called: !1 };
  return (...a) => {
    if (!t.called)
      return t.called = !0, e(...a);
  };
}
function al(e, ...t) {
  e && t.length > 0 && e.classList.add(...t);
}
function nr(e, ...t) {
  e && t.length > 0 && e.classList.remove(...t);
}
var Dl = ((e) => (e.Finished = "finished", e.Cancelled = "cancelled", e))(Dl || {});
function N0(e, t) {
  let a = ii();
  if (!e)
    return a.dispose;
  let { transitionDuration: n, transitionDelay: r } = getComputedStyle(e), [i, l] = [n, r].map((o) => {
    let [s = 0] = o.split(",").filter(Boolean).map((d) => d.includes("ms") ? parseFloat(d) : parseFloat(d) * 1e3).sort((d, u) => u - d);
    return s;
  });
  return i !== 0 ? a.setTimeout(() => t("finished"), i + l) : t("finished"), a.add(() => t("cancelled")), a.dispose;
}
function fo(e, t, a, n, r, i) {
  let l = ii(), o = i !== void 0 ? R0(i) : () => {
  };
  return nr(e, ...r), al(e, ...t, ...a), l.nextFrame(() => {
    nr(e, ...a), al(e, ...n), l.add(N0(e, (s) => (nr(e, ...n, ...t), al(e, ...r), o(s))));
  }), l.add(() => nr(e, ...t, ...a, ...n, ...r)), l.add(() => o("cancelled")), l.dispose;
}
function Ia(e = "") {
  return e.split(/\s+/).filter((t) => t.length > 1);
}
let oi = Symbol("TransitionContext");
var L0 = ((e) => (e.Visible = "visible", e.Hidden = "hidden", e))(L0 || {});
function H0() {
  return $t(oi, null) !== null;
}
function F0() {
  let e = $t(oi, null);
  if (e === null)
    throw new Error("A <TransitionChild /> is used but it is missing a parent <TransitionRoot />.");
  return e;
}
function z0() {
  let e = $t(si, null);
  if (e === null)
    throw new Error("A <TransitionChild /> is used but it is missing a parent <TransitionRoot />.");
  return e;
}
let si = Symbol("NestingContext");
function Rr(e) {
  return "children" in e ? Rr(e.children) : e.value.filter(({ state: t }) => t === "visible").length > 0;
}
function Bs(e) {
  let t = z([]), a = z(!1);
  He(() => a.value = !0), Bt(() => a.value = !1);
  function n(i, l = ba.Hidden) {
    let o = t.value.findIndex(({ id: s }) => s === i);
    o !== -1 && (_t(l, { [ba.Unmount]() {
      t.value.splice(o, 1);
    }, [ba.Hidden]() {
      t.value[o].state = "hidden";
    } }), !Rr(t) && a.value && (e == null || e()));
  }
  function r(i) {
    let l = t.value.find(({ id: o }) => o === i);
    return l ? l.state !== "visible" && (l.state = "visible") : t.value.push({ id: i, state: "visible" }), () => n(i, ba.Unmount);
  }
  return { children: t, register: r, unregister: n };
}
let js = ln.RenderStrategy, V0 = _e({ props: { as: { type: [Object, String], default: "div" }, show: { type: [Boolean], default: null }, unmount: { type: [Boolean], default: !0 }, appear: { type: [Boolean], default: !1 }, enter: { type: [String], default: "" }, enterFrom: { type: [String], default: "" }, enterTo: { type: [String], default: "" }, entered: { type: [String], default: "" }, leave: { type: [String], default: "" }, leaveFrom: { type: [String], default: "" }, leaveTo: { type: [String], default: "" } }, emits: { beforeEnter: () => !0, afterEnter: () => !0, beforeLeave: () => !0, afterLeave: () => !0 }, setup(e, { emit: t, attrs: a, slots: n, expose: r }) {
  let i = z(0);
  function l() {
    i.value |= pt.Opening, t("beforeEnter");
  }
  function o() {
    i.value &= ~pt.Opening, t("afterEnter");
  }
  function s() {
    i.value |= pt.Closing, t("beforeLeave");
  }
  function d() {
    i.value &= ~pt.Closing, t("afterLeave");
  }
  if (!H0() && o0())
    return () => ea(Us, { ...e, onBeforeEnter: l, onAfterEnter: o, onBeforeLeave: s, onAfterLeave: d }, n);
  let u = z(null), c = $(() => e.unmount ? ba.Unmount : ba.Hidden);
  r({ el: u, $el: u });
  let { show: f, appear: v } = F0(), { register: g, unregister: y } = z0(), P = z(f.value ? "visible" : "hidden"), b = { value: !0 }, w = jt(), h = { value: !1 }, _ = Bs(() => {
    !h.value && P.value !== "hidden" && (P.value = "hidden", y(w), d());
  });
  He(() => {
    let ve = g(w);
    Bt(ve);
  }), ta(() => {
    if (c.value === ba.Hidden && w) {
      if (f.value && P.value !== "visible") {
        P.value = "visible";
        return;
      }
      _t(P.value, { hidden: () => y(w), visible: () => g(w) });
    }
  });
  let C = Ia(e.enter), A = Ia(e.enterFrom), I = Ia(e.enterTo), V = Ia(e.entered), L = Ia(e.leave), F = Ia(e.leaveFrom), ne = Ia(e.leaveTo);
  He(() => {
    ta(() => {
      if (P.value === "visible") {
        let ve = Se(u);
        if (ve instanceof Comment && ve.data === "")
          throw new Error("Did you forget to passthrough the `ref` to the actual DOM node?");
      }
    });
  });
  function ce(ve) {
    let T = b.value && !v.value, D = Se(u);
    !D || !(D instanceof HTMLElement) || T || (h.value = !0, f.value && l(), f.value || s(), ve(f.value ? fo(D, C, A, I, V, (O) => {
      h.value = !1, O === Dl.Finished && o();
    }) : fo(D, L, F, ne, V, (O) => {
      h.value = !1, O === Dl.Finished && (Rr(_) || (P.value = "hidden", y(w), d()));
    })));
  }
  return He(() => {
    ht([f], (ve, T, D) => {
      ce(D), b.value = !1;
    }, { immediate: !0 });
  }), aa(si, _), li($(() => _t(P.value, { visible: pt.Open, hidden: pt.Closed }) | i.value)), () => {
    let { appear: ve, show: T, enter: D, enterFrom: O, enterTo: W, entered: re, leave: U, leaveFrom: E, leaveTo: oe, ...q } = e, H = { ref: u }, m = { ...q, ...v.value && f.value && Sr.isServer ? { class: ge([a.class, q.class, ...C, ...A]) } : {} };
    return wt({ theirProps: m, ourProps: H, slot: {}, slots: n, attrs: a, features: js, visible: P.value === "visible", name: "TransitionChild" });
  };
} }), Y0 = V0, Us = _e({ inheritAttrs: !1, props: { as: { type: [Object, String], default: "div" }, show: { type: [Boolean], default: null }, unmount: { type: [Boolean], default: !0 }, appear: { type: [Boolean], default: !1 }, enter: { type: [String], default: "" }, enterFrom: { type: [String], default: "" }, enterTo: { type: [String], default: "" }, entered: { type: [String], default: "" }, leave: { type: [String], default: "" }, leaveFrom: { type: [String], default: "" }, leaveTo: { type: [String], default: "" } }, emits: { beforeEnter: () => !0, afterEnter: () => !0, beforeLeave: () => !0, afterLeave: () => !0 }, setup(e, { emit: t, attrs: a, slots: n }) {
  let r = Ir(), i = $(() => e.show === null && r !== null ? (r.value & pt.Open) === pt.Open : e.show);
  ta(() => {
    if (![!0, !1].includes(i.value))
      throw new Error('A <Transition /> is used but it is missing a `:show="true | false"` prop.');
  });
  let l = z(i.value ? "visible" : "hidden"), o = Bs(() => {
    l.value = "hidden";
  }), s = z(!0), d = { show: i, appear: $(() => e.appear || !s.value) };
  return He(() => {
    ta(() => {
      s.value = !1, i.value ? l.value = "visible" : Rr(o) || (l.value = "hidden");
    });
  }), aa(si, o), aa(oi, d), () => {
    let u = $r(e, ["show", "appear", "unmount", "onBeforeEnter", "onBeforeLeave", "onAfterEnter", "onAfterLeave"]), c = { unmount: e.unmount };
    return wt({ ourProps: { ...c, as: "template" }, theirProps: {}, slot: {}, slots: { ...n, default: () => [ea(Y0, { onBeforeEnter: () => t("beforeEnter"), onAfterEnter: () => t("afterEnter"), onBeforeLeave: () => t("beforeLeave"), onAfterLeave: () => t("afterLeave"), ...a, ...c, ...u }, n.default)] }, attrs: {}, features: js, visible: l.value === "visible", name: "Transition" });
  };
} });
const B0 = /* @__PURE__ */ le("div", {
  "aria-hidden": "true",
  class: "relative w-8 px-1 pointer-events-none h-5 rounded-full border-2 border-solid border-ui-gray-800 bg-white transition-colors duration-200 ease-in-out group-focus/toggle:shadow-glow ui-checked:bg-pivot-teal-600 ui-checked:border-pivot-teal-600 group-disabled/toggle:border-ui-gray-300 group-disabled/toggle:bg-ui-gray-300"
}, [
  /* @__PURE__ */ le("div", {
    "aria-hidden": "true",
    class: "absolute top-[3px] pointer-events-none h-[10px] w-[10px] rounded-full shadow bg-ui-gray-800 transition duration-200 ease-in-out group-disabled/toggle:bg-white left-1 ui-checked:translate-x-full ui-checked:bg-white"
  })
], -1), wh = /* @__PURE__ */ _e({
  __name: "UISwitch",
  props: {
    name: {},
    disabled: { type: Boolean, default: !1 },
    modelValue: { type: Boolean },
    controlled: { type: Boolean, default: !0 },
    class: {},
    style: {}
  },
  setup(e) {
    const t = e, { value: a } = Ua(tt(t, "name"), void 0, {
      syncVModel: !t.controlled,
      controlled: t.controlled
    });
    return (n, r) => (M(), Me(p($0), {
      as: "div",
      class: ge([t.class, "tw-reset flex items-center"]),
      style: Ge(n.style)
    }, {
      default: pe(() => [
        Ee(p(I0), Ue(n.$attrs, {
          modelValue: p(a),
          "onUpdate:modelValue": r[0] || (r[0] = (i) => ia(a) ? a.value = i : null),
          disabled: n.disabled,
          class: "group/toggle p-0 py-1 border-none flex-shrink-0 rounded-full focus:outline-none"
        }), {
          default: pe(() => [
            B0
          ]),
          _: 1
        }, 16, ["modelValue", "disabled"]),
        Ee(p(E0), { class: "ml-2 cursor-pointer font-bold" }, {
          default: pe(() => [
            ie(n.$slots, "default")
          ]),
          _: 3
        })
      ]),
      _: 3
    }, 8, ["class", "style"]));
  }
}), kh = /* @__PURE__ */ _e({
  __name: "UINumberInput",
  props: {
    name: {},
    modelValue: {},
    label: {},
    rules: {}
  },
  setup(e) {
    const t = e, { value: a, errorMessage: n, handleBlur: r } = Ua(
      tt(t, "name"),
      tt(t, "rules"),
      {
        label: tt(t, "label"),
        syncVModel: !0
      }
    );
    return (i, l) => ka((M(), Y("input", {
      ref: "input",
      "onUpdate:modelValue": l[0] || (l[0] = (o) => ia(a) ? a.value = o : null),
      type: "number",
      onWheel: l[1] || (l[1] = za(() => {
      }, ["prevent"])),
      onBlur: l[2] || (l[2] = //@ts-ignore
      (...o) => p(r) && p(r)(...o)),
      class: ge([
        "hide-stepper tw-reset px-2 h-8 border rounded border-ui-gray-400 focus:shadow-glow focus:outline-none placeholder:text-ui-gray-400 disabled:text-ui-gray-300 disabled:bg-ui-gray-100",
        {
          "border-warning-orange-600 text-warning-orange-600 italic": p(n)
        }
      ])
    }, null, 34)), [
      [Vo, p(a)]
    ]);
  }
}), j0 = {
  left: "right",
  right: "left",
  bottom: "top",
  top: "bottom"
}, U0 = {
  start: "end",
  end: "start"
};
function Ws(e, t) {
  return typeof e == "function" ? e(t) : e;
}
function $n(e) {
  return e.split("-")[0];
}
function ui(e) {
  return e.split("-")[1];
}
function W0(e) {
  return e === "x" ? "y" : "x";
}
function qs(e) {
  return e === "y" ? "height" : "width";
}
function Xs(e) {
  return ["top", "bottom"].includes($n(e)) ? "y" : "x";
}
function Qs(e) {
  return W0(Xs(e));
}
function q0(e, t, a) {
  a === void 0 && (a = !1);
  const n = ui(e), r = Qs(e), i = qs(r);
  let l = r === "x" ? n === (a ? "end" : "start") ? "right" : "left" : n === "start" ? "bottom" : "top";
  return t.reference[i] > t.floating[i] && (l = yr(l)), [l, yr(l)];
}
function X0(e) {
  const t = yr(e);
  return [$l(e), t, $l(t)];
}
function $l(e) {
  return e.replace(/start|end/g, (t) => U0[t]);
}
function Q0(e, t, a) {
  const n = ["left", "right"], r = ["right", "left"], i = ["top", "bottom"], l = ["bottom", "top"];
  switch (e) {
    case "top":
    case "bottom":
      return a ? t ? r : n : t ? n : r;
    case "left":
    case "right":
      return t ? i : l;
    default:
      return [];
  }
}
function K0(e, t, a, n) {
  const r = ui(e);
  let i = Q0($n(e), a === "start", n);
  return r && (i = i.map((l) => l + "-" + r), t && (i = i.concat(i.map($l)))), i;
}
function yr(e) {
  return e.replace(/left|right|bottom|top/g, (t) => j0[t]);
}
function G0(e) {
  return {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    ...e
  };
}
function J0(e) {
  return typeof e != "number" ? G0(e) : {
    top: e,
    right: e,
    bottom: e,
    left: e
  };
}
function gr(e) {
  return {
    ...e,
    top: e.y,
    left: e.x,
    right: e.x + e.width,
    bottom: e.y + e.height
  };
}
function vo(e, t, a) {
  let {
    reference: n,
    floating: r
  } = e;
  const i = Xs(t), l = Qs(t), o = qs(l), s = $n(t), d = i === "y", u = n.x + n.width / 2 - r.width / 2, c = n.y + n.height / 2 - r.height / 2, f = n[o] / 2 - r[o] / 2;
  let v;
  switch (s) {
    case "top":
      v = {
        x: u,
        y: n.y - r.height
      };
      break;
    case "bottom":
      v = {
        x: u,
        y: n.y + n.height
      };
      break;
    case "right":
      v = {
        x: n.x + n.width,
        y: c
      };
      break;
    case "left":
      v = {
        x: n.x - r.width,
        y: c
      };
      break;
    default:
      v = {
        x: n.x,
        y: n.y
      };
  }
  switch (ui(t)) {
    case "start":
      v[l] -= f * (a && d ? -1 : 1);
      break;
    case "end":
      v[l] += f * (a && d ? -1 : 1);
      break;
  }
  return v;
}
const Z0 = async (e, t, a) => {
  const {
    placement: n = "bottom",
    strategy: r = "absolute",
    middleware: i = [],
    platform: l
  } = a, o = i.filter(Boolean), s = await (l.isRTL == null ? void 0 : l.isRTL(t));
  let d = await l.getElementRects({
    reference: e,
    floating: t,
    strategy: r
  }), {
    x: u,
    y: c
  } = vo(d, n, s), f = n, v = {}, g = 0;
  for (let y = 0; y < o.length; y++) {
    const {
      name: P,
      fn: b
    } = o[y], {
      x: w,
      y: h,
      data: _,
      reset: C
    } = await b({
      x: u,
      y: c,
      initialPlacement: n,
      placement: f,
      strategy: r,
      middlewareData: v,
      rects: d,
      platform: l,
      elements: {
        reference: e,
        floating: t
      }
    });
    if (u = w ?? u, c = h ?? c, v = {
      ...v,
      [P]: {
        ...v[P],
        ..._
      }
    }, C && g <= 50) {
      g++, typeof C == "object" && (C.placement && (f = C.placement), C.rects && (d = C.rects === !0 ? await l.getElementRects({
        reference: e,
        floating: t,
        strategy: r
      }) : C.rects), {
        x: u,
        y: c
      } = vo(d, f, s)), y = -1;
      continue;
    }
  }
  return {
    x: u,
    y: c,
    placement: f,
    strategy: r,
    middlewareData: v
  };
};
async function ev(e, t) {
  var a;
  t === void 0 && (t = {});
  const {
    x: n,
    y: r,
    platform: i,
    rects: l,
    elements: o,
    strategy: s
  } = e, {
    boundary: d = "clippingAncestors",
    rootBoundary: u = "viewport",
    elementContext: c = "floating",
    altBoundary: f = !1,
    padding: v = 0
  } = Ws(t, e), g = J0(v), P = o[f ? c === "floating" ? "reference" : "floating" : c], b = gr(await i.getClippingRect({
    element: (a = await (i.isElement == null ? void 0 : i.isElement(P))) == null || a ? P : P.contextElement || await (i.getDocumentElement == null ? void 0 : i.getDocumentElement(o.floating)),
    boundary: d,
    rootBoundary: u,
    strategy: s
  })), w = c === "floating" ? {
    ...l.floating,
    x: n,
    y: r
  } : l.reference, h = await (i.getOffsetParent == null ? void 0 : i.getOffsetParent(o.floating)), _ = await (i.isElement == null ? void 0 : i.isElement(h)) ? await (i.getScale == null ? void 0 : i.getScale(h)) || {
    x: 1,
    y: 1
  } : {
    x: 1,
    y: 1
  }, C = gr(i.convertOffsetParentRelativeRectToViewportRelativeRect ? await i.convertOffsetParentRelativeRectToViewportRelativeRect({
    rect: w,
    offsetParent: h,
    strategy: s
  }) : w);
  return {
    top: (b.top - C.top + g.top) / _.y,
    bottom: (C.bottom - b.bottom + g.bottom) / _.y,
    left: (b.left - C.left + g.left) / _.x,
    right: (C.right - b.right + g.right) / _.x
  };
}
const tv = function(e) {
  return e === void 0 && (e = {}), {
    name: "flip",
    options: e,
    async fn(t) {
      var a, n;
      const {
        placement: r,
        middlewareData: i,
        rects: l,
        initialPlacement: o,
        platform: s,
        elements: d
      } = t, {
        mainAxis: u = !0,
        crossAxis: c = !0,
        fallbackPlacements: f,
        fallbackStrategy: v = "bestFit",
        fallbackAxisSideDirection: g = "none",
        flipAlignment: y = !0,
        ...P
      } = Ws(e, t);
      if ((a = i.arrow) != null && a.alignmentOffset)
        return {};
      const b = $n(r), w = $n(o) === o, h = await (s.isRTL == null ? void 0 : s.isRTL(d.floating)), _ = f || (w || !y ? [yr(o)] : X0(o));
      !f && g !== "none" && _.push(...K0(o, y, g, h));
      const C = [o, ..._], A = await ev(t, P), I = [];
      let V = ((n = i.flip) == null ? void 0 : n.overflows) || [];
      if (u && I.push(A[b]), c) {
        const ce = q0(r, l, h);
        I.push(A[ce[0]], A[ce[1]]);
      }
      if (V = [...V, {
        placement: r,
        overflows: I
      }], !I.every((ce) => ce <= 0)) {
        var L, F;
        const ce = (((L = i.flip) == null ? void 0 : L.index) || 0) + 1, ve = C[ce];
        if (ve)
          return {
            data: {
              index: ce,
              overflows: V
            },
            reset: {
              placement: ve
            }
          };
        let T = (F = V.filter((D) => D.overflows[0] <= 0).sort((D, O) => D.overflows[1] - O.overflows[1])[0]) == null ? void 0 : F.placement;
        if (!T)
          switch (v) {
            case "bestFit": {
              var ne;
              const D = (ne = V.map((O) => [O.placement, O.overflows.filter((W) => W > 0).reduce((W, re) => W + re, 0)]).sort((O, W) => O[1] - W[1])[0]) == null ? void 0 : ne[0];
              D && (T = D);
              break;
            }
            case "initialPlacement":
              T = o;
              break;
          }
        if (r !== T)
          return {
            reset: {
              placement: T
            }
          };
      }
      return {};
    }
  };
}, Il = Math.min, an = Math.max, br = Math.round, rr = Math.floor, Ta = (e) => ({
  x: e,
  y: e
});
function Oa(e) {
  return Ks(e) ? (e.nodeName || "").toLowerCase() : "#document";
}
function St(e) {
  var t;
  return (e == null || (t = e.ownerDocument) == null ? void 0 : t.defaultView) || window;
}
function ma(e) {
  var t;
  return (t = (Ks(e) ? e.ownerDocument : e.document) || window.document) == null ? void 0 : t.documentElement;
}
function Ks(e) {
  return e instanceof Node || e instanceof St(e).Node;
}
function da(e) {
  return e instanceof Element || e instanceof St(e).Element;
}
function na(e) {
  return e instanceof HTMLElement || e instanceof St(e).HTMLElement;
}
function mo(e) {
  return typeof ShadowRoot > "u" ? !1 : e instanceof ShadowRoot || e instanceof St(e).ShadowRoot;
}
function Vn(e) {
  const {
    overflow: t,
    overflowX: a,
    overflowY: n,
    display: r
  } = Dt(e);
  return /auto|scroll|overlay|hidden|clip/.test(t + n + a) && !["inline", "contents"].includes(r);
}
function av(e) {
  return ["table", "td", "th"].includes(Oa(e));
}
function ci(e) {
  const t = di(), a = Dt(e);
  return a.transform !== "none" || a.perspective !== "none" || (a.containerType ? a.containerType !== "normal" : !1) || !t && (a.backdropFilter ? a.backdropFilter !== "none" : !1) || !t && (a.filter ? a.filter !== "none" : !1) || ["transform", "perspective", "filter"].some((n) => (a.willChange || "").includes(n)) || ["paint", "layout", "strict", "content"].some((n) => (a.contain || "").includes(n));
}
function nv(e) {
  let t = on(e);
  for (; na(t) && !Nr(t); ) {
    if (ci(t))
      return t;
    t = on(t);
  }
  return null;
}
function di() {
  return typeof CSS > "u" || !CSS.supports ? !1 : CSS.supports("-webkit-backdrop-filter", "none");
}
function Nr(e) {
  return ["html", "body", "#document"].includes(Oa(e));
}
function Dt(e) {
  return St(e).getComputedStyle(e);
}
function Lr(e) {
  return da(e) ? {
    scrollLeft: e.scrollLeft,
    scrollTop: e.scrollTop
  } : {
    scrollLeft: e.pageXOffset,
    scrollTop: e.pageYOffset
  };
}
function on(e) {
  if (Oa(e) === "html")
    return e;
  const t = (
    // Step into the shadow DOM of the parent of a slotted node.
    e.assignedSlot || // DOM Element detected.
    e.parentNode || // ShadowRoot detected.
    mo(e) && e.host || // Fallback.
    ma(e)
  );
  return mo(t) ? t.host : t;
}
function Gs(e) {
  const t = on(e);
  return Nr(t) ? e.ownerDocument ? e.ownerDocument.body : e.body : na(t) && Vn(t) ? t : Gs(t);
}
function In(e, t, a) {
  var n;
  t === void 0 && (t = []), a === void 0 && (a = !0);
  const r = Gs(e), i = r === ((n = e.ownerDocument) == null ? void 0 : n.body), l = St(r);
  return i ? t.concat(l, l.visualViewport || [], Vn(r) ? r : [], l.frameElement && a ? In(l.frameElement) : []) : t.concat(r, In(r, [], a));
}
function Js(e) {
  const t = Dt(e);
  let a = parseFloat(t.width) || 0, n = parseFloat(t.height) || 0;
  const r = na(e), i = r ? e.offsetWidth : a, l = r ? e.offsetHeight : n, o = br(a) !== i || br(n) !== l;
  return o && (a = i, n = l), {
    width: a,
    height: n,
    $: o
  };
}
function fi(e) {
  return da(e) ? e : e.contextElement;
}
function nn(e) {
  const t = fi(e);
  if (!na(t))
    return Ta(1);
  const a = t.getBoundingClientRect(), {
    width: n,
    height: r,
    $: i
  } = Js(t);
  let l = (i ? br(a.width) : a.width) / n, o = (i ? br(a.height) : a.height) / r;
  return (!l || !Number.isFinite(l)) && (l = 1), (!o || !Number.isFinite(o)) && (o = 1), {
    x: l,
    y: o
  };
}
const rv = /* @__PURE__ */ Ta(0);
function Zs(e) {
  const t = St(e);
  return !di() || !t.visualViewport ? rv : {
    x: t.visualViewport.offsetLeft,
    y: t.visualViewport.offsetTop
  };
}
function lv(e, t, a) {
  return t === void 0 && (t = !1), !a || t && a !== St(e) ? !1 : t;
}
function Ba(e, t, a, n) {
  t === void 0 && (t = !1), a === void 0 && (a = !1);
  const r = e.getBoundingClientRect(), i = fi(e);
  let l = Ta(1);
  t && (n ? da(n) && (l = nn(n)) : l = nn(e));
  const o = lv(i, a, n) ? Zs(i) : Ta(0);
  let s = (r.left + o.x) / l.x, d = (r.top + o.y) / l.y, u = r.width / l.x, c = r.height / l.y;
  if (i) {
    const f = St(i), v = n && da(n) ? St(n) : n;
    let g = f, y = g.frameElement;
    for (; y && n && v !== g; ) {
      const P = nn(y), b = y.getBoundingClientRect(), w = Dt(y), h = b.left + (y.clientLeft + parseFloat(w.paddingLeft)) * P.x, _ = b.top + (y.clientTop + parseFloat(w.paddingTop)) * P.y;
      s *= P.x, d *= P.y, u *= P.x, c *= P.y, s += h, d += _, g = St(y), y = g.frameElement;
    }
  }
  return gr({
    width: u,
    height: c,
    x: s,
    y: d
  });
}
const iv = [":popover-open", ":modal"];
function eu(e) {
  return iv.some((t) => {
    try {
      return e.matches(t);
    } catch {
      return !1;
    }
  });
}
function ov(e) {
  let {
    elements: t,
    rect: a,
    offsetParent: n,
    strategy: r
  } = e;
  const i = r === "fixed", l = ma(n), o = t ? eu(t.floating) : !1;
  if (n === l || o && i)
    return a;
  let s = {
    scrollLeft: 0,
    scrollTop: 0
  }, d = Ta(1);
  const u = Ta(0), c = na(n);
  if ((c || !c && !i) && ((Oa(n) !== "body" || Vn(l)) && (s = Lr(n)), na(n))) {
    const f = Ba(n);
    d = nn(n), u.x = f.x + n.clientLeft, u.y = f.y + n.clientTop;
  }
  return {
    width: a.width * d.x,
    height: a.height * d.y,
    x: a.x * d.x - s.scrollLeft * d.x + u.x,
    y: a.y * d.y - s.scrollTop * d.y + u.y
  };
}
function sv(e) {
  return Array.from(e.getClientRects());
}
function tu(e) {
  return Ba(ma(e)).left + Lr(e).scrollLeft;
}
function uv(e) {
  const t = ma(e), a = Lr(e), n = e.ownerDocument.body, r = an(t.scrollWidth, t.clientWidth, n.scrollWidth, n.clientWidth), i = an(t.scrollHeight, t.clientHeight, n.scrollHeight, n.clientHeight);
  let l = -a.scrollLeft + tu(e);
  const o = -a.scrollTop;
  return Dt(n).direction === "rtl" && (l += an(t.clientWidth, n.clientWidth) - r), {
    width: r,
    height: i,
    x: l,
    y: o
  };
}
function cv(e, t) {
  const a = St(e), n = ma(e), r = a.visualViewport;
  let i = n.clientWidth, l = n.clientHeight, o = 0, s = 0;
  if (r) {
    i = r.width, l = r.height;
    const d = di();
    (!d || d && t === "fixed") && (o = r.offsetLeft, s = r.offsetTop);
  }
  return {
    width: i,
    height: l,
    x: o,
    y: s
  };
}
function dv(e, t) {
  const a = Ba(e, !0, t === "fixed"), n = a.top + e.clientTop, r = a.left + e.clientLeft, i = na(e) ? nn(e) : Ta(1), l = e.clientWidth * i.x, o = e.clientHeight * i.y, s = r * i.x, d = n * i.y;
  return {
    width: l,
    height: o,
    x: s,
    y: d
  };
}
function po(e, t, a) {
  let n;
  if (t === "viewport")
    n = cv(e, a);
  else if (t === "document")
    n = uv(ma(e));
  else if (da(t))
    n = dv(t, a);
  else {
    const r = Zs(e);
    n = {
      ...t,
      x: t.x - r.x,
      y: t.y - r.y
    };
  }
  return gr(n);
}
function au(e, t) {
  const a = on(e);
  return a === t || !da(a) || Nr(a) ? !1 : Dt(a).position === "fixed" || au(a, t);
}
function fv(e, t) {
  const a = t.get(e);
  if (a)
    return a;
  let n = In(e, [], !1).filter((o) => da(o) && Oa(o) !== "body"), r = null;
  const i = Dt(e).position === "fixed";
  let l = i ? on(e) : e;
  for (; da(l) && !Nr(l); ) {
    const o = Dt(l), s = ci(l);
    !s && o.position === "fixed" && (r = null), (i ? !s && !r : !s && o.position === "static" && !!r && ["absolute", "fixed"].includes(r.position) || Vn(l) && !s && au(e, l)) ? n = n.filter((u) => u !== l) : r = o, l = on(l);
  }
  return t.set(e, n), n;
}
function vv(e) {
  let {
    element: t,
    boundary: a,
    rootBoundary: n,
    strategy: r
  } = e;
  const l = [...a === "clippingAncestors" ? fv(t, this._c) : [].concat(a), n], o = l[0], s = l.reduce((d, u) => {
    const c = po(t, u, r);
    return d.top = an(c.top, d.top), d.right = Il(c.right, d.right), d.bottom = Il(c.bottom, d.bottom), d.left = an(c.left, d.left), d;
  }, po(t, o, r));
  return {
    width: s.right - s.left,
    height: s.bottom - s.top,
    x: s.left,
    y: s.top
  };
}
function mv(e) {
  const {
    width: t,
    height: a
  } = Js(e);
  return {
    width: t,
    height: a
  };
}
function pv(e, t, a) {
  const n = na(t), r = ma(t), i = a === "fixed", l = Ba(e, !0, i, t);
  let o = {
    scrollLeft: 0,
    scrollTop: 0
  };
  const s = Ta(0);
  if (n || !n && !i)
    if ((Oa(t) !== "body" || Vn(r)) && (o = Lr(t)), n) {
      const c = Ba(t, !0, i, t);
      s.x = c.x + t.clientLeft, s.y = c.y + t.clientTop;
    } else
      r && (s.x = tu(r));
  const d = l.left + o.scrollLeft - s.x, u = l.top + o.scrollTop - s.y;
  return {
    x: d,
    y: u,
    width: l.width,
    height: l.height
  };
}
function ho(e, t) {
  return !na(e) || Dt(e).position === "fixed" ? null : t ? t(e) : e.offsetParent;
}
function nu(e, t) {
  const a = St(e);
  if (!na(e) || eu(e))
    return a;
  let n = ho(e, t);
  for (; n && av(n) && Dt(n).position === "static"; )
    n = ho(n, t);
  return n && (Oa(n) === "html" || Oa(n) === "body" && Dt(n).position === "static" && !ci(n)) ? a : n || nv(e) || a;
}
const hv = async function(e) {
  const t = this.getOffsetParent || nu, a = this.getDimensions;
  return {
    reference: pv(e.reference, await t(e.floating), e.strategy),
    floating: {
      x: 0,
      y: 0,
      ...await a(e.floating)
    }
  };
};
function yv(e) {
  return Dt(e).direction === "rtl";
}
const gv = {
  convertOffsetParentRelativeRectToViewportRelativeRect: ov,
  getDocumentElement: ma,
  getClippingRect: vv,
  getOffsetParent: nu,
  getElementRects: hv,
  getClientRects: sv,
  getDimensions: mv,
  getScale: nn,
  isElement: da,
  isRTL: yv
};
function bv(e, t) {
  let a = null, n;
  const r = ma(e);
  function i() {
    var o;
    clearTimeout(n), (o = a) == null || o.disconnect(), a = null;
  }
  function l(o, s) {
    o === void 0 && (o = !1), s === void 0 && (s = 1), i();
    const {
      left: d,
      top: u,
      width: c,
      height: f
    } = e.getBoundingClientRect();
    if (o || t(), !c || !f)
      return;
    const v = rr(u), g = rr(r.clientWidth - (d + c)), y = rr(r.clientHeight - (u + f)), P = rr(d), w = {
      rootMargin: -v + "px " + -g + "px " + -y + "px " + -P + "px",
      threshold: an(0, Il(1, s)) || 1
    };
    let h = !0;
    function _(C) {
      const A = C[0].intersectionRatio;
      if (A !== s) {
        if (!h)
          return l();
        A ? l(!1, A) : n = setTimeout(() => {
          l(!1, 1e-7);
        }, 100);
      }
      h = !1;
    }
    try {
      a = new IntersectionObserver(_, {
        ...w,
        // Handle <iframe>s
        root: r.ownerDocument
      });
    } catch {
      a = new IntersectionObserver(_, w);
    }
    a.observe(e);
  }
  return l(!0), i;
}
function wv(e, t, a, n) {
  n === void 0 && (n = {});
  const {
    ancestorScroll: r = !0,
    ancestorResize: i = !0,
    elementResize: l = typeof ResizeObserver == "function",
    layoutShift: o = typeof IntersectionObserver == "function",
    animationFrame: s = !1
  } = n, d = fi(e), u = r || i ? [...d ? In(d) : [], ...In(t)] : [];
  u.forEach((b) => {
    r && b.addEventListener("scroll", a, {
      passive: !0
    }), i && b.addEventListener("resize", a);
  });
  const c = d && o ? bv(d, a) : null;
  let f = -1, v = null;
  l && (v = new ResizeObserver((b) => {
    let [w] = b;
    w && w.target === d && v && (v.unobserve(t), cancelAnimationFrame(f), f = requestAnimationFrame(() => {
      var h;
      (h = v) == null || h.observe(t);
    })), a();
  }), d && !s && v.observe(d), v.observe(t));
  let g, y = s ? Ba(e) : null;
  s && P();
  function P() {
    const b = Ba(e);
    y && (b.x !== y.x || b.y !== y.y || b.width !== y.width || b.height !== y.height) && a(), y = b, g = requestAnimationFrame(P);
  }
  return a(), () => {
    var b;
    u.forEach((w) => {
      r && w.removeEventListener("scroll", a), i && w.removeEventListener("resize", a);
    }), c == null || c(), (b = v) == null || b.disconnect(), v = null, s && cancelAnimationFrame(g);
  };
}
const kv = tv, xv = (e, t, a) => {
  const n = /* @__PURE__ */ new Map(), r = {
    platform: gv,
    ...a
  }, i = {
    ...r.platform,
    _c: n
  };
  return Z0(e, t, {
    ...r,
    platform: i
  });
}, _v = { class: "pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2" }, xh = /* @__PURE__ */ _e({
  __name: "UISelectInput",
  props: {
    name: {},
    modelValue: {},
    label: {},
    rules: {},
    options: {},
    placeholder: { default: "Choose One" },
    multiple: { type: Boolean, default: !1 }
  },
  setup(e) {
    const t = e, { value: a, errorMessage: n, handleBlur: r } = Ua(
      tt(t, "name"),
      tt(t, "rules"),
      {
        label: tt(t, "label"),
        syncVModel: !0
      }
    ), i = $(() => {
      const s = Array.isArray(a.value) ? a.value : [a.value];
      return t.options.filter((d) => s.includes(d.value));
    }), l = z(), o = z();
    return ec((s) => {
      const d = l.value, u = o.value;
      !d || !u || s(
        wv(d, u, async () => {
          const { x: c, y: f } = await xv(d, u, {
            placement: "bottom-start",
            middleware: [kv()]
          });
          u.style.left = `${c}px`, u.style.top = `${f}px`, u.style.width = `${d.clientWidth}px`, d.closest("[role=dialog]") && (u.style.zIndex = "10000");
        })
      );
    }), (s, d) => (M(), Y("div", {
      ref_key: "anchorRef",
      ref: l
    }, [
      Ee(p(b0), {
        as: "div",
        modelValue: p(a),
        "onUpdate:modelValue": d[0] || (d[0] = (u) => ia(a) ? a.value = u : null),
        multiple: s.multiple
      }, {
        default: pe(() => [
          Ee(p(w0), {
            class: ge([
              "tw-reset relative text-left cursor-default w-full px-2 h-8 border rounded-sm border-ui-gray-400 focus:shadow-glow focus:outline-none placeholder:text-ui-gray-400 disabled:text-ui-gray-300 disabled:bg-ui-gray-100",
              {
                "border-warning-orange-600 text-warning-orange-600 italic": p(n)
              }
            ]),
            onBlur: p(r)
          }, {
            default: pe(({ open: u }) => [
              i.value.length > 0 ? (M(), Y(Ae, { key: 0 }, [
                ut(Ne(i.value.map((c) => c.label).join(", ")), 1)
              ], 64)) : (M(), Y(Ae, { key: 1 }, [
                ut(Ne(s.placeholder), 1)
              ], 64)),
              le("span", _v, [
                Ee(p(ca), {
                  class: "h-4 w-4 text-gray-800",
                  "aria-hidden": "true",
                  icon: u ? "caret-up" : "caret-down"
                }, null, 8, ["icon"])
              ])
            ]),
            _: 1
          }, 8, ["class", "onBlur"]),
          (M(), Me(Yo, { to: "body" }, [
            le("div", {
              ref_key: "menuRef",
              ref: o,
              class: "absolute z-10 [[role=dialog]_&]:z-[10000]"
            }, [
              Ee(Ca, {
                "leave-active-class": "transition ease-in duration-100",
                "leave-from-class": "opacity-100",
                "leave-to-class": "opacity-0"
              }, {
                default: pe(() => [
                  Ee(p(k0), { class: "max-h-60 overflow-auto rounded-sm bg-white shadow-lg border border-ui-gray-400 border-t-0 focus:outline-none" }, {
                    default: pe(() => [
                      (M(!0), Y(Ae, null, ze(s.options, (u) => (M(), Me(p(x0), {
                        key: u.value,
                        value: u.value,
                        as: "li",
                        class: "ui-active:bg-pivot-teal-600 ui-active:text-white text-ui-gray-800 relative cursor-default select-none py-1 pl-2 pr-9 ui-selected:font-semibold font-normal"
                      }, {
                        default: pe(() => [
                          ut(Ne(u.label), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))), 128))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ], 512)
          ]))
        ]),
        _: 1
      }, 8, ["modelValue", "multiple"])
    ], 512));
  }
}), ru = 6048e5, Mv = 864e5, Pv = 6e4, lu = 36e5, Av = 1e3, yo = Symbol.for("constructDateFrom");
function Ve(e, t) {
  return typeof e == "function" ? e(t) : e && typeof e == "object" && yo in e ? e[yo](t) : e instanceof Date ? new e.constructor(t) : new Date(t);
}
function Pe(e, t) {
  return Ve(t || e, e);
}
function Ht(e, t, a) {
  const n = Pe(e, a == null ? void 0 : a.in);
  return isNaN(t) ? Ve((a == null ? void 0 : a.in) || e, NaN) : (t && n.setDate(n.getDate() + t), n);
}
function Vt(e, t, a) {
  const n = Pe(e, a == null ? void 0 : a.in);
  if (isNaN(t))
    return Ve((a == null ? void 0 : a.in) || e, NaN);
  if (!t)
    return n;
  const r = n.getDate(), i = Ve((a == null ? void 0 : a.in) || e, n.getTime());
  i.setMonth(n.getMonth() + t + 1, 0);
  const l = i.getDate();
  return r >= l ? i : (n.setFullYear(
    i.getFullYear(),
    i.getMonth(),
    r
  ), n);
}
function iu(e, t, a) {
  const {
    years: n = 0,
    months: r = 0,
    weeks: i = 0,
    days: l = 0,
    hours: o = 0,
    minutes: s = 0,
    seconds: d = 0
  } = t, u = Pe(e, a == null ? void 0 : a.in), c = r || n ? Vt(u, r + n * 12) : u, f = l || i ? Ht(c, l + i * 7) : c, v = s + o * 60, y = (d + v * 60) * 1e3;
  return Ve((a == null ? void 0 : a.in) || e, +f + y);
}
function Tv(e, t, a) {
  return Ve((a == null ? void 0 : a.in) || e, +Pe(e) + t);
}
function Ov(e, t, a) {
  return Tv(e, t * lu, a);
}
let Sv = {};
function Wa() {
  return Sv;
}
function Yt(e, t) {
  var o, s, d, u;
  const a = Wa(), n = (t == null ? void 0 : t.weekStartsOn) ?? ((s = (o = t == null ? void 0 : t.locale) == null ? void 0 : o.options) == null ? void 0 : s.weekStartsOn) ?? a.weekStartsOn ?? ((u = (d = a.locale) == null ? void 0 : d.options) == null ? void 0 : u.weekStartsOn) ?? 0, r = Pe(e, t == null ? void 0 : t.in), i = r.getDay(), l = (i < n ? 7 : 0) + i - n;
  return r.setDate(r.getDate() - l), r.setHours(0, 0, 0, 0), r;
}
function sn(e, t) {
  return Yt(e, { ...t, weekStartsOn: 1 });
}
function ou(e, t) {
  const a = Pe(e, t == null ? void 0 : t.in), n = a.getFullYear(), r = Ve(a, 0);
  r.setFullYear(n + 1, 0, 4), r.setHours(0, 0, 0, 0);
  const i = sn(r), l = Ve(a, 0);
  l.setFullYear(n, 0, 4), l.setHours(0, 0, 0, 0);
  const o = sn(l);
  return a.getTime() >= i.getTime() ? n + 1 : a.getTime() >= o.getTime() ? n : n - 1;
}
function wr(e) {
  const t = Pe(e), a = new Date(
    Date.UTC(
      t.getFullYear(),
      t.getMonth(),
      t.getDate(),
      t.getHours(),
      t.getMinutes(),
      t.getSeconds(),
      t.getMilliseconds()
    )
  );
  return a.setUTCFullYear(t.getFullYear()), +e - +a;
}
function Yn(e, ...t) {
  const a = Ve.bind(
    null,
    e || t.find((n) => typeof n == "object")
  );
  return t.map(a);
}
function go(e, t) {
  const a = Pe(e, t == null ? void 0 : t.in);
  return a.setHours(0, 0, 0, 0), a;
}
function su(e, t, a) {
  const [n, r] = Yn(
    a == null ? void 0 : a.in,
    e,
    t
  ), i = go(n), l = go(r), o = +i - wr(i), s = +l - wr(l);
  return Math.round((o - s) / Mv);
}
function Cv(e, t) {
  const a = ou(e, t), n = Ve((t == null ? void 0 : t.in) || e, 0);
  return n.setFullYear(a, 0, 4), n.setHours(0, 0, 0, 0), sn(n);
}
function Dv(e, t, a) {
  return Vt(e, t * 3, a);
}
function vi(e, t, a) {
  return Vt(e, t * 12, a);
}
function bo(e, t) {
  const a = +Pe(e) - +Pe(t);
  return a < 0 ? -1 : a > 0 ? 1 : a;
}
function uu(e) {
  return e instanceof Date || typeof e == "object" && Object.prototype.toString.call(e) === "[object Date]";
}
function Pn(e) {
  return !(!uu(e) && typeof e != "number" || isNaN(+Pe(e)));
}
function wo(e, t) {
  const a = Pe(e, t == null ? void 0 : t.in);
  return Math.trunc(a.getMonth() / 3) + 1;
}
function $v(e, t, a) {
  const [n, r] = Yn(
    a == null ? void 0 : a.in,
    e,
    t
  );
  return n.getFullYear() - r.getFullYear();
}
function Iv(e, t, a) {
  const [n, r] = Yn(
    a == null ? void 0 : a.in,
    e,
    t
  ), i = bo(n, r), l = Math.abs($v(n, r));
  n.setFullYear(1584), r.setFullYear(1584);
  const o = bo(n, r) === -i, s = i * (l - +o);
  return s === 0 ? 0 : s;
}
function cu(e, t) {
  const [a, n] = Yn(e, t.start, t.end);
  return { start: a, end: n };
}
function du(e, t) {
  const { start: a, end: n } = cu(t == null ? void 0 : t.in, e);
  let r = +a > +n;
  const i = r ? +a : +n, l = r ? n : a;
  l.setHours(0, 0, 0, 0);
  let o = (t == null ? void 0 : t.step) ?? 1;
  if (!o)
    return [];
  o < 0 && (o = -o, r = !r);
  const s = [];
  for (; +l <= i; )
    s.push(Ve(a, l)), l.setDate(l.getDate() + o), l.setHours(0, 0, 0, 0);
  return r ? s.reverse() : s;
}
function La(e, t) {
  const a = Pe(e, t == null ? void 0 : t.in), n = a.getMonth(), r = n - n % 3;
  return a.setMonth(r, 1), a.setHours(0, 0, 0, 0), a;
}
function Ev(e, t) {
  const { start: a, end: n } = cu(t == null ? void 0 : t.in, e);
  let r = +a > +n;
  const i = r ? +La(a) : +La(n);
  let l = La(r ? n : a), o = (t == null ? void 0 : t.step) ?? 1;
  if (!o)
    return [];
  o < 0 && (o = -o, r = !r);
  const s = [];
  for (; +l <= i; )
    s.push(Ve(a, l)), l = Dv(l, o);
  return r ? s.reverse() : s;
}
function Rv(e, t) {
  const a = Pe(e, t == null ? void 0 : t.in);
  return a.setDate(1), a.setHours(0, 0, 0, 0), a;
}
function fu(e, t) {
  const a = Pe(e, t == null ? void 0 : t.in), n = a.getFullYear();
  return a.setFullYear(n + 1, 0, 0), a.setHours(23, 59, 59, 999), a;
}
function En(e, t) {
  const a = Pe(e, t == null ? void 0 : t.in);
  return a.setFullYear(a.getFullYear(), 0, 1), a.setHours(0, 0, 0, 0), a;
}
function vu(e, t) {
  var o, s, d, u;
  const a = Wa(), n = (t == null ? void 0 : t.weekStartsOn) ?? ((s = (o = t == null ? void 0 : t.locale) == null ? void 0 : o.options) == null ? void 0 : s.weekStartsOn) ?? a.weekStartsOn ?? ((u = (d = a.locale) == null ? void 0 : d.options) == null ? void 0 : u.weekStartsOn) ?? 0, r = Pe(e, t == null ? void 0 : t.in), i = r.getDay(), l = (i < n ? -7 : 0) + 6 - (i - n);
  return r.setDate(r.getDate() + l), r.setHours(23, 59, 59, 999), r;
}
function ko(e, t) {
  const a = Pe(e, t == null ? void 0 : t.in), n = a.getMonth(), r = n - n % 3 + 3;
  return a.setMonth(r, 0), a.setHours(23, 59, 59, 999), a;
}
const Nv = {
  lessThanXSeconds: {
    one: "less than a second",
    other: "less than {{count}} seconds"
  },
  xSeconds: {
    one: "1 second",
    other: "{{count}} seconds"
  },
  halfAMinute: "half a minute",
  lessThanXMinutes: {
    one: "less than a minute",
    other: "less than {{count}} minutes"
  },
  xMinutes: {
    one: "1 minute",
    other: "{{count}} minutes"
  },
  aboutXHours: {
    one: "about 1 hour",
    other: "about {{count}} hours"
  },
  xHours: {
    one: "1 hour",
    other: "{{count}} hours"
  },
  xDays: {
    one: "1 day",
    other: "{{count}} days"
  },
  aboutXWeeks: {
    one: "about 1 week",
    other: "about {{count}} weeks"
  },
  xWeeks: {
    one: "1 week",
    other: "{{count}} weeks"
  },
  aboutXMonths: {
    one: "about 1 month",
    other: "about {{count}} months"
  },
  xMonths: {
    one: "1 month",
    other: "{{count}} months"
  },
  aboutXYears: {
    one: "about 1 year",
    other: "about {{count}} years"
  },
  xYears: {
    one: "1 year",
    other: "{{count}} years"
  },
  overXYears: {
    one: "over 1 year",
    other: "over {{count}} years"
  },
  almostXYears: {
    one: "almost 1 year",
    other: "almost {{count}} years"
  }
}, Lv = (e, t, a) => {
  let n;
  const r = Nv[e];
  return typeof r == "string" ? n = r : t === 1 ? n = r.one : n = r.other.replace("{{count}}", t.toString()), a != null && a.addSuffix ? a.comparison && a.comparison > 0 ? "in " + n : n + " ago" : n;
};
function nl(e) {
  return (t = {}) => {
    const a = t.width ? String(t.width) : e.defaultWidth;
    return e.formats[a] || e.formats[e.defaultWidth];
  };
}
const Hv = {
  full: "EEEE, MMMM do, y",
  long: "MMMM do, y",
  medium: "MMM d, y",
  short: "MM/dd/yyyy"
}, Fv = {
  full: "h:mm:ss a zzzz",
  long: "h:mm:ss a z",
  medium: "h:mm:ss a",
  short: "h:mm a"
}, zv = {
  full: "{{date}} 'at' {{time}}",
  long: "{{date}} 'at' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
}, Vv = {
  date: nl({
    formats: Hv,
    defaultWidth: "full"
  }),
  time: nl({
    formats: Fv,
    defaultWidth: "full"
  }),
  dateTime: nl({
    formats: zv,
    defaultWidth: "full"
  })
}, Yv = {
  lastWeek: "'last' eeee 'at' p",
  yesterday: "'yesterday at' p",
  today: "'today at' p",
  tomorrow: "'tomorrow at' p",
  nextWeek: "eeee 'at' p",
  other: "P"
}, Bv = (e, t, a, n) => Yv[e];
function gn(e) {
  return (t, a) => {
    const n = a != null && a.context ? String(a.context) : "standalone";
    let r;
    if (n === "formatting" && e.formattingValues) {
      const l = e.defaultFormattingWidth || e.defaultWidth, o = a != null && a.width ? String(a.width) : l;
      r = e.formattingValues[o] || e.formattingValues[l];
    } else {
      const l = e.defaultWidth, o = a != null && a.width ? String(a.width) : e.defaultWidth;
      r = e.values[o] || e.values[l];
    }
    const i = e.argumentCallback ? e.argumentCallback(t) : t;
    return r[i];
  };
}
const jv = {
  narrow: ["B", "A"],
  abbreviated: ["BC", "AD"],
  wide: ["Before Christ", "Anno Domini"]
}, Uv = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["Q1", "Q2", "Q3", "Q4"],
  wide: ["1st quarter", "2nd quarter", "3rd quarter", "4th quarter"]
}, Wv = {
  narrow: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
  abbreviated: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ],
  wide: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ]
}, qv = {
  narrow: ["S", "M", "T", "W", "T", "F", "S"],
  short: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
  abbreviated: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  wide: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ]
}, Xv = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "mi",
    noon: "n",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "midnight",
    noon: "noon",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "midnight",
    noon: "noon",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  }
}, Qv = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "mi",
    noon: "n",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "midnight",
    noon: "noon",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "midnight",
    noon: "noon",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  }
}, Kv = (e, t) => {
  const a = Number(e), n = a % 100;
  if (n > 20 || n < 10)
    switch (n % 10) {
      case 1:
        return a + "st";
      case 2:
        return a + "nd";
      case 3:
        return a + "rd";
    }
  return a + "th";
}, Gv = {
  ordinalNumber: Kv,
  era: gn({
    values: jv,
    defaultWidth: "wide"
  }),
  quarter: gn({
    values: Uv,
    defaultWidth: "wide",
    argumentCallback: (e) => e - 1
  }),
  month: gn({
    values: Wv,
    defaultWidth: "wide"
  }),
  day: gn({
    values: qv,
    defaultWidth: "wide"
  }),
  dayPeriod: gn({
    values: Xv,
    defaultWidth: "wide",
    formattingValues: Qv,
    defaultFormattingWidth: "wide"
  })
};
function bn(e) {
  return (t, a = {}) => {
    const n = a.width, r = n && e.matchPatterns[n] || e.matchPatterns[e.defaultMatchWidth], i = t.match(r);
    if (!i)
      return null;
    const l = i[0], o = n && e.parsePatterns[n] || e.parsePatterns[e.defaultParseWidth], s = Array.isArray(o) ? Zv(o, (c) => c.test(l)) : (
      // [TODO] -- I challenge you to fix the type
      Jv(o, (c) => c.test(l))
    );
    let d;
    d = e.valueCallback ? e.valueCallback(s) : s, d = a.valueCallback ? (
      // [TODO] -- I challenge you to fix the type
      a.valueCallback(d)
    ) : d;
    const u = t.slice(l.length);
    return { value: d, rest: u };
  };
}
function Jv(e, t) {
  for (const a in e)
    if (Object.prototype.hasOwnProperty.call(e, a) && t(e[a]))
      return a;
}
function Zv(e, t) {
  for (let a = 0; a < e.length; a++)
    if (t(e[a]))
      return a;
}
function em(e) {
  return (t, a = {}) => {
    const n = t.match(e.matchPattern);
    if (!n)
      return null;
    const r = n[0], i = t.match(e.parsePattern);
    if (!i)
      return null;
    let l = e.valueCallback ? e.valueCallback(i[0]) : i[0];
    l = a.valueCallback ? a.valueCallback(l) : l;
    const o = t.slice(r.length);
    return { value: l, rest: o };
  };
}
const tm = /^(\d+)(th|st|nd|rd)?/i, am = /\d+/i, nm = {
  narrow: /^(b|a)/i,
  abbreviated: /^(b\.?\s?c\.?|b\.?\s?c\.?\s?e\.?|a\.?\s?d\.?|c\.?\s?e\.?)/i,
  wide: /^(before christ|before common era|anno domini|common era)/i
}, rm = {
  any: [/^b/i, /^(a|c)/i]
}, lm = {
  narrow: /^[1234]/i,
  abbreviated: /^q[1234]/i,
  wide: /^[1234](th|st|nd|rd)? quarter/i
}, im = {
  any: [/1/i, /2/i, /3/i, /4/i]
}, om = {
  narrow: /^[jfmasond]/i,
  abbreviated: /^(jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)/i,
  wide: /^(january|february|march|april|may|june|july|august|september|october|november|december)/i
}, sm = {
  narrow: [
    /^j/i,
    /^f/i,
    /^m/i,
    /^a/i,
    /^m/i,
    /^j/i,
    /^j/i,
    /^a/i,
    /^s/i,
    /^o/i,
    /^n/i,
    /^d/i
  ],
  any: [
    /^ja/i,
    /^f/i,
    /^mar/i,
    /^ap/i,
    /^may/i,
    /^jun/i,
    /^jul/i,
    /^au/i,
    /^s/i,
    /^o/i,
    /^n/i,
    /^d/i
  ]
}, um = {
  narrow: /^[smtwf]/i,
  short: /^(su|mo|tu|we|th|fr|sa)/i,
  abbreviated: /^(sun|mon|tue|wed|thu|fri|sat)/i,
  wide: /^(sunday|monday|tuesday|wednesday|thursday|friday|saturday)/i
}, cm = {
  narrow: [/^s/i, /^m/i, /^t/i, /^w/i, /^t/i, /^f/i, /^s/i],
  any: [/^su/i, /^m/i, /^tu/i, /^w/i, /^th/i, /^f/i, /^sa/i]
}, dm = {
  narrow: /^(a|p|mi|n|(in the|at) (morning|afternoon|evening|night))/i,
  any: /^([ap]\.?\s?m\.?|midnight|noon|(in the|at) (morning|afternoon|evening|night))/i
}, fm = {
  any: {
    am: /^a/i,
    pm: /^p/i,
    midnight: /^mi/i,
    noon: /^no/i,
    morning: /morning/i,
    afternoon: /afternoon/i,
    evening: /evening/i,
    night: /night/i
  }
}, vm = {
  ordinalNumber: em({
    matchPattern: tm,
    parsePattern: am,
    valueCallback: (e) => parseInt(e, 10)
  }),
  era: bn({
    matchPatterns: nm,
    defaultMatchWidth: "wide",
    parsePatterns: rm,
    defaultParseWidth: "any"
  }),
  quarter: bn({
    matchPatterns: lm,
    defaultMatchWidth: "wide",
    parsePatterns: im,
    defaultParseWidth: "any",
    valueCallback: (e) => e + 1
  }),
  month: bn({
    matchPatterns: om,
    defaultMatchWidth: "wide",
    parsePatterns: sm,
    defaultParseWidth: "any"
  }),
  day: bn({
    matchPatterns: um,
    defaultMatchWidth: "wide",
    parsePatterns: cm,
    defaultParseWidth: "any"
  }),
  dayPeriod: bn({
    matchPatterns: dm,
    defaultMatchWidth: "any",
    parsePatterns: fm,
    defaultParseWidth: "any"
  })
}, mu = {
  code: "en-US",
  formatDistance: Lv,
  formatLong: Vv,
  formatRelative: Bv,
  localize: Gv,
  match: vm,
  options: {
    weekStartsOn: 0,
    firstWeekContainsDate: 1
  }
};
function mm(e, t) {
  const a = Pe(e, t == null ? void 0 : t.in);
  return su(a, En(a)) + 1;
}
function mi(e, t) {
  const a = Pe(e, t == null ? void 0 : t.in), n = +sn(a) - +Cv(a);
  return Math.round(n / ru) + 1;
}
function pi(e, t) {
  var u, c, f, v;
  const a = Pe(e, t == null ? void 0 : t.in), n = a.getFullYear(), r = Wa(), i = (t == null ? void 0 : t.firstWeekContainsDate) ?? ((c = (u = t == null ? void 0 : t.locale) == null ? void 0 : u.options) == null ? void 0 : c.firstWeekContainsDate) ?? r.firstWeekContainsDate ?? ((v = (f = r.locale) == null ? void 0 : f.options) == null ? void 0 : v.firstWeekContainsDate) ?? 1, l = Ve((t == null ? void 0 : t.in) || e, 0);
  l.setFullYear(n + 1, 0, i), l.setHours(0, 0, 0, 0);
  const o = Yt(l, t), s = Ve((t == null ? void 0 : t.in) || e, 0);
  s.setFullYear(n, 0, i), s.setHours(0, 0, 0, 0);
  const d = Yt(s, t);
  return +a >= +o ? n + 1 : +a >= +d ? n : n - 1;
}
function pm(e, t) {
  var o, s, d, u;
  const a = Wa(), n = (t == null ? void 0 : t.firstWeekContainsDate) ?? ((s = (o = t == null ? void 0 : t.locale) == null ? void 0 : o.options) == null ? void 0 : s.firstWeekContainsDate) ?? a.firstWeekContainsDate ?? ((u = (d = a.locale) == null ? void 0 : d.options) == null ? void 0 : u.firstWeekContainsDate) ?? 1, r = pi(e, t), i = Ve((t == null ? void 0 : t.in) || e, 0);
  return i.setFullYear(r, 0, n), i.setHours(0, 0, 0, 0), Yt(i, t);
}
function hi(e, t) {
  const a = Pe(e, t == null ? void 0 : t.in), n = +Yt(a, t) - +pm(a, t);
  return Math.round(n / ru) + 1;
}
function Fe(e, t) {
  const a = e < 0 ? "-" : "", n = Math.abs(e).toString().padStart(t, "0");
  return a + n;
}
const ya = {
  // Year
  y(e, t) {
    const a = e.getFullYear(), n = a > 0 ? a : 1 - a;
    return Fe(t === "yy" ? n % 100 : n, t.length);
  },
  // Month
  M(e, t) {
    const a = e.getMonth();
    return t === "M" ? String(a + 1) : Fe(a + 1, 2);
  },
  // Day of the month
  d(e, t) {
    return Fe(e.getDate(), t.length);
  },
  // AM or PM
  a(e, t) {
    const a = e.getHours() / 12 >= 1 ? "pm" : "am";
    switch (t) {
      case "a":
      case "aa":
        return a.toUpperCase();
      case "aaa":
        return a;
      case "aaaaa":
        return a[0];
      case "aaaa":
      default:
        return a === "am" ? "a.m." : "p.m.";
    }
  },
  // Hour [1-12]
  h(e, t) {
    return Fe(e.getHours() % 12 || 12, t.length);
  },
  // Hour [0-23]
  H(e, t) {
    return Fe(e.getHours(), t.length);
  },
  // Minute
  m(e, t) {
    return Fe(e.getMinutes(), t.length);
  },
  // Second
  s(e, t) {
    return Fe(e.getSeconds(), t.length);
  },
  // Fraction of second
  S(e, t) {
    const a = t.length, n = e.getMilliseconds(), r = Math.trunc(
      n * Math.pow(10, a - 3)
    );
    return Fe(r, t.length);
  }
}, Xa = {
  am: "am",
  pm: "pm",
  midnight: "midnight",
  noon: "noon",
  morning: "morning",
  afternoon: "afternoon",
  evening: "evening",
  night: "night"
}, xo = {
  // Era
  G: function(e, t, a) {
    const n = e.getFullYear() > 0 ? 1 : 0;
    switch (t) {
      case "G":
      case "GG":
      case "GGG":
        return a.era(n, { width: "abbreviated" });
      case "GGGGG":
        return a.era(n, { width: "narrow" });
      case "GGGG":
      default:
        return a.era(n, { width: "wide" });
    }
  },
  // Year
  y: function(e, t, a) {
    if (t === "yo") {
      const n = e.getFullYear(), r = n > 0 ? n : 1 - n;
      return a.ordinalNumber(r, { unit: "year" });
    }
    return ya.y(e, t);
  },
  // Local week-numbering year
  Y: function(e, t, a, n) {
    const r = pi(e, n), i = r > 0 ? r : 1 - r;
    if (t === "YY") {
      const l = i % 100;
      return Fe(l, 2);
    }
    return t === "Yo" ? a.ordinalNumber(i, { unit: "year" }) : Fe(i, t.length);
  },
  // ISO week-numbering year
  R: function(e, t) {
    const a = ou(e);
    return Fe(a, t.length);
  },
  // Extended year. This is a single number designating the year of this calendar system.
  // The main difference between `y` and `u` localizers are B.C. years:
  // | Year | `y` | `u` |
  // |------|-----|-----|
  // | AC 1 |   1 |   1 |
  // | BC 1 |   1 |   0 |
  // | BC 2 |   2 |  -1 |
  // Also `yy` always returns the last two digits of a year,
  // while `uu` pads single digit years to 2 characters and returns other years unchanged.
  u: function(e, t) {
    const a = e.getFullYear();
    return Fe(a, t.length);
  },
  // Quarter
  Q: function(e, t, a) {
    const n = Math.ceil((e.getMonth() + 1) / 3);
    switch (t) {
      case "Q":
        return String(n);
      case "QQ":
        return Fe(n, 2);
      case "Qo":
        return a.ordinalNumber(n, { unit: "quarter" });
      case "QQQ":
        return a.quarter(n, {
          width: "abbreviated",
          context: "formatting"
        });
      case "QQQQQ":
        return a.quarter(n, {
          width: "narrow",
          context: "formatting"
        });
      case "QQQQ":
      default:
        return a.quarter(n, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Stand-alone quarter
  q: function(e, t, a) {
    const n = Math.ceil((e.getMonth() + 1) / 3);
    switch (t) {
      case "q":
        return String(n);
      case "qq":
        return Fe(n, 2);
      case "qo":
        return a.ordinalNumber(n, { unit: "quarter" });
      case "qqq":
        return a.quarter(n, {
          width: "abbreviated",
          context: "standalone"
        });
      case "qqqqq":
        return a.quarter(n, {
          width: "narrow",
          context: "standalone"
        });
      case "qqqq":
      default:
        return a.quarter(n, {
          width: "wide",
          context: "standalone"
        });
    }
  },
  // Month
  M: function(e, t, a) {
    const n = e.getMonth();
    switch (t) {
      case "M":
      case "MM":
        return ya.M(e, t);
      case "Mo":
        return a.ordinalNumber(n + 1, { unit: "month" });
      case "MMM":
        return a.month(n, {
          width: "abbreviated",
          context: "formatting"
        });
      case "MMMMM":
        return a.month(n, {
          width: "narrow",
          context: "formatting"
        });
      case "MMMM":
      default:
        return a.month(n, { width: "wide", context: "formatting" });
    }
  },
  // Stand-alone month
  L: function(e, t, a) {
    const n = e.getMonth();
    switch (t) {
      case "L":
        return String(n + 1);
      case "LL":
        return Fe(n + 1, 2);
      case "Lo":
        return a.ordinalNumber(n + 1, { unit: "month" });
      case "LLL":
        return a.month(n, {
          width: "abbreviated",
          context: "standalone"
        });
      case "LLLLL":
        return a.month(n, {
          width: "narrow",
          context: "standalone"
        });
      case "LLLL":
      default:
        return a.month(n, { width: "wide", context: "standalone" });
    }
  },
  // Local week of year
  w: function(e, t, a, n) {
    const r = hi(e, n);
    return t === "wo" ? a.ordinalNumber(r, { unit: "week" }) : Fe(r, t.length);
  },
  // ISO week of year
  I: function(e, t, a) {
    const n = mi(e);
    return t === "Io" ? a.ordinalNumber(n, { unit: "week" }) : Fe(n, t.length);
  },
  // Day of the month
  d: function(e, t, a) {
    return t === "do" ? a.ordinalNumber(e.getDate(), { unit: "date" }) : ya.d(e, t);
  },
  // Day of year
  D: function(e, t, a) {
    const n = mm(e);
    return t === "Do" ? a.ordinalNumber(n, { unit: "dayOfYear" }) : Fe(n, t.length);
  },
  // Day of week
  E: function(e, t, a) {
    const n = e.getDay();
    switch (t) {
      case "E":
      case "EE":
      case "EEE":
        return a.day(n, {
          width: "abbreviated",
          context: "formatting"
        });
      case "EEEEE":
        return a.day(n, {
          width: "narrow",
          context: "formatting"
        });
      case "EEEEEE":
        return a.day(n, {
          width: "short",
          context: "formatting"
        });
      case "EEEE":
      default:
        return a.day(n, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Local day of week
  e: function(e, t, a, n) {
    const r = e.getDay(), i = (r - n.weekStartsOn + 8) % 7 || 7;
    switch (t) {
      case "e":
        return String(i);
      case "ee":
        return Fe(i, 2);
      case "eo":
        return a.ordinalNumber(i, { unit: "day" });
      case "eee":
        return a.day(r, {
          width: "abbreviated",
          context: "formatting"
        });
      case "eeeee":
        return a.day(r, {
          width: "narrow",
          context: "formatting"
        });
      case "eeeeee":
        return a.day(r, {
          width: "short",
          context: "formatting"
        });
      case "eeee":
      default:
        return a.day(r, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Stand-alone local day of week
  c: function(e, t, a, n) {
    const r = e.getDay(), i = (r - n.weekStartsOn + 8) % 7 || 7;
    switch (t) {
      case "c":
        return String(i);
      case "cc":
        return Fe(i, t.length);
      case "co":
        return a.ordinalNumber(i, { unit: "day" });
      case "ccc":
        return a.day(r, {
          width: "abbreviated",
          context: "standalone"
        });
      case "ccccc":
        return a.day(r, {
          width: "narrow",
          context: "standalone"
        });
      case "cccccc":
        return a.day(r, {
          width: "short",
          context: "standalone"
        });
      case "cccc":
      default:
        return a.day(r, {
          width: "wide",
          context: "standalone"
        });
    }
  },
  // ISO day of week
  i: function(e, t, a) {
    const n = e.getDay(), r = n === 0 ? 7 : n;
    switch (t) {
      case "i":
        return String(r);
      case "ii":
        return Fe(r, t.length);
      case "io":
        return a.ordinalNumber(r, { unit: "day" });
      case "iii":
        return a.day(n, {
          width: "abbreviated",
          context: "formatting"
        });
      case "iiiii":
        return a.day(n, {
          width: "narrow",
          context: "formatting"
        });
      case "iiiiii":
        return a.day(n, {
          width: "short",
          context: "formatting"
        });
      case "iiii":
      default:
        return a.day(n, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // AM or PM
  a: function(e, t, a) {
    const r = e.getHours() / 12 >= 1 ? "pm" : "am";
    switch (t) {
      case "a":
      case "aa":
        return a.dayPeriod(r, {
          width: "abbreviated",
          context: "formatting"
        });
      case "aaa":
        return a.dayPeriod(r, {
          width: "abbreviated",
          context: "formatting"
        }).toLowerCase();
      case "aaaaa":
        return a.dayPeriod(r, {
          width: "narrow",
          context: "formatting"
        });
      case "aaaa":
      default:
        return a.dayPeriod(r, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // AM, PM, midnight, noon
  b: function(e, t, a) {
    const n = e.getHours();
    let r;
    switch (n === 12 ? r = Xa.noon : n === 0 ? r = Xa.midnight : r = n / 12 >= 1 ? "pm" : "am", t) {
      case "b":
      case "bb":
        return a.dayPeriod(r, {
          width: "abbreviated",
          context: "formatting"
        });
      case "bbb":
        return a.dayPeriod(r, {
          width: "abbreviated",
          context: "formatting"
        }).toLowerCase();
      case "bbbbb":
        return a.dayPeriod(r, {
          width: "narrow",
          context: "formatting"
        });
      case "bbbb":
      default:
        return a.dayPeriod(r, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // in the morning, in the afternoon, in the evening, at night
  B: function(e, t, a) {
    const n = e.getHours();
    let r;
    switch (n >= 17 ? r = Xa.evening : n >= 12 ? r = Xa.afternoon : n >= 4 ? r = Xa.morning : r = Xa.night, t) {
      case "B":
      case "BB":
      case "BBB":
        return a.dayPeriod(r, {
          width: "abbreviated",
          context: "formatting"
        });
      case "BBBBB":
        return a.dayPeriod(r, {
          width: "narrow",
          context: "formatting"
        });
      case "BBBB":
      default:
        return a.dayPeriod(r, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Hour [1-12]
  h: function(e, t, a) {
    if (t === "ho") {
      let n = e.getHours() % 12;
      return n === 0 && (n = 12), a.ordinalNumber(n, { unit: "hour" });
    }
    return ya.h(e, t);
  },
  // Hour [0-23]
  H: function(e, t, a) {
    return t === "Ho" ? a.ordinalNumber(e.getHours(), { unit: "hour" }) : ya.H(e, t);
  },
  // Hour [0-11]
  K: function(e, t, a) {
    const n = e.getHours() % 12;
    return t === "Ko" ? a.ordinalNumber(n, { unit: "hour" }) : Fe(n, t.length);
  },
  // Hour [1-24]
  k: function(e, t, a) {
    let n = e.getHours();
    return n === 0 && (n = 24), t === "ko" ? a.ordinalNumber(n, { unit: "hour" }) : Fe(n, t.length);
  },
  // Minute
  m: function(e, t, a) {
    return t === "mo" ? a.ordinalNumber(e.getMinutes(), { unit: "minute" }) : ya.m(e, t);
  },
  // Second
  s: function(e, t, a) {
    return t === "so" ? a.ordinalNumber(e.getSeconds(), { unit: "second" }) : ya.s(e, t);
  },
  // Fraction of second
  S: function(e, t) {
    return ya.S(e, t);
  },
  // Timezone (ISO-8601. If offset is 0, output is always `'Z'`)
  X: function(e, t, a) {
    const n = e.getTimezoneOffset();
    if (n === 0)
      return "Z";
    switch (t) {
      case "X":
        return Mo(n);
      case "XXXX":
      case "XX":
        return Ea(n);
      case "XXXXX":
      case "XXX":
      default:
        return Ea(n, ":");
    }
  },
  // Timezone (ISO-8601. If offset is 0, output is `'+00:00'` or equivalent)
  x: function(e, t, a) {
    const n = e.getTimezoneOffset();
    switch (t) {
      case "x":
        return Mo(n);
      case "xxxx":
      case "xx":
        return Ea(n);
      case "xxxxx":
      case "xxx":
      default:
        return Ea(n, ":");
    }
  },
  // Timezone (GMT)
  O: function(e, t, a) {
    const n = e.getTimezoneOffset();
    switch (t) {
      case "O":
      case "OO":
      case "OOO":
        return "GMT" + _o(n, ":");
      case "OOOO":
      default:
        return "GMT" + Ea(n, ":");
    }
  },
  // Timezone (specific non-location)
  z: function(e, t, a) {
    const n = e.getTimezoneOffset();
    switch (t) {
      case "z":
      case "zz":
      case "zzz":
        return "GMT" + _o(n, ":");
      case "zzzz":
      default:
        return "GMT" + Ea(n, ":");
    }
  },
  // Seconds timestamp
  t: function(e, t, a) {
    const n = Math.trunc(+e / 1e3);
    return Fe(n, t.length);
  },
  // Milliseconds timestamp
  T: function(e, t, a) {
    return Fe(+e, t.length);
  }
};
function _o(e, t = "") {
  const a = e > 0 ? "-" : "+", n = Math.abs(e), r = Math.trunc(n / 60), i = n % 60;
  return i === 0 ? a + String(r) : a + String(r) + t + Fe(i, 2);
}
function Mo(e, t) {
  return e % 60 === 0 ? (e > 0 ? "-" : "+") + Fe(Math.abs(e) / 60, 2) : Ea(e, t);
}
function Ea(e, t = "") {
  const a = e > 0 ? "-" : "+", n = Math.abs(e), r = Fe(Math.trunc(n / 60), 2), i = Fe(n % 60, 2);
  return a + r + t + i;
}
const Po = (e, t) => {
  switch (e) {
    case "P":
      return t.date({ width: "short" });
    case "PP":
      return t.date({ width: "medium" });
    case "PPP":
      return t.date({ width: "long" });
    case "PPPP":
    default:
      return t.date({ width: "full" });
  }
}, pu = (e, t) => {
  switch (e) {
    case "p":
      return t.time({ width: "short" });
    case "pp":
      return t.time({ width: "medium" });
    case "ppp":
      return t.time({ width: "long" });
    case "pppp":
    default:
      return t.time({ width: "full" });
  }
}, hm = (e, t) => {
  const a = e.match(/(P+)(p+)?/) || [], n = a[1], r = a[2];
  if (!r)
    return Po(e, t);
  let i;
  switch (n) {
    case "P":
      i = t.dateTime({ width: "short" });
      break;
    case "PP":
      i = t.dateTime({ width: "medium" });
      break;
    case "PPP":
      i = t.dateTime({ width: "long" });
      break;
    case "PPPP":
    default:
      i = t.dateTime({ width: "full" });
      break;
  }
  return i.replace("{{date}}", Po(n, t)).replace("{{time}}", pu(r, t));
}, El = {
  p: pu,
  P: hm
}, ym = /^D+$/, gm = /^Y+$/, bm = ["D", "DD", "YY", "YYYY"];
function hu(e) {
  return ym.test(e);
}
function yu(e) {
  return gm.test(e);
}
function Rl(e, t, a) {
  const n = wm(e, t, a);
  if (console.warn(n), bm.includes(e))
    throw new RangeError(n);
}
function wm(e, t, a) {
  const n = e[0] === "Y" ? "years" : "days of the month";
  return `Use \`${e.toLowerCase()}\` instead of \`${e}\` (in \`${t}\`) for formatting ${n} to the input \`${a}\`; see: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md`;
}
const km = /[yYQqMLwIdDecihHKkms]o|(\w)\1*|''|'(''|[^'])+('|$)|./g, xm = /P+p+|P+|p+|''|'(''|[^'])+('|$)|./g, _m = /^'([^]*?)'?$/, Mm = /''/g, Pm = /[a-zA-Z]/;
function Zt(e, t, a) {
  var u, c, f, v, g, y, P, b;
  const n = Wa(), r = (a == null ? void 0 : a.locale) ?? n.locale ?? mu, i = (a == null ? void 0 : a.firstWeekContainsDate) ?? ((c = (u = a == null ? void 0 : a.locale) == null ? void 0 : u.options) == null ? void 0 : c.firstWeekContainsDate) ?? n.firstWeekContainsDate ?? ((v = (f = n.locale) == null ? void 0 : f.options) == null ? void 0 : v.firstWeekContainsDate) ?? 1, l = (a == null ? void 0 : a.weekStartsOn) ?? ((y = (g = a == null ? void 0 : a.locale) == null ? void 0 : g.options) == null ? void 0 : y.weekStartsOn) ?? n.weekStartsOn ?? ((b = (P = n.locale) == null ? void 0 : P.options) == null ? void 0 : b.weekStartsOn) ?? 0, o = Pe(e, a == null ? void 0 : a.in);
  if (!Pn(o))
    throw new RangeError("Invalid time value");
  let s = t.match(xm).map((w) => {
    const h = w[0];
    if (h === "p" || h === "P") {
      const _ = El[h];
      return _(w, r.formatLong);
    }
    return w;
  }).join("").match(km).map((w) => {
    if (w === "''")
      return { isToken: !1, value: "'" };
    const h = w[0];
    if (h === "'")
      return { isToken: !1, value: Am(w) };
    if (xo[h])
      return { isToken: !0, value: w };
    if (h.match(Pm))
      throw new RangeError(
        "Format string contains an unescaped latin alphabet character `" + h + "`"
      );
    return { isToken: !1, value: w };
  });
  r.localize.preprocessor && (s = r.localize.preprocessor(o, s));
  const d = {
    firstWeekContainsDate: i,
    weekStartsOn: l,
    locale: r
  };
  return s.map((w) => {
    if (!w.isToken)
      return w.value;
    const h = w.value;
    (!(a != null && a.useAdditionalWeekYearTokens) && yu(h) || !(a != null && a.useAdditionalDayOfYearTokens) && hu(h)) && Rl(h, t, String(e));
    const _ = xo[h[0]];
    return _(o, h, r.localize, d);
  }).join("");
}
function Am(e) {
  const t = e.match(_m);
  return t ? t[1].replace(Mm, "'") : e;
}
function Tm(e, t) {
  return Pe(e, t == null ? void 0 : t.in).getDay();
}
function Om(e, t) {
  const a = Pe(e, t == null ? void 0 : t.in), n = a.getFullYear(), r = a.getMonth(), i = Ve(a, 0);
  return i.setFullYear(n, r + 1, 0), i.setHours(0, 0, 0, 0), i.getDate();
}
function Sm() {
  return Object.assign({}, Wa());
}
function fa(e, t) {
  return Pe(e, t == null ? void 0 : t.in).getHours();
}
function Cm(e, t) {
  const a = Pe(e, t == null ? void 0 : t.in).getDay();
  return a === 0 ? 7 : a;
}
function Sa(e, t) {
  return Pe(e, t == null ? void 0 : t.in).getMinutes();
}
function De(e, t) {
  return Pe(e, t == null ? void 0 : t.in).getMonth();
}
function un(e) {
  return Pe(e).getSeconds();
}
function Te(e, t) {
  return Pe(e, t == null ? void 0 : t.in).getFullYear();
}
function cn(e, t) {
  return +Pe(e) > +Pe(t);
}
function Rn(e, t) {
  return +Pe(e) < +Pe(t);
}
function Za(e, t) {
  return +Pe(e) == +Pe(t);
}
function Dm(e, t) {
  const a = $m(t) ? new t(0) : Ve(t, 0);
  return a.setFullYear(e.getFullYear(), e.getMonth(), e.getDate()), a.setHours(
    e.getHours(),
    e.getMinutes(),
    e.getSeconds(),
    e.getMilliseconds()
  ), a;
}
function $m(e) {
  var t;
  return typeof e == "function" && ((t = e.prototype) == null ? void 0 : t.constructor) === e;
}
const Im = 10;
class gu {
  constructor() {
    fe(this, "subPriority", 0);
  }
  validate(t, a) {
    return !0;
  }
}
class Em extends gu {
  constructor(t, a, n, r, i) {
    super(), this.value = t, this.validateValue = a, this.setValue = n, this.priority = r, i && (this.subPriority = i);
  }
  validate(t, a) {
    return this.validateValue(t, this.value, a);
  }
  set(t, a, n) {
    return this.setValue(t, a, this.value, n);
  }
}
class Rm extends gu {
  constructor(a, n) {
    super();
    fe(this, "priority", Im);
    fe(this, "subPriority", -1);
    this.context = a || ((r) => Ve(n, r));
  }
  set(a, n) {
    return n.timestampIsSet ? a : Ve(a, Dm(a, this.context));
  }
}
class Le {
  run(t, a, n, r) {
    const i = this.parse(t, a, n, r);
    return i ? {
      setter: new Em(
        i.value,
        this.validate,
        this.set,
        this.priority,
        this.subPriority
      ),
      rest: i.rest
    } : null;
  }
  validate(t, a, n) {
    return !0;
  }
}
class Nm extends Le {
  constructor() {
    super(...arguments);
    fe(this, "priority", 140);
    fe(this, "incompatibleTokens", ["R", "u", "t", "T"]);
  }
  parse(a, n, r) {
    switch (n) {
      case "G":
      case "GG":
      case "GGG":
        return r.era(a, { width: "abbreviated" }) || r.era(a, { width: "narrow" });
      case "GGGGG":
        return r.era(a, { width: "narrow" });
      case "GGGG":
      default:
        return r.era(a, { width: "wide" }) || r.era(a, { width: "abbreviated" }) || r.era(a, { width: "narrow" });
    }
  }
  set(a, n, r) {
    return n.era = r, a.setFullYear(r, 0, 1), a.setHours(0, 0, 0, 0), a;
  }
}
const it = {
  month: /^(1[0-2]|0?\d)/,
  // 0 to 12
  date: /^(3[0-1]|[0-2]?\d)/,
  // 0 to 31
  dayOfYear: /^(36[0-6]|3[0-5]\d|[0-2]?\d?\d)/,
  // 0 to 366
  week: /^(5[0-3]|[0-4]?\d)/,
  // 0 to 53
  hour23h: /^(2[0-3]|[0-1]?\d)/,
  // 0 to 23
  hour24h: /^(2[0-4]|[0-1]?\d)/,
  // 0 to 24
  hour11h: /^(1[0-1]|0?\d)/,
  // 0 to 11
  hour12h: /^(1[0-2]|0?\d)/,
  // 0 to 12
  minute: /^[0-5]?\d/,
  // 0 to 59
  second: /^[0-5]?\d/,
  // 0 to 59
  singleDigit: /^\d/,
  // 0 to 9
  twoDigits: /^\d{1,2}/,
  // 0 to 99
  threeDigits: /^\d{1,3}/,
  // 0 to 999
  fourDigits: /^\d{1,4}/,
  // 0 to 9999
  anyDigitsSigned: /^-?\d+/,
  singleDigitSigned: /^-?\d/,
  // 0 to 9, -0 to -9
  twoDigitsSigned: /^-?\d{1,2}/,
  // 0 to 99, -0 to -99
  threeDigitsSigned: /^-?\d{1,3}/,
  // 0 to 999, -0 to -999
  fourDigitsSigned: /^-?\d{1,4}/
  // 0 to 9999, -0 to -9999
}, Qt = {
  basicOptionalMinutes: /^([+-])(\d{2})(\d{2})?|Z/,
  basic: /^([+-])(\d{2})(\d{2})|Z/,
  basicOptionalSeconds: /^([+-])(\d{2})(\d{2})((\d{2}))?|Z/,
  extended: /^([+-])(\d{2}):(\d{2})|Z/,
  extendedOptionalSeconds: /^([+-])(\d{2}):(\d{2})(:(\d{2}))?|Z/
};
function ot(e, t) {
  return e && {
    value: t(e.value),
    rest: e.rest
  };
}
function Je(e, t) {
  const a = t.match(e);
  return a ? {
    value: parseInt(a[0], 10),
    rest: t.slice(a[0].length)
  } : null;
}
function Kt(e, t) {
  const a = t.match(e);
  if (!a)
    return null;
  if (a[0] === "Z")
    return {
      value: 0,
      rest: t.slice(1)
    };
  const n = a[1] === "+" ? 1 : -1, r = a[2] ? parseInt(a[2], 10) : 0, i = a[3] ? parseInt(a[3], 10) : 0, l = a[5] ? parseInt(a[5], 10) : 0;
  return {
    value: n * (r * lu + i * Pv + l * Av),
    rest: t.slice(a[0].length)
  };
}
function bu(e) {
  return Je(it.anyDigitsSigned, e);
}
function at(e, t) {
  switch (e) {
    case 1:
      return Je(it.singleDigit, t);
    case 2:
      return Je(it.twoDigits, t);
    case 3:
      return Je(it.threeDigits, t);
    case 4:
      return Je(it.fourDigits, t);
    default:
      return Je(new RegExp("^\\d{1," + e + "}"), t);
  }
}
function kr(e, t) {
  switch (e) {
    case 1:
      return Je(it.singleDigitSigned, t);
    case 2:
      return Je(it.twoDigitsSigned, t);
    case 3:
      return Je(it.threeDigitsSigned, t);
    case 4:
      return Je(it.fourDigitsSigned, t);
    default:
      return Je(new RegExp("^-?\\d{1," + e + "}"), t);
  }
}
function yi(e) {
  switch (e) {
    case "morning":
      return 4;
    case "evening":
      return 17;
    case "pm":
    case "noon":
    case "afternoon":
      return 12;
    case "am":
    case "midnight":
    case "night":
    default:
      return 0;
  }
}
function wu(e, t) {
  const a = t > 0, n = a ? t : 1 - t;
  let r;
  if (n <= 50)
    r = e || 100;
  else {
    const i = n + 50, l = Math.trunc(i / 100) * 100, o = e >= i % 100;
    r = e + l - (o ? 100 : 0);
  }
  return a ? r : 1 - r;
}
function ku(e) {
  return e % 400 === 0 || e % 4 === 0 && e % 100 !== 0;
}
class Lm extends Le {
  constructor() {
    super(...arguments);
    fe(this, "priority", 130);
    fe(this, "incompatibleTokens", ["Y", "R", "u", "w", "I", "i", "e", "c", "t", "T"]);
  }
  parse(a, n, r) {
    const i = (l) => ({
      year: l,
      isTwoDigitYear: n === "yy"
    });
    switch (n) {
      case "y":
        return ot(at(4, a), i);
      case "yo":
        return ot(
          r.ordinalNumber(a, {
            unit: "year"
          }),
          i
        );
      default:
        return ot(at(n.length, a), i);
    }
  }
  validate(a, n) {
    return n.isTwoDigitYear || n.year > 0;
  }
  set(a, n, r) {
    const i = a.getFullYear();
    if (r.isTwoDigitYear) {
      const o = wu(
        r.year,
        i
      );
      return a.setFullYear(o, 0, 1), a.setHours(0, 0, 0, 0), a;
    }
    const l = !("era" in n) || n.era === 1 ? r.year : 1 - r.year;
    return a.setFullYear(l, 0, 1), a.setHours(0, 0, 0, 0), a;
  }
}
class Hm extends Le {
  constructor() {
    super(...arguments);
    fe(this, "priority", 130);
    fe(this, "incompatibleTokens", [
      "y",
      "R",
      "u",
      "Q",
      "q",
      "M",
      "L",
      "I",
      "d",
      "D",
      "i",
      "t",
      "T"
    ]);
  }
  parse(a, n, r) {
    const i = (l) => ({
      year: l,
      isTwoDigitYear: n === "YY"
    });
    switch (n) {
      case "Y":
        return ot(at(4, a), i);
      case "Yo":
        return ot(
          r.ordinalNumber(a, {
            unit: "year"
          }),
          i
        );
      default:
        return ot(at(n.length, a), i);
    }
  }
  validate(a, n) {
    return n.isTwoDigitYear || n.year > 0;
  }
  set(a, n, r, i) {
    const l = pi(a, i);
    if (r.isTwoDigitYear) {
      const s = wu(
        r.year,
        l
      );
      return a.setFullYear(
        s,
        0,
        i.firstWeekContainsDate
      ), a.setHours(0, 0, 0, 0), Yt(a, i);
    }
    const o = !("era" in n) || n.era === 1 ? r.year : 1 - r.year;
    return a.setFullYear(o, 0, i.firstWeekContainsDate), a.setHours(0, 0, 0, 0), Yt(a, i);
  }
}
class Fm extends Le {
  constructor() {
    super(...arguments);
    fe(this, "priority", 130);
    fe(this, "incompatibleTokens", [
      "G",
      "y",
      "Y",
      "u",
      "Q",
      "q",
      "M",
      "L",
      "w",
      "d",
      "D",
      "e",
      "c",
      "t",
      "T"
    ]);
  }
  parse(a, n) {
    return kr(n === "R" ? 4 : n.length, a);
  }
  set(a, n, r) {
    const i = Ve(a, 0);
    return i.setFullYear(r, 0, 4), i.setHours(0, 0, 0, 0), sn(i);
  }
}
class zm extends Le {
  constructor() {
    super(...arguments);
    fe(this, "priority", 130);
    fe(this, "incompatibleTokens", ["G", "y", "Y", "R", "w", "I", "i", "e", "c", "t", "T"]);
  }
  parse(a, n) {
    return kr(n === "u" ? 4 : n.length, a);
  }
  set(a, n, r) {
    return a.setFullYear(r, 0, 1), a.setHours(0, 0, 0, 0), a;
  }
}
class Vm extends Le {
  constructor() {
    super(...arguments);
    fe(this, "priority", 120);
    fe(this, "incompatibleTokens", [
      "Y",
      "R",
      "q",
      "M",
      "L",
      "w",
      "I",
      "d",
      "D",
      "i",
      "e",
      "c",
      "t",
      "T"
    ]);
  }
  parse(a, n, r) {
    switch (n) {
      case "Q":
      case "QQ":
        return at(n.length, a);
      case "Qo":
        return r.ordinalNumber(a, { unit: "quarter" });
      case "QQQ":
        return r.quarter(a, {
          width: "abbreviated",
          context: "formatting"
        }) || r.quarter(a, {
          width: "narrow",
          context: "formatting"
        });
      case "QQQQQ":
        return r.quarter(a, {
          width: "narrow",
          context: "formatting"
        });
      case "QQQQ":
      default:
        return r.quarter(a, {
          width: "wide",
          context: "formatting"
        }) || r.quarter(a, {
          width: "abbreviated",
          context: "formatting"
        }) || r.quarter(a, {
          width: "narrow",
          context: "formatting"
        });
    }
  }
  validate(a, n) {
    return n >= 1 && n <= 4;
  }
  set(a, n, r) {
    return a.setMonth((r - 1) * 3, 1), a.setHours(0, 0, 0, 0), a;
  }
}
class Ym extends Le {
  constructor() {
    super(...arguments);
    fe(this, "priority", 120);
    fe(this, "incompatibleTokens", [
      "Y",
      "R",
      "Q",
      "M",
      "L",
      "w",
      "I",
      "d",
      "D",
      "i",
      "e",
      "c",
      "t",
      "T"
    ]);
  }
  parse(a, n, r) {
    switch (n) {
      case "q":
      case "qq":
        return at(n.length, a);
      case "qo":
        return r.ordinalNumber(a, { unit: "quarter" });
      case "qqq":
        return r.quarter(a, {
          width: "abbreviated",
          context: "standalone"
        }) || r.quarter(a, {
          width: "narrow",
          context: "standalone"
        });
      case "qqqqq":
        return r.quarter(a, {
          width: "narrow",
          context: "standalone"
        });
      case "qqqq":
      default:
        return r.quarter(a, {
          width: "wide",
          context: "standalone"
        }) || r.quarter(a, {
          width: "abbreviated",
          context: "standalone"
        }) || r.quarter(a, {
          width: "narrow",
          context: "standalone"
        });
    }
  }
  validate(a, n) {
    return n >= 1 && n <= 4;
  }
  set(a, n, r) {
    return a.setMonth((r - 1) * 3, 1), a.setHours(0, 0, 0, 0), a;
  }
}
class Bm extends Le {
  constructor() {
    super(...arguments);
    fe(this, "incompatibleTokens", [
      "Y",
      "R",
      "q",
      "Q",
      "L",
      "w",
      "I",
      "D",
      "i",
      "e",
      "c",
      "t",
      "T"
    ]);
    fe(this, "priority", 110);
  }
  parse(a, n, r) {
    const i = (l) => l - 1;
    switch (n) {
      case "M":
        return ot(
          Je(it.month, a),
          i
        );
      case "MM":
        return ot(at(2, a), i);
      case "Mo":
        return ot(
          r.ordinalNumber(a, {
            unit: "month"
          }),
          i
        );
      case "MMM":
        return r.month(a, {
          width: "abbreviated",
          context: "formatting"
        }) || r.month(a, { width: "narrow", context: "formatting" });
      case "MMMMM":
        return r.month(a, {
          width: "narrow",
          context: "formatting"
        });
      case "MMMM":
      default:
        return r.month(a, { width: "wide", context: "formatting" }) || r.month(a, {
          width: "abbreviated",
          context: "formatting"
        }) || r.month(a, { width: "narrow", context: "formatting" });
    }
  }
  validate(a, n) {
    return n >= 0 && n <= 11;
  }
  set(a, n, r) {
    return a.setMonth(r, 1), a.setHours(0, 0, 0, 0), a;
  }
}
class jm extends Le {
  constructor() {
    super(...arguments);
    fe(this, "priority", 110);
    fe(this, "incompatibleTokens", [
      "Y",
      "R",
      "q",
      "Q",
      "M",
      "w",
      "I",
      "D",
      "i",
      "e",
      "c",
      "t",
      "T"
    ]);
  }
  parse(a, n, r) {
    const i = (l) => l - 1;
    switch (n) {
      case "L":
        return ot(
          Je(it.month, a),
          i
        );
      case "LL":
        return ot(at(2, a), i);
      case "Lo":
        return ot(
          r.ordinalNumber(a, {
            unit: "month"
          }),
          i
        );
      case "LLL":
        return r.month(a, {
          width: "abbreviated",
          context: "standalone"
        }) || r.month(a, { width: "narrow", context: "standalone" });
      case "LLLLL":
        return r.month(a, {
          width: "narrow",
          context: "standalone"
        });
      case "LLLL":
      default:
        return r.month(a, { width: "wide", context: "standalone" }) || r.month(a, {
          width: "abbreviated",
          context: "standalone"
        }) || r.month(a, { width: "narrow", context: "standalone" });
    }
  }
  validate(a, n) {
    return n >= 0 && n <= 11;
  }
  set(a, n, r) {
    return a.setMonth(r, 1), a.setHours(0, 0, 0, 0), a;
  }
}
function Um(e, t, a) {
  const n = Pe(e, a == null ? void 0 : a.in), r = hi(n, a) - t;
  return n.setDate(n.getDate() - r * 7), Pe(n, a == null ? void 0 : a.in);
}
class Wm extends Le {
  constructor() {
    super(...arguments);
    fe(this, "priority", 100);
    fe(this, "incompatibleTokens", [
      "y",
      "R",
      "u",
      "q",
      "Q",
      "M",
      "L",
      "I",
      "d",
      "D",
      "i",
      "t",
      "T"
    ]);
  }
  parse(a, n, r) {
    switch (n) {
      case "w":
        return Je(it.week, a);
      case "wo":
        return r.ordinalNumber(a, { unit: "week" });
      default:
        return at(n.length, a);
    }
  }
  validate(a, n) {
    return n >= 1 && n <= 53;
  }
  set(a, n, r, i) {
    return Yt(Um(a, r, i), i);
  }
}
function qm(e, t, a) {
  const n = Pe(e, a == null ? void 0 : a.in), r = mi(n, a) - t;
  return n.setDate(n.getDate() - r * 7), n;
}
class Xm extends Le {
  constructor() {
    super(...arguments);
    fe(this, "priority", 100);
    fe(this, "incompatibleTokens", [
      "y",
      "Y",
      "u",
      "q",
      "Q",
      "M",
      "L",
      "w",
      "d",
      "D",
      "e",
      "c",
      "t",
      "T"
    ]);
  }
  parse(a, n, r) {
    switch (n) {
      case "I":
        return Je(it.week, a);
      case "Io":
        return r.ordinalNumber(a, { unit: "week" });
      default:
        return at(n.length, a);
    }
  }
  validate(a, n) {
    return n >= 1 && n <= 53;
  }
  set(a, n, r) {
    return sn(qm(a, r));
  }
}
const Qm = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31], Km = [
  31,
  29,
  31,
  30,
  31,
  30,
  31,
  31,
  30,
  31,
  30,
  31
];
class Gm extends Le {
  constructor() {
    super(...arguments);
    fe(this, "priority", 90);
    fe(this, "subPriority", 1);
    fe(this, "incompatibleTokens", [
      "Y",
      "R",
      "q",
      "Q",
      "w",
      "I",
      "D",
      "i",
      "e",
      "c",
      "t",
      "T"
    ]);
  }
  parse(a, n, r) {
    switch (n) {
      case "d":
        return Je(it.date, a);
      case "do":
        return r.ordinalNumber(a, { unit: "date" });
      default:
        return at(n.length, a);
    }
  }
  validate(a, n) {
    const r = a.getFullYear(), i = ku(r), l = a.getMonth();
    return i ? n >= 1 && n <= Km[l] : n >= 1 && n <= Qm[l];
  }
  set(a, n, r) {
    return a.setDate(r), a.setHours(0, 0, 0, 0), a;
  }
}
class Jm extends Le {
  constructor() {
    super(...arguments);
    fe(this, "priority", 90);
    fe(this, "subpriority", 1);
    fe(this, "incompatibleTokens", [
      "Y",
      "R",
      "q",
      "Q",
      "M",
      "L",
      "w",
      "I",
      "d",
      "E",
      "i",
      "e",
      "c",
      "t",
      "T"
    ]);
  }
  parse(a, n, r) {
    switch (n) {
      case "D":
      case "DD":
        return Je(it.dayOfYear, a);
      case "Do":
        return r.ordinalNumber(a, { unit: "date" });
      default:
        return at(n.length, a);
    }
  }
  validate(a, n) {
    const r = a.getFullYear();
    return ku(r) ? n >= 1 && n <= 366 : n >= 1 && n <= 365;
  }
  set(a, n, r) {
    return a.setMonth(0, r), a.setHours(0, 0, 0, 0), a;
  }
}
function gi(e, t, a) {
  var c, f, v, g;
  const n = Wa(), r = (a == null ? void 0 : a.weekStartsOn) ?? ((f = (c = a == null ? void 0 : a.locale) == null ? void 0 : c.options) == null ? void 0 : f.weekStartsOn) ?? n.weekStartsOn ?? ((g = (v = n.locale) == null ? void 0 : v.options) == null ? void 0 : g.weekStartsOn) ?? 0, i = Pe(e, a == null ? void 0 : a.in), l = i.getDay(), s = (t % 7 + 7) % 7, d = 7 - r, u = t < 0 || t > 6 ? t - (l + d) % 7 : (s + d) % 7 - (l + d) % 7;
  return Ht(i, u, a);
}
class Zm extends Le {
  constructor() {
    super(...arguments);
    fe(this, "priority", 90);
    fe(this, "incompatibleTokens", ["D", "i", "e", "c", "t", "T"]);
  }
  parse(a, n, r) {
    switch (n) {
      case "E":
      case "EE":
      case "EEE":
        return r.day(a, {
          width: "abbreviated",
          context: "formatting"
        }) || r.day(a, { width: "short", context: "formatting" }) || r.day(a, { width: "narrow", context: "formatting" });
      case "EEEEE":
        return r.day(a, {
          width: "narrow",
          context: "formatting"
        });
      case "EEEEEE":
        return r.day(a, { width: "short", context: "formatting" }) || r.day(a, { width: "narrow", context: "formatting" });
      case "EEEE":
      default:
        return r.day(a, { width: "wide", context: "formatting" }) || r.day(a, {
          width: "abbreviated",
          context: "formatting"
        }) || r.day(a, { width: "short", context: "formatting" }) || r.day(a, { width: "narrow", context: "formatting" });
    }
  }
  validate(a, n) {
    return n >= 0 && n <= 6;
  }
  set(a, n, r, i) {
    return a = gi(a, r, i), a.setHours(0, 0, 0, 0), a;
  }
}
class e2 extends Le {
  constructor() {
    super(...arguments);
    fe(this, "priority", 90);
    fe(this, "incompatibleTokens", [
      "y",
      "R",
      "u",
      "q",
      "Q",
      "M",
      "L",
      "I",
      "d",
      "D",
      "E",
      "i",
      "c",
      "t",
      "T"
    ]);
  }
  parse(a, n, r, i) {
    const l = (o) => {
      const s = Math.floor((o - 1) / 7) * 7;
      return (o + i.weekStartsOn + 6) % 7 + s;
    };
    switch (n) {
      case "e":
      case "ee":
        return ot(at(n.length, a), l);
      case "eo":
        return ot(
          r.ordinalNumber(a, {
            unit: "day"
          }),
          l
        );
      case "eee":
        return r.day(a, {
          width: "abbreviated",
          context: "formatting"
        }) || r.day(a, { width: "short", context: "formatting" }) || r.day(a, { width: "narrow", context: "formatting" });
      case "eeeee":
        return r.day(a, {
          width: "narrow",
          context: "formatting"
        });
      case "eeeeee":
        return r.day(a, { width: "short", context: "formatting" }) || r.day(a, { width: "narrow", context: "formatting" });
      case "eeee":
      default:
        return r.day(a, { width: "wide", context: "formatting" }) || r.day(a, {
          width: "abbreviated",
          context: "formatting"
        }) || r.day(a, { width: "short", context: "formatting" }) || r.day(a, { width: "narrow", context: "formatting" });
    }
  }
  validate(a, n) {
    return n >= 0 && n <= 6;
  }
  set(a, n, r, i) {
    return a = gi(a, r, i), a.setHours(0, 0, 0, 0), a;
  }
}
class t2 extends Le {
  constructor() {
    super(...arguments);
    fe(this, "priority", 90);
    fe(this, "incompatibleTokens", [
      "y",
      "R",
      "u",
      "q",
      "Q",
      "M",
      "L",
      "I",
      "d",
      "D",
      "E",
      "i",
      "e",
      "t",
      "T"
    ]);
  }
  parse(a, n, r, i) {
    const l = (o) => {
      const s = Math.floor((o - 1) / 7) * 7;
      return (o + i.weekStartsOn + 6) % 7 + s;
    };
    switch (n) {
      case "c":
      case "cc":
        return ot(at(n.length, a), l);
      case "co":
        return ot(
          r.ordinalNumber(a, {
            unit: "day"
          }),
          l
        );
      case "ccc":
        return r.day(a, {
          width: "abbreviated",
          context: "standalone"
        }) || r.day(a, { width: "short", context: "standalone" }) || r.day(a, { width: "narrow", context: "standalone" });
      case "ccccc":
        return r.day(a, {
          width: "narrow",
          context: "standalone"
        });
      case "cccccc":
        return r.day(a, { width: "short", context: "standalone" }) || r.day(a, { width: "narrow", context: "standalone" });
      case "cccc":
      default:
        return r.day(a, { width: "wide", context: "standalone" }) || r.day(a, {
          width: "abbreviated",
          context: "standalone"
        }) || r.day(a, { width: "short", context: "standalone" }) || r.day(a, { width: "narrow", context: "standalone" });
    }
  }
  validate(a, n) {
    return n >= 0 && n <= 6;
  }
  set(a, n, r, i) {
    return a = gi(a, r, i), a.setHours(0, 0, 0, 0), a;
  }
}
function a2(e, t, a) {
  const n = Pe(e, a == null ? void 0 : a.in), r = Cm(n, a), i = t - r;
  return Ht(n, i, a);
}
class n2 extends Le {
  constructor() {
    super(...arguments);
    fe(this, "priority", 90);
    fe(this, "incompatibleTokens", [
      "y",
      "Y",
      "u",
      "q",
      "Q",
      "M",
      "L",
      "w",
      "d",
      "D",
      "E",
      "e",
      "c",
      "t",
      "T"
    ]);
  }
  parse(a, n, r) {
    const i = (l) => l === 0 ? 7 : l;
    switch (n) {
      case "i":
      case "ii":
        return at(n.length, a);
      case "io":
        return r.ordinalNumber(a, { unit: "day" });
      case "iii":
        return ot(
          r.day(a, {
            width: "abbreviated",
            context: "formatting"
          }) || r.day(a, {
            width: "short",
            context: "formatting"
          }) || r.day(a, {
            width: "narrow",
            context: "formatting"
          }),
          i
        );
      case "iiiii":
        return ot(
          r.day(a, {
            width: "narrow",
            context: "formatting"
          }),
          i
        );
      case "iiiiii":
        return ot(
          r.day(a, {
            width: "short",
            context: "formatting"
          }) || r.day(a, {
            width: "narrow",
            context: "formatting"
          }),
          i
        );
      case "iiii":
      default:
        return ot(
          r.day(a, {
            width: "wide",
            context: "formatting"
          }) || r.day(a, {
            width: "abbreviated",
            context: "formatting"
          }) || r.day(a, {
            width: "short",
            context: "formatting"
          }) || r.day(a, {
            width: "narrow",
            context: "formatting"
          }),
          i
        );
    }
  }
  validate(a, n) {
    return n >= 1 && n <= 7;
  }
  set(a, n, r) {
    return a = a2(a, r), a.setHours(0, 0, 0, 0), a;
  }
}
class r2 extends Le {
  constructor() {
    super(...arguments);
    fe(this, "priority", 80);
    fe(this, "incompatibleTokens", ["b", "B", "H", "k", "t", "T"]);
  }
  parse(a, n, r) {
    switch (n) {
      case "a":
      case "aa":
      case "aaa":
        return r.dayPeriod(a, {
          width: "abbreviated",
          context: "formatting"
        }) || r.dayPeriod(a, {
          width: "narrow",
          context: "formatting"
        });
      case "aaaaa":
        return r.dayPeriod(a, {
          width: "narrow",
          context: "formatting"
        });
      case "aaaa":
      default:
        return r.dayPeriod(a, {
          width: "wide",
          context: "formatting"
        }) || r.dayPeriod(a, {
          width: "abbreviated",
          context: "formatting"
        }) || r.dayPeriod(a, {
          width: "narrow",
          context: "formatting"
        });
    }
  }
  set(a, n, r) {
    return a.setHours(yi(r), 0, 0, 0), a;
  }
}
class l2 extends Le {
  constructor() {
    super(...arguments);
    fe(this, "priority", 80);
    fe(this, "incompatibleTokens", ["a", "B", "H", "k", "t", "T"]);
  }
  parse(a, n, r) {
    switch (n) {
      case "b":
      case "bb":
      case "bbb":
        return r.dayPeriod(a, {
          width: "abbreviated",
          context: "formatting"
        }) || r.dayPeriod(a, {
          width: "narrow",
          context: "formatting"
        });
      case "bbbbb":
        return r.dayPeriod(a, {
          width: "narrow",
          context: "formatting"
        });
      case "bbbb":
      default:
        return r.dayPeriod(a, {
          width: "wide",
          context: "formatting"
        }) || r.dayPeriod(a, {
          width: "abbreviated",
          context: "formatting"
        }) || r.dayPeriod(a, {
          width: "narrow",
          context: "formatting"
        });
    }
  }
  set(a, n, r) {
    return a.setHours(yi(r), 0, 0, 0), a;
  }
}
class i2 extends Le {
  constructor() {
    super(...arguments);
    fe(this, "priority", 80);
    fe(this, "incompatibleTokens", ["a", "b", "t", "T"]);
  }
  parse(a, n, r) {
    switch (n) {
      case "B":
      case "BB":
      case "BBB":
        return r.dayPeriod(a, {
          width: "abbreviated",
          context: "formatting"
        }) || r.dayPeriod(a, {
          width: "narrow",
          context: "formatting"
        });
      case "BBBBB":
        return r.dayPeriod(a, {
          width: "narrow",
          context: "formatting"
        });
      case "BBBB":
      default:
        return r.dayPeriod(a, {
          width: "wide",
          context: "formatting"
        }) || r.dayPeriod(a, {
          width: "abbreviated",
          context: "formatting"
        }) || r.dayPeriod(a, {
          width: "narrow",
          context: "formatting"
        });
    }
  }
  set(a, n, r) {
    return a.setHours(yi(r), 0, 0, 0), a;
  }
}
class o2 extends Le {
  constructor() {
    super(...arguments);
    fe(this, "priority", 70);
    fe(this, "incompatibleTokens", ["H", "K", "k", "t", "T"]);
  }
  parse(a, n, r) {
    switch (n) {
      case "h":
        return Je(it.hour12h, a);
      case "ho":
        return r.ordinalNumber(a, { unit: "hour" });
      default:
        return at(n.length, a);
    }
  }
  validate(a, n) {
    return n >= 1 && n <= 12;
  }
  set(a, n, r) {
    const i = a.getHours() >= 12;
    return i && r < 12 ? a.setHours(r + 12, 0, 0, 0) : !i && r === 12 ? a.setHours(0, 0, 0, 0) : a.setHours(r, 0, 0, 0), a;
  }
}
class s2 extends Le {
  constructor() {
    super(...arguments);
    fe(this, "priority", 70);
    fe(this, "incompatibleTokens", ["a", "b", "h", "K", "k", "t", "T"]);
  }
  parse(a, n, r) {
    switch (n) {
      case "H":
        return Je(it.hour23h, a);
      case "Ho":
        return r.ordinalNumber(a, { unit: "hour" });
      default:
        return at(n.length, a);
    }
  }
  validate(a, n) {
    return n >= 0 && n <= 23;
  }
  set(a, n, r) {
    return a.setHours(r, 0, 0, 0), a;
  }
}
class u2 extends Le {
  constructor() {
    super(...arguments);
    fe(this, "priority", 70);
    fe(this, "incompatibleTokens", ["h", "H", "k", "t", "T"]);
  }
  parse(a, n, r) {
    switch (n) {
      case "K":
        return Je(it.hour11h, a);
      case "Ko":
        return r.ordinalNumber(a, { unit: "hour" });
      default:
        return at(n.length, a);
    }
  }
  validate(a, n) {
    return n >= 0 && n <= 11;
  }
  set(a, n, r) {
    return a.getHours() >= 12 && r < 12 ? a.setHours(r + 12, 0, 0, 0) : a.setHours(r, 0, 0, 0), a;
  }
}
class c2 extends Le {
  constructor() {
    super(...arguments);
    fe(this, "priority", 70);
    fe(this, "incompatibleTokens", ["a", "b", "h", "H", "K", "t", "T"]);
  }
  parse(a, n, r) {
    switch (n) {
      case "k":
        return Je(it.hour24h, a);
      case "ko":
        return r.ordinalNumber(a, { unit: "hour" });
      default:
        return at(n.length, a);
    }
  }
  validate(a, n) {
    return n >= 1 && n <= 24;
  }
  set(a, n, r) {
    const i = r <= 24 ? r % 24 : r;
    return a.setHours(i, 0, 0, 0), a;
  }
}
class d2 extends Le {
  constructor() {
    super(...arguments);
    fe(this, "priority", 60);
    fe(this, "incompatibleTokens", ["t", "T"]);
  }
  parse(a, n, r) {
    switch (n) {
      case "m":
        return Je(it.minute, a);
      case "mo":
        return r.ordinalNumber(a, { unit: "minute" });
      default:
        return at(n.length, a);
    }
  }
  validate(a, n) {
    return n >= 0 && n <= 59;
  }
  set(a, n, r) {
    return a.setMinutes(r, 0, 0), a;
  }
}
class f2 extends Le {
  constructor() {
    super(...arguments);
    fe(this, "priority", 50);
    fe(this, "incompatibleTokens", ["t", "T"]);
  }
  parse(a, n, r) {
    switch (n) {
      case "s":
        return Je(it.second, a);
      case "so":
        return r.ordinalNumber(a, { unit: "second" });
      default:
        return at(n.length, a);
    }
  }
  validate(a, n) {
    return n >= 0 && n <= 59;
  }
  set(a, n, r) {
    return a.setSeconds(r, 0), a;
  }
}
class v2 extends Le {
  constructor() {
    super(...arguments);
    fe(this, "priority", 30);
    fe(this, "incompatibleTokens", ["t", "T"]);
  }
  parse(a, n) {
    const r = (i) => Math.trunc(i * Math.pow(10, -n.length + 3));
    return ot(at(n.length, a), r);
  }
  set(a, n, r) {
    return a.setMilliseconds(r), a;
  }
}
class m2 extends Le {
  constructor() {
    super(...arguments);
    fe(this, "priority", 10);
    fe(this, "incompatibleTokens", ["t", "T", "x"]);
  }
  parse(a, n) {
    switch (n) {
      case "X":
        return Kt(
          Qt.basicOptionalMinutes,
          a
        );
      case "XX":
        return Kt(Qt.basic, a);
      case "XXXX":
        return Kt(
          Qt.basicOptionalSeconds,
          a
        );
      case "XXXXX":
        return Kt(
          Qt.extendedOptionalSeconds,
          a
        );
      case "XXX":
      default:
        return Kt(Qt.extended, a);
    }
  }
  set(a, n, r) {
    return n.timestampIsSet ? a : Ve(
      a,
      a.getTime() - wr(a) - r
    );
  }
}
class p2 extends Le {
  constructor() {
    super(...arguments);
    fe(this, "priority", 10);
    fe(this, "incompatibleTokens", ["t", "T", "X"]);
  }
  parse(a, n) {
    switch (n) {
      case "x":
        return Kt(
          Qt.basicOptionalMinutes,
          a
        );
      case "xx":
        return Kt(Qt.basic, a);
      case "xxxx":
        return Kt(
          Qt.basicOptionalSeconds,
          a
        );
      case "xxxxx":
        return Kt(
          Qt.extendedOptionalSeconds,
          a
        );
      case "xxx":
      default:
        return Kt(Qt.extended, a);
    }
  }
  set(a, n, r) {
    return n.timestampIsSet ? a : Ve(
      a,
      a.getTime() - wr(a) - r
    );
  }
}
class h2 extends Le {
  constructor() {
    super(...arguments);
    fe(this, "priority", 40);
    fe(this, "incompatibleTokens", "*");
  }
  parse(a) {
    return bu(a);
  }
  set(a, n, r) {
    return [Ve(a, r * 1e3), { timestampIsSet: !0 }];
  }
}
class y2 extends Le {
  constructor() {
    super(...arguments);
    fe(this, "priority", 20);
    fe(this, "incompatibleTokens", "*");
  }
  parse(a) {
    return bu(a);
  }
  set(a, n, r) {
    return [Ve(a, r), { timestampIsSet: !0 }];
  }
}
const g2 = {
  G: new Nm(),
  y: new Lm(),
  Y: new Hm(),
  R: new Fm(),
  u: new zm(),
  Q: new Vm(),
  q: new Ym(),
  M: new Bm(),
  L: new jm(),
  w: new Wm(),
  I: new Xm(),
  d: new Gm(),
  D: new Jm(),
  E: new Zm(),
  e: new e2(),
  c: new t2(),
  i: new n2(),
  a: new r2(),
  b: new l2(),
  B: new i2(),
  h: new o2(),
  H: new s2(),
  K: new u2(),
  k: new c2(),
  m: new d2(),
  s: new f2(),
  S: new v2(),
  X: new m2(),
  x: new p2(),
  t: new h2(),
  T: new y2()
}, b2 = /[yYQqMLwIdDecihHKkms]o|(\w)\1*|''|'(''|[^'])+('|$)|./g, w2 = /P+p+|P+|p+|''|'(''|[^'])+('|$)|./g, k2 = /^'([^]*?)'?$/, x2 = /''/g, _2 = /\S/, M2 = /[a-zA-Z]/;
function Nl(e, t, a, n) {
  var P, b, w, h, _, C, A, I;
  const r = () => Ve((n == null ? void 0 : n.in) || a, NaN), i = Sm(), l = (n == null ? void 0 : n.locale) ?? i.locale ?? mu, o = (n == null ? void 0 : n.firstWeekContainsDate) ?? ((b = (P = n == null ? void 0 : n.locale) == null ? void 0 : P.options) == null ? void 0 : b.firstWeekContainsDate) ?? i.firstWeekContainsDate ?? ((h = (w = i.locale) == null ? void 0 : w.options) == null ? void 0 : h.firstWeekContainsDate) ?? 1, s = (n == null ? void 0 : n.weekStartsOn) ?? ((C = (_ = n == null ? void 0 : n.locale) == null ? void 0 : _.options) == null ? void 0 : C.weekStartsOn) ?? i.weekStartsOn ?? ((I = (A = i.locale) == null ? void 0 : A.options) == null ? void 0 : I.weekStartsOn) ?? 0;
  if (!t)
    return e ? r() : Pe(a, n == null ? void 0 : n.in);
  const d = {
    firstWeekContainsDate: o,
    weekStartsOn: s,
    locale: l
  }, u = [new Rm(n == null ? void 0 : n.in, a)], c = t.match(w2).map((V) => {
    const L = V[0];
    if (L in El) {
      const F = El[L];
      return F(V, l.formatLong);
    }
    return V;
  }).join("").match(b2), f = [];
  for (let V of c) {
    !(n != null && n.useAdditionalWeekYearTokens) && yu(V) && Rl(V, t, e), !(n != null && n.useAdditionalDayOfYearTokens) && hu(V) && Rl(V, t, e);
    const L = V[0], F = g2[L];
    if (F) {
      const { incompatibleTokens: ne } = F;
      if (Array.isArray(ne)) {
        const ve = f.find(
          (T) => ne.includes(T.token) || T.token === L
        );
        if (ve)
          throw new RangeError(
            `The format string mustn't contain \`${ve.fullToken}\` and \`${V}\` at the same time`
          );
      } else if (F.incompatibleTokens === "*" && f.length > 0)
        throw new RangeError(
          `The format string mustn't contain \`${V}\` and any other token at the same time`
        );
      f.push({ token: L, fullToken: V });
      const ce = F.run(
        e,
        V,
        l.match,
        d
      );
      if (!ce)
        return r();
      u.push(ce.setter), e = ce.rest;
    } else {
      if (L.match(M2))
        throw new RangeError(
          "Format string contains an unescaped latin alphabet character `" + L + "`"
        );
      if (V === "''" ? V = "'" : L === "'" && (V = P2(V)), e.indexOf(V) === 0)
        e = e.slice(V.length);
      else
        return r();
    }
  }
  if (e.length > 0 && _2.test(e))
    return r();
  const v = u.map((V) => V.priority).sort((V, L) => L - V).filter((V, L, F) => F.indexOf(V) === L).map(
    (V) => u.filter((L) => L.priority === V).sort((L, F) => F.subPriority - L.subPriority)
  ).map((V) => V[0]);
  let g = Pe(a, n == null ? void 0 : n.in);
  if (isNaN(+g))
    return r();
  const y = {};
  for (const V of v) {
    if (!V.validate(g, d))
      return r();
    const L = V.set(g, y, d);
    Array.isArray(L) ? (g = L[0], Object.assign(y, L[1])) : g = L;
  }
  return g;
}
function P2(e) {
  return e.match(k2)[1].replace(x2, "'");
}
function Ao(e, t, a) {
  const [n, r] = Yn(
    a == null ? void 0 : a.in,
    e,
    t
  );
  return +La(n) == +La(r);
}
function A2(e, t, a) {
  return Ht(e, -t, a);
}
function xu(e, t, a) {
  const n = Pe(e, a == null ? void 0 : a.in), r = n.getFullYear(), i = n.getDate(), l = Ve((a == null ? void 0 : a.in) || e, 0);
  l.setFullYear(r, t, 15), l.setHours(0, 0, 0, 0);
  const o = Om(l);
  return n.setMonth(t, Math.min(i, o)), n;
}
function Be(e, t, a) {
  let n = Pe(e, a == null ? void 0 : a.in);
  return isNaN(+n) ? Ve((a == null ? void 0 : a.in) || e, NaN) : (t.year != null && n.setFullYear(t.year), t.month != null && (n = xu(n, t.month)), t.date != null && n.setDate(t.date), t.hours != null && n.setHours(t.hours), t.minutes != null && n.setMinutes(t.minutes), t.seconds != null && n.setSeconds(t.seconds), t.milliseconds != null && n.setMilliseconds(t.milliseconds), n);
}
function T2(e, t, a) {
  const n = Pe(e, a == null ? void 0 : a.in);
  return n.setHours(t), n;
}
function _u(e, t, a) {
  const n = Pe(e, a == null ? void 0 : a.in);
  return n.setMilliseconds(t), n;
}
function O2(e, t, a) {
  const n = Pe(e, a == null ? void 0 : a.in);
  return n.setMinutes(t), n;
}
function Mu(e, t, a) {
  const n = Pe(e, a == null ? void 0 : a.in);
  return n.setSeconds(t), n;
}
function Jt(e, t, a) {
  const n = Pe(e, a == null ? void 0 : a.in);
  return isNaN(+n) ? Ve((a == null ? void 0 : a.in) || e, NaN) : (n.setFullYear(t), n);
}
function dn(e, t, a) {
  return Vt(e, -t, a);
}
function S2(e, t, a) {
  const {
    years: n = 0,
    months: r = 0,
    weeks: i = 0,
    days: l = 0,
    hours: o = 0,
    minutes: s = 0,
    seconds: d = 0
  } = t, u = dn(e, r + n * 12, a), c = A2(u, l + i * 7, a), f = s + o * 60, g = (d + f * 60) * 1e3;
  return Ve((a == null ? void 0 : a.in) || e, +c - g);
}
function Pu(e, t, a) {
  return vi(e, -t, a);
}
function hn() {
  const e = zo();
  return M(), Y(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 32 32",
      fill: "currentColor",
      "aria-hidden": "true",
      class: "dp__icon",
      role: "img",
      ...e
    },
    [
      le("path", {
        d: "M29.333 8c0-2.208-1.792-4-4-4h-18.667c-2.208 0-4 1.792-4 4v18.667c0 2.208 1.792 4 4 4h18.667c2.208 0 4-1.792 4-4v-18.667zM26.667 8v18.667c0 0.736-0.597 1.333-1.333 1.333 0 0-18.667 0-18.667 0-0.736 0-1.333-0.597-1.333-1.333 0 0 0-18.667 0-18.667 0-0.736 0.597-1.333 1.333-1.333 0 0 18.667 0 18.667 0 0.736 0 1.333 0.597 1.333 1.333z"
      }),
      le("path", {
        d: "M20 2.667v5.333c0 0.736 0.597 1.333 1.333 1.333s1.333-0.597 1.333-1.333v-5.333c0-0.736-0.597-1.333-1.333-1.333s-1.333 0.597-1.333 1.333z"
      }),
      le("path", {
        d: "M9.333 2.667v5.333c0 0.736 0.597 1.333 1.333 1.333s1.333-0.597 1.333-1.333v-5.333c0-0.736-0.597-1.333-1.333-1.333s-1.333 0.597-1.333 1.333z"
      }),
      le("path", {
        d: "M4 14.667h24c0.736 0 1.333-0.597 1.333-1.333s-0.597-1.333-1.333-1.333h-24c-0.736 0-1.333 0.597-1.333 1.333s0.597 1.333 1.333 1.333z"
      })
    ]
  );
}
hn.compatConfig = {
  MODE: 3
};
function Au() {
  return M(), Y(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 32 32",
      fill: "currentColor",
      "aria-hidden": "true",
      class: "dp__icon",
      role: "img"
    },
    [
      le("path", {
        d: "M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
      }),
      le("path", {
        d: "M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
      })
    ]
  );
}
Au.compatConfig = {
  MODE: 3
};
function bi() {
  return M(), Y(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 32 32",
      fill: "currentColor",
      "aria-hidden": "true",
      class: "dp__icon",
      role: "img"
    },
    [
      le("path", {
        d: "M20.943 23.057l-7.057-7.057c0 0 7.057-7.057 7.057-7.057 0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0l-8 8c-0.521 0.521-0.521 1.365 0 1.885l8 8c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885z"
      })
    ]
  );
}
bi.compatConfig = {
  MODE: 3
};
function wi() {
  return M(), Y(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 32 32",
      fill: "currentColor",
      "aria-hidden": "true",
      class: "dp__icon",
      role: "img"
    },
    [
      le("path", {
        d: "M12.943 24.943l8-8c0.521-0.521 0.521-1.365 0-1.885l-8-8c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885l7.057 7.057c0 0-7.057 7.057-7.057 7.057-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0z"
      })
    ]
  );
}
wi.compatConfig = {
  MODE: 3
};
function ki() {
  return M(), Y(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 32 32",
      fill: "currentColor",
      "aria-hidden": "true",
      class: "dp__icon",
      role: "img"
    },
    [
      le("path", {
        d: "M16 1.333c-8.095 0-14.667 6.572-14.667 14.667s6.572 14.667 14.667 14.667c8.095 0 14.667-6.572 14.667-14.667s-6.572-14.667-14.667-14.667zM16 4c6.623 0 12 5.377 12 12s-5.377 12-12 12c-6.623 0-12-5.377-12-12s5.377-12 12-12z"
      }),
      le("path", {
        d: "M14.667 8v8c0 0.505 0.285 0.967 0.737 1.193l5.333 2.667c0.658 0.329 1.46 0.062 1.789-0.596s0.062-1.46-0.596-1.789l-4.596-2.298c0 0 0-7.176 0-7.176 0-0.736-0.597-1.333-1.333-1.333s-1.333 0.597-1.333 1.333z"
      })
    ]
  );
}
ki.compatConfig = {
  MODE: 3
};
function xi() {
  return M(), Y(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 32 32",
      fill: "currentColor",
      "aria-hidden": "true",
      class: "dp__icon",
      role: "img"
    },
    [
      le("path", {
        d: "M24.943 19.057l-8-8c-0.521-0.521-1.365-0.521-1.885 0l-8 8c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l7.057-7.057c0 0 7.057 7.057 7.057 7.057 0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885z"
      })
    ]
  );
}
xi.compatConfig = {
  MODE: 3
};
function _i() {
  return M(), Y(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 32 32",
      fill: "currentColor",
      "aria-hidden": "true",
      class: "dp__icon",
      role: "img"
    },
    [
      le("path", {
        d: "M7.057 12.943l8 8c0.521 0.521 1.365 0.521 1.885 0l8-8c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0l-7.057 7.057c0 0-7.057-7.057-7.057-7.057-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
      })
    ]
  );
}
_i.compatConfig = {
  MODE: 3
};
const Tt = (e, t) => t ? new Date(e.toLocaleString("en-US", { timeZone: t })) : new Date(e), Mi = (e, t, a) => Ll(e, t, a) || J(), C2 = (e, t, a) => {
  const n = t.dateInTz ? Tt(new Date(e), t.dateInTz) : J(e);
  return a ? Mt(n, !0) : n;
}, Ll = (e, t, a) => {
  if (!e)
    return null;
  const n = a ? Mt(J(e), !0) : J(e);
  return t ? t.exactMatch ? C2(e, t, a) : Tt(n, t.timezone) : n;
}, D2 = (e, t) => {
  if (!e)
    return 0;
  const a = /* @__PURE__ */ new Date(), n = new Date(a.toLocaleString("en-US", { timeZone: "UTC" })), r = new Date(a.toLocaleString("en-US", { timeZone: e })), i = (t ?? r).getTimezoneOffset() / 60;
  return (+n - +r) / (1e3 * 60 * 60) - i;
};
var Et = /* @__PURE__ */ ((e) => (e.month = "month", e.year = "year", e))(Et || {}), Rt = /* @__PURE__ */ ((e) => (e.top = "top", e.bottom = "bottom", e))(Rt || {}), Ha = /* @__PURE__ */ ((e) => (e.header = "header", e.calendar = "calendar", e.timePicker = "timePicker", e))(Ha || {}), gt = /* @__PURE__ */ ((e) => (e.month = "month", e.year = "year", e.calendar = "calendar", e.time = "time", e.minutes = "minutes", e.hours = "hours", e.seconds = "seconds", e))(gt || {});
const $2 = ["timestamp", "date", "iso"];
var kt = /* @__PURE__ */ ((e) => (e.up = "up", e.down = "down", e.left = "left", e.right = "right", e))(kt || {}), qe = /* @__PURE__ */ ((e) => (e.arrowUp = "ArrowUp", e.arrowDown = "ArrowDown", e.arrowLeft = "ArrowLeft", e.arrowRight = "ArrowRight", e.enter = "Enter", e.space = " ", e.esc = "Escape", e.tab = "Tab", e.home = "Home", e.end = "End", e.pageUp = "PageUp", e.pageDown = "PageDown", e))(qe || {});
function To(e) {
  return (t) => new Intl.DateTimeFormat(e, { weekday: "short", timeZone: "UTC" }).format(/* @__PURE__ */ new Date(`2017-01-0${t}T00:00:00+00:00`)).slice(0, 2);
}
function I2(e) {
  return (t) => Zt(Tt(/* @__PURE__ */ new Date(`2017-01-0${t}T00:00:00+00:00`), "UTC"), "EEEEEE", { locale: e });
}
const E2 = (e, t, a) => {
  const n = [1, 2, 3, 4, 5, 6, 7];
  let r;
  if (e !== null)
    try {
      r = n.map(I2(e));
    } catch {
      r = n.map(To(t));
    }
  else
    r = n.map(To(t));
  const i = r.slice(0, a), l = r.slice(a + 1, r.length);
  return [r[a]].concat(...l).concat(...i);
}, Pi = (e, t, a) => {
  const n = [];
  for (let r = +e[0]; r <= +e[1]; r++)
    n.push({ value: +r, text: Cu(r, t) });
  return a ? n.reverse() : n;
}, Tu = (e, t, a) => {
  const n = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i) => {
    const l = i < 10 ? `0${i}` : i;
    return /* @__PURE__ */ new Date(`2017-${l}-01T00:00:00+00:00`);
  });
  if (e !== null)
    try {
      const i = a === "long" ? "LLLL" : "LLL";
      return n.map((l, o) => {
        const s = Zt(Tt(l, "UTC"), i, { locale: e });
        return {
          text: s.charAt(0).toUpperCase() + s.substring(1),
          value: o
        };
      });
    } catch {
    }
  const r = new Intl.DateTimeFormat(t, { month: a, timeZone: "UTC" });
  return n.map((i, l) => {
    const o = r.format(i);
    return {
      text: o.charAt(0).toUpperCase() + o.substring(1),
      value: l
    };
  });
}, R2 = (e) => [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11][e], dt = (e) => {
  const t = p(e);
  return t != null && t.$el ? t == null ? void 0 : t.$el : t;
}, N2 = (e) => ({ type: "dot", ...e ?? {} }), Ou = (e) => Array.isArray(e) ? !!e[0] && !!e[1] : !1, Ai = {
  prop: (e) => `"${e}" prop must be enabled!`,
  dateArr: (e) => `You need to use array as "model-value" binding in order to support "${e}"`
}, vt = (e) => e, Oo = (e) => e === 0 ? e : !e || isNaN(+e) ? null : +e, So = (e) => e === null, Su = (e) => {
  if (e)
    return [...e.querySelectorAll("input, button, select, textarea, a[href]")][0];
}, L2 = (e) => {
  const t = [], a = (n) => n.filter((r) => r);
  for (let n = 0; n < e.length; n += 3) {
    const r = [e[n], e[n + 1], e[n + 2]];
    t.push(a(r));
  }
  return t;
}, Nn = (e, t, a) => {
  const n = a != null, r = t != null;
  if (!n && !r)
    return !1;
  const i = +a, l = +t;
  return n && r ? +e > i || +e < l : n ? +e > i : r ? +e < l : !1;
}, fn = (e, t) => L2(e).map((a) => a.map((n) => {
  const { active: r, disabled: i, isBetween: l, highlighted: o } = t(n);
  return {
    ...n,
    active: r,
    disabled: i,
    className: {
      dp__overlay_cell_active: r,
      dp__overlay_cell: !r,
      dp__overlay_cell_disabled: i,
      dp__overlay_cell_pad: !0,
      dp__overlay_cell_active_disabled: i && r,
      dp__cell_in_between: l,
      "dp--highlighted": o
    }
  };
})), xa = (e, t, a = !1) => {
  e && t.allowStopPropagation && (a && e.stopImmediatePropagation(), e.stopPropagation());
}, H2 = () => [
  "a[href]",
  "area[href]",
  "input:not([disabled]):not([type='hidden'])",
  "select:not([disabled])",
  "textarea:not([disabled])",
  "button:not([disabled])",
  "[tabindex]:not([tabindex='-1'])",
  "[data-datepicker-instance]"
].join(", ");
function F2(e, t) {
  let a = [...document.querySelectorAll(H2())];
  a = a.filter((r) => !e.contains(r) || r.hasAttribute("data-datepicker-instance"));
  const n = a.indexOf(e);
  if (n >= 0 && (t ? n - 1 >= 0 : n + 1 <= a.length))
    return a[n + (t ? -1 : 1)];
}
const Hl = (e, t) => e == null ? void 0 : e.querySelector(`[data-dp-element="${t}"]`), Cu = (e, t) => new Intl.NumberFormat(t, { useGrouping: !1, style: "decimal" }).format(e), Ti = (e) => Zt(e, "dd-MM-yyyy"), rl = (e) => Array.isArray(e), xr = (e, t) => t.get(Ti(e)), z2 = (e, t) => e ? t ? t instanceof Map ? !!xr(e, t) : t(J(e)) : !1 : !0, xt = (e, t, a = !1, n) => {
  if (e.key === qe.enter || e.key === qe.space)
    return a && e.preventDefault(), t();
  if (n)
    return n(e);
}, Co = () => ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].some(
  (e) => navigator.userAgent.includes(e)
) || navigator.userAgent.includes("Mac") && "ontouchend" in document, Do = (e, t, a, n, r, i) => {
  const l = Nl(e, t.slice(0, e.length), /* @__PURE__ */ new Date(), { locale: i });
  return Pn(l) && uu(l) ? n || r ? l : Be(l, {
    hours: +a.hours,
    minutes: +(a == null ? void 0 : a.minutes),
    seconds: +(a == null ? void 0 : a.seconds),
    milliseconds: 0
  }) : null;
}, V2 = (e, t, a, n, r, i) => {
  const l = Array.isArray(a) ? a[0] : a;
  if (typeof t == "string")
    return Do(e, t, l, n, r, i);
  if (Array.isArray(t)) {
    let o = null;
    for (const s of t)
      if (o = Do(e, s, l, n, r, i), o)
        break;
    return o;
  }
  return typeof t == "function" ? t(e) : null;
}, J = (e) => e ? new Date(e) : /* @__PURE__ */ new Date(), Y2 = (e, t, a) => {
  if (t) {
    const r = (e.getMonth() + 1).toString().padStart(2, "0"), i = e.getDate().toString().padStart(2, "0"), l = e.getHours().toString().padStart(2, "0"), o = e.getMinutes().toString().padStart(2, "0"), s = a ? e.getSeconds().toString().padStart(2, "0") : "00";
    return `${e.getFullYear()}-${r}-${i}T${l}:${o}:${s}.000Z`;
  }
  const n = Date.UTC(
    e.getUTCFullYear(),
    e.getUTCMonth(),
    e.getUTCDate(),
    e.getUTCHours(),
    e.getUTCMinutes(),
    e.getUTCSeconds()
  );
  return new Date(n).toISOString();
}, Mt = (e, t) => {
  const a = J(JSON.parse(JSON.stringify(e))), n = Be(a, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
  return t ? Rv(n) : n;
}, _a = (e, t, a, n) => {
  let r = e ? J(e) : J();
  return (t || t === 0) && (r = T2(r, +t)), (a || a === 0) && (r = O2(r, +a)), (n || n === 0) && (r = Mu(r, +n)), _u(r, 0);
}, Ze = (e, t) => !e || !t ? !1 : Rn(Mt(e), Mt(t)), $e = (e, t) => !e || !t ? !1 : Za(Mt(e), Mt(t)), lt = (e, t) => !e || !t ? !1 : cn(Mt(e), Mt(t)), Hr = (e, t, a) => e != null && e[0] && e != null && e[1] ? lt(a, e[0]) && Ze(a, e[1]) : e != null && e[0] && t ? lt(a, e[0]) && Ze(a, t) || Ze(a, e[0]) && lt(a, t) : !1, Ft = (e) => {
  const t = Be(new Date(e), { date: 1 });
  return Mt(t);
}, ll = (e, t, a) => t && (a || a === 0) ? Object.fromEntries(
  ["hours", "minutes", "seconds"].map((n) => n === t ? [n, a] : [n, isNaN(+e[n]) ? void 0 : +e[n]])
) : {
  hours: isNaN(+e.hours) ? void 0 : +e.hours,
  minutes: isNaN(+e.minutes) ? void 0 : +e.minutes,
  seconds: isNaN(+e.seconds) ? void 0 : +e.seconds
}, Fa = (e) => ({
  hours: fa(e),
  minutes: Sa(e),
  seconds: un(e)
}), Du = (e, t) => {
  if (t) {
    const a = Te(J(t));
    if (a > e)
      return 12;
    if (a === e)
      return De(J(t));
  }
}, $u = (e, t) => {
  if (t) {
    const a = Te(J(t));
    return a < e ? -1 : a === e ? De(J(t)) : void 0;
  }
}, vn = (e) => {
  if (e)
    return Te(J(e));
}, Iu = (e, t) => {
  const a = lt(e, t) ? t : e, n = lt(t, e) ? t : e;
  return du({ start: a, end: n });
}, B2 = (e) => {
  const t = Vt(e, 1);
  return { month: De(t), year: Te(t) };
}, ra = (e, t) => {
  const a = Yt(e, { weekStartsOn: +t }), n = vu(e, { weekStartsOn: +t });
  return [a, n];
}, Eu = (e, t) => {
  const a = {
    hours: fa(J()),
    minutes: Sa(J()),
    seconds: t ? un(J()) : 0
  };
  return Object.assign(a, e);
}, wa = (e, t, a) => [Be(J(e), { date: 1 }), Be(J(), { month: t, year: a, date: 1 })], la = (e, t, a) => {
  let n = e ? J(e) : J();
  return (t || t === 0) && (n = xu(n, t)), a && (n = Jt(n, a)), n;
}, Ru = (e, t, a, n, r) => {
  if (!n || r && !t || !r && !a)
    return !1;
  const i = r ? Vt(e, 1) : dn(e, 1), l = [De(i), Te(i)];
  return r ? !U2(...l, t) : !j2(...l, a);
}, j2 = (e, t, a) => Ze(...wa(a, e, t)) || $e(...wa(a, e, t)), U2 = (e, t, a) => lt(...wa(a, e, t)) || $e(...wa(a, e, t)), Nu = (e, t, a, n, r, i, l) => {
  if (typeof t == "function" && !l)
    return t(e);
  const o = a ? { locale: a } : void 0;
  return Array.isArray(e) ? `${Zt(e[0], i, o)}${r && !e[1] ? "" : n}${e[1] ? Zt(e[1], i, o) : ""}` : Zt(e, i, o);
}, Qa = (e) => {
  if (e)
    return null;
  throw new Error(Ai.prop("partial-range"));
}, lr = (e, t) => {
  if (t)
    return e();
  throw new Error(Ai.prop("range"));
}, Fl = (e) => Array.isArray(e) ? Pn(e[0]) && (e[1] ? Pn(e[1]) : !0) : e ? Pn(e) : !1, W2 = (e, t) => Be(t ?? J(), {
  hours: +e.hours || 0,
  minutes: +e.minutes || 0,
  seconds: +e.seconds || 0
}), il = (e, t, a, n) => {
  if (!e)
    return !0;
  if (n) {
    const r = a === "max" ? Rn(e, t) : cn(e, t), i = { seconds: 0, milliseconds: 0 };
    return r || Za(Be(e, i), Be(t, i));
  }
  return a === "max" ? e.getTime() <= t.getTime() : e.getTime() >= t.getTime();
}, ol = (e, t, a) => e ? W2(e, t) : J(a ?? t), $o = (e, t, a, n, r) => {
  if (Array.isArray(n)) {
    const l = ol(e, n[0], t), o = ol(e, n[1], t);
    return il(n[0], l, a, !!t) && il(n[1], o, a, !!t) && r;
  }
  const i = ol(e, n, t);
  return il(n, i, a, !!t) && r;
}, sl = (e) => Be(J(), Fa(e)), q2 = (e, t) => e instanceof Map ? Array.from(e.values()).filter((a) => Te(J(a)) === t).map((a) => De(a)) : [], X2 = (e, t, a) => {
  if (e instanceof Map) {
    const n = Array.from(e.values()).filter((r) => Te(J(r)) === t).map((r) => De(r));
    return n.length ? n.includes(a) : !0;
  }
  return !0;
}, Lu = (e, t, a) => typeof e == "function" ? e({ month: t, year: a }) : !!e.months.find((n) => n.month === t && n.year === a), Oi = (e, t) => typeof e == "function" ? e(t) : e.years.includes(t), Hu = (e) => Zt(e, "yyyy-MM-dd"), wn = mn({
  menuFocused: !1,
  shiftKeyInMenu: !1
}), Fu = () => {
  const e = (a) => {
    wn.menuFocused = a;
  }, t = (a) => {
    wn.shiftKeyInMenu !== a && (wn.shiftKeyInMenu = a);
  };
  return {
    control: $(() => ({ shiftKeyInMenu: wn.shiftKeyInMenu, menuFocused: wn.menuFocused })),
    setMenuFocused: e,
    setShiftKey: t
  };
}, We = mn({
  monthYear: [],
  calendar: [],
  time: [],
  actionRow: [],
  selectionGrid: [],
  timePicker: {
    0: [],
    1: []
  },
  monthPicker: []
}), ul = z(null), ir = z(!1), cl = z(!1), dl = z(!1), fl = z(!1), yt = z(0), rt = z(0), Da = () => {
  const e = $(() => ir.value ? [...We.selectionGrid, We.actionRow].filter((c) => c.length) : cl.value ? [
    ...We.timePicker[0],
    ...We.timePicker[1],
    fl.value ? [] : [ul.value],
    We.actionRow
  ].filter((c) => c.length) : dl.value ? [...We.monthPicker, We.actionRow] : [We.monthYear, ...We.calendar, We.time, We.actionRow].filter((c) => c.length)), t = (c) => {
    yt.value = c ? yt.value + 1 : yt.value - 1;
    let f = null;
    e.value[rt.value] && (f = e.value[rt.value][yt.value]), !f && e.value[rt.value + (c ? 1 : -1)] ? (rt.value = rt.value + (c ? 1 : -1), yt.value = c ? 0 : e.value[rt.value].length - 1) : f || (yt.value = c ? yt.value - 1 : yt.value + 1);
  }, a = (c) => {
    rt.value === 0 && !c || rt.value === e.value.length && c || (rt.value = c ? rt.value + 1 : rt.value - 1, e.value[rt.value] ? e.value[rt.value] && !e.value[rt.value][yt.value] && yt.value !== 0 && (yt.value = e.value[rt.value].length - 1) : rt.value = c ? rt.value - 1 : rt.value + 1);
  }, n = (c) => {
    let f = null;
    e.value[rt.value] && (f = e.value[rt.value][yt.value]), f ? f.focus({ preventScroll: !ir.value }) : yt.value = c ? yt.value - 1 : yt.value + 1;
  }, r = () => {
    t(!0), n(!0);
  }, i = () => {
    t(!1), n(!1);
  }, l = () => {
    a(!1), n(!0);
  }, o = () => {
    a(!0), n(!0);
  }, s = (c, f) => {
    We[f] = c;
  }, d = (c, f) => {
    We[f] = c;
  }, u = () => {
    yt.value = 0, rt.value = 0;
  };
  return {
    buildMatrix: s,
    buildMultiLevelMatrix: d,
    setTimePickerBackRef: (c) => {
      ul.value = c;
    },
    setSelectionGrid: (c) => {
      ir.value = c, u(), c || (We.selectionGrid = []);
    },
    setTimePicker: (c, f = !1) => {
      cl.value = c, fl.value = f, u(), c || (We.timePicker[0] = [], We.timePicker[1] = []);
    },
    setTimePickerElements: (c, f = 0) => {
      We.timePicker[f] = c;
    },
    arrowRight: r,
    arrowLeft: i,
    arrowUp: l,
    arrowDown: o,
    clearArrowNav: () => {
      We.monthYear = [], We.calendar = [], We.time = [], We.actionRow = [], We.selectionGrid = [], We.timePicker[0] = [], We.timePicker[1] = [], ir.value = !1, cl.value = !1, fl.value = !1, dl.value = !1, u(), ul.value = null;
    },
    setMonthPicker: (c) => {
      dl.value = c, u();
    },
    refSets: We
    // exposed for testing
  };
}, Io = (e) => ({
  menuAppearTop: "dp-menu-appear-top",
  menuAppearBottom: "dp-menu-appear-bottom",
  open: "dp-slide-down",
  close: "dp-slide-up",
  next: "calendar-next",
  previous: "calendar-prev",
  vNext: "dp-slide-up",
  vPrevious: "dp-slide-down",
  ...e ?? {}
}), Q2 = (e) => ({
  toggleOverlay: "Toggle overlay",
  menu: "Datepicker menu",
  input: "Datepicker input",
  openTimePicker: "Open time picker",
  closeTimePicker: "Close time Picker",
  incrementValue: (t) => `Increment ${t}`,
  decrementValue: (t) => `Decrement ${t}`,
  openTpOverlay: (t) => `Open ${t} overlay`,
  amPmButton: "Switch AM/PM mode",
  openYearsOverlay: "Open years overlay",
  openMonthsOverlay: "Open months overlay",
  nextMonth: "Next month",
  prevMonth: "Previous month",
  nextYear: "Next year",
  prevYear: "Previous year",
  day: void 0,
  weekDay: void 0,
  clearInput: "Clear value",
  calendarIcon: "Calendar icon",
  timePicker: "Time picker",
  monthPicker: (t) => `Month picker${t ? " overlay" : ""}`,
  yearPicker: (t) => `Year picker${t ? " overlay" : ""}`,
  timeOverlay: (t) => `${t} overlay`,
  ...e ?? {}
}), Eo = (e) => e ? typeof e == "boolean" ? e ? 2 : 0 : +e >= 2 ? +e : 2 : 0, K2 = (e) => {
  const t = typeof e == "object" && e, a = {
    static: !0,
    solo: !1
  };
  if (!e)
    return { ...a, count: Eo(!1) };
  const n = t ? e : {}, r = t ? n.count ?? !0 : e, i = Eo(r);
  return Object.assign(a, n, { count: i });
}, G2 = (e, t, a) => e || (typeof a == "string" ? a : t), J2 = (e) => typeof e == "boolean" ? e ? Io({}) : !1 : Io(e), Z2 = (e) => {
  const t = {
    enterSubmit: !0,
    tabSubmit: !0,
    openMenu: "open",
    selectOnFocus: !1,
    rangeSeparator: " - "
  };
  return typeof e == "object" ? { ...t, ...e ?? {}, enabled: !0 } : { ...t, enabled: e };
}, ep = (e) => ({
  months: [],
  years: [],
  times: { hours: [], minutes: [], seconds: [] },
  ...e ?? {}
}), tp = (e) => ({
  showSelect: !0,
  showCancel: !0,
  showNow: !1,
  showPreview: !0,
  ...e ?? {}
}), ap = (e) => {
  const t = { input: !1 };
  return typeof e == "object" ? { ...t, ...e ?? {}, enabled: !0 } : {
    enabled: e,
    ...t
  };
}, np = (e) => ({ allowStopPropagation: !0, closeOnScroll: !1, modeHeight: 255, allowPreventDefault: !1, closeOnClearValue: !0, closeOnAutoApply: !0, noSwipe: !1, keepActionRow: !1, onClickOutside: void 0, tabOutClosesMenu: !0, arrowLeft: void 0, keepViewOnOffsetClick: !1, timeArrowHoldThreshold: 0, shadowDom: !1, mobileBreakpoint: 600, setDateOnMenuClose: !1, ...e ?? {} }), rp = (e) => {
  const t = {
    dates: Array.isArray(e) ? e.map((a) => J(a)) : [],
    years: [],
    months: [],
    quarters: [],
    weeks: [],
    weekdays: [],
    options: { highlightDisabled: !1 }
  };
  return typeof e == "function" ? e : { ...t, ...e ?? {} };
}, lp = (e) => typeof e == "object" ? {
  type: (e == null ? void 0 : e.type) ?? "local",
  hideOnOffsetDates: (e == null ? void 0 : e.hideOnOffsetDates) ?? !1
} : {
  type: e,
  hideOnOffsetDates: !1
}, ip = (e) => {
  const t = {
    noDisabledRange: !1,
    showLastInRange: !0,
    minMaxRawRange: !1,
    partialRange: !0,
    disableTimeRangeValidation: !1,
    maxRange: void 0,
    minRange: void 0,
    autoRange: void 0,
    fixedStart: !1,
    fixedEnd: !1
  };
  return typeof e == "object" ? { enabled: !0, ...t, ...e } : {
    enabled: e,
    ...t
  };
}, op = (e) => e ? typeof e == "string" ? {
  timezone: e,
  exactMatch: !1,
  dateInTz: void 0,
  emitTimezone: void 0,
  convertModel: !0
} : {
  timezone: e.timezone,
  exactMatch: e.exactMatch ?? !1,
  dateInTz: e.dateInTz ?? void 0,
  emitTimezone: e.emitTimezone ?? void 0,
  convertModel: e.convertModel ?? !0
} : { timezone: void 0, exactMatch: !1, emitTimezone: void 0 }, vl = (e, t, a) => new Map(
  e.map((n) => {
    const r = Mi(n, t, a);
    return [Ti(r), r];
  })
), sp = (e, t) => e.length ? new Map(
  e.map((a) => {
    const n = Mi(a.date, t);
    return [Ti(n), a];
  })
) : null, up = (e) => {
  var t;
  return {
    minDate: Ll(e.minDate, e.timezone, e.isSpecific),
    maxDate: Ll(e.maxDate, e.timezone, e.isSpecific),
    disabledDates: rl(e.disabledDates) ? vl(e.disabledDates, e.timezone, e.isSpecific) : e.disabledDates,
    allowedDates: rl(e.allowedDates) ? vl(e.allowedDates, e.timezone, e.isSpecific) : null,
    highlight: typeof e.highlight == "object" && rl((t = e.highlight) == null ? void 0 : t.dates) ? vl(e.highlight.dates, e.timezone) : e.highlight,
    markers: sp(e.markers, e.timezone)
  };
}, cp = (e) => typeof e == "boolean" ? { enabled: e, dragSelect: !0, limit: null } : {
  enabled: !!e,
  limit: e.limit ? +e.limit : null,
  dragSelect: e.dragSelect ?? !0
}, dp = (e) => ({
  ...Object.fromEntries(
    Object.keys(e).map((t) => {
      const a = t, n = e[a], r = typeof e[a] == "string" ? { [n]: !0 } : Object.fromEntries(n.map((i) => [i, !0]));
      return [t, r];
    })
  )
}), Ke = (e) => {
  const t = () => {
    const A = e.enableSeconds ? ":ss" : "", I = e.enableMinutes ? ":mm" : "";
    return e.is24 ? `HH${I}${A}` : `hh${I}${A} aa`;
  }, a = () => {
    var A;
    return e.format ? e.format : e.monthPicker ? "MM/yyyy" : e.timePicker ? t() : e.weekPicker ? `${((A = P.value) == null ? void 0 : A.type) === "iso" ? "RR" : "ww"}-yyyy` : e.yearPicker ? "yyyy" : e.quarterPicker ? "QQQ/yyyy" : e.enableTimePicker ? `MM/dd/yyyy, ${t()}` : "MM/dd/yyyy";
  }, n = (A) => Eu(A, e.enableSeconds), r = () => _.value.enabled ? e.startTime && Array.isArray(e.startTime) ? [n(e.startTime[0]), n(e.startTime[1])] : null : e.startTime && !Array.isArray(e.startTime) ? n(e.startTime) : null, i = $(() => K2(e.multiCalendars)), l = $(() => r()), o = $(() => Q2(e.ariaLabels)), s = $(() => ep(e.filters)), d = $(() => J2(e.transitions)), u = $(() => tp(e.actionRow)), c = $(
    () => G2(e.previewFormat, e.format, a())
  ), f = $(() => Z2(e.textInput)), v = $(() => ap(e.inline)), g = $(() => np(e.config)), y = $(() => rp(e.highlight)), P = $(() => lp(e.weekNumbers)), b = $(() => op(e.timezone)), w = $(() => cp(e.multiDates)), h = $(
    () => up({
      minDate: e.minDate,
      maxDate: e.maxDate,
      disabledDates: e.disabledDates,
      allowedDates: e.allowedDates,
      highlight: y.value,
      markers: e.markers,
      timezone: b.value,
      isSpecific: e.monthPicker || e.yearPicker || e.quarterPicker
    })
  ), _ = $(() => ip(e.range)), C = $(() => dp(e.ui));
  return {
    defaultedTransitions: d,
    defaultedMultiCalendars: i,
    defaultedStartTime: l,
    defaultedAriaLabels: o,
    defaultedFilters: s,
    defaultedActionRow: u,
    defaultedPreviewFormat: c,
    defaultedTextInput: f,
    defaultedInline: v,
    defaultedConfig: g,
    defaultedHighlight: y,
    defaultedWeekNumbers: P,
    defaultedRange: _,
    propDates: h,
    defaultedTz: b,
    defaultedMultiDates: w,
    defaultedUI: C,
    getDefaultPattern: a,
    getDefaultStartTime: r
  };
}, fp = (e, t, a) => {
  const n = z(), { defaultedTextInput: r, defaultedRange: i, defaultedTz: l, defaultedMultiDates: o, getDefaultPattern: s } = Ke(t), d = z(""), u = tt(t, "format"), c = tt(t, "formatLocale");
  ht(
    n,
    () => {
      typeof t.onInternalModelChange == "function" && e("internal-model-change", n.value, K(!0));
    },
    { deep: !0 }
  ), ht(i, (x, se) => {
    x.enabled !== se.enabled && (n.value = null);
  }), ht(u, () => {
    re();
  });
  const f = (x) => l.value.timezone && l.value.convertModel ? Tt(x, l.value.timezone) : x, v = (x) => {
    if (l.value.timezone && l.value.convertModel) {
      const se = D2(l.value.timezone, x);
      return Ov(x, se);
    }
    return x;
  }, g = (x, se, we = !1) => Nu(
    x,
    t.format,
    t.formatLocale,
    r.value.rangeSeparator,
    t.modelAuto,
    se ?? s(),
    we
  ), y = (x) => x ? t.modelType ? E(x) : {
    hours: fa(x),
    minutes: Sa(x),
    seconds: t.enableSeconds ? un(x) : 0
  } : null, P = (x) => t.modelType ? E(x) : { month: De(x), year: Te(x) }, b = (x) => Array.isArray(x) ? o.value.enabled ? x.map((se) => w(se, Jt(J(), se))) : lr(
    () => [
      Jt(J(), x[0]),
      x[1] ? Jt(J(), x[1]) : Qa(i.value.partialRange)
    ],
    i.value.enabled
  ) : Jt(J(), +x), w = (x, se) => (typeof x == "string" || typeof x == "number") && t.modelType ? U(x) : se, h = (x) => Array.isArray(x) ? [
    w(
      x[0],
      _a(null, +x[0].hours, +x[0].minutes, x[0].seconds)
    ),
    w(
      x[1],
      _a(null, +x[1].hours, +x[1].minutes, x[1].seconds)
    )
  ] : w(x, _a(null, x.hours, x.minutes, x.seconds)), _ = (x) => {
    const se = Be(J(), { date: 1 });
    return Array.isArray(x) ? o.value.enabled ? x.map((we) => w(we, la(se, +we.month, +we.year))) : lr(
      () => [
        w(x[0], la(se, +x[0].month, +x[0].year)),
        w(
          x[1],
          x[1] ? la(se, +x[1].month, +x[1].year) : Qa(i.value.partialRange)
        )
      ],
      i.value.enabled
    ) : w(x, la(se, +x.month, +x.year));
  }, C = (x) => {
    if (Array.isArray(x))
      return x.map((se) => U(se));
    throw new Error(Ai.dateArr("multi-dates"));
  }, A = (x) => {
    if (Array.isArray(x) && i.value.enabled) {
      const se = x[0], we = x[1];
      return [
        J(Array.isArray(se) ? se[0] : null),
        Array.isArray(we) && we.length ? J(we[0]) : null
      ];
    }
    return J(x[0]);
  }, I = (x) => t.modelAuto ? Array.isArray(x) ? [U(x[0]), U(x[1])] : t.autoApply ? [U(x)] : [U(x), null] : Array.isArray(x) ? lr(
    () => x[1] ? [
      U(x[0]),
      x[1] ? U(x[1]) : Qa(i.value.partialRange)
    ] : [U(x[0])],
    i.value.enabled
  ) : U(x), V = () => {
    Array.isArray(n.value) && i.value.enabled && n.value.length === 1 && n.value.push(Qa(i.value.partialRange));
  }, L = () => {
    const x = n.value;
    return [
      E(x[0]),
      x[1] ? E(x[1]) : Qa(i.value.partialRange)
    ];
  }, F = () => n.value[1] ? L() : E(vt(n.value[0])), ne = () => (n.value || []).map((x) => E(x)), ce = (x = !1) => (x || V(), t.modelAuto ? F() : o.value.enabled ? ne() : Array.isArray(n.value) ? lr(() => L(), i.value.enabled) : E(vt(n.value))), ve = (x) => !x || Array.isArray(x) && !x.length ? null : t.timePicker ? h(vt(x)) : t.monthPicker ? _(vt(x)) : t.yearPicker ? b(vt(x)) : o.value.enabled ? C(vt(x)) : t.weekPicker ? A(vt(x)) : I(vt(x)), T = (x) => {
    const se = ve(x);
    Fl(vt(se)) ? (n.value = vt(se), re()) : (n.value = null, d.value = "");
  }, D = () => {
    const x = (se) => Zt(se, r.value.format);
    return `${x(n.value[0])} ${r.value.rangeSeparator} ${n.value[1] ? x(n.value[1]) : ""}`;
  }, O = () => a.value && n.value ? Array.isArray(n.value) ? D() : Zt(n.value, r.value.format) : g(n.value), W = () => n.value ? o.value.enabled ? n.value.map((x) => g(x)).join("; ") : r.value.enabled && typeof r.value.format == "string" ? O() : g(n.value) : "", re = () => {
    !t.format || typeof t.format == "string" || r.value.enabled && typeof r.value.format == "string" ? d.value = W() : d.value = t.format(n.value);
  }, U = (x) => {
    if (t.utc) {
      const se = new Date(x);
      return t.utc === "preserve" ? new Date(se.getTime() + se.getTimezoneOffset() * 6e4) : se;
    }
    return t.modelType ? $2.includes(t.modelType) ? f(new Date(x)) : t.modelType === "format" && (typeof t.format == "string" || !t.format) ? f(
      Nl(x, s(), /* @__PURE__ */ new Date(), { locale: c.value })
    ) : f(
      Nl(x, t.modelType, /* @__PURE__ */ new Date(), { locale: c.value })
    ) : f(new Date(x));
  }, E = (x) => x ? t.utc ? Y2(x, t.utc === "preserve", t.enableSeconds) : t.modelType ? t.modelType === "timestamp" ? +v(x) : t.modelType === "iso" ? v(x).toISOString() : t.modelType === "format" && (typeof t.format == "string" || !t.format) ? g(v(x)) : g(v(x), t.modelType, !0) : v(x) : "", oe = (x, se = !1, we = !1) => {
    if (we)
      return x;
    if (e("update:model-value", x), l.value.emitTimezone && se) {
      const st = Array.isArray(x) ? x.map((B) => Tt(vt(B), l.value.emitTimezone)) : Tt(vt(x), l.value.emitTimezone);
      e("update:model-timezone-value", st);
    }
  }, q = (x) => Array.isArray(n.value) ? o.value.enabled ? n.value.map((se) => x(se)) : [
    x(n.value[0]),
    n.value[1] ? x(n.value[1]) : Qa(i.value.partialRange)
  ] : x(vt(n.value)), H = () => {
    if (Array.isArray(n.value)) {
      const x = ra(n.value[0], t.weekStart), se = n.value[1] ? ra(n.value[1], t.weekStart) : [];
      return [x.map((we) => J(we)), se.map((we) => J(we))];
    }
    return ra(n.value, t.weekStart).map((x) => J(x));
  }, m = (x, se) => oe(vt(q(x)), !1, se), S = (x) => {
    const se = H();
    return x ? se : e("update:model-value", H());
  }, K = (x = !1) => (x || re(), t.monthPicker ? m(P, x) : t.timePicker ? m(y, x) : t.yearPicker ? m(Te, x) : t.weekPicker ? S(x) : oe(ce(x), !0, x));
  return {
    inputValue: d,
    internalModelValue: n,
    checkBeforeEmit: () => n.value ? i.value.enabled ? i.value.partialRange ? n.value.length >= 1 : n.value.length === 2 : !!n.value : !1,
    parseExternalModelValue: T,
    formatInputValue: re,
    emitModelValue: K
  };
}, vp = (e, t) => {
  const { defaultedFilters: a, propDates: n } = Ke(e), { validateMonthYearInRange: r } = $a(e), i = (u, c) => {
    let f = u;
    return a.value.months.includes(De(f)) ? (f = c ? Vt(u, 1) : dn(u, 1), i(f, c)) : f;
  }, l = (u, c) => {
    let f = u;
    return a.value.years.includes(Te(f)) ? (f = c ? vi(u, 1) : Pu(u, 1), l(f, c)) : f;
  }, o = (u, c = !1) => {
    const f = Be(J(), { month: e.month, year: e.year });
    let v = u ? Vt(f, 1) : dn(f, 1);
    e.disableYearSelect && (v = Jt(v, e.year));
    let g = De(v), y = Te(v);
    a.value.months.includes(g) && (v = i(v, u), g = De(v), y = Te(v)), a.value.years.includes(y) && (v = l(v, u), y = Te(v)), r(g, y, u, e.preventMinMaxNavigation) && s(g, y, c);
  }, s = (u, c, f) => {
    t("update-month-year", { month: u, year: c, fromNav: f });
  }, d = $(() => (u) => Ru(
    Be(J(), { month: e.month, year: e.year }),
    n.value.maxDate,
    n.value.minDate,
    e.preventMinMaxNavigation,
    u
  ));
  return { handleMonthYearChange: o, isDisabled: d, updateMonthYear: s };
}, Fr = {
  multiCalendars: { type: [Boolean, Number, String, Object], default: void 0 },
  modelValue: { type: [String, Date, Array, Object, Number], default: null },
  modelType: { type: String, default: null },
  position: { type: String, default: "center" },
  dark: { type: Boolean, default: !1 },
  format: {
    type: [String, Function],
    default: () => null
  },
  autoPosition: { type: [Boolean, String], default: !0 },
  altPosition: { type: Function, default: null },
  transitions: { type: [Boolean, Object], default: !0 },
  formatLocale: { type: Object, default: null },
  utc: { type: [Boolean, String], default: !1 },
  ariaLabels: { type: Object, default: () => ({}) },
  offset: { type: [Number, String], default: 10 },
  hideNavigation: { type: Array, default: () => [] },
  timezone: { type: [String, Object], default: null },
  vertical: { type: Boolean, default: !1 },
  disableMonthYearSelect: { type: Boolean, default: !1 },
  disableYearSelect: { type: Boolean, default: !1 },
  dayClass: {
    type: Function,
    default: null
  },
  yearRange: { type: Array, default: () => [1900, 2100] },
  enableTimePicker: { type: Boolean, default: !0 },
  autoApply: { type: Boolean, default: !1 },
  disabledDates: { type: [Array, Function], default: () => [] },
  monthNameFormat: { type: String, default: "short" },
  startDate: { type: [Date, String], default: null },
  startTime: { type: [Object, Array], default: null },
  hideOffsetDates: { type: Boolean, default: !1 },
  noToday: { type: Boolean, default: !1 },
  disabledWeekDays: { type: Array, default: () => [] },
  allowedDates: { type: Array, default: null },
  nowButtonLabel: { type: String, default: "Now" },
  markers: { type: Array, default: () => [] },
  escClose: { type: Boolean, default: !0 },
  spaceConfirm: { type: Boolean, default: !0 },
  monthChangeOnArrows: { type: Boolean, default: !0 },
  presetDates: { type: Array, default: () => [] },
  flow: { type: Array, default: () => [] },
  partialFlow: { type: Boolean, default: !1 },
  preventMinMaxNavigation: { type: Boolean, default: !1 },
  reverseYears: { type: Boolean, default: !1 },
  weekPicker: { type: Boolean, default: !1 },
  filters: { type: Object, default: () => ({}) },
  arrowNavigation: { type: Boolean, default: !1 },
  highlight: {
    type: [Function, Object],
    default: null
  },
  teleport: { type: [Boolean, String, Object], default: null },
  teleportCenter: { type: Boolean, default: !1 },
  locale: { type: String, default: "en-Us" },
  weekNumName: { type: String, default: "W" },
  weekStart: { type: [Number, String], default: 1 },
  weekNumbers: {
    type: [String, Function, Object],
    default: null
  },
  monthChangeOnScroll: { type: [Boolean, String], default: !0 },
  dayNames: {
    type: [Function, Array],
    default: null
  },
  monthPicker: { type: Boolean, default: !1 },
  customProps: { type: Object, default: null },
  yearPicker: { type: Boolean, default: !1 },
  modelAuto: { type: Boolean, default: !1 },
  selectText: { type: String, default: "Select" },
  cancelText: { type: String, default: "Cancel" },
  previewFormat: {
    type: [String, Function],
    default: () => ""
  },
  multiDates: { type: [Object, Boolean], default: !1 },
  ignoreTimeValidation: { type: Boolean, default: !1 },
  minDate: { type: [Date, String], default: null },
  maxDate: { type: [Date, String], default: null },
  minTime: { type: Object, default: null },
  maxTime: { type: Object, default: null },
  name: { type: String, default: null },
  placeholder: { type: String, default: "" },
  hideInputIcon: { type: Boolean, default: !1 },
  clearable: { type: Boolean, default: !0 },
  state: { type: Boolean, default: null },
  required: { type: Boolean, default: !1 },
  autocomplete: { type: String, default: "off" },
  timePicker: { type: Boolean, default: !1 },
  enableSeconds: { type: Boolean, default: !1 },
  is24: { type: Boolean, default: !0 },
  noHoursOverlay: { type: Boolean, default: !1 },
  noMinutesOverlay: { type: Boolean, default: !1 },
  noSecondsOverlay: { type: Boolean, default: !1 },
  hoursGridIncrement: { type: [String, Number], default: 1 },
  minutesGridIncrement: { type: [String, Number], default: 5 },
  secondsGridIncrement: { type: [String, Number], default: 5 },
  hoursIncrement: { type: [Number, String], default: 1 },
  minutesIncrement: { type: [Number, String], default: 1 },
  secondsIncrement: { type: [Number, String], default: 1 },
  range: { type: [Boolean, Object], default: !1 },
  uid: { type: String, default: null },
  disabled: { type: Boolean, default: !1 },
  readonly: { type: Boolean, default: !1 },
  inline: { type: [Boolean, Object], default: !1 },
  textInput: { type: [Boolean, Object], default: !1 },
  sixWeeks: { type: [Boolean, String], default: !1 },
  actionRow: { type: Object, default: () => ({}) },
  focusStartDate: { type: Boolean, default: !1 },
  disabledTimes: { type: [Function, Array], default: void 0 },
  timePickerInline: { type: Boolean, default: !1 },
  calendar: { type: Function, default: null },
  config: { type: Object, default: void 0 },
  quarterPicker: { type: Boolean, default: !1 },
  yearFirst: { type: Boolean, default: !1 },
  loading: { type: Boolean, default: !1 },
  onInternalModelChange: { type: [Function, Object], default: null },
  enableMinutes: { type: Boolean, default: !0 },
  ui: { type: Object, default: () => ({}) }
}, Ut = {
  ...Fr,
  shadow: { type: Boolean, default: !1 },
  flowStep: { type: Number, default: 0 },
  internalModelValue: { type: [Date, Array], default: null },
  noOverlayFocus: { type: Boolean, default: !1 },
  collapse: { type: Boolean, default: !1 },
  menuWrapRef: { type: Object, default: null },
  getInputRect: { type: Function, default: () => ({}) },
  isTextInputDate: { type: Boolean, default: !1 },
  isMobile: { type: Boolean, default: void 0 }
}, mp = ["title"], pp = ["disabled"], hp = /* @__PURE__ */ _e({
  compatConfig: {
    MODE: 3
  },
  __name: "ActionRow",
  props: {
    menuMount: { type: Boolean, default: !1 },
    calendarWidth: { type: Number, default: 0 },
    ...Ut
  },
  emits: ["close-picker", "select-date", "select-now", "invalid-select"],
  setup(e, { emit: t }) {
    const a = t, n = e, {
      defaultedActionRow: r,
      defaultedPreviewFormat: i,
      defaultedMultiCalendars: l,
      defaultedTextInput: o,
      defaultedInline: s,
      defaultedRange: d,
      defaultedMultiDates: u
    } = Ke(n), { isTimeValid: c, isMonthValid: f } = $a(n), { buildMatrix: v } = Da(), g = z(null), y = z(null), P = z(!1), b = z({}), w = z(null), h = z(null);
    He(() => {
      n.arrowNavigation && v([dt(g), dt(y)], "actionRow"), _(), window.addEventListener("resize", _);
    }), Bt(() => {
      window.removeEventListener("resize", _);
    });
    const _ = () => {
      P.value = !1, setTimeout(() => {
        var T, D;
        const O = (T = w.value) == null ? void 0 : T.getBoundingClientRect(), W = (D = h.value) == null ? void 0 : D.getBoundingClientRect();
        O && W && (b.value.maxWidth = `${W.width - O.width - 20}px`), P.value = !0;
      }, 0);
    }, C = $(() => d.value.enabled && !d.value.partialRange && n.internalModelValue ? n.internalModelValue.length === 2 : !0), A = $(
      () => !c.value(n.internalModelValue) || !f.value(n.internalModelValue) || !C.value
    ), I = () => {
      const T = i.value;
      return n.timePicker || n.monthPicker, T(vt(n.internalModelValue));
    }, V = () => {
      const T = n.internalModelValue;
      return l.value.count > 0 ? `${L(T[0])} - ${L(T[1])}` : [L(T[0]), L(T[1])];
    }, L = (T) => Nu(
      T,
      i.value,
      n.formatLocale,
      o.value.rangeSeparator,
      n.modelAuto,
      i.value
    ), F = $(() => !n.internalModelValue || !n.menuMount ? "" : typeof i.value == "string" ? Array.isArray(n.internalModelValue) ? n.internalModelValue.length === 2 && n.internalModelValue[1] ? V() : u.value.enabled ? n.internalModelValue.map((T) => `${L(T)}`) : n.modelAuto ? `${L(n.internalModelValue[0])}` : `${L(n.internalModelValue[0])} -` : L(n.internalModelValue) : I()), ne = () => u.value.enabled ? "; " : " - ", ce = $(
      () => Array.isArray(F.value) ? F.value.join(ne()) : F.value
    ), ve = () => {
      c.value(n.internalModelValue) && f.value(n.internalModelValue) && C.value ? a("select-date") : a("invalid-select");
    };
    return (T, D) => (M(), Y("div", {
      ref_key: "actionRowRef",
      ref: h,
      class: "dp__action_row"
    }, [
      T.$slots["action-row"] ? ie(T.$slots, "action-row", mt(Ue({ key: 0 }, {
        internalModelValue: T.internalModelValue,
        disabled: A.value,
        selectDate: () => T.$emit("select-date"),
        closePicker: () => T.$emit("close-picker")
      }))) : (M(), Y(Ae, { key: 1 }, [
        p(r).showPreview ? (M(), Y("div", {
          key: 0,
          class: "dp__selection_preview",
          title: ce.value,
          style: Ge(b.value)
        }, [
          T.$slots["action-preview"] && P.value ? ie(T.$slots, "action-preview", {
            key: 0,
            value: T.internalModelValue
          }) : G("", !0),
          !T.$slots["action-preview"] && P.value ? (M(), Y(Ae, { key: 1 }, [
            ut(Ne(ce.value), 1)
          ], 64)) : G("", !0)
        ], 12, mp)) : G("", !0),
        le("div", {
          ref_key: "actionBtnContainer",
          ref: w,
          class: "dp__action_buttons",
          "data-dp-element": "action-row"
        }, [
          T.$slots["action-buttons"] ? ie(T.$slots, "action-buttons", {
            key: 0,
            value: T.internalModelValue
          }) : G("", !0),
          T.$slots["action-buttons"] ? G("", !0) : (M(), Y(Ae, { key: 1 }, [
            !p(s).enabled && p(r).showCancel ? (M(), Y("button", {
              key: 0,
              ref_key: "cancelButtonRef",
              ref: g,
              type: "button",
              class: "dp__action_button dp__action_cancel",
              onClick: D[0] || (D[0] = (O) => T.$emit("close-picker")),
              onKeydown: D[1] || (D[1] = (O) => p(xt)(O, () => T.$emit("close-picker")))
            }, Ne(T.cancelText), 545)) : G("", !0),
            p(r).showNow ? (M(), Y("button", {
              key: 1,
              type: "button",
              class: "dp__action_button dp__action_cancel",
              onClick: D[2] || (D[2] = (O) => T.$emit("select-now")),
              onKeydown: D[3] || (D[3] = (O) => p(xt)(O, () => T.$emit("select-now")))
            }, Ne(T.nowButtonLabel), 33)) : G("", !0),
            p(r).showSelect ? (M(), Y("button", {
              key: 2,
              ref_key: "selectButtonRef",
              ref: y,
              type: "button",
              class: "dp__action_button dp__action_select",
              disabled: A.value,
              "data-test-id": "select-button",
              onKeydown: D[4] || (D[4] = (O) => p(xt)(O, () => ve())),
              onClick: ve
            }, Ne(T.selectText), 41, pp)) : G("", !0)
          ], 64))
        ], 512)
      ], 64))
    ], 512));
  }
}), yp = ["role", "aria-label", "tabindex"], gp = { class: "dp__selection_grid_header" }, bp = ["aria-selected", "aria-disabled", "data-test-id", "onClick", "onKeydown", "onMouseover"], wp = ["aria-label"], Bn = /* @__PURE__ */ _e({
  __name: "SelectionOverlay",
  props: {
    items: {},
    type: {},
    isLast: { type: Boolean },
    arrowNavigation: { type: Boolean },
    skipButtonRef: { type: Boolean },
    headerRefs: {},
    hideNavigation: {},
    escClose: { type: Boolean },
    useRelative: { type: Boolean },
    height: {},
    textInput: { type: [Boolean, Object] },
    config: {},
    noOverlayFocus: { type: Boolean },
    focusValue: {},
    menuWrapRef: {},
    ariaLabels: {},
    overlayLabel: {}
  },
  emits: ["selected", "toggle", "reset-flow", "hover-value"],
  setup(e, { expose: t, emit: a }) {
    const { setSelectionGrid: n, buildMultiLevelMatrix: r, setMonthPicker: i } = Da(), l = a, o = e, { defaultedAriaLabels: s, defaultedTextInput: d, defaultedConfig: u } = Ke(
      o
    ), { hideNavigationButtons: c } = Yr(), f = z(!1), v = z(null), g = z(null), y = z([]), P = z(), b = z(null), w = z(0), h = z(null);
    tc(() => {
      v.value = null;
    }), He(() => {
      je().then(() => ne()), o.noOverlayFocus || C(), _(!0);
    }), Bt(() => _(!1));
    const _ = (q) => {
      var H;
      o.arrowNavigation && ((H = o.headerRefs) != null && H.length ? i(q) : n(q));
    }, C = () => {
      var q;
      const H = dt(g);
      H && (d.value.enabled || (v.value ? (q = v.value) == null || q.focus({ preventScroll: !0 }) : H.focus({ preventScroll: !0 })), f.value = H.clientHeight < H.scrollHeight);
    }, A = $(
      () => ({
        dp__overlay: !0,
        "dp--overlay-absolute": !o.useRelative,
        "dp--overlay-relative": o.useRelative
      })
    ), I = $(
      () => o.useRelative ? { height: `${o.height}px`, width: "var(--dp-menu-min-width)" } : void 0
    ), V = $(() => ({
      dp__overlay_col: !0
    })), L = $(
      () => ({
        dp__btn: !0,
        dp__button: !0,
        dp__overlay_action: !0,
        dp__over_action_scroll: f.value,
        dp__button_bottom: o.isLast
      })
    ), F = $(() => {
      var q, H;
      return {
        dp__overlay_container: !0,
        dp__container_flex: ((q = o.items) == null ? void 0 : q.length) <= 6,
        dp__container_block: ((H = o.items) == null ? void 0 : H.length) > 6
      };
    });
    ht(
      () => o.items,
      () => ne(!1),
      { deep: !0 }
    );
    const ne = (q = !0) => {
      je().then(() => {
        const H = dt(v), m = dt(g), S = dt(b), K = dt(h), x = S ? S.getBoundingClientRect().height : 0;
        m && (m.getBoundingClientRect().height ? w.value = m.getBoundingClientRect().height - x : w.value = u.value.modeHeight - x), H && K && q && (K.scrollTop = H.offsetTop - K.offsetTop - (w.value / 2 - H.getBoundingClientRect().height) - x);
      });
    }, ce = (q) => {
      q.disabled || l("selected", q.value);
    }, ve = () => {
      l("toggle"), l("reset-flow");
    }, T = () => {
      o.escClose && ve();
    }, D = (q, H, m, S) => {
      q && ((H.active || H.value === o.focusValue) && (v.value = q), o.arrowNavigation && (Array.isArray(y.value[m]) ? y.value[m][S] = q : y.value[m] = [q], O()));
    }, O = () => {
      var q, H;
      const m = (q = o.headerRefs) != null && q.length ? [o.headerRefs].concat(y.value) : y.value.concat([o.skipButtonRef ? [] : [b.value]]);
      r(vt(m), (H = o.headerRefs) != null && H.length ? "monthPicker" : "selectionGrid");
    }, W = (q) => {
      o.arrowNavigation || xa(q, u.value, !0);
    }, re = (q) => {
      P.value = q, l("hover-value", q);
    }, U = () => {
      if (ve(), !o.isLast) {
        const q = Hl(o.menuWrapRef ?? null, "action-row");
        if (q) {
          const H = Su(q);
          H == null || H.focus();
        }
      }
    }, E = (q) => {
      switch (q.key) {
        case qe.esc:
          return T();
        case qe.arrowLeft:
          return W(q);
        case qe.arrowRight:
          return W(q);
        case qe.arrowUp:
          return W(q);
        case qe.arrowDown:
          return W(q);
        default:
          return;
      }
    }, oe = (q) => {
      if (q.key === qe.enter)
        return ve();
      if (q.key === qe.tab)
        return U();
    };
    return t({ focusGrid: C }), (q, H) => {
      var m;
      return M(), Y("div", {
        ref_key: "gridWrapRef",
        ref: g,
        class: ge(A.value),
        style: Ge(I.value),
        role: q.useRelative ? void 0 : "dialog",
        "aria-label": q.overlayLabel,
        tabindex: q.useRelative ? void 0 : "0",
        onKeydown: E,
        onClick: H[0] || (H[0] = za(() => {
        }, ["prevent"]))
      }, [
        le("div", {
          ref_key: "containerRef",
          ref: h,
          class: ge(F.value),
          style: Ge({ "--dp-overlay-height": `${w.value}px` }),
          role: "grid"
        }, [
          le("div", gp, [
            ie(q.$slots, "header")
          ]),
          q.$slots.overlay ? ie(q.$slots, "overlay", { key: 0 }) : (M(!0), Y(Ae, { key: 1 }, ze(q.items, (S, K) => (M(), Y("div", {
            key: K,
            class: ge(["dp__overlay_row", { dp__flex_row: q.items.length >= 3 }]),
            role: "row"
          }, [
            (M(!0), Y(Ae, null, ze(S, (x, se) => (M(), Y("div", {
              key: x.value,
              ref_for: !0,
              ref: (we) => D(we, x, K, se),
              role: "gridcell",
              class: ge(V.value),
              "aria-selected": x.active || void 0,
              "aria-disabled": x.disabled || void 0,
              tabindex: "0",
              "data-test-id": x.text,
              onClick: za((we) => ce(x), ["prevent"]),
              onKeydown: (we) => p(xt)(we, () => ce(x), !0),
              onMouseover: (we) => re(x.value)
            }, [
              le("div", {
                class: ge(x.className)
              }, [
                q.$slots.item ? ie(q.$slots, "item", {
                  key: 0,
                  item: x
                }) : G("", !0),
                q.$slots.item ? G("", !0) : (M(), Y(Ae, { key: 1 }, [
                  ut(Ne(x.text), 1)
                ], 64))
              ], 2)
            ], 42, bp))), 128))
          ], 2))), 128))
        ], 6),
        q.$slots["button-icon"] ? ka((M(), Y("button", {
          key: 0,
          ref_key: "toggleButton",
          ref: b,
          type: "button",
          "aria-label": (m = p(s)) == null ? void 0 : m.toggleOverlay,
          class: ge(L.value),
          tabindex: "0",
          onClick: ve,
          onKeydown: oe
        }, [
          ie(q.$slots, "button-icon")
        ], 42, wp)), [
          [sr, !p(c)(q.hideNavigation, q.type)]
        ]) : G("", !0)
      ], 46, yp);
    };
  }
}), kp = ["data-dp-mobile"], zr = /* @__PURE__ */ _e({
  __name: "InstanceWrap",
  props: {
    multiCalendars: {},
    stretch: { type: Boolean },
    collapse: { type: Boolean },
    isMobile: { type: Boolean }
  },
  setup(e) {
    const t = e, a = $(
      () => t.multiCalendars > 0 ? [...Array(t.multiCalendars).keys()] : [0]
    ), n = $(() => ({
      dp__instance_calendar: t.multiCalendars > 0
    }));
    return (r, i) => (M(), Y("div", {
      class: ge({
        dp__menu_inner: !r.stretch,
        "dp--menu--inner-stretched": r.stretch,
        dp__flex_display: r.multiCalendars > 0,
        "dp--flex-display-collapsed": r.collapse
      }),
      "data-dp-mobile": r.isMobile
    }, [
      (M(!0), Y(Ae, null, ze(a.value, (l, o) => (M(), Y("div", {
        key: l,
        class: ge(n.value)
      }, [
        ie(r.$slots, "default", {
          instance: l,
          index: o
        })
      ], 2))), 128))
    ], 10, kp));
  }
}), xp = ["data-dp-element", "aria-label", "aria-disabled"], An = /* @__PURE__ */ _e({
  compatConfig: {
    MODE: 3
  },
  __name: "ArrowBtn",
  props: {
    ariaLabel: {},
    elName: {},
    disabled: { type: Boolean }
  },
  emits: ["activate", "set-ref"],
  setup(e, { emit: t }) {
    const a = t, n = z(null);
    return He(() => a("set-ref", n)), (r, i) => (M(), Y("button", {
      ref_key: "elRef",
      ref: n,
      type: "button",
      "data-dp-element": r.elName,
      class: "dp__btn dp--arrow-btn-nav",
      tabindex: "0",
      "aria-label": r.ariaLabel,
      "aria-disabled": r.disabled || void 0,
      onClick: i[0] || (i[0] = (l) => r.$emit("activate")),
      onKeydown: i[1] || (i[1] = (l) => p(xt)(l, () => r.$emit("activate"), !0))
    }, [
      le("span", {
        class: ge(["dp__inner_nav", { dp__inner_nav_disabled: r.disabled }])
      }, [
        ie(r.$slots, "default")
      ], 2)
    ], 40, xp));
  }
}), _p = ["aria-label", "data-test-id"], zu = /* @__PURE__ */ _e({
  __name: "YearModePicker",
  props: {
    ...Ut,
    showYearPicker: { type: Boolean, default: !1 },
    items: { type: Array, default: () => [] },
    instance: { type: Number, default: 0 },
    year: { type: Number, default: 0 },
    isDisabled: { type: Function, default: () => !1 }
  },
  emits: ["toggle-year-picker", "year-select", "handle-year"],
  setup(e, { emit: t }) {
    const a = t, n = e, { showRightIcon: r, showLeftIcon: i } = Yr(), { defaultedConfig: l, defaultedMultiCalendars: o, defaultedAriaLabels: s, defaultedTransitions: d, defaultedUI: u } = Ke(n), { showTransition: c, transitionName: f } = jn(d), v = z(!1), g = (b = !1, w) => {
      v.value = !v.value, a("toggle-year-picker", { flow: b, show: w });
    }, y = (b) => {
      v.value = !1, a("year-select", b);
    }, P = (b = !1) => {
      a("handle-year", b);
    };
    return (b, w) => {
      var h, _, C, A, I;
      return M(), Y(Ae, null, [
        le("div", {
          class: ge(["dp--year-mode-picker", { "dp--hidden-el": v.value }])
        }, [
          p(i)(p(o), e.instance) ? (M(), Me(An, {
            key: 0,
            ref: "mpPrevIconRef",
            "aria-label": (h = p(s)) == null ? void 0 : h.prevYear,
            disabled: e.isDisabled(!1),
            class: ge((_ = p(u)) == null ? void 0 : _.navBtnPrev),
            onActivate: w[0] || (w[0] = (V) => P(!1))
          }, {
            default: pe(() => [
              b.$slots["arrow-left"] ? ie(b.$slots, "arrow-left", { key: 0 }) : G("", !0),
              b.$slots["arrow-left"] ? G("", !0) : (M(), Me(p(bi), { key: 1 }))
            ]),
            _: 3
          }, 8, ["aria-label", "disabled", "class"])) : G("", !0),
          le("button", {
            ref: "mpYearButtonRef",
            class: "dp__btn dp--year-select",
            type: "button",
            "aria-label": `${e.year}-${(C = p(s)) == null ? void 0 : C.openYearsOverlay}`,
            "data-test-id": `year-mode-btn-${e.instance}`,
            onClick: w[1] || (w[1] = () => g(!1)),
            onKeydown: w[2] || (w[2] = ur(() => g(!1), ["enter"]))
          }, [
            b.$slots.year ? ie(b.$slots, "year", {
              key: 0,
              year: e.year
            }) : G("", !0),
            b.$slots.year ? G("", !0) : (M(), Y(Ae, { key: 1 }, [
              ut(Ne(e.year), 1)
            ], 64))
          ], 40, _p),
          p(r)(p(o), e.instance) ? (M(), Me(An, {
            key: 1,
            ref: "mpNextIconRef",
            "aria-label": (A = p(s)) == null ? void 0 : A.nextYear,
            disabled: e.isDisabled(!0),
            class: ge((I = p(u)) == null ? void 0 : I.navBtnNext),
            onActivate: w[3] || (w[3] = (V) => P(!0))
          }, {
            default: pe(() => [
              b.$slots["arrow-right"] ? ie(b.$slots, "arrow-right", { key: 0 }) : G("", !0),
              b.$slots["arrow-right"] ? G("", !0) : (M(), Me(p(wi), { key: 1 }))
            ]),
            _: 3
          }, 8, ["aria-label", "disabled", "class"])) : G("", !0)
        ], 2),
        Ee(Ca, {
          name: p(f)(e.showYearPicker),
          css: p(c)
        }, {
          default: pe(() => {
            var V, L;
            return [
              e.showYearPicker ? (M(), Me(Bn, {
                key: 0,
                items: e.items,
                "text-input": b.textInput,
                "esc-close": b.escClose,
                config: b.config,
                "is-last": b.autoApply && !p(l).keepActionRow,
                "hide-navigation": b.hideNavigation,
                "aria-labels": b.ariaLabels,
                "overlay-label": (L = (V = p(s)) == null ? void 0 : V.yearPicker) == null ? void 0 : L.call(V, !0),
                type: "year",
                onToggle: g,
                onSelected: w[4] || (w[4] = (F) => y(F))
              }, bt({
                "button-icon": pe(() => [
                  b.$slots["calendar-icon"] ? ie(b.$slots, "calendar-icon", { key: 0 }) : G("", !0),
                  b.$slots["calendar-icon"] ? G("", !0) : (M(), Me(p(hn), { key: 1 }))
                ]),
                _: 2
              }, [
                b.$slots["year-overlay-value"] ? {
                  name: "item",
                  fn: pe(({ item: F }) => [
                    ie(b.$slots, "year-overlay-value", {
                      text: F.text,
                      value: F.value
                    })
                  ]),
                  key: "0"
                } : void 0
              ]), 1032, ["items", "text-input", "esc-close", "config", "is-last", "hide-navigation", "aria-labels", "overlay-label"])) : G("", !0)
            ];
          }),
          _: 3
        }, 8, ["name", "css"])
      ], 64);
    };
  }
}), Si = (e, t, a) => {
  if (t.value && Array.isArray(t.value))
    if (t.value.some((n) => $e(e, n))) {
      const n = t.value.filter((r) => !$e(r, e));
      t.value = n.length ? n : null;
    } else
      (a && +a > t.value.length || !a) && t.value.push(e);
  else
    t.value = [e];
}, Ci = (e, t, a) => {
  let n = e.value ? e.value.slice() : [];
  return n.length === 2 && n[1] !== null && (n = []), n.length ? (Ze(t, n[0]) ? n.unshift(t) : n[1] = t, a("range-end", t)) : (n = [t], a("range-start", t)), n;
}, Vr = (e, t, a, n) => {
  e && (e[0] && e[1] && a && t("auto-apply"), e[0] && !e[1] && n && a && t("auto-apply"));
}, Vu = (e) => {
  Array.isArray(e.value) && e.value.length <= 2 && e.range ? e.modelValue.value = e.value.map((t) => Tt(J(t), e.timezone)) : Array.isArray(e.value) || (e.modelValue.value = Tt(J(e.value), e.timezone));
}, Yu = (e, t, a, n) => Array.isArray(t.value) && (t.value.length === 2 || t.value.length === 1 && n.value.partialRange) ? n.value.fixedStart && (lt(e, t.value[0]) || $e(e, t.value[0])) ? [t.value[0], e] : n.value.fixedEnd && (Ze(e, t.value[1]) || $e(e, t.value[1])) ? [e, t.value[1]] : (a("invalid-fixed-range", e), t.value) : [], Bu = ({
  multiCalendars: e,
  range: t,
  highlight: a,
  propDates: n,
  calendars: r,
  modelValue: i,
  props: l,
  filters: o,
  year: s,
  month: d,
  emit: u
}) => {
  const c = $(() => Pi(l.yearRange, l.locale, l.reverseYears)), f = z([!1]), v = $(() => (F, ne) => {
    const ce = Be(Ft(/* @__PURE__ */ new Date()), {
      month: d.value(F),
      year: s.value(F)
    }), ve = ne ? fu(ce) : En(ce);
    return Ru(
      ve,
      n.value.maxDate,
      n.value.minDate,
      l.preventMinMaxNavigation,
      ne
    );
  }), g = () => Array.isArray(i.value) && e.value.solo && i.value[1], y = () => {
    for (let F = 0; F < e.value.count; F++)
      if (F === 0)
        r.value[F] = r.value[0];
      else if (F === e.value.count - 1 && g())
        r.value[F] = {
          month: De(i.value[1]),
          year: Te(i.value[1])
        };
      else {
        const ne = Be(J(), r.value[F - 1]);
        r.value[F] = { month: De(ne), year: Te(vi(ne, 1)) };
      }
  }, P = (F) => {
    if (!F)
      return y();
    const ne = Be(J(), r.value[F]);
    return r.value[0].year = Te(Pu(ne, e.value.count - 1)), y();
  }, b = (F, ne) => {
    const ce = Iv(ne, F);
    return t.value.showLastInRange && ce > 1 ? ne : F;
  }, w = (F) => l.focusStartDate || e.value.solo ? F[0] : F[1] ? b(F[0], F[1]) : F[0], h = () => {
    if (i.value) {
      const F = Array.isArray(i.value) ? w(i.value) : i.value;
      r.value[0] = { month: De(F), year: Te(F) };
    }
  }, _ = () => {
    h(), e.value.count && y();
  };
  ht(i, (F, ne) => {
    l.isTextInputDate && JSON.stringify(F ?? {}) !== JSON.stringify(ne ?? {}) && _();
  }), He(() => {
    _();
  });
  const C = (F, ne) => {
    r.value[ne].year = F, u("update-month-year", { instance: ne, year: F, month: r.value[ne].month }), e.value.count && !e.value.solo && P(ne);
  }, A = $(() => (F) => fn(c.value, (ne) => {
    var ce;
    const ve = s.value(F) === ne.value, T = Nn(
      ne.value,
      vn(n.value.minDate),
      vn(n.value.maxDate)
    ) || ((ce = o.value.years) == null ? void 0 : ce.includes(s.value(F))), D = Oi(a.value, ne.value);
    return { active: ve, disabled: T, highlighted: D };
  })), I = (F, ne) => {
    C(F, ne), L(ne);
  }, V = (F, ne = !1) => {
    if (!v.value(F, ne)) {
      const ce = ne ? s.value(F) + 1 : s.value(F) - 1;
      C(ce, F);
    }
  }, L = (F, ne = !1, ce) => {
    ne || u("reset-flow"), ce !== void 0 ? f.value[F] = ce : f.value[F] = !f.value[F], f.value[F] ? u("overlay-toggle", { open: !0, overlay: gt.year }) : (u("overlay-closed"), u("overlay-toggle", { open: !1, overlay: gt.year }));
  };
  return {
    isDisabled: v,
    groupedYears: A,
    showYearPicker: f,
    selectYear: C,
    toggleYearPicker: L,
    handleYearSelect: I,
    handleYear: V
  };
}, Mp = (e, t) => {
  const {
    defaultedMultiCalendars: a,
    defaultedAriaLabels: n,
    defaultedTransitions: r,
    defaultedConfig: i,
    defaultedRange: l,
    defaultedHighlight: o,
    propDates: s,
    defaultedTz: d,
    defaultedFilters: u,
    defaultedMultiDates: c
  } = Ke(e), f = () => {
    e.isTextInputDate && _(Te(J(e.startDate)), 0);
  }, { modelValue: v, year: g, month: y, calendars: P } = Un(e, t, f), b = $(() => Tu(e.formatLocale, e.locale, e.monthNameFormat)), w = z(null), { checkMinMaxRange: h } = $a(e), {
    selectYear: _,
    groupedYears: C,
    showYearPicker: A,
    toggleYearPicker: I,
    handleYearSelect: V,
    handleYear: L,
    isDisabled: F
  } = Bu({
    modelValue: v,
    multiCalendars: a,
    range: l,
    highlight: o,
    calendars: P,
    year: g,
    propDates: s,
    month: y,
    filters: u,
    props: e,
    emit: t
  });
  He(() => {
    e.startDate && (v.value && e.focusStartDate || !v.value) && _(Te(J(e.startDate)), 0);
  });
  const ne = (m) => m ? { month: De(m), year: Te(m) } : { month: null, year: null }, ce = () => v.value ? Array.isArray(v.value) ? v.value.map((m) => ne(m)) : ne(v.value) : ne(), ve = (m, S) => {
    const K = P.value[m], x = ce();
    return Array.isArray(x) ? x.some((se) => se.year === (K == null ? void 0 : K.year) && se.month === S) : (K == null ? void 0 : K.year) === x.year && S === x.month;
  }, T = (m, S, K) => {
    var x, se;
    const we = ce();
    return Array.isArray(we) ? g.value(S) === ((x = we[K]) == null ? void 0 : x.year) && m === ((se = we[K]) == null ? void 0 : se.month) : !1;
  }, D = (m, S) => {
    if (l.value.enabled) {
      const K = ce();
      if (Array.isArray(v.value) && Array.isArray(K)) {
        const x = T(m, S, 0) || T(m, S, 1), se = la(Ft(J()), m, g.value(S));
        return Hr(v.value, w.value, se) && !x;
      }
      return !1;
    }
    return !1;
  }, O = $(() => (m) => fn(b.value, (S) => {
    var K;
    const x = ve(m, S.value), se = Nn(
      S.value,
      Du(g.value(m), s.value.minDate),
      $u(g.value(m), s.value.maxDate)
    ) || q2(s.value.disabledDates, g.value(m)).includes(S.value) || ((K = u.value.months) == null ? void 0 : K.includes(S.value)) || !X2(s.value.allowedDates, g.value(m), S.value), we = D(S.value, m), st = Lu(o.value, S.value, g.value(m));
    return { active: x, disabled: se, isBetween: we, highlighted: st };
  })), W = (m, S) => la(Ft(J()), m, g.value(S)), re = (m, S) => {
    const K = v.value ? v.value : Ft(/* @__PURE__ */ new Date());
    v.value = la(K, m, g.value(S)), t("auto-apply"), t("update-flow-step");
  }, U = (m, S) => {
    const K = W(m, S);
    l.value.fixedEnd || l.value.fixedStart ? v.value = Yu(K, v, t, l) : v.value ? h(K, v.value) && (v.value = Ci(v, W(m, S), t)) : v.value = [W(m, S)], je().then(() => {
      Vr(v.value, t, e.autoApply, e.modelAuto);
    });
  }, E = (m, S) => {
    Si(W(m, S), v, c.value.limit), t("auto-apply", !0);
  }, oe = (m, S) => (P.value[S].month = m, H(S, P.value[S].year, m), c.value.enabled ? E(m, S) : l.value.enabled ? U(m, S) : re(m, S)), q = (m, S) => {
    _(m, S), H(S, m, null);
  }, H = (m, S, K) => {
    let x = K;
    if (!x && x !== 0) {
      const se = ce();
      x = Array.isArray(se) ? se[m].month : se.month;
    }
    t("update-month-year", { instance: m, year: S, month: x });
  };
  return {
    groupedMonths: O,
    groupedYears: C,
    year: g,
    isDisabled: F,
    defaultedMultiCalendars: a,
    defaultedAriaLabels: n,
    defaultedTransitions: r,
    defaultedConfig: i,
    showYearPicker: A,
    modelValue: v,
    presetDate: (m, S) => {
      Vu({
        value: m,
        modelValue: v,
        range: l.value.enabled,
        timezone: S ? void 0 : d.value.timezone
      }), t("auto-apply");
    },
    setHoverDate: (m, S) => {
      w.value = W(m, S);
    },
    selectMonth: oe,
    selectYear: q,
    toggleYearPicker: I,
    handleYearSelect: V,
    handleYear: L,
    getModelMonthYear: ce
  };
}, Pp = /* @__PURE__ */ _e({
  compatConfig: {
    MODE: 3
  },
  __name: "MonthPicker",
  props: {
    ...Ut
  },
  emits: [
    "update:internal-model-value",
    "overlay-closed",
    "reset-flow",
    "range-start",
    "range-end",
    "auto-apply",
    "update-month-year",
    "update-flow-step",
    "mount",
    "invalid-fixed-range",
    "overlay-toggle"
  ],
  setup(e, { expose: t, emit: a }) {
    const n = a, r = ja(), i = Ot(r, "yearMode"), l = e;
    He(() => {
      l.shadow || n("mount", null);
    });
    const {
      groupedMonths: o,
      groupedYears: s,
      year: d,
      isDisabled: u,
      defaultedMultiCalendars: c,
      defaultedConfig: f,
      showYearPicker: v,
      modelValue: g,
      presetDate: y,
      setHoverDate: P,
      selectMonth: b,
      selectYear: w,
      toggleYearPicker: h,
      handleYearSelect: _,
      handleYear: C,
      getModelMonthYear: A
    } = Mp(l, n);
    return t({ getSidebarProps: () => ({
      modelValue: g,
      year: d,
      getModelMonthYear: A,
      selectMonth: b,
      selectYear: w,
      handleYear: C
    }), presetDate: y, toggleYearPicker: (I) => h(0, I) }), (I, V) => (M(), Me(zr, {
      "multi-calendars": p(c).count,
      collapse: I.collapse,
      stretch: "",
      "is-mobile": I.isMobile
    }, {
      default: pe(({ instance: L }) => [
        I.$slots["top-extra"] ? ie(I.$slots, "top-extra", {
          key: 0,
          value: I.internalModelValue
        }) : G("", !0),
        I.$slots["month-year"] ? ie(I.$slots, "month-year", mt(Ue({ key: 1 }, {
          year: p(d),
          months: p(o)(L),
          years: p(s)(L),
          selectMonth: p(b),
          selectYear: p(w),
          instance: L
        }))) : (M(), Me(Bn, {
          key: 2,
          items: p(o)(L),
          "arrow-navigation": I.arrowNavigation,
          "is-last": I.autoApply && !p(f).keepActionRow,
          "esc-close": I.escClose,
          height: p(f).modeHeight,
          config: I.config,
          "no-overlay-focus": !!(I.noOverlayFocus || I.textInput),
          "use-relative": "",
          type: "month",
          onSelected: (F) => p(b)(F, L),
          onHoverValue: (F) => p(P)(F, L)
        }, bt({
          header: pe(() => [
            Ee(zu, Ue(I.$props, {
              items: p(s)(L),
              instance: L,
              "show-year-picker": p(v)[L],
              year: p(d)(L),
              "is-disabled": (F) => p(u)(L, F),
              onHandleYear: (F) => p(C)(L, F),
              onYearSelect: (F) => p(_)(F, L),
              onToggleYearPicker: (F) => p(h)(L, F == null ? void 0 : F.flow, F == null ? void 0 : F.show)
            }), bt({ _: 2 }, [
              ze(p(i), (F, ne) => ({
                name: F,
                fn: pe((ce) => [
                  ie(I.$slots, F, mt(At(ce)))
                ])
              }))
            ]), 1040, ["items", "instance", "show-year-picker", "year", "is-disabled", "onHandleYear", "onYearSelect", "onToggleYearPicker"])
          ]),
          _: 2
        }, [
          I.$slots["month-overlay-value"] ? {
            name: "item",
            fn: pe(({ item: F }) => [
              ie(I.$slots, "month-overlay-value", {
                text: F.text,
                value: F.value
              })
            ]),
            key: "0"
          } : void 0
        ]), 1032, ["items", "arrow-navigation", "is-last", "esc-close", "height", "config", "no-overlay-focus", "onSelected", "onHoverValue"]))
      ]),
      _: 3
    }, 8, ["multi-calendars", "collapse", "is-mobile"]));
  }
}), Ap = (e, t) => {
  const a = () => {
    e.isTextInputDate && (u.value = Te(J(e.startDate)));
  }, { modelValue: n } = Un(e, t, a), r = z(null), { defaultedHighlight: i, defaultedMultiDates: l, defaultedFilters: o, defaultedRange: s, propDates: d } = Ke(e), u = z();
  He(() => {
    e.startDate && (n.value && e.focusStartDate || !n.value) && (u.value = Te(J(e.startDate)));
  });
  const c = (y) => Array.isArray(n.value) ? n.value.some((P) => Te(P) === y) : n.value ? Te(n.value) === y : !1, f = (y) => s.value.enabled && Array.isArray(n.value) ? Hr(n.value, r.value, g(y)) : !1, v = $(() => fn(Pi(e.yearRange, e.locale, e.reverseYears), (y) => {
    const P = c(y.value), b = Nn(
      y.value,
      vn(d.value.minDate),
      vn(d.value.maxDate)
    ) || o.value.years.includes(y.value), w = f(y.value) && !P, h = Oi(i.value, y.value);
    return { active: P, disabled: b, isBetween: w, highlighted: h };
  })), g = (y) => Jt(Ft(En(/* @__PURE__ */ new Date())), y);
  return {
    groupedYears: v,
    modelValue: n,
    focusYear: u,
    setHoverValue: (y) => {
      r.value = Jt(Ft(/* @__PURE__ */ new Date()), y);
    },
    selectYear: (y) => {
      var P;
      if (t("update-month-year", { instance: 0, year: y }), l.value.enabled)
        return n.value ? Array.isArray(n.value) && (((P = n.value) == null ? void 0 : P.map((b) => Te(b))).includes(y) ? n.value = n.value.filter((b) => Te(b) !== y) : n.value.push(Jt(Mt(J()), y))) : n.value = [Jt(Mt(En(J())), y)], t("auto-apply", !0);
      s.value.enabled ? (n.value = Ci(n, g(y), t), je().then(() => {
        Vr(n.value, t, e.autoApply, e.modelAuto);
      })) : (n.value = g(y), t("auto-apply"));
    }
  };
}, Tp = /* @__PURE__ */ _e({
  compatConfig: {
    MODE: 3
  },
  __name: "YearPicker",
  props: {
    ...Ut
  },
  emits: [
    "update:internal-model-value",
    "reset-flow",
    "range-start",
    "range-end",
    "auto-apply",
    "update-month-year"
  ],
  setup(e, { expose: t, emit: a }) {
    const n = a, r = e, { groupedYears: i, modelValue: l, focusYear: o, selectYear: s, setHoverValue: d } = Ap(r, n), { defaultedConfig: u } = Ke(r);
    return t({ getSidebarProps: () => ({
      modelValue: l,
      selectYear: s
    }) }), (c, f) => (M(), Y("div", null, [
      c.$slots["top-extra"] ? ie(c.$slots, "top-extra", {
        key: 0,
        value: c.internalModelValue
      }) : G("", !0),
      c.$slots["month-year"] ? ie(c.$slots, "month-year", mt(Ue({ key: 1 }, {
        years: p(i),
        selectYear: p(s)
      }))) : (M(), Me(Bn, {
        key: 2,
        items: p(i),
        "is-last": c.autoApply && !p(u).keepActionRow,
        height: p(u).modeHeight,
        config: c.config,
        "no-overlay-focus": !!(c.noOverlayFocus || c.textInput),
        "focus-value": p(o),
        type: "year",
        "use-relative": "",
        onSelected: p(s),
        onHoverValue: p(d)
      }, bt({ _: 2 }, [
        c.$slots["year-overlay-value"] ? {
          name: "item",
          fn: pe(({ item: v }) => [
            ie(c.$slots, "year-overlay-value", {
              text: v.text,
              value: v.value
            })
          ]),
          key: "0"
        } : void 0
      ]), 1032, ["items", "is-last", "height", "config", "no-overlay-focus", "focus-value", "onSelected", "onHoverValue"]))
    ]));
  }
}), Op = {
  key: 0,
  class: "dp__time_input"
}, Sp = ["data-compact", "data-collapsed"], Cp = ["data-test-id", "aria-label", "onKeydown", "onClick", "onMousedown"], Dp = ["aria-label", "disabled", "data-test-id", "onKeydown", "onClick"], $p = ["data-test-id", "aria-label", "onKeydown", "onClick", "onMousedown"], Ip = { key: 0 }, Ep = ["aria-label", "data-compact"], Rp = /* @__PURE__ */ _e({
  compatConfig: {
    MODE: 3
  },
  __name: "TimeInput",
  props: {
    hours: { type: Number, default: 0 },
    minutes: { type: Number, default: 0 },
    seconds: { type: Number, default: 0 },
    closeTimePickerBtn: { type: Object, default: null },
    order: { type: Number, default: 0 },
    disabledTimesConfig: { type: Function, default: null },
    validateTime: { type: Function, default: () => !1 },
    ...Ut
  },
  emits: [
    "set-hours",
    "set-minutes",
    "update:hours",
    "update:minutes",
    "update:seconds",
    "reset-flow",
    "mounted",
    "overlay-closed",
    "overlay-opened",
    "am-pm-change"
  ],
  setup(e, { expose: t, emit: a }) {
    const n = a, r = e, { setTimePickerElements: i, setTimePickerBackRef: l } = Da(), {
      defaultedAriaLabels: o,
      defaultedTransitions: s,
      defaultedFilters: d,
      defaultedConfig: u,
      defaultedRange: c,
      defaultedMultiCalendars: f
    } = Ke(r), { transitionName: v, showTransition: g } = jn(s), y = mn({
      hours: !1,
      minutes: !1,
      seconds: !1
    }), P = z("AM"), b = z(null), w = z([]), h = z(), _ = z(!1);
    He(() => {
      n("mounted");
    });
    const C = (k) => Be(/* @__PURE__ */ new Date(), {
      hours: k.hours,
      minutes: k.minutes,
      seconds: r.enableSeconds ? k.seconds : 0,
      milliseconds: 0
    }), A = $(
      () => (k) => U(k, r[k]) || V(k, r[k])
    ), I = $(() => ({ hours: r.hours, minutes: r.minutes, seconds: r.seconds })), V = (k, X) => c.value.enabled && !c.value.disableTimeRangeValidation ? !r.validateTime(k, X) : !1, L = (k, X) => {
      if (c.value.enabled && !c.value.disableTimeRangeValidation) {
        const Oe = X ? +r[`${k}Increment`] : -+r[`${k}Increment`], ae = r[k] + Oe;
        return !r.validateTime(k, ae);
      }
      return !1;
    }, F = $(() => (k) => !m(+r[k] + +r[`${k}Increment`], k) || L(k, !0)), ne = $(() => (k) => !m(+r[k] - +r[`${k}Increment`], k) || L(k, !1)), ce = (k, X) => iu(Be(J(), k), X), ve = (k, X) => S2(Be(J(), k), X), T = $(
      () => ({
        dp__time_col: !0,
        dp__time_col_block: !r.timePickerInline,
        dp__time_col_reg_block: !r.enableSeconds && r.is24 && !r.timePickerInline,
        dp__time_col_reg_inline: !r.enableSeconds && r.is24 && r.timePickerInline,
        dp__time_col_reg_with_button: !r.enableSeconds && !r.is24,
        dp__time_col_sec: r.enableSeconds && r.is24,
        dp__time_col_sec_with_button: r.enableSeconds && !r.is24
      })
    ), D = $(
      () => r.timePickerInline && c.value.enabled && !f.value.count
    ), O = $(() => {
      const k = [{ type: "hours" }];
      return r.enableMinutes && k.push({ type: "", separator: !0 }, {
        type: "minutes"
      }), r.enableSeconds && k.push({ type: "", separator: !0 }, {
        type: "seconds"
      }), k;
    }), W = $(() => O.value.filter((k) => !k.separator)), re = $(() => (k) => {
      if (k === "hours") {
        const X = st(+r.hours);
        return { text: X < 10 ? `0${X}` : `${X}`, value: X };
      }
      return { text: r[k] < 10 ? `0${r[k]}` : `${r[k]}`, value: r[k] };
    }), U = (k, X) => {
      var Oe;
      if (!r.disabledTimesConfig)
        return !1;
      const ae = r.disabledTimesConfig(r.order, k === "hours" ? X : void 0);
      return ae[k] ? !!((Oe = ae[k]) != null && Oe.includes(X)) : !0;
    }, E = (k, X) => X !== "hours" || P.value === "AM" ? k : k + 12, oe = (k) => {
      const X = r.is24 ? 24 : 12, Oe = k === "hours" ? X : 60, ae = +r[`${k}GridIncrement`], Re = k === "hours" && !r.is24 ? ae : 0, Ce = [];
      for (let N = Re; N < Oe; N += ae)
        Ce.push({ value: r.is24 ? N : E(N, k), text: N < 10 ? `0${N}` : `${N}` });
      return k === "hours" && !r.is24 && Ce.unshift({ value: P.value === "PM" ? 12 : 0, text: "12" }), fn(Ce, (N) => ({ active: !1, disabled: d.value.times[k].includes(N.value) || !m(N.value, k) || U(k, N.value) || V(k, N.value) }));
    }, q = (k) => k >= 0 ? k : 59, H = (k) => k >= 0 ? k : 23, m = (k, X) => {
      const Oe = r.minTime ? C(ll(r.minTime)) : null, ae = r.maxTime ? C(ll(r.maxTime)) : null, Re = C(
        ll(
          I.value,
          X,
          X === "minutes" || X === "seconds" ? q(k) : H(k)
        )
      );
      return Oe && ae ? (Rn(Re, ae) || Za(Re, ae)) && (cn(Re, Oe) || Za(Re, Oe)) : Oe ? cn(Re, Oe) || Za(Re, Oe) : ae ? Rn(Re, ae) || Za(Re, ae) : !0;
    }, S = (k) => r[`no${k[0].toUpperCase() + k.slice(1)}Overlay`], K = (k) => {
      S(k) || (y[k] = !y[k], y[k] ? (_.value = !0, n("overlay-opened", k)) : (_.value = !1, n("overlay-closed", k)));
    }, x = (k) => k === "hours" ? fa : k === "minutes" ? Sa : un, se = () => {
      h.value && clearTimeout(h.value);
    }, we = (k, X = !0, Oe) => {
      const ae = X ? ce : ve, Re = X ? +r[`${k}Increment`] : -+r[`${k}Increment`];
      m(+r[k] + Re, k) && n(
        `update:${k}`,
        x(k)(ae({ [k]: +r[k] }, { [k]: +r[`${k}Increment`] }))
      ), !(Oe != null && Oe.keyboard) && u.value.timeArrowHoldThreshold && (h.value = setTimeout(() => {
        we(k, X);
      }, u.value.timeArrowHoldThreshold));
    }, st = (k) => r.is24 ? k : (k >= 12 ? P.value = "PM" : P.value = "AM", R2(k)), B = () => {
      P.value === "PM" ? (P.value = "AM", n("update:hours", r.hours - 12)) : (P.value = "PM", n("update:hours", r.hours + 12)), n("am-pm-change", P.value);
    }, Z = (k) => {
      y[k] = !0;
    }, Q = (k, X, Oe) => {
      if (k && r.arrowNavigation) {
        Array.isArray(w.value[X]) ? w.value[X][Oe] = k : w.value[X] = [k];
        const ae = w.value.reduce(
          (Re, Ce) => Ce.map((N, ee) => [...Re[ee] || [], Ce[ee]]),
          []
        );
        l(r.closeTimePickerBtn), b.value && (ae[1] = ae[1].concat(b.value)), i(ae, r.order);
      }
    }, de = (k, X) => (K(k), n(`update:${k}`, X));
    return t({ openChildCmp: Z }), (k, X) => {
      var Oe;
      return k.disabled ? G("", !0) : (M(), Y("div", Op, [
        (M(!0), Y(Ae, null, ze(O.value, (ae, Re) => {
          var Ce, N, ee;
          return M(), Y("div", {
            key: Re,
            class: ge(T.value),
            "data-compact": D.value && !k.enableSeconds,
            "data-collapsed": D.value && k.enableSeconds
          }, [
            ae.separator ? (M(), Y(Ae, { key: 0 }, [
              _.value ? G("", !0) : (M(), Y(Ae, { key: 0 }, [
                ut(":")
              ], 64))
            ], 64)) : (M(), Y(Ae, { key: 1 }, [
              le("button", {
                ref_for: !0,
                ref: (be) => Q(be, Re, 0),
                type: "button",
                class: ge({
                  dp__btn: !0,
                  dp__inc_dec_button: !k.timePickerInline,
                  dp__inc_dec_button_inline: k.timePickerInline,
                  dp__tp_inline_btn_top: k.timePickerInline,
                  dp__inc_dec_button_disabled: F.value(ae.type),
                  "dp--hidden-el": _.value
                }),
                "data-test-id": `${ae.type}-time-inc-btn-${r.order}`,
                "aria-label": (Ce = p(o)) == null ? void 0 : Ce.incrementValue(ae.type),
                tabindex: "0",
                onKeydown: (be) => p(xt)(be, () => we(ae.type, !0, { keyboard: !0 }), !0),
                onClick: (be) => p(u).timeArrowHoldThreshold ? void 0 : we(ae.type, !0),
                onMousedown: (be) => p(u).timeArrowHoldThreshold ? we(ae.type, !0) : void 0,
                onMouseup: se
              }, [
                r.timePickerInline ? (M(), Y(Ae, { key: 1 }, [
                  k.$slots["tp-inline-arrow-up"] ? ie(k.$slots, "tp-inline-arrow-up", { key: 0 }) : (M(), Y(Ae, { key: 1 }, [
                    X[2] || (X[2] = le("span", { class: "dp__tp_inline_btn_bar dp__tp_btn_in_l" }, null, -1)),
                    X[3] || (X[3] = le("span", { class: "dp__tp_inline_btn_bar dp__tp_btn_in_r" }, null, -1))
                  ], 64))
                ], 64)) : (M(), Y(Ae, { key: 0 }, [
                  k.$slots["arrow-up"] ? ie(k.$slots, "arrow-up", { key: 0 }) : G("", !0),
                  k.$slots["arrow-up"] ? G("", !0) : (M(), Me(p(xi), { key: 1 }))
                ], 64))
              ], 42, Cp),
              le("button", {
                ref_for: !0,
                ref: (be) => Q(be, Re, 1),
                type: "button",
                "aria-label": `${re.value(ae.type).text}-${(N = p(o)) == null ? void 0 : N.openTpOverlay(ae.type)}`,
                class: ge({
                  dp__time_display: !0,
                  dp__time_display_block: !k.timePickerInline,
                  dp__time_display_inline: k.timePickerInline,
                  "dp--time-invalid": A.value(ae.type),
                  "dp--time-overlay-btn": !A.value(ae.type),
                  "dp--hidden-el": _.value
                }),
                disabled: S(ae.type),
                tabindex: "0",
                "data-test-id": `${ae.type}-toggle-overlay-btn-${r.order}`,
                onKeydown: (be) => p(xt)(be, () => K(ae.type), !0),
                onClick: (be) => K(ae.type)
              }, [
                k.$slots[ae.type] ? ie(k.$slots, ae.type, {
                  key: 0,
                  text: re.value(ae.type).text,
                  value: re.value(ae.type).value
                }) : G("", !0),
                k.$slots[ae.type] ? G("", !0) : (M(), Y(Ae, { key: 1 }, [
                  ut(Ne(re.value(ae.type).text), 1)
                ], 64))
              ], 42, Dp),
              le("button", {
                ref_for: !0,
                ref: (be) => Q(be, Re, 2),
                type: "button",
                class: ge({
                  dp__btn: !0,
                  dp__inc_dec_button: !k.timePickerInline,
                  dp__inc_dec_button_inline: k.timePickerInline,
                  dp__tp_inline_btn_bottom: k.timePickerInline,
                  dp__inc_dec_button_disabled: ne.value(ae.type),
                  "dp--hidden-el": _.value
                }),
                "data-test-id": `${ae.type}-time-dec-btn-${r.order}`,
                "aria-label": (ee = p(o)) == null ? void 0 : ee.decrementValue(ae.type),
                tabindex: "0",
                onKeydown: (be) => p(xt)(be, () => we(ae.type, !1, { keyboard: !0 }), !0),
                onClick: (be) => p(u).timeArrowHoldThreshold ? void 0 : we(ae.type, !1),
                onMousedown: (be) => p(u).timeArrowHoldThreshold ? we(ae.type, !1) : void 0,
                onMouseup: se
              }, [
                r.timePickerInline ? (M(), Y(Ae, { key: 1 }, [
                  k.$slots["tp-inline-arrow-down"] ? ie(k.$slots, "tp-inline-arrow-down", { key: 0 }) : (M(), Y(Ae, { key: 1 }, [
                    X[4] || (X[4] = le("span", { class: "dp__tp_inline_btn_bar dp__tp_btn_in_l" }, null, -1)),
                    X[5] || (X[5] = le("span", { class: "dp__tp_inline_btn_bar dp__tp_btn_in_r" }, null, -1))
                  ], 64))
                ], 64)) : (M(), Y(Ae, { key: 0 }, [
                  k.$slots["arrow-down"] ? ie(k.$slots, "arrow-down", { key: 0 }) : G("", !0),
                  k.$slots["arrow-down"] ? G("", !0) : (M(), Me(p(_i), { key: 1 }))
                ], 64))
              ], 42, $p)
            ], 64))
          ], 10, Sp);
        }), 128)),
        k.is24 ? G("", !0) : (M(), Y("div", Ip, [
          k.$slots["am-pm-button"] ? ie(k.$slots, "am-pm-button", {
            key: 0,
            toggle: B,
            value: P.value
          }) : G("", !0),
          k.$slots["am-pm-button"] ? G("", !0) : (M(), Y("button", {
            key: 1,
            ref_key: "amPmButton",
            ref: b,
            type: "button",
            class: "dp__pm_am_button",
            role: "button",
            "aria-label": (Oe = p(o)) == null ? void 0 : Oe.amPmButton,
            tabindex: "0",
            "data-compact": D.value,
            onClick: B,
            onKeydown: X[0] || (X[0] = (ae) => p(xt)(ae, () => B(), !0))
          }, Ne(P.value), 41, Ep))
        ])),
        (M(!0), Y(Ae, null, ze(W.value, (ae, Re) => (M(), Me(Ca, {
          key: Re,
          name: p(v)(y[ae.type]),
          css: p(g)
        }, {
          default: pe(() => {
            var Ce, N;
            return [
              y[ae.type] ? (M(), Me(Bn, {
                key: 0,
                items: oe(ae.type),
                "is-last": k.autoApply && !p(u).keepActionRow,
                "esc-close": k.escClose,
                type: ae.type,
                "text-input": k.textInput,
                config: k.config,
                "arrow-navigation": k.arrowNavigation,
                "aria-labels": k.ariaLabels,
                "overlay-label": (N = (Ce = p(o)).timeOverlay) == null ? void 0 : N.call(Ce, ae.type),
                onSelected: (ee) => de(ae.type, ee),
                onToggle: (ee) => K(ae.type),
                onResetFlow: X[1] || (X[1] = (ee) => k.$emit("reset-flow"))
              }, bt({
                "button-icon": pe(() => [
                  k.$slots["clock-icon"] ? ie(k.$slots, "clock-icon", { key: 0 }) : G("", !0),
                  k.$slots["clock-icon"] ? G("", !0) : (M(), Me(Mr(k.timePickerInline ? p(hn) : p(ki)), { key: 1 }))
                ]),
                _: 2
              }, [
                k.$slots[`${ae.type}-overlay-value`] ? {
                  name: "item",
                  fn: pe(({ item: ee }) => [
                    ie(k.$slots, `${ae.type}-overlay-value`, {
                      text: ee.text,
                      value: ee.value
                    })
                  ]),
                  key: "0"
                } : void 0,
                k.$slots[`${ae.type}-overlay-header`] ? {
                  name: "header",
                  fn: pe(() => [
                    ie(k.$slots, `${ae.type}-overlay-header`, {
                      toggle: () => K(ae.type)
                    })
                  ]),
                  key: "1"
                } : void 0
              ]), 1032, ["items", "is-last", "esc-close", "type", "text-input", "config", "arrow-navigation", "aria-labels", "overlay-label", "onSelected", "onToggle"])) : G("", !0)
            ];
          }),
          _: 2
        }, 1032, ["name", "css"]))), 128))
      ]));
    };
  }
}), Np = ["data-dp-mobile"], Lp = ["aria-label", "tabindex"], Hp = ["role", "aria-label", "tabindex"], Fp = ["aria-label"], ju = /* @__PURE__ */ _e({
  compatConfig: {
    MODE: 3
  },
  __name: "TimePicker",
  props: {
    hours: { type: [Number, Array], default: 0 },
    minutes: { type: [Number, Array], default: 0 },
    seconds: { type: [Number, Array], default: 0 },
    disabledTimesConfig: { type: Function, default: null },
    validateTime: {
      type: Function,
      default: () => !1
    },
    ...Ut
  },
  emits: [
    "update:hours",
    "update:minutes",
    "update:seconds",
    "mount",
    "reset-flow",
    "overlay-opened",
    "overlay-closed",
    "am-pm-change"
  ],
  setup(e, { expose: t, emit: a }) {
    const n = a, r = e, { buildMatrix: i, setTimePicker: l } = Da(), o = ja(), { defaultedTransitions: s, defaultedAriaLabels: d, defaultedTextInput: u, defaultedConfig: c, defaultedRange: f } = Ke(r), { transitionName: v, showTransition: g } = jn(s), { hideNavigationButtons: y } = Yr(), P = z(null), b = z(null), w = z([]), h = z(null), _ = z(!1);
    He(() => {
      n("mount"), !r.timePicker && r.arrowNavigation ? i([dt(P.value)], "time") : l(!0, r.timePicker);
    });
    const C = $(() => f.value.enabled && r.modelAuto ? Ou(r.internalModelValue) : !0), A = z(!1), I = (U) => ({
      hours: Array.isArray(r.hours) ? r.hours[U] : r.hours,
      minutes: Array.isArray(r.minutes) ? r.minutes[U] : r.minutes,
      seconds: Array.isArray(r.seconds) ? r.seconds[U] : r.seconds
    }), V = $(() => {
      const U = [];
      if (f.value.enabled)
        for (let E = 0; E < 2; E++)
          U.push(I(E));
      else
        U.push(I(0));
      return U;
    }), L = (U, E = !1, oe = "") => {
      E || n("reset-flow"), A.value = U, n(U ? "overlay-opened" : "overlay-closed", gt.time), r.arrowNavigation && l(U), je(() => {
        oe !== "" && w.value[0] && w.value[0].openChildCmp(oe);
      });
    }, F = $(() => ({
      dp__btn: !0,
      dp__button: !0,
      dp__button_bottom: r.autoApply && !c.value.keepActionRow
    })), ne = Ot(o, "timePicker"), ce = (U, E, oe) => f.value.enabled ? E === 0 ? [U, V.value[1][oe]] : [V.value[0][oe], U] : U, ve = (U) => {
      n("update:hours", U);
    }, T = (U) => {
      n("update:minutes", U);
    }, D = (U) => {
      n("update:seconds", U);
    }, O = () => {
      if (h.value && !u.value.enabled && !r.noOverlayFocus) {
        const U = Su(h.value);
        U && U.focus({ preventScroll: !0 });
      }
    }, W = (U) => {
      _.value = !1, n("overlay-closed", U);
    }, re = (U) => {
      _.value = !0, n("overlay-opened", U);
    };
    return t({ toggleTimePicker: L }), (U, E) => {
      var oe;
      return M(), Y("div", {
        class: "dp--tp-wrap",
        "data-dp-mobile": U.isMobile
      }, [
        !U.timePicker && !U.timePickerInline ? ka((M(), Y("button", {
          key: 0,
          ref_key: "openTimePickerBtn",
          ref: P,
          type: "button",
          class: ge({ ...F.value, "dp--hidden-el": A.value }),
          "aria-label": (oe = p(d)) == null ? void 0 : oe.openTimePicker,
          tabindex: U.noOverlayFocus ? void 0 : 0,
          "data-test-id": "open-time-picker-btn",
          onKeydown: E[0] || (E[0] = (q) => p(xt)(q, () => L(!0))),
          onClick: E[1] || (E[1] = (q) => L(!0))
        }, [
          U.$slots["clock-icon"] ? ie(U.$slots, "clock-icon", { key: 0 }) : G("", !0),
          U.$slots["clock-icon"] ? G("", !0) : (M(), Me(p(ki), { key: 1 }))
        ], 42, Lp)), [
          [sr, !p(y)(U.hideNavigation, "time")]
        ]) : G("", !0),
        Ee(Ca, {
          name: p(v)(A.value),
          css: p(g) && !U.timePickerInline
        }, {
          default: pe(() => {
            var q, H;
            return [
              A.value || U.timePicker || U.timePickerInline ? (M(), Y("div", {
                key: 0,
                ref_key: "overlayRef",
                ref: h,
                role: U.timePickerInline ? void 0 : "dialog",
                class: ge({
                  dp__overlay: !U.timePickerInline,
                  "dp--overlay-absolute": !r.timePicker && !U.timePickerInline,
                  "dp--overlay-relative": r.timePicker
                }),
                style: Ge(U.timePicker ? { height: `${p(c).modeHeight}px` } : void 0),
                "aria-label": (q = p(d)) == null ? void 0 : q.timePicker,
                tabindex: U.timePickerInline ? void 0 : 0
              }, [
                le("div", {
                  class: ge(
                    U.timePickerInline ? "dp__time_picker_inline_container" : "dp__overlay_container dp__container_flex dp__time_picker_overlay_container"
                  ),
                  style: { display: "flex" }
                }, [
                  U.$slots["time-picker-overlay"] ? ie(U.$slots, "time-picker-overlay", {
                    key: 0,
                    hours: e.hours,
                    minutes: e.minutes,
                    seconds: e.seconds,
                    setHours: ve,
                    setMinutes: T,
                    setSeconds: D
                  }) : G("", !0),
                  U.$slots["time-picker-overlay"] ? G("", !0) : (M(), Y("div", {
                    key: 1,
                    class: ge(U.timePickerInline ? "dp__flex" : "dp__overlay_row dp__flex_row")
                  }, [
                    (M(!0), Y(Ae, null, ze(V.value, (m, S) => ka((M(), Me(Rp, Ue({
                      key: S,
                      ref_for: !0
                    }, {
                      ...U.$props,
                      order: S,
                      hours: m.hours,
                      minutes: m.minutes,
                      seconds: m.seconds,
                      closeTimePickerBtn: b.value,
                      disabledTimesConfig: e.disabledTimesConfig,
                      disabled: S === 0 ? p(f).fixedStart : p(f).fixedEnd
                    }, {
                      ref_for: !0,
                      ref_key: "timeInputRefs",
                      ref: w,
                      "validate-time": (K, x) => e.validateTime(K, ce(x, S, K)),
                      "onUpdate:hours": (K) => ve(ce(K, S, "hours")),
                      "onUpdate:minutes": (K) => T(ce(K, S, "minutes")),
                      "onUpdate:seconds": (K) => D(ce(K, S, "seconds")),
                      onMounted: O,
                      onOverlayClosed: W,
                      onOverlayOpened: re,
                      onAmPmChange: E[2] || (E[2] = (K) => U.$emit("am-pm-change", K))
                    }), bt({ _: 2 }, [
                      ze(p(ne), (K, x) => ({
                        name: K,
                        fn: pe((se) => [
                          ie(U.$slots, K, Ue({ ref_for: !0 }, se))
                        ])
                      }))
                    ]), 1040, ["validate-time", "onUpdate:hours", "onUpdate:minutes", "onUpdate:seconds"])), [
                      [sr, S === 0 ? !0 : C.value]
                    ])), 128))
                  ], 2)),
                  !U.timePicker && !U.timePickerInline ? ka((M(), Y("button", {
                    key: 2,
                    ref_key: "closeTimePickerBtn",
                    ref: b,
                    type: "button",
                    class: ge({ ...F.value, "dp--hidden-el": _.value }),
                    "aria-label": (H = p(d)) == null ? void 0 : H.closeTimePicker,
                    tabindex: "0",
                    onKeydown: E[3] || (E[3] = (m) => p(xt)(m, () => L(!1))),
                    onClick: E[4] || (E[4] = (m) => L(!1))
                  }, [
                    U.$slots["calendar-icon"] ? ie(U.$slots, "calendar-icon", { key: 0 }) : G("", !0),
                    U.$slots["calendar-icon"] ? G("", !0) : (M(), Me(p(hn), { key: 1 }))
                  ], 42, Fp)), [
                    [sr, !p(y)(U.hideNavigation, "time")]
                  ]) : G("", !0)
                ], 2)
              ], 14, Hp)) : G("", !0)
            ];
          }),
          _: 3
        }, 8, ["name", "css"])
      ], 8, Np);
    };
  }
}), Uu = (e, t, a, n) => {
  const { defaultedRange: r } = Ke(e), i = (h, _) => Array.isArray(t[h]) ? t[h][_] : t[h], l = (h) => e.enableSeconds ? Array.isArray(t.seconds) ? t.seconds[h] : t.seconds : 0, o = (h, _) => h ? _ !== void 0 ? _a(h, i("hours", _), i("minutes", _), l(_)) : _a(h, t.hours, t.minutes, l()) : Mu(J(), l(_)), s = (h, _) => {
    t[h] = _;
  }, d = $(() => e.modelAuto && r.value.enabled ? Array.isArray(a.value) ? a.value.length > 1 : !1 : r.value.enabled), u = (h, _) => {
    const C = Object.fromEntries(
      Object.keys(t).map((A) => A === h ? [A, _] : [A, t[A]].slice())
    );
    if (d.value && !r.value.disableTimeRangeValidation) {
      const A = (V) => a.value ? _a(
        a.value[V],
        C.hours[V],
        C.minutes[V],
        C.seconds[V]
      ) : null, I = (V) => _u(a.value[V], 0);
      return !($e(A(0), A(1)) && (cn(A(0), I(1)) || Rn(A(1), I(0))));
    }
    return !0;
  }, c = (h, _) => {
    u(h, _) && (s(h, _), n && n());
  }, f = (h) => {
    c("hours", h);
  }, v = (h) => {
    c("minutes", h);
  }, g = (h) => {
    c("seconds", h);
  }, y = (h, _, C, A) => {
    _ && f(h), !_ && !C && v(h), C && g(h), a.value && A(a.value);
  }, P = (h) => {
    if (h) {
      const _ = Array.isArray(h), C = _ ? [+h[0].hours, +h[1].hours] : +h.hours, A = _ ? [+h[0].minutes, +h[1].minutes] : +h.minutes, I = _ ? [+h[0].seconds, +h[1].seconds] : +h.seconds;
      s("hours", C), s("minutes", A), e.enableSeconds && s("seconds", I);
    }
  }, b = (h, _) => {
    const C = {
      hours: Array.isArray(t.hours) ? t.hours[h] : t.hours,
      disabledArr: []
    };
    return (_ || _ === 0) && (C.hours = _), Array.isArray(e.disabledTimes) && (C.disabledArr = r.value.enabled && Array.isArray(e.disabledTimes[h]) ? e.disabledTimes[h] : e.disabledTimes), C;
  }, w = $(() => (h, _) => {
    var C;
    if (Array.isArray(e.disabledTimes)) {
      const { disabledArr: A, hours: I } = b(h, _), V = A.filter((L) => +L.hours === I);
      return ((C = V[0]) == null ? void 0 : C.minutes) === "*" ? { hours: [I], minutes: void 0, seconds: void 0 } : {
        hours: [],
        minutes: (V == null ? void 0 : V.map((L) => +L.minutes)) ?? [],
        seconds: (V == null ? void 0 : V.map((L) => L.seconds ? +L.seconds : void 0)) ?? []
      };
    }
    return { hours: [], minutes: [], seconds: [] };
  });
  return {
    setTime: s,
    updateHours: f,
    updateMinutes: v,
    updateSeconds: g,
    getSetDateTime: o,
    updateTimeValues: y,
    getSecondsValue: l,
    assignStartTime: P,
    validateTime: u,
    disabledTimesConfig: w
  };
}, zp = (e, t) => {
  const a = () => {
    e.isTextInputDate && _();
  }, { modelValue: n, time: r } = Un(e, t, a), { defaultedStartTime: i, defaultedRange: l, defaultedTz: o } = Ke(e), { updateTimeValues: s, getSetDateTime: d, setTime: u, assignStartTime: c, disabledTimesConfig: f, validateTime: v } = Uu(e, r, n, g);
  function g() {
    t("update-flow-step");
  }
  const y = (A) => {
    const { hours: I, minutes: V, seconds: L } = A;
    return { hours: +I, minutes: +V, seconds: L ? +L : 0 };
  }, P = () => {
    if (e.startTime) {
      if (Array.isArray(e.startTime)) {
        const I = y(e.startTime[0]), V = y(e.startTime[1]);
        return [Be(J(), I), Be(J(), V)];
      }
      const A = y(e.startTime);
      return Be(J(), A);
    }
    return l.value.enabled ? [null, null] : null;
  }, b = () => {
    if (l.value.enabled) {
      const [A, I] = P();
      n.value = [
        Tt(d(A, 0), o.value.timezone),
        Tt(d(I, 1), o.value.timezone)
      ];
    } else
      n.value = Tt(d(P()), o.value.timezone);
  }, w = (A) => Array.isArray(A) ? [Fa(J(A[0])), Fa(J(A[1]))] : [Fa(A ?? J())], h = (A, I, V) => {
    u("hours", A), u("minutes", I), u("seconds", e.enableSeconds ? V : 0);
  }, _ = () => {
    const [A, I] = w(n.value);
    return l.value.enabled ? h(
      [A.hours, I.hours],
      [A.minutes, I.minutes],
      [A.seconds, I.seconds]
    ) : h(A.hours, A.minutes, A.seconds);
  };
  He(() => {
    if (!e.shadow)
      return c(i.value), n.value ? _() : b();
  });
  const C = () => {
    Array.isArray(n.value) ? n.value = n.value.map((A, I) => A && d(A, I)) : n.value = d(n.value), t("time-update");
  };
  return {
    modelValue: n,
    time: r,
    disabledTimesConfig: f,
    updateTime: (A, I = !0, V = !1) => {
      s(A, I, V, C);
    },
    validateTime: v
  };
}, Vp = /* @__PURE__ */ _e({
  compatConfig: {
    MODE: 3
  },
  __name: "TimePickerSolo",
  props: {
    ...Ut
  },
  emits: [
    "update:internal-model-value",
    "time-update",
    "am-pm-change",
    "mount",
    "reset-flow",
    "update-flow-step",
    "overlay-toggle"
  ],
  setup(e, { expose: t, emit: a }) {
    const n = a, r = e, i = ja(), l = Ot(i, "timePicker"), o = z(null), { time: s, modelValue: d, disabledTimesConfig: u, updateTime: c, validateTime: f } = zp(r, n);
    return He(() => {
      r.shadow || n("mount", null);
    }), t({ getSidebarProps: () => ({
      modelValue: d,
      time: s,
      updateTime: c
    }), toggleTimePicker: (v, g = !1, y = "") => {
      var P;
      (P = o.value) == null || P.toggleTimePicker(v, g, y);
    } }), (v, g) => (M(), Me(zr, {
      "multi-calendars": 0,
      stretch: "",
      "is-mobile": v.isMobile
    }, {
      default: pe(() => [
        Ee(ju, Ue({
          ref_key: "tpRef",
          ref: o
        }, v.$props, {
          hours: p(s).hours,
          minutes: p(s).minutes,
          seconds: p(s).seconds,
          "internal-model-value": v.internalModelValue,
          "disabled-times-config": p(u),
          "validate-time": p(f),
          "onUpdate:hours": g[0] || (g[0] = (y) => p(c)(y)),
          "onUpdate:minutes": g[1] || (g[1] = (y) => p(c)(y, !1)),
          "onUpdate:seconds": g[2] || (g[2] = (y) => p(c)(y, !1, !0)),
          onAmPmChange: g[3] || (g[3] = (y) => v.$emit("am-pm-change", y)),
          onResetFlow: g[4] || (g[4] = (y) => v.$emit("reset-flow")),
          onOverlayClosed: g[5] || (g[5] = (y) => v.$emit("overlay-toggle", { open: !1, overlay: y })),
          onOverlayOpened: g[6] || (g[6] = (y) => v.$emit("overlay-toggle", { open: !0, overlay: y }))
        }), bt({ _: 2 }, [
          ze(p(l), (y, P) => ({
            name: y,
            fn: pe((b) => [
              ie(v.$slots, y, mt(At(b)))
            ])
          }))
        ]), 1040, ["hours", "minutes", "seconds", "internal-model-value", "disabled-times-config", "validate-time"])
      ]),
      _: 3
    }, 8, ["is-mobile"]));
  }
}), Yp = { class: "dp--header-wrap" }, Bp = {
  key: 0,
  class: "dp__month_year_wrap"
}, jp = { key: 0 }, Up = { class: "dp__month_year_wrap" }, Wp = ["data-dp-element", "aria-label", "data-test-id", "onClick", "onKeydown"], qp = /* @__PURE__ */ _e({
  compatConfig: {
    MODE: 3
  },
  __name: "DpHeader",
  props: {
    month: { type: Number, default: 0 },
    year: { type: Number, default: 0 },
    instance: { type: Number, default: 0 },
    years: { type: Array, default: () => [] },
    months: { type: Array, default: () => [] },
    ...Ut
  },
  emits: ["update-month-year", "mount", "reset-flow", "overlay-closed", "overlay-opened"],
  setup(e, { expose: t, emit: a }) {
    const n = a, r = e, {
      defaultedTransitions: i,
      defaultedAriaLabels: l,
      defaultedMultiCalendars: o,
      defaultedFilters: s,
      defaultedConfig: d,
      defaultedHighlight: u,
      propDates: c,
      defaultedUI: f
    } = Ke(r), { transitionName: v, showTransition: g } = jn(i), { buildMatrix: y } = Da(), { handleMonthYearChange: P, isDisabled: b, updateMonthYear: w } = vp(r, n), { showLeftIcon: h, showRightIcon: _ } = Yr(), C = z(!1), A = z(!1), I = z(!1), V = z([null, null, null, null]);
    He(() => {
      n("mount");
    });
    const L = (H) => ({
      get: () => r[H],
      set: (m) => {
        const S = H === Et.month ? Et.year : Et.month;
        n("update-month-year", { [H]: m, [S]: r[S] }), H === Et.month ? W(!0) : re(!0);
      }
    }), F = $(L(Et.month)), ne = $(L(Et.year)), ce = $(() => (H) => ({
      month: r.month,
      year: r.year,
      items: H === Et.month ? r.months : r.years,
      instance: r.instance,
      updateMonthYear: w,
      toggle: H === Et.month ? W : re
    })), ve = $(() => r.months.find((m) => m.value === r.month) || { text: "", value: 0 }), T = $(() => fn(r.months, (H) => {
      const m = r.month === H.value, S = Nn(
        H.value,
        Du(r.year, c.value.minDate),
        $u(r.year, c.value.maxDate)
      ) || s.value.months.includes(H.value), K = Lu(u.value, H.value, r.year);
      return { active: m, disabled: S, highlighted: K };
    })), D = $(() => fn(r.years, (H) => {
      const m = r.year === H.value, S = Nn(
        H.value,
        vn(c.value.minDate),
        vn(c.value.maxDate)
      ) || s.value.years.includes(H.value), K = Oi(u.value, H.value);
      return { active: m, disabled: S, highlighted: K };
    })), O = (H, m, S) => {
      S !== void 0 ? H.value = S : H.value = !H.value, H.value ? (I.value = !0, n("overlay-opened", m)) : (I.value = !1, n("overlay-closed", m));
    }, W = (H = !1, m) => {
      U(H), O(C, gt.month, m);
    }, re = (H = !1, m) => {
      U(H), O(A, gt.year, m);
    }, U = (H) => {
      H || n("reset-flow");
    }, E = (H, m) => {
      r.arrowNavigation && (V.value[m] = dt(H), y(V.value, "monthYear"));
    }, oe = $(() => {
      var H, m, S, K, x, se;
      return [
        {
          type: Et.month,
          index: 1,
          toggle: W,
          modelValue: F.value,
          updateModelValue: (we) => F.value = we,
          text: ve.value.text,
          showSelectionGrid: C.value,
          items: T.value,
          ariaLabel: (H = l.value) == null ? void 0 : H.openMonthsOverlay,
          overlayLabel: ((S = (m = l.value).monthPicker) == null ? void 0 : S.call(m, !0)) ?? void 0
        },
        {
          type: Et.year,
          index: 2,
          toggle: re,
          modelValue: ne.value,
          updateModelValue: (we) => ne.value = we,
          text: Cu(r.year, r.locale),
          showSelectionGrid: A.value,
          items: D.value,
          ariaLabel: (K = l.value) == null ? void 0 : K.openYearsOverlay,
          overlayLabel: ((se = (x = l.value).yearPicker) == null ? void 0 : se.call(x, !0)) ?? void 0
        }
      ];
    }), q = $(() => r.disableYearSelect ? [oe.value[0]] : r.yearFirst ? [...oe.value].reverse() : oe.value);
    return t({
      toggleMonthPicker: W,
      toggleYearPicker: re,
      handleMonthYearChange: P
    }), (H, m) => {
      var S, K, x, se, we, st;
      return M(), Y("div", Yp, [
        H.$slots["month-year"] ? (M(), Y("div", Bp, [
          ie(H.$slots, "month-year", mt(At({ month: e.month, year: e.year, months: e.months, years: e.years, updateMonthYear: p(w), handleMonthYearChange: p(P), instance: e.instance })))
        ])) : (M(), Y(Ae, { key: 1 }, [
          H.$slots["top-extra"] ? (M(), Y("div", jp, [
            ie(H.$slots, "top-extra", { value: H.internalModelValue })
          ])) : G("", !0),
          le("div", Up, [
            p(h)(p(o), e.instance) && !H.vertical ? (M(), Me(An, {
              key: 0,
              "aria-label": (S = p(l)) == null ? void 0 : S.prevMonth,
              disabled: p(b)(!1),
              class: ge((K = p(f)) == null ? void 0 : K.navBtnPrev),
              "el-name": "action-prev",
              onActivate: m[0] || (m[0] = (B) => p(P)(!1, !0)),
              onSetRef: m[1] || (m[1] = (B) => E(B, 0))
            }, {
              default: pe(() => [
                H.$slots["arrow-left"] ? ie(H.$slots, "arrow-left", { key: 0 }) : G("", !0),
                H.$slots["arrow-left"] ? G("", !0) : (M(), Me(p(bi), { key: 1 }))
              ]),
              _: 3
            }, 8, ["aria-label", "disabled", "class"])) : G("", !0),
            le("div", {
              class: ge(["dp__month_year_wrap", {
                dp__year_disable_select: H.disableYearSelect
              }])
            }, [
              (M(!0), Y(Ae, null, ze(q.value, (B, Z) => (M(), Y(Ae, {
                key: B.type
              }, [
                le("button", {
                  ref_for: !0,
                  ref: (Q) => E(Q, Z + 1),
                  type: "button",
                  "data-dp-element": `overlay-${B.type}`,
                  class: ge(["dp__btn dp__month_year_select", { "dp--hidden-el": I.value }]),
                  "aria-label": `${B.text}-${B.ariaLabel}`,
                  "data-test-id": `${B.type}-toggle-overlay-${e.instance}`,
                  onClick: B.toggle,
                  onKeydown: (Q) => p(xt)(Q, () => B.toggle(), !0)
                }, [
                  H.$slots[B.type] ? ie(H.$slots, B.type, {
                    key: 0,
                    text: B.text,
                    value: r[B.type]
                  }) : G("", !0),
                  H.$slots[B.type] ? G("", !0) : (M(), Y(Ae, { key: 1 }, [
                    ut(Ne(B.text), 1)
                  ], 64))
                ], 42, Wp),
                Ee(Ca, {
                  name: p(v)(B.showSelectionGrid),
                  css: p(g)
                }, {
                  default: pe(() => [
                    B.showSelectionGrid ? (M(), Me(Bn, {
                      key: 0,
                      items: B.items,
                      "arrow-navigation": H.arrowNavigation,
                      "hide-navigation": H.hideNavigation,
                      "is-last": H.autoApply && !p(d).keepActionRow,
                      "skip-button-ref": !1,
                      config: H.config,
                      type: B.type,
                      "header-refs": [],
                      "esc-close": H.escClose,
                      "menu-wrap-ref": H.menuWrapRef,
                      "text-input": H.textInput,
                      "aria-labels": H.ariaLabels,
                      "overlay-label": B.overlayLabel,
                      onSelected: B.updateModelValue,
                      onToggle: B.toggle
                    }, bt({
                      "button-icon": pe(() => [
                        H.$slots["calendar-icon"] ? ie(H.$slots, "calendar-icon", { key: 0 }) : G("", !0),
                        H.$slots["calendar-icon"] ? G("", !0) : (M(), Me(p(hn), { key: 1 }))
                      ]),
                      _: 2
                    }, [
                      H.$slots[`${B.type}-overlay-value`] ? {
                        name: "item",
                        fn: pe(({ item: Q }) => [
                          ie(H.$slots, `${B.type}-overlay-value`, {
                            text: Q.text,
                            value: Q.value
                          })
                        ]),
                        key: "0"
                      } : void 0,
                      H.$slots[`${B.type}-overlay`] ? {
                        name: "overlay",
                        fn: pe(() => [
                          ie(H.$slots, `${B.type}-overlay`, Ue({ ref_for: !0 }, ce.value(B.type)))
                        ]),
                        key: "1"
                      } : void 0,
                      H.$slots[`${B.type}-overlay-header`] ? {
                        name: "header",
                        fn: pe(() => [
                          ie(H.$slots, `${B.type}-overlay-header`, {
                            toggle: B.toggle
                          })
                        ]),
                        key: "2"
                      } : void 0
                    ]), 1032, ["items", "arrow-navigation", "hide-navigation", "is-last", "config", "type", "esc-close", "menu-wrap-ref", "text-input", "aria-labels", "overlay-label", "onSelected", "onToggle"])) : G("", !0)
                  ]),
                  _: 2
                }, 1032, ["name", "css"])
              ], 64))), 128))
            ], 2),
            p(h)(p(o), e.instance) && H.vertical ? (M(), Me(An, {
              key: 1,
              "aria-label": (x = p(l)) == null ? void 0 : x.prevMonth,
              "el-name": "action-prev",
              disabled: p(b)(!1),
              class: ge((se = p(f)) == null ? void 0 : se.navBtnPrev),
              onActivate: m[2] || (m[2] = (B) => p(P)(!1, !0))
            }, {
              default: pe(() => [
                H.$slots["arrow-up"] ? ie(H.$slots, "arrow-up", { key: 0 }) : G("", !0),
                H.$slots["arrow-up"] ? G("", !0) : (M(), Me(p(xi), { key: 1 }))
              ]),
              _: 3
            }, 8, ["aria-label", "disabled", "class"])) : G("", !0),
            p(_)(p(o), e.instance) ? (M(), Me(An, {
              key: 2,
              ref: "rightIcon",
              "el-name": "action-next",
              disabled: p(b)(!0),
              "aria-label": (we = p(l)) == null ? void 0 : we.nextMonth,
              class: ge((st = p(f)) == null ? void 0 : st.navBtnNext),
              onActivate: m[3] || (m[3] = (B) => p(P)(!0, !0)),
              onSetRef: m[4] || (m[4] = (B) => E(B, H.disableYearSelect ? 2 : 3))
            }, {
              default: pe(() => [
                H.$slots[H.vertical ? "arrow-down" : "arrow-right"] ? ie(H.$slots, H.vertical ? "arrow-down" : "arrow-right", { key: 0 }) : G("", !0),
                H.$slots[H.vertical ? "arrow-down" : "arrow-right"] ? G("", !0) : (M(), Me(Mr(H.vertical ? p(_i) : p(wi)), { key: 1 }))
              ]),
              _: 3
            }, 8, ["disabled", "aria-label", "class"])) : G("", !0)
          ])
        ], 64))
      ]);
    };
  }
}), Xp = {
  class: "dp__calendar_header",
  role: "row"
}, Qp = {
  key: 0,
  class: "dp__calendar_header_item",
  role: "gridcell"
}, Kp = ["aria-label"], Gp = {
  key: 0,
  class: "dp__calendar_item dp__week_num",
  role: "gridcell"
}, Jp = { class: "dp__cell_inner" }, Zp = ["id", "aria-pressed", "aria-disabled", "aria-label", "tabindex", "data-test-id", "onClick", "onTouchend", "onKeydown", "onMouseenter", "onMouseleave", "onMousedown"], e3 = /* @__PURE__ */ _e({
  compatConfig: {
    MODE: 3
  },
  __name: "DpCalendar",
  props: {
    mappedDates: { type: Array, default: () => [] },
    instance: { type: Number, default: 0 },
    month: { type: Number, default: 0 },
    year: { type: Number, default: 0 },
    ...Ut
  },
  emits: [
    "select-date",
    "set-hover-date",
    "handle-scroll",
    "mount",
    "handle-swipe",
    "handle-space",
    "tooltip-open",
    "tooltip-close"
  ],
  setup(e, { expose: t, emit: a }) {
    const n = a, r = e, { buildMultiLevelMatrix: i } = Da(), {
      defaultedTransitions: l,
      defaultedConfig: o,
      defaultedAriaLabels: s,
      defaultedMultiCalendars: d,
      defaultedWeekNumbers: u,
      defaultedMultiDates: c,
      defaultedUI: f
    } = Ke(r), v = z(null), g = z({
      bottom: "",
      left: "",
      transform: ""
    }), y = z([]), P = z(null), b = z(!0), w = z(""), h = z({ startX: 0, endX: 0, startY: 0, endY: 0 }), _ = z([]), C = z({ left: "50%" }), A = z(!1), I = $(() => r.calendar ? r.calendar(r.mappedDates) : r.mappedDates), V = $(() => r.dayNames ? Array.isArray(r.dayNames) ? r.dayNames : r.dayNames(r.locale, +r.weekStart) : E2(r.formatLocale, r.locale, +r.weekStart));
    He(() => {
      n("mount", { cmp: "calendar", refs: y }), o.value.noSwipe || P.value && (P.value.addEventListener("touchstart", E, { passive: !1 }), P.value.addEventListener("touchend", oe, { passive: !1 }), P.value.addEventListener("touchmove", q, { passive: !1 })), r.monthChangeOnScroll && P.value && P.value.addEventListener("wheel", S, { passive: !1 });
    });
    const L = (B) => B ? r.vertical ? "vNext" : "next" : r.vertical ? "vPrevious" : "previous", F = (B, Z) => {
      if (r.transitions) {
        const Q = Mt(la(J(), r.month, r.year));
        w.value = lt(Mt(la(J(), B, Z)), Q) ? l.value[L(!0)] : l.value[L(!1)], b.value = !1, je(() => {
          b.value = !0;
        });
      }
    }, ne = $(
      () => ({
        ...f.value.calendar ?? {}
      })
    ), ce = $(() => (B) => {
      const Z = N2(B);
      return {
        dp__marker_dot: Z.type === "dot",
        dp__marker_line: Z.type === "line"
      };
    }), ve = $(() => (B) => $e(B, v.value)), T = $(() => ({
      dp__calendar: !0,
      dp__calendar_next: d.value.count > 0 && r.instance !== 0
    })), D = $(() => (B) => r.hideOffsetDates ? B.current : !0), O = async (B, Z) => {
      const { width: Q, height: de } = B.getBoundingClientRect();
      v.value = Z.value;
      let k = { left: `${Q / 2}px` }, X = -50;
      if (await je(), _.value[0]) {
        const { left: Oe, width: ae } = _.value[0].getBoundingClientRect();
        Oe < 0 && (k = { left: "0" }, X = 0, C.value.left = `${Q / 2}px`), window.innerWidth < Oe + ae && (k = { right: "0" }, X = 0, C.value.left = `${ae - Q / 2}px`);
      }
      g.value = {
        bottom: `${de}px`,
        ...k,
        transform: `translateX(${X}%)`
      };
    }, W = async (B, Z, Q) => {
      var de, k, X;
      const Oe = dt(y.value[Z][Q]);
      Oe && ((de = B.marker) != null && de.customPosition && (X = (k = B.marker) == null ? void 0 : k.tooltip) != null && X.length ? g.value = B.marker.customPosition(Oe) : await O(Oe, B), n("tooltip-open", B.marker));
    }, re = async (B, Z, Q) => {
      var de, k;
      if (A.value && c.value.enabled && c.value.dragSelect)
        return n("select-date", B);
      if (n("set-hover-date", B), (k = (de = B.marker) == null ? void 0 : de.tooltip) != null && k.length) {
        if (r.hideOffsetDates && !B.current)
          return;
        await W(B, Z, Q);
      }
    }, U = (B) => {
      v.value && (v.value = null, g.value = JSON.parse(JSON.stringify({ bottom: "", left: "", transform: "" })), n("tooltip-close", B.marker));
    }, E = (B) => {
      h.value.startX = B.changedTouches[0].screenX, h.value.startY = B.changedTouches[0].screenY;
    }, oe = (B) => {
      h.value.endX = B.changedTouches[0].screenX, h.value.endY = B.changedTouches[0].screenY, H();
    }, q = (B) => {
      r.vertical && !r.inline && B.preventDefault();
    }, H = () => {
      const B = r.vertical ? "Y" : "X";
      Math.abs(h.value[`start${B}`] - h.value[`end${B}`]) > 10 && n("handle-swipe", h.value[`start${B}`] > h.value[`end${B}`] ? "right" : "left");
    }, m = (B, Z, Q) => {
      B && (Array.isArray(y.value[Z]) ? y.value[Z][Q] = B : y.value[Z] = [B]), r.arrowNavigation && i(y.value, "calendar");
    }, S = (B) => {
      r.monthChangeOnScroll && (B.preventDefault(), n("handle-scroll", B));
    }, K = (B) => u.value.type === "local" ? hi(B.value, { weekStartsOn: +r.weekStart }) : u.value.type === "iso" ? mi(B.value) : typeof u.value.type == "function" ? u.value.type(B.value) : "", x = (B) => {
      const Z = B[0];
      return u.value.hideOnOffsetDates ? B.some((Q) => Q.current) ? K(Z) : "" : K(Z);
    }, se = (B, Z, Q = !0) => {
      Q && Co() || !Q && !Co() || (!c.value.enabled || o.value.allowPreventDefault) && (xa(B, o.value), n("select-date", Z));
    }, we = (B) => {
      xa(B, o.value);
    }, st = (B) => {
      c.value.enabled && c.value.dragSelect ? (A.value = !0, n("select-date", B)) : c.value.enabled && n("select-date", B);
    };
    return t({ triggerTransition: F }), (B, Z) => (M(), Y("div", {
      class: ge(T.value)
    }, [
      le("div", {
        ref_key: "calendarWrapRef",
        ref: P,
        class: ge(ne.value),
        role: "grid"
      }, [
        le("div", Xp, [
          B.weekNumbers ? (M(), Y("div", Qp, Ne(B.weekNumName), 1)) : G("", !0),
          (M(!0), Y(Ae, null, ze(V.value, (Q, de) => {
            var k, X;
            return M(), Y("div", {
              key: de,
              class: "dp__calendar_header_item",
              role: "gridcell",
              "data-test-id": "calendar-header",
              "aria-label": (X = (k = p(s)) == null ? void 0 : k.weekDay) == null ? void 0 : X.call(k, de)
            }, [
              B.$slots["calendar-header"] ? ie(B.$slots, "calendar-header", {
                key: 0,
                day: Q,
                index: de
              }) : G("", !0),
              B.$slots["calendar-header"] ? G("", !0) : (M(), Y(Ae, { key: 1 }, [
                ut(Ne(Q), 1)
              ], 64))
            ], 8, Kp);
          }), 128))
        ]),
        Z[2] || (Z[2] = le("div", { class: "dp__calendar_header_separator" }, null, -1)),
        Ee(Ca, {
          name: w.value,
          css: !!B.transitions
        }, {
          default: pe(() => [
            b.value ? (M(), Y("div", {
              key: 0,
              class: "dp__calendar",
              role: "rowgroup",
              onMouseleave: Z[1] || (Z[1] = (Q) => A.value = !1)
            }, [
              (M(!0), Y(Ae, null, ze(I.value, (Q, de) => (M(), Y("div", {
                key: de,
                class: "dp__calendar_row",
                role: "row"
              }, [
                B.weekNumbers ? (M(), Y("div", Gp, [
                  le("div", Jp, Ne(x(Q.days)), 1)
                ])) : G("", !0),
                (M(!0), Y(Ae, null, ze(Q.days, (k, X) => {
                  var Oe, ae, Re;
                  return M(), Y("div", {
                    id: p(Hu)(k.value),
                    ref_for: !0,
                    ref: (Ce) => m(Ce, de, X),
                    key: X + de,
                    role: "gridcell",
                    class: "dp__calendar_item",
                    "aria-pressed": (k.classData.dp__active_date || k.classData.dp__range_start || k.classData.dp__range_start) ?? void 0,
                    "aria-disabled": k.classData.dp__cell_disabled || void 0,
                    "aria-label": (ae = (Oe = p(s)) == null ? void 0 : Oe.day) == null ? void 0 : ae.call(Oe, k),
                    tabindex: !k.current && B.hideOffsetDates ? void 0 : 0,
                    "data-test-id": k.value,
                    onClick: za((Ce) => se(Ce, k), ["prevent"]),
                    onTouchend: (Ce) => se(Ce, k, !1),
                    onKeydown: (Ce) => p(xt)(Ce, () => B.$emit("select-date", k)),
                    onMouseenter: (Ce) => re(k, de, X),
                    onMouseleave: (Ce) => U(k),
                    onMousedown: (Ce) => st(k),
                    onMouseup: Z[0] || (Z[0] = (Ce) => A.value = !1)
                  }, [
                    le("div", {
                      class: ge(["dp__cell_inner", k.classData])
                    }, [
                      B.$slots.day && D.value(k) ? ie(B.$slots, "day", {
                        key: 0,
                        day: +k.text,
                        date: k.value
                      }) : G("", !0),
                      B.$slots.day ? G("", !0) : (M(), Y(Ae, { key: 1 }, [
                        ut(Ne(k.text), 1)
                      ], 64)),
                      k.marker && D.value(k) ? (M(), Y(Ae, { key: 2 }, [
                        B.$slots.marker ? ie(B.$slots, "marker", {
                          key: 0,
                          marker: k.marker,
                          day: +k.text,
                          date: k.value
                        }) : (M(), Y("div", {
                          key: 1,
                          class: ge(ce.value(k.marker)),
                          style: Ge(k.marker.color ? { backgroundColor: k.marker.color } : {})
                        }, null, 6))
                      ], 64)) : G("", !0),
                      ve.value(k.value) ? (M(), Y("div", {
                        key: 3,
                        ref_for: !0,
                        ref_key: "activeTooltip",
                        ref: _,
                        class: "dp__marker_tooltip",
                        style: Ge(g.value)
                      }, [
                        (Re = k.marker) != null && Re.tooltip ? (M(), Y("div", {
                          key: 0,
                          class: "dp__tooltip_content",
                          onClick: we
                        }, [
                          (M(!0), Y(Ae, null, ze(k.marker.tooltip, (Ce, N) => (M(), Y("div", {
                            key: N,
                            class: "dp__tooltip_text"
                          }, [
                            B.$slots["marker-tooltip"] ? ie(B.$slots, "marker-tooltip", {
                              key: 0,
                              tooltip: Ce,
                              day: k.value
                            }) : G("", !0),
                            B.$slots["marker-tooltip"] ? G("", !0) : (M(), Y(Ae, { key: 1 }, [
                              le("div", {
                                class: "dp__tooltip_mark",
                                style: Ge(Ce.color ? { backgroundColor: Ce.color } : {})
                              }, null, 4),
                              le("div", null, Ne(Ce.text), 1)
                            ], 64))
                          ]))), 128)),
                          le("div", {
                            class: "dp__arrow_bottom_tp",
                            style: Ge(C.value)
                          }, null, 4)
                        ])) : G("", !0)
                      ], 4)) : G("", !0)
                    ], 2)
                  ], 40, Zp);
                }), 128))
              ]))), 128))
            ], 32)) : G("", !0)
          ]),
          _: 3
        }, 8, ["name", "css"])
      ], 2)
    ], 2));
  }
}), Ro = (e) => Array.isArray(e), t3 = (e, t, a, n) => {
  const r = z([]), i = z(/* @__PURE__ */ new Date()), l = z(), o = () => E(e.isTextInputDate), { modelValue: s, calendars: d, time: u, today: c } = Un(e, t, o), {
    defaultedMultiCalendars: f,
    defaultedStartTime: v,
    defaultedRange: g,
    defaultedConfig: y,
    defaultedTz: P,
    propDates: b,
    defaultedMultiDates: w
  } = Ke(e), { validateMonthYearInRange: h, isDisabled: _, isDateRangeAllowed: C, checkMinMaxRange: A } = $a(e), { updateTimeValues: I, getSetDateTime: V, setTime: L, assignStartTime: F, validateTime: ne, disabledTimesConfig: ce } = Uu(e, u, s, n), ve = $(
    () => (R) => d.value[R] ? d.value[R].month : 0
  ), T = $(
    () => (R) => d.value[R] ? d.value[R].year : 0
  ), D = (R) => !y.value.keepViewOnOffsetClick || R ? !0 : !l.value, O = (R, te, xe, j = !1) => {
    var ue, ye;
    D(j) && (d.value[R] || (d.value[R] = { month: 0, year: 0 }), d.value[R].month = So(te) ? (ue = d.value[R]) == null ? void 0 : ue.month : te, d.value[R].year = So(xe) ? (ye = d.value[R]) == null ? void 0 : ye.year : xe);
  }, W = () => {
    e.autoApply && t("select-date");
  };
  He(() => {
    e.shadow || (s.value || (B(), v.value && F(v.value)), E(!0), e.focusStartDate && e.startDate && B());
  });
  const re = $(() => {
    var R;
    return (R = e.flow) != null && R.length && !e.partialFlow ? e.flowStep === e.flow.length : !0;
  }), U = () => {
    e.autoApply && re.value && t("auto-apply", e.partialFlow ? e.flowStep !== e.flow.length : !1);
  }, E = (R = !1) => {
    if (s.value)
      return Array.isArray(s.value) ? (r.value = s.value, x(R)) : H(s.value, R);
    if (f.value.count && R && !e.startDate)
      return q(J(), R);
  }, oe = () => Array.isArray(s.value) && g.value.enabled ? De(s.value[0]) === De(s.value[1] ?? s.value[0]) : !1, q = (R = /* @__PURE__ */ new Date(), te = !1) => {
    if ((!f.value.count || !f.value.static || te) && O(0, De(R), Te(R)), f.value.count && (!s.value || oe() || !f.value.solo) && (!f.value.solo || te))
      for (let xe = 1; xe < f.value.count; xe++) {
        const j = Be(J(), { month: ve.value(xe - 1), year: T.value(xe - 1) }), ue = iu(j, { months: 1 });
        d.value[xe] = { month: De(ue), year: Te(ue) };
      }
  }, H = (R, te) => {
    q(R), L("hours", fa(R)), L("minutes", Sa(R)), L("seconds", un(R)), f.value.count && te && st();
  }, m = (R) => {
    if (f.value.count) {
      if (f.value.solo)
        return 0;
      const te = De(R[0]), xe = De(R[1]);
      return Math.abs(xe - te) < f.value.count ? 0 : 1;
    }
    return 1;
  }, S = (R, te) => {
    R[1] && g.value.showLastInRange ? q(R[m(R)], te) : q(R[0], te);
    const xe = (j, ue) => [
      j(R[0]),
      R[1] ? j(R[1]) : u[ue][1]
    ];
    L("hours", xe(fa, "hours")), L("minutes", xe(Sa, "minutes")), L("seconds", xe(un, "seconds"));
  }, K = (R, te) => {
    if ((g.value.enabled || e.weekPicker) && !w.value.enabled)
      return S(R, te);
    if (w.value.enabled && te) {
      const xe = R[R.length - 1];
      return H(xe, te);
    }
  }, x = (R) => {
    const te = s.value;
    K(te, R), f.value.count && f.value.solo && st();
  }, se = (R, te) => {
    const xe = Be(J(), { month: ve.value(te), year: T.value(te) }), j = R < 0 ? Vt(xe, 1) : dn(xe, 1);
    h(De(j), Te(j), R < 0, e.preventMinMaxNavigation) && (O(te, De(j), Te(j)), t("update-month-year", { instance: te, month: De(j), year: Te(j) }), f.value.count && !f.value.solo && we(te), a());
  }, we = (R) => {
    for (let te = R - 1; te >= 0; te--) {
      const xe = dn(Be(J(), { month: ve.value(te + 1), year: T.value(te + 1) }), 1);
      O(te, De(xe), Te(xe));
    }
    for (let te = R + 1; te <= f.value.count - 1; te++) {
      const xe = Vt(Be(J(), { month: ve.value(te - 1), year: T.value(te - 1) }), 1);
      O(te, De(xe), Te(xe));
    }
  }, st = () => {
    if (Array.isArray(s.value) && s.value.length === 2) {
      const R = J(
        J(s.value[1] ? s.value[1] : Vt(s.value[0], 1))
      ), [te, xe] = [De(s.value[0]), Te(s.value[0])], [j, ue] = [De(s.value[1]), Te(s.value[1])];
      (te !== j || te === j && xe !== ue) && f.value.solo && O(1, De(R), Te(R));
    } else
      s.value && !Array.isArray(s.value) && (O(0, De(s.value), Te(s.value)), q(J()));
  }, B = () => {
    e.startDate && (O(0, De(J(e.startDate)), Te(J(e.startDate))), f.value.count && we(0));
  }, Z = (R, te) => {
    if (e.monthChangeOnScroll) {
      const xe = (/* @__PURE__ */ new Date()).getTime() - i.value.getTime(), j = Math.abs(R.deltaY);
      let ue = 500;
      j > 1 && (ue = 100), j > 100 && (ue = 0), xe > ue && (i.value = /* @__PURE__ */ new Date(), se(e.monthChangeOnScroll !== "inverse" ? -R.deltaY : R.deltaY, te));
    }
  }, Q = (R, te, xe = !1) => {
    e.monthChangeOnArrows && e.vertical === xe && de(R, te);
  }, de = (R, te) => {
    se(R === "right" ? -1 : 1, te);
  }, k = (R) => {
    if (b.value.markers)
      return xr(R.value, b.value.markers);
  }, X = (R, te) => {
    switch (e.sixWeeks === !0 ? "append" : e.sixWeeks) {
      case "prepend":
        return [!0, !1];
      case "center":
        return [R == 0, !0];
      case "fair":
        return [R == 0 || te > R, !0];
      case "append":
        return [!1, !1];
      default:
        return [!1, !1];
    }
  }, Oe = (R, te, xe, j) => {
    if (e.sixWeeks && R.length < 6) {
      const ue = 6 - R.length, ye = (te.getDay() + 7 - j) % 7, Xt = 6 - (xe.getDay() + 7 - j) % 7, [It, qa] = X(ye, Xt);
      for (let yn = 1; yn <= ue; yn++)
        if (qa ? !!(yn % 2) == It : It) {
          const pa = R[0].days[0], Kr = ae(Ht(pa.value, -7), De(te));
          R.unshift({ days: Kr });
        } else {
          const pa = R[R.length - 1], Kr = pa.days[pa.days.length - 1], Xu = ae(Ht(Kr.value, 1), De(te));
          R.push({ days: Xu });
        }
    }
    return R;
  }, ae = (R, te) => {
    const xe = J(R), j = [];
    for (let ue = 0; ue < 7; ue++) {
      const ye = Ht(xe, ue), Xt = De(ye) !== te;
      j.push({
        text: e.hideOffsetDates && Xt ? "" : ye.getDate(),
        value: ye,
        current: !Xt,
        classData: {}
      });
    }
    return j;
  }, Re = (R, te) => {
    const xe = [], j = new Date(te, R), ue = new Date(te, R + 1, 0), ye = e.weekStart, Xt = Yt(j, { weekStartsOn: ye }), It = (qa) => {
      const yn = ae(qa, R);
      if (xe.push({ days: yn }), !xe[xe.length - 1].days.some(
        (pa) => $e(Mt(pa.value), Mt(ue))
      )) {
        const pa = Ht(qa, 7);
        It(pa);
      }
    };
    return It(Xt), Oe(xe, j, ue, ye);
  }, Ce = (R) => {
    const te = _a(J(R.value), u.hours, u.minutes, qt());
    t("date-update", te), w.value.enabled ? Si(te, s, w.value.limit) : s.value = te, n(), je().then(() => {
      U();
    });
  }, N = (R) => g.value.noDisabledRange ? Iu(r.value[0], R).some((te) => _(te)) : !1, ee = () => {
    r.value = s.value ? s.value.slice() : [], r.value.length === 2 && !(g.value.fixedStart || g.value.fixedEnd) && (r.value = []);
  }, be = (R, te) => {
    const xe = [
      J(R.value),
      Ht(J(R.value), +g.value.autoRange)
    ];
    C(xe) ? (te && ct(R.value), r.value = xe) : t("invalid-date", R.value);
  }, ct = (R) => {
    const te = De(J(R)), xe = Te(J(R));
    if (O(0, te, xe), f.value.count > 0)
      for (let j = 1; j < f.value.count; j++) {
        const ue = B2(
          Be(J(R), { year: T.value(j - 1), month: ve.value(j - 1) })
        );
        O(j, ue.month, ue.year);
      }
  }, Wt = (R) => {
    if (N(R.value) || !A(R.value, s.value, g.value.fixedStart ? 0 : 1))
      return t("invalid-date", R.value);
    r.value = Yu(J(R.value), s, t, g);
  }, ke = (R, te) => {
    if (ee(), g.value.autoRange)
      return be(R, te);
    if (g.value.fixedStart || g.value.fixedEnd)
      return Wt(R);
    r.value[0] ? A(J(R.value), s.value) && !N(R.value) ? Ze(J(R.value), J(r.value[0])) ? (r.value.unshift(J(R.value)), t("range-end", r.value[0])) : (r.value[1] = J(R.value), t("range-end", r.value[1])) : (e.autoApply && t("auto-apply-invalid", R.value), t("invalid-date", R.value)) : (r.value[0] = J(R.value), t("range-start", r.value[0]));
  }, qt = (R = !0) => e.enableSeconds ? Array.isArray(u.seconds) ? R ? u.seconds[0] : u.seconds[1] : u.seconds : 0, nt = (R) => {
    r.value[R] = _a(
      r.value[R],
      u.hours[R],
      u.minutes[R],
      qt(R !== 1)
    );
  }, Br = () => {
    var R, te;
    r.value[0] && r.value[1] && +((R = r.value) == null ? void 0 : R[0]) > +((te = r.value) == null ? void 0 : te[1]) && (r.value.reverse(), t("range-start", r.value[0]), t("range-end", r.value[1]));
  }, jr = () => {
    r.value.length && (r.value[0] && !r.value[1] ? nt(0) : (nt(0), nt(1), n()), Br(), s.value = r.value.slice(), Vr(r.value, t, e.autoApply, e.modelAuto));
  }, Wn = (R, te = !1) => {
    if (_(R.value) || !R.current && e.hideOffsetDates)
      return t("invalid-date", R.value);
    if (l.value = JSON.parse(JSON.stringify(R)), !g.value.enabled)
      return Ce(R);
    Ro(u.hours) && Ro(u.minutes) && !w.value.enabled && (ke(R, te), jr());
  }, Ur = (R, te) => {
    var xe;
    O(R, te.month, te.year, !0), f.value.count && !f.value.solo && we(R), t("update-month-year", { instance: R, month: te.month, year: te.year }), a(f.value.solo ? R : void 0);
    const j = (xe = e.flow) != null && xe.length ? e.flow[e.flowStep] : void 0;
    !te.fromNav && (j === gt.month || j === gt.year) && n();
  }, Wr = (R, te) => {
    Vu({
      value: R,
      modelValue: s,
      range: g.value.enabled,
      timezone: te ? void 0 : P.value.timezone
    }), W(), e.multiCalendars && je().then(() => E(!0));
  }, qr = () => {
    const R = Mi(J(), P.value);
    !g.value.enabled && !w.value.enabled ? s.value = R : s.value && Array.isArray(s.value) && s.value[0] ? w.value.enabled ? s.value = [...s.value, R] : s.value = Ze(R, s.value[0]) ? [R, s.value[0]] : [s.value[0], R] : s.value = [R], W();
  }, Xr = () => {
    if (Array.isArray(s.value))
      if (w.value.enabled) {
        const R = Qr();
        s.value[s.value.length - 1] = V(R);
      } else
        s.value = s.value.map((R, te) => R && V(R, te));
    else
      s.value = V(s.value);
    t("time-update");
  }, Qr = () => Array.isArray(s.value) && s.value.length ? s.value[s.value.length - 1] : null;
  return {
    calendars: d,
    modelValue: s,
    month: ve,
    year: T,
    time: u,
    disabledTimesConfig: ce,
    today: c,
    validateTime: ne,
    getCalendarDays: Re,
    getMarker: k,
    handleScroll: Z,
    handleSwipe: de,
    handleArrow: Q,
    selectDate: Wn,
    updateMonthYear: Ur,
    presetDate: Wr,
    selectCurrentDate: qr,
    updateTime: (R, te = !0, xe = !1) => {
      I(R, te, xe, Xr);
    },
    assignMonthAndYear: q
  };
}, a3 = { key: 0 }, n3 = /* @__PURE__ */ _e({
  __name: "DatePicker",
  props: {
    ...Ut
  },
  emits: [
    "tooltip-open",
    "tooltip-close",
    "mount",
    "update:internal-model-value",
    "update-flow-step",
    "reset-flow",
    "auto-apply",
    "focus-menu",
    "select-date",
    "range-start",
    "range-end",
    "invalid-fixed-range",
    "time-update",
    "am-pm-change",
    "time-picker-open",
    "time-picker-close",
    "recalculate-position",
    "update-month-year",
    "auto-apply-invalid",
    "date-update",
    "invalid-date",
    "overlay-toggle"
  ],
  setup(e, { expose: t, emit: a }) {
    const n = a, r = e, {
      calendars: i,
      month: l,
      year: o,
      modelValue: s,
      time: d,
      disabledTimesConfig: u,
      today: c,
      validateTime: f,
      getCalendarDays: v,
      getMarker: g,
      handleArrow: y,
      handleScroll: P,
      handleSwipe: b,
      selectDate: w,
      updateMonthYear: h,
      presetDate: _,
      selectCurrentDate: C,
      updateTime: A,
      assignMonthAndYear: I
    } = t3(r, n, oe, q), V = ja(), { setHoverDate: L, getDayClassData: F, clearHoverDate: ne } = w3(s, r), { defaultedMultiCalendars: ce } = Ke(r), ve = z([]), T = z([]), D = z(null), O = Ot(V, "calendar"), W = Ot(V, "monthYear"), re = Ot(V, "timePicker"), U = (Z) => {
      r.shadow || n("mount", Z);
    };
    ht(
      i,
      () => {
        r.shadow || setTimeout(() => {
          n("recalculate-position");
        }, 0);
      },
      { deep: !0 }
    ), ht(
      ce,
      (Z, Q) => {
        Z.count - Q.count > 0 && I();
      },
      { deep: !0 }
    );
    const E = $(() => (Z) => v(l.value(Z), o.value(Z)).map((Q) => ({
      ...Q,
      days: Q.days.map((de) => (de.marker = g(de), de.classData = F(de), de))
    })));
    function oe(Z) {
      var Q;
      Z || Z === 0 ? (Q = T.value[Z]) == null || Q.triggerTransition(l.value(Z), o.value(Z)) : T.value.forEach((de, k) => de.triggerTransition(l.value(k), o.value(k)));
    }
    function q() {
      n("update-flow-step");
    }
    const H = (Z, Q = !1) => {
      w(Z, Q), r.spaceConfirm && n("select-date");
    }, m = (Z, Q, de = 0) => {
      var k;
      (k = ve.value[de]) == null || k.toggleMonthPicker(Z, Q);
    }, S = (Z, Q, de = 0) => {
      var k;
      (k = ve.value[de]) == null || k.toggleYearPicker(Z, Q);
    }, K = (Z, Q, de) => {
      var k;
      (k = D.value) == null || k.toggleTimePicker(Z, Q, de);
    }, x = (Z, Q) => {
      var de;
      if (!r.range) {
        const k = s.value ? s.value : c, X = Q ? new Date(Q) : k, Oe = Z ? Yt(X, { weekStartsOn: 1 }) : vu(X, { weekStartsOn: 1 });
        w({
          value: Oe,
          current: De(X) === l.value(0),
          text: "",
          classData: {}
        }), (de = document.getElementById(Hu(Oe))) == null || de.focus();
      }
    }, se = (Z) => {
      var Q;
      (Q = ve.value[0]) == null || Q.handleMonthYearChange(Z, !0);
    }, we = (Z) => {
      h(0, { month: l.value(0), year: o.value(0) + (Z ? 1 : -1), fromNav: !0 });
    }, st = (Z, Q) => {
      Z === gt.time && n(`time-picker-${Q ? "open" : "close"}`), n("overlay-toggle", { open: Q, overlay: Z });
    }, B = (Z) => {
      n("overlay-toggle", { open: !1, overlay: Z }), n("focus-menu");
    };
    return t({
      clearHoverDate: ne,
      presetDate: _,
      selectCurrentDate: C,
      toggleMonthPicker: m,
      toggleYearPicker: S,
      toggleTimePicker: K,
      handleArrow: y,
      updateMonthYear: h,
      getSidebarProps: () => ({
        modelValue: s,
        month: l,
        year: o,
        time: d,
        updateTime: A,
        updateMonthYear: h,
        selectDate: w,
        presetDate: _
      }),
      changeMonth: se,
      changeYear: we,
      selectWeekDate: x
    }), (Z, Q) => (M(), Y(Ae, null, [
      Ee(zr, {
        "multi-calendars": p(ce).count,
        collapse: Z.collapse,
        "is-mobile": Z.isMobile
      }, {
        default: pe(({ instance: de, index: k }) => [
          Z.disableMonthYearSelect ? G("", !0) : (M(), Me(qp, Ue({
            key: 0,
            ref: (X) => {
              X && (ve.value[k] = X);
            },
            months: p(Tu)(Z.formatLocale, Z.locale, Z.monthNameFormat),
            years: p(Pi)(Z.yearRange, Z.locale, Z.reverseYears),
            month: p(l)(de),
            year: p(o)(de),
            instance: de
          }, Z.$props, {
            onMount: Q[0] || (Q[0] = (X) => U(p(Ha).header)),
            onResetFlow: Q[1] || (Q[1] = (X) => Z.$emit("reset-flow")),
            onUpdateMonthYear: (X) => p(h)(de, X),
            onOverlayClosed: B,
            onOverlayOpened: Q[2] || (Q[2] = (X) => Z.$emit("overlay-toggle", { open: !0, overlay: X }))
          }), bt({ _: 2 }, [
            ze(p(W), (X, Oe) => ({
              name: X,
              fn: pe((ae) => [
                ie(Z.$slots, X, mt(At(ae)))
              ])
            }))
          ]), 1040, ["months", "years", "month", "year", "instance", "onUpdateMonthYear"])),
          Ee(e3, Ue({
            ref: (X) => {
              X && (T.value[k] = X);
            },
            "mapped-dates": E.value(de),
            month: p(l)(de),
            year: p(o)(de),
            instance: de
          }, Z.$props, {
            onSelectDate: (X) => p(w)(X, de !== 1),
            onHandleSpace: (X) => H(X, de !== 1),
            onSetHoverDate: Q[3] || (Q[3] = (X) => p(L)(X)),
            onHandleScroll: (X) => p(P)(X, de),
            onHandleSwipe: (X) => p(b)(X, de),
            onMount: Q[4] || (Q[4] = (X) => U(p(Ha).calendar)),
            onResetFlow: Q[5] || (Q[5] = (X) => Z.$emit("reset-flow")),
            onTooltipOpen: Q[6] || (Q[6] = (X) => Z.$emit("tooltip-open", X)),
            onTooltipClose: Q[7] || (Q[7] = (X) => Z.$emit("tooltip-close", X))
          }), bt({ _: 2 }, [
            ze(p(O), (X, Oe) => ({
              name: X,
              fn: pe((ae) => [
                ie(Z.$slots, X, mt(At({ ...ae })))
              ])
            }))
          ]), 1040, ["mapped-dates", "month", "year", "instance", "onSelectDate", "onHandleSpace", "onHandleScroll", "onHandleSwipe"])
        ]),
        _: 3
      }, 8, ["multi-calendars", "collapse", "is-mobile"]),
      Z.enableTimePicker ? (M(), Y("div", a3, [
        Z.$slots["time-picker"] ? ie(Z.$slots, "time-picker", mt(Ue({ key: 0 }, { time: p(d), updateTime: p(A) }))) : (M(), Me(ju, Ue({
          key: 1,
          ref_key: "timePickerRef",
          ref: D
        }, Z.$props, {
          hours: p(d).hours,
          minutes: p(d).minutes,
          seconds: p(d).seconds,
          "internal-model-value": Z.internalModelValue,
          "disabled-times-config": p(u),
          "validate-time": p(f),
          onMount: Q[8] || (Q[8] = (de) => U(p(Ha).timePicker)),
          "onUpdate:hours": Q[9] || (Q[9] = (de) => p(A)(de)),
          "onUpdate:minutes": Q[10] || (Q[10] = (de) => p(A)(de, !1)),
          "onUpdate:seconds": Q[11] || (Q[11] = (de) => p(A)(de, !1, !0)),
          onResetFlow: Q[12] || (Q[12] = (de) => Z.$emit("reset-flow")),
          onOverlayClosed: Q[13] || (Q[13] = (de) => st(de, !1)),
          onOverlayOpened: Q[14] || (Q[14] = (de) => st(de, !0)),
          onAmPmChange: Q[15] || (Q[15] = (de) => Z.$emit("am-pm-change", de))
        }), bt({ _: 2 }, [
          ze(p(re), (de, k) => ({
            name: de,
            fn: pe((X) => [
              ie(Z.$slots, de, mt(At(X)))
            ])
          }))
        ]), 1040, ["hours", "minutes", "seconds", "internal-model-value", "disabled-times-config", "validate-time"]))
      ])) : G("", !0)
    ], 64));
  }
}), r3 = (e, t) => {
  const a = z(), {
    defaultedMultiCalendars: n,
    defaultedConfig: r,
    defaultedHighlight: i,
    defaultedRange: l,
    propDates: o,
    defaultedFilters: s,
    defaultedMultiDates: d
  } = Ke(e), { modelValue: u, year: c, month: f, calendars: v } = Un(e, t), { isDisabled: g } = $a(e), { selectYear: y, groupedYears: P, showYearPicker: b, isDisabled: w, toggleYearPicker: h, handleYearSelect: _, handleYear: C } = Bu({
    modelValue: u,
    multiCalendars: n,
    range: l,
    highlight: i,
    calendars: v,
    propDates: o,
    month: f,
    year: c,
    filters: s,
    props: e,
    emit: t
  }), A = (D, O) => [D, O].map((W) => Zt(W, "MMMM", { locale: e.formatLocale })).join("-"), I = $(() => (D) => u.value ? Array.isArray(u.value) ? u.value.some((O) => Ao(D, O)) : Ao(u.value, D) : !1), V = (D) => {
    if (l.value.enabled) {
      if (Array.isArray(u.value)) {
        const O = $e(D, u.value[0]) || $e(D, u.value[1]);
        return Hr(u.value, a.value, D) && !O;
      }
      return !1;
    }
    return !1;
  }, L = (D, O) => D.quarter === wo(O) && D.year === Te(O), F = (D) => typeof i.value == "function" ? i.value({ quarter: wo(D), year: Te(D) }) : !!i.value.quarters.find((O) => L(O, D)), ne = $(() => (D) => {
    const O = Be(/* @__PURE__ */ new Date(), { year: c.value(D) });
    return Ev({
      start: En(O),
      end: fu(O)
    }).map((W) => {
      const re = La(W), U = ko(W), E = g(W), oe = V(re), q = F(re);
      return {
        text: A(re, U),
        value: re,
        active: I.value(re),
        highlighted: q,
        disabled: E,
        isBetween: oe
      };
    });
  }), ce = (D) => {
    Si(D, u, d.value.limit), t("auto-apply", !0);
  }, ve = (D) => {
    u.value = Ci(u, D, t), Vr(u.value, t, e.autoApply, e.modelAuto);
  }, T = (D) => {
    u.value = D, t("auto-apply");
  };
  return {
    defaultedConfig: r,
    defaultedMultiCalendars: n,
    groupedYears: P,
    year: c,
    isDisabled: w,
    quarters: ne,
    showYearPicker: b,
    modelValue: u,
    setHoverDate: (D) => {
      a.value = D;
    },
    selectYear: y,
    selectQuarter: (D, O, W) => {
      if (!W)
        return v.value[O].month = De(ko(D)), d.value.enabled ? ce(D) : l.value.enabled ? ve(D) : T(D);
    },
    toggleYearPicker: h,
    handleYearSelect: _,
    handleYear: C
  };
}, l3 = { class: "dp--quarter-items" }, i3 = ["data-test-id", "disabled", "onClick", "onMouseover"], o3 = /* @__PURE__ */ _e({
  compatConfig: {
    MODE: 3
  },
  __name: "QuarterPicker",
  props: {
    ...Ut
  },
  emits: [
    "update:internal-model-value",
    "reset-flow",
    "overlay-closed",
    "auto-apply",
    "range-start",
    "range-end",
    "overlay-toggle",
    "update-month-year"
  ],
  setup(e, { expose: t, emit: a }) {
    const n = a, r = e, i = ja(), l = Ot(i, "yearMode"), {
      defaultedMultiCalendars: o,
      defaultedConfig: s,
      groupedYears: d,
      year: u,
      isDisabled: c,
      quarters: f,
      modelValue: v,
      showYearPicker: g,
      setHoverDate: y,
      selectQuarter: P,
      toggleYearPicker: b,
      handleYearSelect: w,
      handleYear: h
    } = r3(r, n);
    return t({ getSidebarProps: () => ({
      modelValue: v,
      year: u,
      selectQuarter: P,
      handleYearSelect: w,
      handleYear: h
    }) }), (_, C) => (M(), Me(zr, {
      "multi-calendars": p(o).count,
      collapse: _.collapse,
      stretch: "",
      "is-mobile": _.isMobile
    }, {
      default: pe(({ instance: A }) => [
        le("div", {
          class: "dp-quarter-picker-wrap",
          style: Ge({ minHeight: `${p(s).modeHeight}px` })
        }, [
          _.$slots["top-extra"] ? ie(_.$slots, "top-extra", {
            key: 0,
            value: _.internalModelValue
          }) : G("", !0),
          le("div", null, [
            Ee(zu, Ue(_.$props, {
              items: p(d)(A),
              instance: A,
              "show-year-picker": p(g)[A],
              year: p(u)(A),
              "is-disabled": (I) => p(c)(A, I),
              onHandleYear: (I) => p(h)(A, I),
              onYearSelect: (I) => p(w)(I, A),
              onToggleYearPicker: (I) => p(b)(A, I == null ? void 0 : I.flow, I == null ? void 0 : I.show)
            }), bt({ _: 2 }, [
              ze(p(l), (I, V) => ({
                name: I,
                fn: pe((L) => [
                  ie(_.$slots, I, mt(At(L)))
                ])
              }))
            ]), 1040, ["items", "instance", "show-year-picker", "year", "is-disabled", "onHandleYear", "onYearSelect", "onToggleYearPicker"])
          ]),
          le("div", l3, [
            (M(!0), Y(Ae, null, ze(p(f)(A), (I, V) => (M(), Y("div", { key: V }, [
              le("button", {
                type: "button",
                class: ge(["dp--qr-btn", {
                  "dp--qr-btn-active": I.active,
                  "dp--qr-btn-between": I.isBetween,
                  "dp--qr-btn-disabled": I.disabled,
                  "dp--highlighted": I.highlighted
                }]),
                "data-test-id": I.value,
                disabled: I.disabled,
                onClick: (L) => p(P)(I.value, A, I.disabled),
                onMouseover: (L) => p(y)(I.value)
              }, [
                _.$slots.quarter ? ie(_.$slots, "quarter", {
                  key: 0,
                  value: I.value,
                  text: I.text
                }) : (M(), Y(Ae, { key: 1 }, [
                  ut(Ne(I.text), 1)
                ], 64))
              ], 42, i3)
            ]))), 128))
          ])
        ], 4)
      ]),
      _: 3
    }, 8, ["multi-calendars", "collapse", "is-mobile"]));
  }
}), Wu = (e, t) => {
  const a = z(0);
  He(() => {
    n(), window.addEventListener("resize", n, { passive: !0 });
  }), Bt(() => {
    window.removeEventListener("resize", n);
  });
  const n = () => {
    a.value = window.document.documentElement.clientWidth;
  };
  return {
    isMobile: $(
      () => a.value <= e.value.mobileBreakpoint && !t ? !0 : void 0
    )
  };
}, s3 = ["id", "tabindex", "role", "aria-label"], u3 = {
  key: 0,
  class: "dp--menu-load-container"
}, c3 = {
  key: 1,
  class: "dp--menu-header"
}, d3 = ["data-dp-mobile"], f3 = {
  key: 0,
  class: "dp__sidebar_left"
}, v3 = ["data-dp-mobile"], m3 = ["data-test-id", "data-dp-mobile", "onClick", "onKeydown"], p3 = {
  key: 2,
  class: "dp__sidebar_right"
}, h3 = {
  key: 3,
  class: "dp__action_extra"
}, No = /* @__PURE__ */ _e({
  compatConfig: {
    MODE: 3
  },
  __name: "DatepickerMenu",
  props: {
    ...Fr,
    shadow: { type: Boolean, default: !1 },
    openOnTop: { type: Boolean, default: !1 },
    internalModelValue: { type: [Date, Array], default: null },
    noOverlayFocus: { type: Boolean, default: !1 },
    collapse: { type: Boolean, default: !1 },
    getInputRect: { type: Function, default: () => ({}) },
    isTextInputDate: { type: Boolean, default: !1 }
  },
  emits: [
    "close-picker",
    "select-date",
    "auto-apply",
    "time-update",
    "flow-step",
    "update-month-year",
    "invalid-select",
    "update:internal-model-value",
    "recalculate-position",
    "invalid-fixed-range",
    "tooltip-open",
    "tooltip-close",
    "time-picker-open",
    "time-picker-close",
    "am-pm-change",
    "range-start",
    "range-end",
    "auto-apply-invalid",
    "date-update",
    "invalid-date",
    "overlay-toggle",
    "menu-blur"
  ],
  setup(e, { expose: t, emit: a }) {
    const n = a, r = e, i = z(null), l = $(() => {
      const { openOnTop: N, ...ee } = r;
      return {
        ...ee,
        isMobile: y.value,
        flowStep: ne.value,
        menuWrapRef: i.value
      };
    }), { setMenuFocused: o, setShiftKey: s, control: d } = Fu(), u = ja(), { defaultedTextInput: c, defaultedInline: f, defaultedConfig: v, defaultedUI: g } = Ke(r), { isMobile: y } = Wu(v, r.shadow), P = z(null), b = z(0), w = z(null), h = z(!1), _ = z(null), C = z(!1);
    He(() => {
      if (!r.shadow) {
        h.value = !0, A(), window.addEventListener("resize", A);
        const N = dt(i);
        if (N && !c.value.enabled && !f.value.enabled && (o(!0), re()), N) {
          const ee = (be) => {
            C.value = !0, v.value.allowPreventDefault && be.preventDefault(), xa(be, v.value, !0);
          };
          N.addEventListener("pointerdown", ee), N.addEventListener("mousedown", ee);
        }
      }
      document.addEventListener("mousedown", Ce);
    }), Bt(() => {
      window.removeEventListener("resize", A), document.addEventListener("mousedown", Ce);
    });
    const A = () => {
      const N = dt(w);
      N && (b.value = N.getBoundingClientRect().width);
    }, { arrowRight: I, arrowLeft: V, arrowDown: L, arrowUp: F } = Da(), { flowStep: ne, updateFlowStep: ce, childMount: ve, resetFlow: T, handleFlow: D } = k3(r, n, _), O = $(() => r.monthPicker ? Pp : r.yearPicker ? Tp : r.timePicker ? Vp : r.quarterPicker ? o3 : n3), W = $(() => {
      var N;
      if (v.value.arrowLeft)
        return v.value.arrowLeft;
      const ee = (N = i.value) == null ? void 0 : N.getBoundingClientRect(), be = r.getInputRect();
      return (be == null ? void 0 : be.width) < (b == null ? void 0 : b.value) && (be == null ? void 0 : be.left) <= ((ee == null ? void 0 : ee.left) ?? 0) ? `${(be == null ? void 0 : be.width) / 2}px` : (be == null ? void 0 : be.right) >= ((ee == null ? void 0 : ee.right) ?? 0) && (be == null ? void 0 : be.width) < (b == null ? void 0 : b.value) ? `${(b == null ? void 0 : b.value) - (be == null ? void 0 : be.width) / 2}px` : "50%";
    }), re = () => {
      const N = dt(i);
      N && N.focus({ preventScroll: !0 });
    }, U = $(() => {
      var N;
      return ((N = _.value) == null ? void 0 : N.getSidebarProps()) || {};
    }), E = () => {
      r.openOnTop && n("recalculate-position");
    }, oe = Ot(u, "action"), q = $(() => r.monthPicker || r.yearPicker ? Ot(u, "monthYear") : r.timePicker ? Ot(u, "timePicker") : Ot(u, "shared")), H = $(() => r.openOnTop ? "dp__arrow_bottom" : "dp__arrow_top"), m = $(() => ({
      dp__menu_disabled: r.disabled,
      dp__menu_readonly: r.readonly,
      "dp-menu-loading": r.loading
    })), S = $(
      () => ({
        dp__menu: !0,
        dp__menu_index: !f.value.enabled,
        dp__relative: f.value.enabled,
        ...g.value.menu ?? {}
      })
    ), K = (N) => {
      xa(N, v.value, !0);
    }, x = () => {
      r.escClose && n("close-picker");
    }, se = (N) => {
      if (r.arrowNavigation) {
        if (N === kt.up)
          return F();
        if (N === kt.down)
          return L();
        if (N === kt.left)
          return V();
        if (N === kt.right)
          return I();
      } else
        N === kt.left || N === kt.up ? Q("handleArrow", kt.left, 0, N === kt.up) : Q("handleArrow", kt.right, 0, N === kt.down);
    }, we = (N) => {
      s(N.shiftKey), !r.disableMonthYearSelect && N.code === qe.tab && N.target.classList.contains("dp__menu") && d.value.shiftKeyInMenu && (N.preventDefault(), xa(N, v.value, !0), n("close-picker"));
    }, st = () => {
      re(), n("time-picker-close");
    }, B = (N) => {
      var ee, be, ct;
      (ee = _.value) == null || ee.toggleTimePicker(!1, !1), (be = _.value) == null || be.toggleMonthPicker(!1, !1, N), (ct = _.value) == null || ct.toggleYearPicker(!1, !1, N);
    }, Z = (N, ee = 0) => {
      var be, ct, Wt;
      return N === "month" ? (be = _.value) == null ? void 0 : be.toggleMonthPicker(!1, !0, ee) : N === "year" ? (ct = _.value) == null ? void 0 : ct.toggleYearPicker(!1, !0, ee) : N === "time" ? (Wt = _.value) == null ? void 0 : Wt.toggleTimePicker(!0, !1) : B(ee);
    }, Q = (N, ...ee) => {
      var be, ct;
      (be = _.value) != null && be[N] && ((ct = _.value) == null || ct[N](...ee));
    }, de = () => {
      Q("selectCurrentDate");
    }, k = (N, ee) => {
      Q("presetDate", N, ee);
    }, X = () => {
      Q("clearHoverDate");
    }, Oe = (N, ee) => {
      Q("updateMonthYear", N, ee);
    }, ae = (N, ee) => {
      N.preventDefault(), se(ee);
    }, Re = (N) => {
      var ee, be, ct;
      if (we(N), N.key === qe.home || N.key === qe.end)
        return Q(
          "selectWeekDate",
          N.key === qe.home,
          N.target.getAttribute("id")
        );
      switch ((N.key === qe.pageUp || N.key === qe.pageDown) && (N.shiftKey ? (Q("changeYear", N.key === qe.pageUp), (ee = Hl(i.value, "overlay-year")) == null || ee.focus()) : (Q("changeMonth", N.key === qe.pageUp), (be = Hl(i.value, N.key === qe.pageUp ? "action-prev" : "action-next")) == null || be.focus()), N.target.getAttribute("id") && ((ct = i.value) == null || ct.focus({ preventScroll: !0 }))), N.key) {
        case qe.esc:
          return x();
        case qe.arrowLeft:
          return ae(N, kt.left);
        case qe.arrowRight:
          return ae(N, kt.right);
        case qe.arrowUp:
          return ae(N, kt.up);
        case qe.arrowDown:
          return ae(N, kt.down);
        default:
          return;
      }
    }, Ce = (N) => {
      var ee;
      f.value.enabled && !f.value.input && !((ee = i.value) != null && ee.contains(N.target)) && C.value && (C.value = !1, n("menu-blur"));
    };
    return t({
      updateMonthYear: Oe,
      switchView: Z,
      handleFlow: D
    }), (N, ee) => {
      var be, ct, Wt;
      return M(), Y("div", {
        id: N.uid ? `dp-menu-${N.uid}` : void 0,
        ref_key: "dpMenuRef",
        ref: i,
        tabindex: p(f).enabled ? void 0 : "0",
        role: p(f).enabled ? void 0 : "dialog",
        "aria-label": (be = N.ariaLabels) == null ? void 0 : be.menu,
        class: ge(S.value),
        style: Ge({ "--dp-arrow-left": W.value }),
        onMouseleave: X,
        onClick: K,
        onKeydown: Re
      }, [
        (N.disabled || N.readonly) && p(f).enabled || N.loading ? (M(), Y("div", {
          key: 0,
          class: ge(m.value)
        }, [
          N.loading ? (M(), Y("div", u3, ee[19] || (ee[19] = [
            le("span", { class: "dp--menu-loader" }, null, -1)
          ]))) : G("", !0)
        ], 2)) : G("", !0),
        N.$slots["menu-header"] ? (M(), Y("div", c3, [
          ie(N.$slots, "menu-header")
        ])) : G("", !0),
        !p(f).enabled && !N.teleportCenter ? (M(), Y("div", {
          key: 2,
          class: ge(H.value)
        }, null, 2)) : G("", !0),
        le("div", {
          ref_key: "innerMenuRef",
          ref: w,
          class: ge({
            dp__menu_content_wrapper: ((ct = N.presetDates) == null ? void 0 : ct.length) || !!N.$slots["left-sidebar"] || !!N.$slots["right-sidebar"],
            "dp--menu-content-wrapper-collapsed": e.collapse && (((Wt = N.presetDates) == null ? void 0 : Wt.length) || !!N.$slots["left-sidebar"] || !!N.$slots["right-sidebar"])
          }),
          "data-dp-mobile": p(y),
          style: Ge({ "--dp-menu-width": `${b.value}px` })
        }, [
          N.$slots["left-sidebar"] ? (M(), Y("div", f3, [
            ie(N.$slots, "left-sidebar", mt(At(U.value)))
          ])) : G("", !0),
          N.presetDates.length ? (M(), Y("div", {
            key: 1,
            class: ge({ "dp--preset-dates-collapsed": e.collapse, "dp--preset-dates": !0 }),
            "data-dp-mobile": p(y)
          }, [
            (M(!0), Y(Ae, null, ze(N.presetDates, (ke, qt) => (M(), Y(Ae, { key: qt }, [
              ke.slot ? ie(N.$slots, ke.slot, {
                key: 0,
                presetDate: k,
                label: ke.label,
                value: ke.value
              }) : (M(), Y("button", {
                key: 1,
                type: "button",
                style: Ge(ke.style || {}),
                class: ge(["dp__btn dp--preset-range", { "dp--preset-range-collapsed": e.collapse }]),
                "data-test-id": ke.testId ?? void 0,
                "data-dp-mobile": p(y),
                onClick: za((nt) => k(ke.value, ke.noTz), ["prevent"]),
                onKeydown: (nt) => p(xt)(nt, () => k(ke.value, ke.noTz), !0)
              }, Ne(ke.label), 47, m3))
            ], 64))), 128))
          ], 10, v3)) : G("", !0),
          le("div", {
            ref_key: "calendarWrapperRef",
            ref: P,
            class: "dp__instance_calendar",
            role: "document"
          }, [
            (M(), Me(Mr(O.value), Ue({
              ref_key: "dynCmpRef",
              ref: _
            }, l.value, {
              "flow-step": p(ne),
              onMount: p(ve),
              onUpdateFlowStep: p(ce),
              onResetFlow: p(T),
              onFocusMenu: re,
              onSelectDate: ee[0] || (ee[0] = (ke) => N.$emit("select-date")),
              onDateUpdate: ee[1] || (ee[1] = (ke) => N.$emit("date-update", ke)),
              onTooltipOpen: ee[2] || (ee[2] = (ke) => N.$emit("tooltip-open", ke)),
              onTooltipClose: ee[3] || (ee[3] = (ke) => N.$emit("tooltip-close", ke)),
              onAutoApply: ee[4] || (ee[4] = (ke) => N.$emit("auto-apply", ke)),
              onRangeStart: ee[5] || (ee[5] = (ke) => N.$emit("range-start", ke)),
              onRangeEnd: ee[6] || (ee[6] = (ke) => N.$emit("range-end", ke)),
              onInvalidFixedRange: ee[7] || (ee[7] = (ke) => N.$emit("invalid-fixed-range", ke)),
              onTimeUpdate: ee[8] || (ee[8] = (ke) => N.$emit("time-update")),
              onAmPmChange: ee[9] || (ee[9] = (ke) => N.$emit("am-pm-change", ke)),
              onTimePickerOpen: ee[10] || (ee[10] = (ke) => N.$emit("time-picker-open", ke)),
              onTimePickerClose: st,
              onRecalculatePosition: E,
              onUpdateMonthYear: ee[11] || (ee[11] = (ke) => N.$emit("update-month-year", ke)),
              onAutoApplyInvalid: ee[12] || (ee[12] = (ke) => N.$emit("auto-apply-invalid", ke)),
              onInvalidDate: ee[13] || (ee[13] = (ke) => N.$emit("invalid-date", ke)),
              onOverlayToggle: ee[14] || (ee[14] = (ke) => N.$emit("overlay-toggle", ke)),
              "onUpdate:internalModelValue": ee[15] || (ee[15] = (ke) => N.$emit("update:internal-model-value", ke))
            }), bt({ _: 2 }, [
              ze(q.value, (ke, qt) => ({
                name: ke,
                fn: pe((nt) => [
                  ie(N.$slots, ke, mt(At({ ...nt })))
                ])
              }))
            ]), 1040, ["flow-step", "onMount", "onUpdateFlowStep", "onResetFlow"]))
          ], 512),
          N.$slots["right-sidebar"] ? (M(), Y("div", p3, [
            ie(N.$slots, "right-sidebar", mt(At(U.value)))
          ])) : G("", !0),
          N.$slots["action-extra"] ? (M(), Y("div", h3, [
            N.$slots["action-extra"] ? ie(N.$slots, "action-extra", {
              key: 0,
              selectCurrentDate: de
            }) : G("", !0)
          ])) : G("", !0)
        ], 14, d3),
        !N.autoApply || p(v).keepActionRow ? (M(), Me(hp, Ue({
          key: 3,
          "menu-mount": h.value
        }, l.value, {
          "calendar-width": b.value,
          onClosePicker: ee[16] || (ee[16] = (ke) => N.$emit("close-picker")),
          onSelectDate: ee[17] || (ee[17] = (ke) => N.$emit("select-date")),
          onInvalidSelect: ee[18] || (ee[18] = (ke) => N.$emit("invalid-select")),
          onSelectNow: de
        }), bt({ _: 2 }, [
          ze(p(oe), (ke, qt) => ({
            name: ke,
            fn: pe((nt) => [
              ie(N.$slots, ke, mt(At({ ...nt })))
            ])
          }))
        ]), 1040, ["menu-mount", "calendar-width"])) : G("", !0)
      ], 46, s3);
    };
  }
});
var Ka = /* @__PURE__ */ ((e) => (e.center = "center", e.left = "left", e.right = "right", e))(Ka || {});
const y3 = ({
  menuRef: e,
  menuRefInner: t,
  inputRef: a,
  pickerWrapperRef: n,
  inline: r,
  emit: i,
  props: l,
  slots: o
}) => {
  const { defaultedConfig: s } = Ke(l), d = z({}), u = z(!1), c = z({
    top: "0",
    left: "0"
  }), f = z(!1), v = tt(l, "teleportCenter");
  ht(v, () => {
    c.value = JSON.parse(JSON.stringify({})), C();
  });
  const g = (O) => {
    if (l.teleport) {
      const W = O.getBoundingClientRect();
      return {
        left: W.left + window.scrollX,
        top: W.top + window.scrollY
      };
    }
    return { top: 0, left: 0 };
  }, y = (O, W) => {
    c.value.left = `${O + W - d.value.width}px`;
  }, P = (O) => {
    c.value.left = `${O}px`;
  }, b = (O, W) => {
    l.position === Ka.left && P(O), l.position === Ka.right && y(O, W), l.position === Ka.center && (c.value.left = `${O + W / 2 - d.value.width / 2}px`);
  }, w = (O) => {
    const { width: W, height: re } = O.getBoundingClientRect(), { top: U, left: E } = g(O);
    return { top: +U, left: +E, width: W, height: re };
  }, h = () => {
    c.value.left = "50%", c.value.top = "50%", c.value.transform = "translate(-50%, -50%)", c.value.position = "fixed", delete c.value.opacity;
  }, _ = () => {
    const O = dt(a);
    c.value = l.altPosition(O);
  }, C = (O = !0) => {
    var W;
    if (!r.value.enabled) {
      if (v.value)
        return h();
      if (l.altPosition !== null)
        return _();
      if (O) {
        const re = l.teleport ? (W = t.value) == null ? void 0 : W.$el : e.value;
        re && (d.value = re.getBoundingClientRect()), i("recalculate-position");
      }
      return ce();
    }
  }, A = ({ inputEl: O, left: W, width: re }) => {
    window.screen.width > 768 && !u.value && b(W, re), L(O);
  }, I = (O) => {
    const { top: W, left: re, height: U, width: E } = w(O);
    c.value.top = `${U + W + +l.offset}px`, f.value = !1, u.value || (c.value.left = `${re + E / 2 - d.value.width / 2}px`), A({ inputEl: O, left: re, width: E });
  }, V = (O) => {
    const { top: W, left: re, width: U } = w(O);
    c.value.top = `${W - +l.offset - d.value.height}px`, f.value = !0, A({ inputEl: O, left: re, width: U });
  }, L = (O) => {
    if (l.autoPosition) {
      const { left: W, width: re } = w(O), { left: U, right: E } = d.value;
      if (!u.value) {
        if (Math.abs(U) !== Math.abs(E)) {
          if (U <= 0)
            return u.value = !0, P(W);
          if (E >= document.documentElement.clientWidth)
            return u.value = !0, y(W, re);
        }
        return b(W, re);
      }
    }
  }, F = () => {
    const O = dt(a);
    if (O) {
      if (l.autoPosition === Rt.top)
        return Rt.top;
      if (l.autoPosition === Rt.bottom)
        return Rt.bottom;
      const { height: W } = d.value, { top: re, height: U } = O.getBoundingClientRect(), E = window.innerHeight - re - U, oe = re;
      return W <= E ? Rt.bottom : W > E && W <= oe ? Rt.top : E >= oe ? Rt.bottom : Rt.top;
    }
    return Rt.bottom;
  }, ne = (O) => F() === Rt.bottom ? I(O) : V(O), ce = () => {
    const O = dt(a);
    if (O)
      return l.autoPosition ? ne(O) : I(O);
  }, ve = function(O) {
    if (O) {
      const W = O.scrollHeight > O.clientHeight, re = window.getComputedStyle(O).overflowY.indexOf("hidden") !== -1;
      return W && !re;
    }
    return !0;
  }, T = function(O) {
    return !O || O === document.body || O.nodeType === Node.DOCUMENT_FRAGMENT_NODE ? window : ve(O) ? O : T(
      O.assignedSlot && s.value.shadowDom ? O.assignedSlot.parentNode : O.parentNode
    );
  }, D = (O) => {
    if (O)
      switch (l.position) {
        case Ka.left:
          return { left: 0, transform: "translateX(0)" };
        case Ka.right:
          return { left: `${O.width}px`, transform: "translateX(-100%)" };
        default:
          return { left: `${O.width / 2}px`, transform: "translateX(-50%)" };
      }
    return {};
  };
  return {
    openOnTop: f,
    menuStyle: c,
    xCorrect: u,
    setMenuPosition: C,
    getScrollableParent: T,
    shadowRender: (O, W) => {
      var re, U, E;
      const oe = document.createElement("div"), q = (re = dt(a)) == null ? void 0 : re.getBoundingClientRect();
      oe.setAttribute("id", "dp--temp-container");
      const H = (U = n.value) != null && U.clientWidth ? n.value : document.body;
      H.append(oe);
      const m = D(q), S = s.value.shadowDom ? Object.keys(o).filter(
        (x) => ["right-sidebar", "left-sidebar", "top-extra", "action-extra"].includes(x)
      ) : Object.keys(o), K = ea(
        O,
        {
          ...W,
          shadow: !0,
          style: { opacity: 0, position: "absolute", ...m }
        },
        Object.fromEntries(S.map((x) => [x, o[x]]))
      );
      $i(K, oe), d.value = (E = K.el) == null ? void 0 : E.getBoundingClientRect(), $i(null, oe), H.removeChild(oe);
    }
  };
}, ga = [
  { name: "clock-icon", use: ["time", "calendar", "shared"] },
  { name: "arrow-left", use: ["month-year", "calendar", "shared", "year-mode"] },
  { name: "arrow-right", use: ["month-year", "calendar", "shared", "year-mode"] },
  { name: "arrow-up", use: ["time", "calendar", "month-year", "shared"] },
  { name: "arrow-down", use: ["time", "calendar", "month-year", "shared"] },
  { name: "calendar-icon", use: ["month-year", "time", "calendar", "shared", "year-mode"] },
  { name: "day", use: ["calendar", "shared"] },
  { name: "month-overlay-value", use: ["calendar", "month-year", "shared"] },
  { name: "year-overlay-value", use: ["calendar", "month-year", "shared", "year-mode"] },
  { name: "year-overlay", use: ["month-year", "shared"] },
  { name: "month-overlay", use: ["month-year", "shared"] },
  { name: "month-overlay-header", use: ["month-year", "shared"] },
  { name: "year-overlay-header", use: ["month-year", "shared"] },
  { name: "hours-overlay-value", use: ["calendar", "time", "shared"] },
  { name: "hours-overlay-header", use: ["calendar", "time", "shared"] },
  { name: "minutes-overlay-value", use: ["calendar", "time", "shared"] },
  { name: "minutes-overlay-header", use: ["calendar", "time", "shared"] },
  { name: "seconds-overlay-value", use: ["calendar", "time", "shared"] },
  { name: "seconds-overlay-header", use: ["calendar", "time", "shared"] },
  { name: "hours", use: ["calendar", "time", "shared"] },
  { name: "minutes", use: ["calendar", "time", "shared"] },
  { name: "month", use: ["calendar", "month-year", "shared"] },
  { name: "year", use: ["calendar", "month-year", "shared", "year-mode"] },
  { name: "action-buttons", use: ["action"] },
  { name: "action-preview", use: ["action"] },
  { name: "calendar-header", use: ["calendar", "shared"] },
  { name: "marker-tooltip", use: ["calendar", "shared"] },
  { name: "action-extra", use: ["menu"] },
  { name: "time-picker-overlay", use: ["calendar", "time", "shared"] },
  { name: "am-pm-button", use: ["calendar", "time", "shared"] },
  { name: "left-sidebar", use: ["menu"] },
  { name: "right-sidebar", use: ["menu"] },
  { name: "month-year", use: ["month-year", "shared"] },
  { name: "time-picker", use: ["menu", "shared"] },
  { name: "action-row", use: ["action"] },
  { name: "marker", use: ["calendar", "shared"] },
  { name: "quarter", use: ["shared"] },
  { name: "top-extra", use: ["shared", "month-year"] },
  { name: "tp-inline-arrow-up", use: ["shared", "time"] },
  { name: "tp-inline-arrow-down", use: ["shared", "time"] },
  { name: "menu-header", use: ["menu"] }
], g3 = [{ name: "trigger" }, { name: "input-icon" }, { name: "clear-icon" }, { name: "dp-input" }], b3 = {
  all: () => ga,
  monthYear: () => ga.filter((e) => e.use.includes("month-year")),
  input: () => g3,
  timePicker: () => ga.filter((e) => e.use.includes("time")),
  action: () => ga.filter((e) => e.use.includes("action")),
  calendar: () => ga.filter((e) => e.use.includes("calendar")),
  menu: () => ga.filter((e) => e.use.includes("menu")),
  shared: () => ga.filter((e) => e.use.includes("shared")),
  yearMode: () => ga.filter((e) => e.use.includes("year-mode"))
}, Ot = (e, t, a) => {
  const n = [];
  return b3[t]().forEach((r) => {
    e[r.name] && n.push(r.name);
  }), a != null && a.length && a.forEach((r) => {
    r.slot && n.push(r.slot);
  }), n;
}, jn = (e) => {
  const t = $(() => (n) => e.value ? n ? e.value.open : e.value.close : ""), a = $(() => (n) => e.value ? n ? e.value.menuAppearTop : e.value.menuAppearBottom : "");
  return { transitionName: t, showTransition: !!e.value, menuTransition: a };
}, Un = (e, t, a) => {
  const { defaultedRange: n, defaultedTz: r } = Ke(e), i = J(Tt(J(), r.value.timezone)), l = z([{ month: De(i), year: Te(i) }]), o = (f) => {
    const v = {
      hours: fa(i),
      minutes: Sa(i),
      seconds: 0
    };
    return n.value.enabled ? [v[f], v[f]] : v[f];
  }, s = mn({
    hours: o("hours"),
    minutes: o("minutes"),
    seconds: o("seconds")
  });
  ht(
    n,
    (f, v) => {
      f.enabled !== v.enabled && (s.hours = o("hours"), s.minutes = o("minutes"), s.seconds = o("seconds"));
    },
    { deep: !0 }
  );
  const d = $({
    get: () => e.internalModelValue,
    set: (f) => {
      !e.readonly && !e.disabled && t("update:internal-model-value", f);
    }
  }), u = $(
    () => (f) => l.value[f] ? l.value[f].month : 0
  ), c = $(
    () => (f) => l.value[f] ? l.value[f].year : 0
  );
  return ht(
    d,
    (f, v) => {
      a && JSON.stringify(f ?? {}) !== JSON.stringify(v ?? {}) && a();
    },
    { deep: !0 }
  ), {
    calendars: l,
    time: s,
    modelValue: d,
    month: u,
    year: c,
    today: i
  };
}, w3 = (e, t) => {
  const {
    defaultedMultiCalendars: a,
    defaultedMultiDates: n,
    defaultedUI: r,
    defaultedHighlight: i,
    defaultedTz: l,
    propDates: o,
    defaultedRange: s
  } = Ke(t), { isDisabled: d } = $a(t), u = z(null), c = z(Tt(/* @__PURE__ */ new Date(), l.value.timezone)), f = (m) => {
    !m.current && t.hideOffsetDates || (u.value = m.value);
  }, v = () => {
    u.value = null;
  }, g = (m) => Array.isArray(e.value) && s.value.enabled && e.value[0] && u.value ? m ? lt(u.value, e.value[0]) : Ze(u.value, e.value[0]) : !0, y = (m, S) => {
    const K = () => e.value ? S ? e.value[0] || null : e.value[1] : null, x = e.value && Array.isArray(e.value) ? K() : null;
    return $e(J(m.value), x);
  }, P = (m) => {
    const S = Array.isArray(e.value) ? e.value[0] : null;
    return m ? !Ze(u.value ?? null, S) : !0;
  }, b = (m, S = !0) => (s.value.enabled || t.weekPicker) && Array.isArray(e.value) && e.value.length === 2 ? t.hideOffsetDates && !m.current ? !1 : $e(J(m.value), e.value[S ? 0 : 1]) : s.value.enabled ? y(m, S) && P(S) || $e(m.value, Array.isArray(e.value) ? e.value[0] : null) && g(S) : !1, w = (m, S) => {
    if (Array.isArray(e.value) && e.value[0] && e.value.length === 1) {
      const K = $e(m.value, u.value);
      return S ? lt(e.value[0], m.value) && K : Ze(e.value[0], m.value) && K;
    }
    return !1;
  }, h = (m) => !e.value || t.hideOffsetDates && !m.current ? !1 : s.value.enabled ? t.modelAuto && Array.isArray(e.value) ? $e(m.value, e.value[0] ? e.value[0] : c.value) : !1 : n.value.enabled && Array.isArray(e.value) ? e.value.some((S) => $e(S, m.value)) : $e(m.value, e.value ? e.value : c.value), _ = (m) => {
    if (s.value.autoRange || t.weekPicker) {
      if (u.value) {
        if (t.hideOffsetDates && !m.current)
          return !1;
        const S = Ht(u.value, +s.value.autoRange), K = ra(J(u.value), t.weekStart);
        return t.weekPicker ? $e(K[1], J(m.value)) : $e(S, J(m.value));
      }
      return !1;
    }
    return !1;
  }, C = (m) => {
    if (s.value.autoRange || t.weekPicker) {
      if (u.value) {
        const S = Ht(u.value, +s.value.autoRange);
        if (t.hideOffsetDates && !m.current)
          return !1;
        const K = ra(J(u.value), t.weekStart);
        return t.weekPicker ? lt(m.value, K[0]) && Ze(m.value, K[1]) : lt(m.value, u.value) && Ze(m.value, S);
      }
      return !1;
    }
    return !1;
  }, A = (m) => {
    if (s.value.autoRange || t.weekPicker) {
      if (u.value) {
        if (t.hideOffsetDates && !m.current)
          return !1;
        const S = ra(J(u.value), t.weekStart);
        return t.weekPicker ? $e(S[0], m.value) : $e(u.value, m.value);
      }
      return !1;
    }
    return !1;
  }, I = (m) => Hr(e.value, u.value, m.value), V = () => t.modelAuto && Array.isArray(t.internalModelValue) ? !!t.internalModelValue[0] : !1, L = () => t.modelAuto ? Ou(t.internalModelValue) : !0, F = (m) => {
    if (t.weekPicker)
      return !1;
    const S = s.value.enabled ? !b(m) && !b(m, !1) : !0;
    return !d(m.value) && !h(m) && !(!m.current && t.hideOffsetDates) && S;
  }, ne = (m) => s.value.enabled ? t.modelAuto ? V() && h(m) : !1 : h(m), ce = (m) => i.value ? z2(m.value, o.value.highlight) : !1, ve = (m) => {
    const S = d(m.value);
    return S && (typeof i.value == "function" ? !i.value(m.value, S) : !i.value.options.highlightDisabled);
  }, T = (m) => {
    var S;
    return typeof i.value == "function" ? i.value(m.value) : (S = i.value.weekdays) == null ? void 0 : S.includes(m.value.getDay());
  }, D = (m) => (s.value.enabled || t.weekPicker) && (!(a.value.count > 0) || m.current) && L() && !(!m.current && t.hideOffsetDates) && !h(m) ? I(m) : !1, O = (m) => {
    const { isRangeStart: S, isRangeEnd: K } = E(m), x = s.value.enabled ? S || K : !1;
    return {
      dp__cell_offset: !m.current,
      dp__pointer: !t.disabled && !(!m.current && t.hideOffsetDates) && !d(m.value),
      dp__cell_disabled: d(m.value),
      dp__cell_highlight: !ve(m) && (ce(m) || T(m)) && !ne(m) && !x && !A(m) && !(D(m) && t.weekPicker) && !K,
      dp__cell_highlight_active: !ve(m) && (ce(m) || T(m)) && ne(m),
      dp__today: !t.noToday && $e(m.value, c.value) && m.current,
      "dp--past": Ze(m.value, c.value),
      "dp--future": lt(m.value, c.value)
    };
  }, W = (m) => ({
    dp__active_date: ne(m),
    dp__date_hover: F(m)
  }), re = (m) => {
    if (e.value && !Array.isArray(e.value)) {
      const S = ra(e.value, t.weekStart);
      return {
        ...q(m),
        dp__range_start: $e(S[0], m.value),
        dp__range_end: $e(S[1], m.value),
        dp__range_between_week: lt(m.value, S[0]) && Ze(m.value, S[1])
      };
    }
    return {
      ...q(m)
    };
  }, U = (m) => {
    if (e.value && Array.isArray(e.value)) {
      const S = ra(e.value[0], t.weekStart), K = e.value[1] ? ra(e.value[1], t.weekStart) : [];
      return {
        ...q(m),
        dp__range_start: $e(S[0], m.value) || $e(K[0], m.value),
        dp__range_end: $e(S[1], m.value) || $e(K[1], m.value),
        dp__range_between_week: lt(m.value, S[0]) && Ze(m.value, S[1]) || lt(m.value, K[0]) && Ze(m.value, K[1]),
        dp__range_between: lt(m.value, S[1]) && Ze(m.value, K[0])
      };
    }
    return {
      ...q(m)
    };
  }, E = (m) => {
    const S = a.value.count > 0 ? m.current && b(m) && L() : b(m) && L(), K = a.value.count > 0 ? m.current && b(m, !1) && L() : b(m, !1) && L();
    return { isRangeStart: S, isRangeEnd: K };
  }, oe = (m) => {
    const { isRangeStart: S, isRangeEnd: K } = E(m);
    return {
      dp__range_start: S,
      dp__range_end: K,
      dp__range_between: D(m),
      dp__date_hover: $e(m.value, u.value) && !S && !K && !t.weekPicker,
      dp__date_hover_start: w(m, !0),
      dp__date_hover_end: w(m, !1)
    };
  }, q = (m) => ({
    ...oe(m),
    dp__cell_auto_range: C(m),
    dp__cell_auto_range_start: A(m),
    dp__cell_auto_range_end: _(m)
  }), H = (m) => s.value.enabled ? s.value.autoRange ? q(m) : t.modelAuto ? { ...W(m), ...oe(m) } : t.weekPicker ? U(m) : oe(m) : t.weekPicker ? re(m) : W(m);
  return {
    setHoverDate: f,
    clearHoverDate: v,
    getDayClassData: (m) => t.hideOffsetDates && !m.current ? {} : {
      ...O(m),
      ...H(m),
      [t.dayClass ? t.dayClass(m.value, t.internalModelValue) : ""]: !0,
      ...r.value.calendarCell ?? {}
    }
  };
}, $a = (e) => {
  const { defaultedFilters: t, defaultedRange: a, propDates: n, defaultedMultiDates: r } = Ke(e), i = (T) => n.value.disabledDates ? typeof n.value.disabledDates == "function" ? n.value.disabledDates(J(T)) : !!xr(T, n.value.disabledDates) : !1, l = (T) => n.value.maxDate ? e.yearPicker ? Te(T) > Te(n.value.maxDate) : lt(T, n.value.maxDate) : !1, o = (T) => n.value.minDate ? e.yearPicker ? Te(T) < Te(n.value.minDate) : Ze(T, n.value.minDate) : !1, s = (T) => {
    const D = l(T), O = o(T), W = i(T), re = t.value.months.map((H) => +H).includes(De(T)), U = e.disabledWeekDays.length ? e.disabledWeekDays.some((H) => +H === Tm(T)) : !1, E = v(T), oe = Te(T), q = oe < +e.yearRange[0] || oe > +e.yearRange[1];
    return !(D || O || W || re || q || U || E);
  }, d = (T, D) => Ze(...wa(n.value.minDate, T, D)) || $e(...wa(n.value.minDate, T, D)), u = (T, D) => lt(...wa(n.value.maxDate, T, D)) || $e(...wa(n.value.maxDate, T, D)), c = (T, D, O) => {
    let W = !1;
    return n.value.maxDate && O && u(T, D) && (W = !0), n.value.minDate && !O && d(T, D) && (W = !0), W;
  }, f = (T, D, O, W) => {
    let re = !1;
    return W && (n.value.minDate || n.value.maxDate) ? n.value.minDate && n.value.maxDate ? re = c(T, D, O) : (n.value.minDate && d(T, D) || n.value.maxDate && u(T, D)) && (re = !0) : re = !0, re;
  }, v = (T) => Array.isArray(n.value.allowedDates) && !n.value.allowedDates.length ? !0 : n.value.allowedDates ? !xr(T, n.value.allowedDates) : !1, g = (T) => !s(T), y = (T) => a.value.noDisabledRange ? !du({ start: T[0], end: T[1] }).some((D) => g(D)) : !0, P = (T) => {
    if (T) {
      const D = Te(T);
      return D >= +e.yearRange[0] && D <= e.yearRange[1];
    }
    return !0;
  }, b = (T, D) => !!(Array.isArray(T) && T[D] && (a.value.maxRange || a.value.minRange) && P(T[D])), w = (T, D, O = 0) => {
    if (b(D, O) && P(T)) {
      const W = su(T, D[O]), re = Iu(D[O], T), U = re.length === 1 ? 0 : re.filter((oe) => g(oe)).length, E = Math.abs(W) - (a.value.minMaxRawRange ? 0 : U);
      if (a.value.minRange && a.value.maxRange)
        return E >= +a.value.minRange && E <= +a.value.maxRange;
      if (a.value.minRange)
        return E >= +a.value.minRange;
      if (a.value.maxRange)
        return E <= +a.value.maxRange;
    }
    return !0;
  }, h = () => !e.enableTimePicker || e.monthPicker || e.yearPicker || e.ignoreTimeValidation, _ = (T) => Array.isArray(T) ? [T[0] ? sl(T[0]) : null, T[1] ? sl(T[1]) : null] : sl(T), C = (T, D, O) => T.find(
    (W) => +W.hours === fa(D) && W.minutes === "*" ? !0 : +W.minutes === Sa(D) && +W.hours === fa(D)
  ) && O, A = (T, D, O) => {
    const [W, re] = T, [U, E] = D;
    return !C(W, U, O) && !C(re, E, O) && O;
  }, I = (T, D) => {
    const O = Array.isArray(D) ? D : [D];
    return Array.isArray(e.disabledTimes) ? Array.isArray(e.disabledTimes[0]) ? A(e.disabledTimes, O, T) : !O.some((W) => C(e.disabledTimes, W, T)) : T;
  }, V = (T, D) => {
    const O = Array.isArray(D) ? [Fa(D[0]), D[1] ? Fa(D[1]) : void 0] : Fa(D), W = !e.disabledTimes(O);
    return T && W;
  }, L = (T, D) => e.disabledTimes ? Array.isArray(e.disabledTimes) ? I(D, T) : V(D, T) : D, F = (T) => {
    let D = !0;
    if (!T || h())
      return !0;
    const O = !n.value.minDate && !n.value.maxDate ? _(T) : T;
    return (e.maxTime || n.value.maxDate) && (D = $o(
      e.maxTime,
      n.value.maxDate,
      "max",
      vt(O),
      D
    )), (e.minTime || n.value.minDate) && (D = $o(
      e.minTime,
      n.value.minDate,
      "min",
      vt(O),
      D
    )), L(T, D);
  }, ne = (T) => {
    if (!e.monthPicker)
      return !0;
    let D = !0;
    const O = J(Ft(T));
    if (n.value.minDate && n.value.maxDate) {
      const W = J(Ft(n.value.minDate)), re = J(Ft(n.value.maxDate));
      return lt(O, W) && Ze(O, re) || $e(O, W) || $e(O, re);
    }
    if (n.value.minDate) {
      const W = J(Ft(n.value.minDate));
      D = lt(O, W) || $e(O, W);
    }
    if (n.value.maxDate) {
      const W = J(Ft(n.value.maxDate));
      D = Ze(O, W) || $e(O, W);
    }
    return D;
  }, ce = $(() => (T) => !e.enableTimePicker || e.ignoreTimeValidation ? !0 : F(T)), ve = $(() => (T) => e.monthPicker ? Array.isArray(T) && (a.value.enabled || r.value.enabled) ? !T.filter((D) => !ne(D)).length : ne(T) : !0);
  return {
    isDisabled: g,
    validateDate: s,
    validateMonthYearInRange: f,
    isDateRangeAllowed: y,
    checkMinMaxRange: w,
    isValidTime: F,
    isTimeValid: ce,
    isMonthValid: ve
  };
}, Yr = () => {
  const e = $(() => (n, r) => n == null ? void 0 : n.includes(r)), t = $(() => (n, r) => n.count ? n.solo ? !0 : r === 0 : !0), a = $(() => (n, r) => n.count ? n.solo ? !0 : r === n.count - 1 : !0);
  return { hideNavigationButtons: e, showLeftIcon: t, showRightIcon: a };
}, k3 = (e, t, a) => {
  const n = z(0), r = mn({
    [Ha.timePicker]: !e.enableTimePicker || e.timePicker || e.monthPicker,
    [Ha.calendar]: !1,
    [Ha.header]: !1
  }), i = $(() => e.monthPicker || e.timePicker), l = (c) => {
    var f;
    if ((f = e.flow) != null && f.length) {
      if (!c && i.value)
        return u();
      r[c] = !0, Object.keys(r).filter((v) => !r[v]).length || u();
    }
  }, o = () => {
    var c, f;
    (c = e.flow) != null && c.length && n.value !== -1 && (n.value += 1, t("flow-step", n.value), u()), ((f = e.flow) == null ? void 0 : f.length) === n.value && je().then(() => s());
  }, s = () => {
    n.value = -1;
  }, d = (c, f, ...v) => {
    var g, y;
    e.flow[n.value] === c && a.value && ((y = (g = a.value)[f]) == null || y.call(g, ...v));
  }, u = (c = 0) => {
    c && (n.value += c), d(gt.month, "toggleMonthPicker", !0), d(gt.year, "toggleYearPicker", !0), d(gt.calendar, "toggleTimePicker", !1, !0), d(gt.time, "toggleTimePicker", !0, !0);
    const f = e.flow[n.value];
    (f === gt.hours || f === gt.minutes || f === gt.seconds) && d(f, "toggleTimePicker", !0, !0, f);
  };
  return { childMount: l, updateFlowStep: o, resetFlow: s, handleFlow: u, flowStep: n };
}, x3 = {
  key: 1,
  class: "dp__input_wrap"
}, _3 = ["id", "name", "inputmode", "placeholder", "disabled", "readonly", "required", "value", "autocomplete", "aria-label", "aria-disabled", "aria-invalid"], M3 = {
  key: 2,
  class: "dp--clear-btn"
}, P3 = ["aria-label"], A3 = /* @__PURE__ */ _e({
  compatConfig: {
    MODE: 3
  },
  __name: "DatepickerInput",
  props: {
    isMenuOpen: { type: Boolean, default: !1 },
    inputValue: { type: String, default: "" },
    ...Fr
  },
  emits: [
    "clear",
    "open",
    "update:input-value",
    "set-input-date",
    "close",
    "select-date",
    "set-empty-date",
    "toggle",
    "focus-prev",
    "focus",
    "blur",
    "real-blur",
    "text-input"
  ],
  setup(e, { expose: t, emit: a }) {
    const n = a, r = e, {
      defaultedTextInput: i,
      defaultedAriaLabels: l,
      defaultedInline: o,
      defaultedConfig: s,
      defaultedRange: d,
      defaultedMultiDates: u,
      defaultedUI: c,
      getDefaultPattern: f,
      getDefaultStartTime: v
    } = Ke(r), { checkMinMaxRange: g } = $a(r), y = z(), P = z(null), b = z(!1), w = z(!1), h = $(
      () => ({
        dp__pointer: !r.disabled && !r.readonly && !i.value.enabled,
        dp__disabled: r.disabled,
        dp__input_readonly: !i.value.enabled,
        dp__input: !0,
        dp__input_icon_pad: !r.hideInputIcon,
        dp__input_valid: typeof r.state == "boolean" ? r.state : !1,
        dp__input_invalid: typeof r.state == "boolean" ? !r.state : !1,
        dp__input_focus: b.value || r.isMenuOpen,
        dp__input_reg: !i.value.enabled,
        ...c.value.input ?? {}
      })
    ), _ = () => {
      n("set-input-date", null), r.clearable && r.autoApply && (n("set-empty-date"), y.value = null);
    }, C = (E) => {
      const oe = v();
      return V2(
        E,
        i.value.format ?? f(),
        oe ?? Eu({}, r.enableSeconds),
        r.inputValue,
        w.value,
        r.formatLocale
      );
    }, A = (E) => {
      const { rangeSeparator: oe } = i.value, [q, H] = E.split(`${oe}`);
      if (q) {
        const m = C(q.trim()), S = H ? C(H.trim()) : null;
        if (cn(m, S))
          return;
        const K = m && S ? [m, S] : [m];
        g(S, K, 0) && (y.value = m ? K : null);
      }
    }, I = () => {
      w.value = !0;
    }, V = (E) => {
      if (d.value.enabled)
        A(E);
      else if (u.value.enabled) {
        const oe = E.split(";");
        y.value = oe.map((q) => C(q.trim())).filter((q) => q);
      } else
        y.value = C(E);
    }, L = (E) => {
      var oe;
      const q = typeof E == "string" ? E : (oe = E.target) == null ? void 0 : oe.value;
      q !== "" ? (i.value.openMenu && !r.isMenuOpen && n("open"), V(q), n("set-input-date", y.value)) : _(), w.value = !1, n("update:input-value", q), n("text-input", E, y.value);
    }, F = (E) => {
      i.value.enabled ? (V(E.target.value), i.value.enterSubmit && Fl(y.value) && r.inputValue !== "" ? (n("set-input-date", y.value, !0), y.value = null) : i.value.enterSubmit && r.inputValue === "" && (y.value = null, n("clear"))) : ve(E);
    }, ne = (E, oe) => {
      i.value.enabled && i.value.tabSubmit && !oe && V(E.target.value), i.value.tabSubmit && Fl(y.value) && r.inputValue !== "" ? (n("set-input-date", y.value, !0, !0), y.value = null) : i.value.tabSubmit && r.inputValue === "" && (y.value = null, n("clear", !0));
    }, ce = () => {
      b.value = !0, n("focus"), je().then(() => {
        var E;
        i.value.enabled && i.value.selectOnFocus && ((E = P.value) == null || E.select());
      });
    }, ve = (E) => {
      if (xa(E, s.value, !0), i.value.enabled && i.value.openMenu && !o.value.input) {
        if (i.value.openMenu === "open" && !r.isMenuOpen)
          return n("open");
        if (i.value.openMenu === "toggle")
          return n("toggle");
      } else
        i.value.enabled || n("toggle");
    }, T = () => {
      n("real-blur"), b.value = !1, (!r.isMenuOpen || o.value.enabled && o.value.input) && n("blur"), r.autoApply && i.value.enabled && y.value && !r.isMenuOpen && (n("set-input-date", y.value), n("select-date"), y.value = null);
    }, D = (E) => {
      xa(E, s.value, !0), n("clear");
    }, O = (E) => {
      if (E.key === "Tab" && ne(E), E.key === "Enter" && F(E), !i.value.enabled) {
        if (E.code === "Tab")
          return;
        E.preventDefault();
      }
    }, W = () => {
      var E;
      (E = P.value) == null || E.focus({ preventScroll: !0 });
    }, re = (E) => {
      y.value = E;
    }, U = (E) => {
      E.key === qe.tab && ne(E, !0);
    };
    return t({
      focusInput: W,
      setParsedDate: re
    }), (E, oe) => {
      var q, H, m;
      return M(), Y("div", { onClick: ve }, [
        E.$slots.trigger && !E.$slots["dp-input"] && !p(o).enabled ? ie(E.$slots, "trigger", { key: 0 }) : G("", !0),
        !E.$slots.trigger && (!p(o).enabled || p(o).input) ? (M(), Y("div", x3, [
          E.$slots["dp-input"] && !E.$slots.trigger && (!p(o).enabled || p(o).enabled && p(o).input) ? ie(E.$slots, "dp-input", {
            key: 0,
            value: e.inputValue,
            isMenuOpen: e.isMenuOpen,
            onInput: L,
            onEnter: F,
            onTab: ne,
            onClear: D,
            onBlur: T,
            onKeypress: O,
            onPaste: I,
            onFocus: ce,
            openMenu: () => E.$emit("open"),
            closeMenu: () => E.$emit("close"),
            toggleMenu: () => E.$emit("toggle")
          }) : G("", !0),
          E.$slots["dp-input"] ? G("", !0) : (M(), Y("input", {
            key: 1,
            id: E.uid ? `dp-input-${E.uid}` : void 0,
            ref_key: "inputRef",
            ref: P,
            "data-test-id": "dp-input",
            name: E.name,
            class: ge(h.value),
            inputmode: p(i).enabled ? "text" : "none",
            placeholder: E.placeholder,
            disabled: E.disabled,
            readonly: E.readonly,
            required: E.required,
            value: e.inputValue,
            autocomplete: E.autocomplete,
            "aria-label": (q = p(l)) == null ? void 0 : q.input,
            "aria-disabled": E.disabled || void 0,
            "aria-invalid": E.state === !1 ? !0 : void 0,
            onInput: L,
            onBlur: T,
            onFocus: ce,
            onKeypress: O,
            onKeydown: oe[0] || (oe[0] = (S) => O(S)),
            onPaste: I
          }, null, 42, _3)),
          le("div", {
            onClick: oe[3] || (oe[3] = (S) => n("toggle"))
          }, [
            E.$slots["input-icon"] && !E.hideInputIcon ? (M(), Y("span", {
              key: 0,
              class: "dp__input_icon",
              onClick: oe[1] || (oe[1] = (S) => n("toggle"))
            }, [
              ie(E.$slots, "input-icon")
            ])) : G("", !0),
            !E.$slots["input-icon"] && !E.hideInputIcon && !E.$slots["dp-input"] ? (M(), Me(p(hn), {
              key: 1,
              "aria-label": (H = p(l)) == null ? void 0 : H.calendarIcon,
              class: "dp__input_icon dp__input_icons",
              onClick: oe[2] || (oe[2] = (S) => n("toggle"))
            }, null, 8, ["aria-label"])) : G("", !0)
          ]),
          E.$slots["clear-icon"] && e.inputValue && E.clearable && !E.disabled && !E.readonly ? (M(), Y("span", M3, [
            ie(E.$slots, "clear-icon", { clear: D })
          ])) : G("", !0),
          E.clearable && !E.$slots["clear-icon"] && e.inputValue && !E.disabled && !E.readonly ? (M(), Y("button", {
            key: 3,
            "aria-label": (m = p(l)) == null ? void 0 : m.clearInput,
            class: "dp--clear-btn",
            type: "button",
            onKeydown: oe[4] || (oe[4] = (S) => p(xt)(S, () => D(S), !0, U)),
            onClick: oe[5] || (oe[5] = za((S) => D(S), ["prevent"]))
          }, [
            Ee(p(Au), {
              class: "dp__input_icons",
              "data-test-id": "clear-icon"
            })
          ], 40, P3)) : G("", !0)
        ])) : G("", !0)
      ]);
    };
  }
}), T3 = typeof window < "u" ? window : void 0, ml = () => {
}, O3 = (e) => ac() ? (nc(e), !0) : !1, S3 = (e, t, a, n) => {
  if (!e)
    return ml;
  let r = ml;
  const i = ht(
    () => p(e),
    (o) => {
      r(), o && (o.addEventListener(t, a, n), r = () => {
        o.removeEventListener(t, a, n), r = ml;
      });
    },
    { immediate: !0, flush: "post" }
  ), l = () => {
    i(), r();
  };
  return O3(l), l;
}, C3 = (e, t, a, n = {}) => {
  const { window: r = T3, event: i = "pointerdown" } = n;
  return r ? S3(r, i, (l) => {
    const o = dt(e), s = dt(t);
    !o || !s || o === l.target || l.composedPath().includes(o) || l.composedPath().includes(s) || a(l);
  }, { passive: !0 }) : void 0;
}, D3 = ["data-dp-mobile"], $3 = /* @__PURE__ */ _e({
  compatConfig: {
    MODE: 3
  },
  __name: "VueDatePicker",
  props: {
    ...Fr
  },
  emits: [
    "update:model-value",
    "update:model-timezone-value",
    "text-submit",
    "closed",
    "cleared",
    "open",
    "focus",
    "blur",
    "internal-model-change",
    "recalculate-position",
    "flow-step",
    "update-month-year",
    "invalid-select",
    "invalid-fixed-range",
    "tooltip-open",
    "tooltip-close",
    "time-picker-open",
    "time-picker-close",
    "am-pm-change",
    "range-start",
    "range-end",
    "date-update",
    "invalid-date",
    "overlay-toggle",
    "text-input"
  ],
  setup(e, { expose: t, emit: a }) {
    const n = a, r = e, i = ja(), l = z(!1), o = tt(r, "modelValue"), s = tt(r, "timezone"), d = z(null), u = z(null), c = z(null), f = z(!1), v = z(null), g = z(!1), y = z(!1), P = z(!1), b = z(!1), { setMenuFocused: w, setShiftKey: h } = Fu(), { clearArrowNav: _ } = Da(), { validateDate: C, isValidTime: A } = $a(r), {
      defaultedTransitions: I,
      defaultedTextInput: V,
      defaultedInline: L,
      defaultedConfig: F,
      defaultedRange: ne,
      defaultedMultiDates: ce
    } = Ke(r), { menuTransition: ve, showTransition: T } = jn(I), { isMobile: D } = Wu(F);
    He(() => {
      K(r.modelValue), je().then(() => {
        if (!L.value.enabled) {
          const j = q(v.value);
          j == null || j.addEventListener("scroll", X), window == null || window.addEventListener("resize", Oe);
        }
      }), L.value.enabled && (l.value = !0), window == null || window.addEventListener("keyup", ae), window == null || window.addEventListener("keydown", Re);
    }), Bt(() => {
      if (!L.value.enabled) {
        const j = q(v.value);
        j == null || j.removeEventListener("scroll", X), window == null || window.removeEventListener("resize", Oe);
      }
      window == null || window.removeEventListener("keyup", ae), window == null || window.removeEventListener("keydown", Re);
    });
    const O = Ot(i, "all", r.presetDates), W = Ot(i, "input");
    ht(
      [o, s],
      () => {
        K(o.value);
      },
      { deep: !0 }
    );
    const { openOnTop: re, menuStyle: U, xCorrect: E, setMenuPosition: oe, getScrollableParent: q, shadowRender: H } = y3({
      menuRef: d,
      menuRefInner: u,
      inputRef: c,
      pickerWrapperRef: v,
      inline: L,
      emit: n,
      props: r,
      slots: i
    }), {
      inputValue: m,
      internalModelValue: S,
      parseExternalModelValue: K,
      emitModelValue: x,
      formatInputValue: se,
      checkBeforeEmit: we
    } = fp(n, r, f), st = $(
      () => ({
        dp__main: !0,
        dp__theme_dark: r.dark,
        dp__theme_light: !r.dark,
        dp__flex_display: L.value.enabled,
        "dp--flex-display-collapsed": P.value,
        dp__flex_display_with_input: L.value.input
      })
    ), B = $(() => r.dark ? "dp__theme_dark" : "dp__theme_light"), Z = $(() => r.teleport ? {
      to: typeof r.teleport == "boolean" ? "body" : r.teleport,
      disabled: !r.teleport || L.value.enabled
    } : {}), Q = $(() => ({ class: "dp__outer_menu_wrap" })), de = $(() => L.value.enabled && (r.timePicker || r.monthPicker || r.yearPicker || r.quarterPicker)), k = () => {
      var j, ue;
      return ((ue = (j = c.value) == null ? void 0 : j.$el) == null ? void 0 : ue.getBoundingClientRect()) ?? { width: 0, left: 0, right: 0 };
    }, X = () => {
      l.value && (F.value.closeOnScroll ? nt() : oe());
    }, Oe = () => {
      var j;
      l.value && oe();
      const ue = ((j = u.value) == null ? void 0 : j.$el.getBoundingClientRect().width) ?? 0;
      P.value = document.body.offsetWidth <= ue;
    }, ae = (j) => {
      j.key === "Tab" && !L.value.enabled && !r.teleport && F.value.tabOutClosesMenu && (v.value.contains(document.activeElement) || nt()), y.value = j.shiftKey;
    }, Re = (j) => {
      y.value = j.shiftKey;
    }, Ce = () => {
      !r.disabled && !r.readonly && (H(No, r), oe(!1), l.value = !0, l.value && n("open"), l.value || qt(), K(r.modelValue));
    }, N = () => {
      var j;
      m.value = "", qt(), (j = c.value) == null || j.setParsedDate(null), n("update:model-value", null), n("update:model-timezone-value", null), n("cleared"), F.value.closeOnClearValue && nt();
    }, ee = () => {
      const j = S.value;
      return !j || !Array.isArray(j) && C(j) ? !0 : Array.isArray(j) ? ce.value.enabled || j.length === 2 && C(j[0]) && C(j[1]) ? !0 : ne.value.partialRange && !r.timePicker ? C(j[0]) : !1 : !1;
    }, be = () => {
      we() && ee() ? (x(), nt()) : n("invalid-select", S.value);
    }, ct = (j) => {
      Wt(), x(), F.value.closeOnAutoApply && !j && nt();
    }, Wt = () => {
      c.value && V.value.enabled && c.value.setParsedDate(S.value);
    }, ke = (j = !1) => {
      r.autoApply && A(S.value) && ee() && (ne.value.enabled && Array.isArray(S.value) ? (ne.value.partialRange || S.value.length === 2) && ct(j) : ct(j));
    }, qt = () => {
      V.value.enabled || (S.value = null);
    }, nt = (j = !1) => {
      j && S.value && F.value.setDateOnMenuClose && be(), L.value.enabled || (l.value && (l.value = !1, E.value = !1, w(!1), h(!1), _(), n("closed"), m.value && K(o.value)), qt(), n("blur"));
    }, Br = (j, ue, ye = !1) => {
      if (!j) {
        S.value = null;
        return;
      }
      const Xt = Array.isArray(j) ? !j.some((qa) => !C(qa)) : C(j), It = A(j);
      Xt && It ? (b.value = !0, S.value = j, ue && (g.value = ye, be(), n("text-submit")), je().then(() => {
        b.value = !1;
      })) : n("invalid-date", j);
    }, jr = () => {
      r.autoApply && A(S.value) && x(), Wt();
    }, Wn = () => l.value ? nt() : Ce(), Ur = (j) => {
      S.value = j;
    }, Wr = () => {
      V.value.enabled && (f.value = !0, se()), n("focus");
    }, qr = () => {
      if (V.value.enabled && (f.value = !1, K(r.modelValue), g.value)) {
        const j = F2(v.value, y.value);
        j == null || j.focus();
      }
      n("blur");
    }, Xr = (j) => {
      u.value && u.value.updateMonthYear(0, {
        month: Oo(j.month),
        year: Oo(j.year)
      });
    }, Qr = (j) => {
      K(j ?? r.modelValue);
    }, R = (j, ue) => {
      var ye;
      (ye = u.value) == null || ye.switchView(j, ue);
    }, te = (j) => F.value.onClickOutside ? F.value.onClickOutside(j) : nt(!0), xe = (j = 0) => {
      var ue;
      (ue = u.value) == null || ue.handleFlow(j);
    };
    return C3(d, c, () => te(ee)), t({
      closeMenu: nt,
      selectDate: be,
      clearValue: N,
      openMenu: Ce,
      onScroll: X,
      formatInputValue: se,
      // exposed for testing purposes
      updateInternalModelValue: Ur,
      // modify internal modelValue
      setMonthYear: Xr,
      parseModel: Qr,
      switchView: R,
      toggleMenu: Wn,
      handleFlow: xe,
      dpWrapMenuRef: d
    }), (j, ue) => (M(), Y("div", {
      ref_key: "pickerWrapperRef",
      ref: v,
      class: ge(st.value),
      "data-datepicker-instance": "",
      "data-dp-mobile": p(D)
    }, [
      Ee(A3, Ue({
        ref_key: "inputRef",
        ref: c,
        "input-value": p(m),
        "onUpdate:inputValue": ue[0] || (ue[0] = (ye) => ia(m) ? m.value = ye : null),
        "is-menu-open": l.value
      }, j.$props, {
        onClear: N,
        onOpen: Ce,
        onSetInputDate: Br,
        onSetEmptyDate: p(x),
        onSelectDate: be,
        onToggle: Wn,
        onClose: nt,
        onFocus: Wr,
        onBlur: qr,
        onRealBlur: ue[1] || (ue[1] = (ye) => f.value = !1),
        onTextInput: ue[2] || (ue[2] = (ye) => j.$emit("text-input", ye))
      }), bt({ _: 2 }, [
        ze(p(W), (ye, Xt) => ({
          name: ye,
          fn: pe((It) => [
            ie(j.$slots, ye, mt(At(It)))
          ])
        }))
      ]), 1040, ["input-value", "is-menu-open", "onSetEmptyDate"]),
      (M(), Me(Mr(j.teleport ? Yo : "div"), mt(At(Z.value)), {
        default: pe(() => [
          Ee(Ca, {
            name: p(ve)(p(re)),
            css: p(T) && !p(L).enabled
          }, {
            default: pe(() => [
              l.value ? (M(), Y("div", Ue({
                key: 0,
                ref_key: "dpWrapMenuRef",
                ref: d
              }, Q.value, {
                class: { "dp--menu-wrapper": !p(L).enabled },
                style: p(L).enabled ? void 0 : p(U)
              }), [
                Ee(No, Ue({
                  ref_key: "dpMenuRef",
                  ref: u
                }, j.$props, {
                  "internal-model-value": p(S),
                  "onUpdate:internalModelValue": ue[3] || (ue[3] = (ye) => ia(S) ? S.value = ye : null),
                  class: { [B.value]: !0, "dp--menu-wrapper": j.teleport },
                  "open-on-top": p(re),
                  "no-overlay-focus": de.value,
                  collapse: P.value,
                  "get-input-rect": k,
                  "is-text-input-date": b.value,
                  onClosePicker: nt,
                  onSelectDate: be,
                  onAutoApply: ke,
                  onTimeUpdate: jr,
                  onFlowStep: ue[4] || (ue[4] = (ye) => j.$emit("flow-step", ye)),
                  onUpdateMonthYear: ue[5] || (ue[5] = (ye) => j.$emit("update-month-year", ye)),
                  onInvalidSelect: ue[6] || (ue[6] = (ye) => j.$emit("invalid-select", p(S))),
                  onAutoApplyInvalid: ue[7] || (ue[7] = (ye) => j.$emit("invalid-select", ye)),
                  onInvalidFixedRange: ue[8] || (ue[8] = (ye) => j.$emit("invalid-fixed-range", ye)),
                  onRecalculatePosition: p(oe),
                  onTooltipOpen: ue[9] || (ue[9] = (ye) => j.$emit("tooltip-open", ye)),
                  onTooltipClose: ue[10] || (ue[10] = (ye) => j.$emit("tooltip-close", ye)),
                  onTimePickerOpen: ue[11] || (ue[11] = (ye) => j.$emit("time-picker-open", ye)),
                  onTimePickerClose: ue[12] || (ue[12] = (ye) => j.$emit("time-picker-close", ye)),
                  onAmPmChange: ue[13] || (ue[13] = (ye) => j.$emit("am-pm-change", ye)),
                  onRangeStart: ue[14] || (ue[14] = (ye) => j.$emit("range-start", ye)),
                  onRangeEnd: ue[15] || (ue[15] = (ye) => j.$emit("range-end", ye)),
                  onDateUpdate: ue[16] || (ue[16] = (ye) => j.$emit("date-update", ye)),
                  onInvalidDate: ue[17] || (ue[17] = (ye) => j.$emit("invalid-date", ye)),
                  onOverlayToggle: ue[18] || (ue[18] = (ye) => j.$emit("overlay-toggle", ye)),
                  onMenuBlur: ue[19] || (ue[19] = (ye) => j.$emit("blur"))
                }), bt({ _: 2 }, [
                  ze(p(O), (ye, Xt) => ({
                    name: ye,
                    fn: pe((It) => [
                      ie(j.$slots, ye, mt(At({ ...It })))
                    ])
                  }))
                ]), 1040, ["internal-model-value", "class", "open-on-top", "no-overlay-focus", "collapse", "is-text-input-date", "onRecalculatePosition"])
              ], 16)) : G("", !0)
            ]),
            _: 3
          }, 8, ["name", "css"])
        ]),
        _: 3
      }, 16))
    ], 10, D3));
  }
}), Di = /* @__PURE__ */ (() => {
  const e = $3;
  return e.install = (t) => {
    t.component("Vue3DatePicker", e);
  }, e;
})(), I3 = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  default: Di
}, Symbol.toStringTag, { value: "Module" }));
Object.entries(I3).forEach(([e, t]) => {
  e !== "default" && (Di[e] = t);
});
const E3 = ["type", "disabled"], Lo = /* @__PURE__ */ _e({
  __name: "UIButton",
  props: {
    size: { default: "md" },
    type: { default: "default" },
    variant: { default: "default" },
    submit: { type: Boolean },
    disabled: { type: Boolean },
    to: {},
    openInNewTab: { type: Boolean }
  },
  setup(e) {
    const t = e, { isSubmitting: a } = ic(), n = $(() => t.submit && a.value);
    return (r, i) => {
      const l = zl("router-link");
      return r.to ? (M(), Me(l, {
        key: 1,
        to: r.to,
        disabled: r.disabled,
        class: ge(["tw-reset focus:outline-none", {
          "text-center text-base font-bold rounded-md border focus:shadow-glow disabled:bg-ui-gray-300 disabled:border-ui-gray-300 disabled:text-white": r.type !== "link",
          "hover:underline disabled:text-ui-gray-300 cursor-pointer font-bold focus:underline": r.type === "link",
          "border-pivot-teal-700 bg-pivot-teal-700 text-white hover:bg-pivot-teal-800 hover:border-pivot-teal-800": r.variant === "default" && r.type === "default",
          "border-pivot-purple-600 bg-pivot-purple-600 text-white hover:bg-pivot-purple-700 hover:border-pivot-purple-700": r.variant === "alt" && r.type === "default",
          "border-error-red-600 bg-error-red-600 text-white hover:bg-error-red-700 hover:border-error-red-700": r.variant === "destructive" && r.type === "default",
          "border-transparent bg-transparent text-pivot-teal-600 hover:bg-pivot-teal-50 disabled:text-white": r.variant === "reverse" && r.type === "default",
          "border-pivot-teal-700 bg-white text-pivot-teal-700 hover:border-pivot-teal-800 hover:text-pivot-teal-800 disabled:bg-transparent": r.variant === "default" && r.type === "outline",
          "border-pivot-purple-600 bg-white text-pivot-purple-600 hover:border-pivot-purple-700 hover:text-pivot-purple-700 disabled:bg-transparent": r.variant === "alt" && r.type === "outline",
          "border-error-red-600 bg-white text-error-red-600 hover:border-error-red-700 hover:text-error-red-700 disabled:bg-transparent": r.variant === "destructive" && r.type === "outline",
          "border-white bg-white text-white hover:bg-pivot-teal-50 disabled:bg-transparent": r.variant === "reverse" && r.type === "outline",
          "border-transparent bg-white text-pivot-teal-700 hover:border-pivot-teal-700 disabled:bg-transparent": r.variant === "default" && r.type === "invisible",
          "border-transparent bg-white text-pivot-purple-600 hover:border-pivot-purple-600 disabled:bg-transparent": r.variant === "alt" && r.type === "invisible",
          "border-transparent bg-white text-error-red-600 hover:border-error-red-600 disabled:bg-transparent": r.variant === "destructive" && r.type === "invisible",
          "text-error-red-600": r.variant === "destructive" && r.type === "link",
          "text-pivot-teal-700": r.variant !== "destructive" && r.type === "link",
          "py-1.5 px-5": r.size === "md" && r.type !== "link",
          "py-0.5 px-2": r.size === "sm" && r.type !== "link"
        }]),
        target: r.openInNewTab ? "_blank" : "_self"
      }, {
        default: pe(() => [
          ie(r.$slots, "default")
        ]),
        _: 3
      }, 8, ["to", "disabled", "target", "class"])) : (M(), Y("button", {
        key: 0,
        type: r.submit ? "submit" : "button",
        disabled: r.disabled,
        class: ge(["tw-reset focus:outline-none", {
          "text-center text-base font-bold rounded-md border focus:shadow-glow disabled:bg-ui-gray-300 disabled:border-ui-gray-300 disabled:text-white": r.type !== "link",
          "hover:underline disabled:text-ui-gray-300 cursor-pointer font-bold focus:underline": r.type === "link",
          "border-pivot-teal-700 bg-pivot-teal-700 text-white hover:bg-pivot-teal-800 hover:border-pivot-teal-800": r.variant === "default" && r.type === "default",
          "border-pivot-purple-600 bg-pivot-purple-600 text-white hover:bg-pivot-purple-700 hover:border-pivot-purple-700": r.variant === "alt" && r.type === "default",
          "border-error-red-600 bg-error-red-600 text-white hover:bg-error-red-700 hover:border-error-red-700": r.variant === "destructive" && r.type === "default",
          "border-transparent bg-transparent text-pivot-teal-600 hover:bg-pivot-teal-50 disabled:text-white": r.variant === "reverse" && r.type === "default",
          "border-pivot-teal-700 text-pivot-teal-700 hover:border-pivot-teal-800 hover:text-pivot-teal-800 disabled:bg-transparent": r.variant === "default" && r.type === "outline",
          "border-pivot-purple-600 text-pivot-purple-600 hover:border-pivot-purple-700 hover:text-pivot-purple-700 disabled:bg-transparent": r.variant === "alt" && r.type === "outline",
          "border-error-red-600 text-error-red-600 hover:border-error-red-700 hover:text-error-red-700 disabled:bg-transparent": r.variant === "destructive" && r.type === "outline",
          "border-white bg-white text-white hover:bg-pivot-teal-50 disabled:bg-transparent": r.variant === "reverse" && r.type === "outline",
          "border-transparent text-pivot-teal-700 hover:border-pivot-teal-700 disabled:bg-transparent": r.variant === "default" && r.type === "invisible",
          "border-transparent text-pivot-purple-600 hover:border-pivot-purple-600 disabled:bg-transparent": r.variant === "alt" && r.type === "invisible",
          "border-transparent text-error-red-600 hover:border-error-red-600 disabled:bg-transparent": r.variant === "destructive" && r.type === "invisible",
          "text-error-red-600": r.variant === "destructive" && r.type === "link",
          "text-pivot-teal-700": r.variant !== "destructive" && r.type === "link",
          "py-1.5 px-5": r.size === "md" && r.type !== "link",
          "py-0.5 px-2": r.size === "sm" && r.type !== "link"
        }])
      }, [
        n.value ? (M(), Y(Ae, { key: 1 }, [
          le("div", {
            class: ge(`inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] ${!r.variant || r.variant == "default" ? "text-white" : "text-pivot-teal-800"}`),
            role: "status",
            "aria-hidden": "true"
          }, null, 2),
          ut(" Submitting ")
        ], 64)) : ie(r.$slots, "default", { key: 0 })
      ], 10, E3));
    };
  }
}), R3 = ["disabled", "onClick"], N3 = /* @__PURE__ */ le("span", { class: "sr-only" }, "Open Calendar", -1), L3 = ["value", "disabled", "onInput", "onBlur", "onKeydown", "onPaste"], H3 = { class: "flex gap-3 items-center w-full mt-2" }, F3 = /* @__PURE__ */ le("div", { class: "flex-grow" }, null, -1), _h = /* @__PURE__ */ _e({
  __name: "UIDateInput",
  props: {
    modelValue: {},
    name: {},
    rules: {},
    label: { default: "date" },
    disabled: { type: Boolean },
    clearable: { type: Boolean },
    time: { type: Boolean },
    max: {},
    min: {}
  },
  setup(e) {
    const t = e, { value: a, errorMessage: n, handleBlur: r } = Ua(
      tt(t, "name"),
      tt(t, "rules"),
      {
        label: tt(t, "label"),
        syncVModel: !0
      }
    ), i = z(null);
    function l() {
      var d;
      (d = i.value) == null || d.clearValue();
    }
    const o = /^\s*(\d+)\s*([/\-.])\s*(\d+)(?:\s*\2\s*(\d+))?(?:(?:\s*,\s*|\s+)(\d+)\s*:\s*(\d+)(?:\s*([APap][mM]))?)?\s*$/;
    function s(d) {
      var f;
      const u = /* @__PURE__ */ new Date();
      u.setSeconds(0), u.setMilliseconds(0);
      const c = o.exec(d);
      if (!c)
        return null;
      if (c[4]) {
        const v = parseInt(c[4]);
        u.setFullYear(v < 100 ? v + 2e3 : v);
      }
      if (u.setMonth(parseInt(c[1]) - 1), u.setDate(parseInt(c[3])), c[5] && c[6]) {
        const v = ((f = c[7]) == null ? void 0 : f.toLowerCase()) == "pm";
        let g = parseInt(c[5]);
        u.setHours(g < 12 && v ? g + 12 : g), u.setMinutes(parseInt(c[6]));
      }
      return u;
    }
    return (d, u) => (M(), Y("div", null, [
      Ee(p(Di), {
        ref_key: "picker",
        ref: i,
        modelValue: p(a),
        "onUpdate:modelValue": u[0] || (u[0] = (c) => ia(a) ? a.value = c : null),
        class: "date-picker",
        disabled: d.disabled,
        clearable: d.clearable,
        "enable-time-picker": d.time,
        "text-input": { openMenu: !1, format: s },
        "min-date": d.min,
        "max-date": d.max,
        "auto-apply": !d.time,
        "six-weeks": "center",
        teleport: "",
        "time-picker-inline": "",
        "is-24": !1,
        onKeydown: u[1] || (u[1] = ur(za(() => {
        }, ["prevent"]), ["enter"])),
        onBlur: p(r)
      }, {
        "dp-input": pe(({
          value: c,
          onInput: f,
          onEnter: v,
          onTab: g,
          onBlur: y,
          onKeypress: P,
          onPaste: b,
          toggleMenu: w
        }) => [
          le("div", {
            class: ge(["tw-reset flex w-full h-8 border rounded-sm border-ui-gray-400 focus-within:shadow-glow", {
              "border-warning-orange-600": p(n)
            }])
          }, [
            le("button", {
              type: "button",
              class: ge(["bg-pivot-teal-700 text-white hover:bg-pivot-teal-800 focus:outline-none w-8 h-full disabled:bg-ui-gray-300", {
                "text-warning-orange-600 italic": p(n)
              }]),
              disabled: d.disabled,
              tabindex: "-1",
              onClick: w
            }, [
              Ee(p(ca), { icon: "calendar" }),
              N3
            ], 10, R3),
            le("input", {
              class: ge(["inline-block px-2 h-full w-full rounded-sm focus:outline-none placeholder:text-ui-gray-400 disabled:text-ui-gray-300 disabled:bg-ui-gray-100", {
                "pr-8": d.clearable
              }]),
              value: c,
              disabled: d.disabled,
              onInput: f,
              onBlur: y,
              onKeydown: [
                P,
                ur(v, ["enter"]),
                ur(g, ["tab"])
              ],
              onPaste: b
            }, null, 42, L3)
          ], 2)
        ]),
        "action-row": pe(({ selectDate: c }) => [
          le("div", H3, [
            d.clearable ? (M(), Me(Lo, {
              key: 0,
              size: "sm",
              type: "link",
              variant: "destructive",
              onClick: l
            }, {
              default: pe(() => [
                ut(" Clear ")
              ]),
              _: 1
            })) : G("", !0),
            F3,
            Ee(Lo, {
              size: "sm",
              onClick: c
            }, {
              default: pe(() => [
                ut(" Select ")
              ]),
              _: 2
            }, 1032, ["onClick"])
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "disabled", "clearable", "enable-time-picker", "text-input", "min-date", "max-date", "auto-apply", "onBlur"])
    ]));
  }
}), z3 = { class: "tw-reset overflow-hidden rounded-lg bg-white shadow-lg border border-ui-gray-300 p-4" }, Mh = /* @__PURE__ */ _e({
  __name: "UICard",
  props: {
    collapsible: { type: Boolean },
    modelValue: { type: Boolean },
    "onUpdate:modelValue": { type: Function }
  },
  setup(e) {
    const t = e, a = z(!1), n = $(() => !!t["onUpdate:modelValue"]), r = $({
      get() {
        return t.collapsible ? n.value ? !!t.modelValue : a.value : !1;
      },
      set(i) {
        var l;
        n.value ? (l = t["onUpdate:modelValue"]) == null || l.call(t, i) : a.value = i;
      }
    });
    return aa(
      "card",
      mn({ collapsed: r, collapsible: tt(t, "collapsible") })
    ), (i, l) => (M(), Y("div", z3, [
      ie(i.$slots, "default")
    ]));
  }
}), V3 = { class: "group-focus:underline group-hover:underline" }, Ph = /* @__PURE__ */ _e({
  __name: "UICardHeading",
  setup(e) {
    const t = $t("card");
    if (!t)
      throw new Error("UICardHeading must be used inside UICard");
    const a = tt(t, "collapsible"), n = tt(t, "collapsed");
    function r() {
      a.value && (n.value = !n.value);
    }
    return (i, l) => (M(), Y("button", {
      type: "button",
      class: ge(["group tw-reset text-pivot-purple-700 font-bold text-lg focus:outline-none", {
        "cursor-pointer": a.value
      }]),
      onClick: r
    }, [
      a.value ? (M(), Me(p(ca), {
        key: 0,
        icon: n.value ? "chevron-up" : "chevron-down",
        class: "text-pivot-purple-700 font-base mr-1",
        size: "lg"
      }, null, 8, ["icon"])) : G("", !0),
      le("span", V3, [
        ie(i.$slots, "default")
      ])
    ], 2));
  }
}), Ah = /* @__PURE__ */ _e({
  __name: "UICardContent",
  props: {
    unmount: { type: Boolean, default: !0 }
  },
  setup(e) {
    const t = $t("card");
    if (!t)
      throw new Error("UICardContent must be used inside UICard");
    const a = tt(t, "collapsed");
    return (n, r) => (M(), Me(p(Us), {
      show: !a.value,
      unmount: n.unmount,
      class: "tw-reset",
      enter: "transition duration-100 ease-out",
      "enter-from": "transform opacity-0",
      "enter-to": "transform opacity-100",
      leave: "transition duration-75 ease-out",
      "leave-from": "transform opacity-100",
      "leave-to": "transform opacity-0"
    }, {
      default: pe(() => [
        ie(n.$slots, "default")
      ]),
      _: 3
    }, 8, ["show", "unmount"]));
  }
}), Y3 = { key: 0 }, or = /* @__PURE__ */ _e({
  __name: "UIStep",
  props: {
    state: { default: "future" },
    index: {}
  },
  setup(e) {
    return (t, a) => (M(), Y("div", {
      class: ge(["group flex text-base font-bold items-center", {
        "text-ui-gray-800": t.state !== "future",
        "text-ui-gray-400 hover:text-ui-gray-600": t.state === "future"
      }])
    }, [
      le("span", {
        class: ge(["border rounded-full w-6 h-6 mr-2 flex items-center justify-center flex-shrink-0", {
          "border-ui-gray-400 group-hover:border-ui-gray-600 group-hover:text-ui-gray-800": t.state === "future",
          "bg-pivot-teal-700 border-pivot-teal-700": t.state === "complete",
          "bg-warning-orange-600 border-warning-orange-600": t.state === "warn",
          "bg-pivot-purple-700 border-pivot-purple-700": t.state === "current",
          "text-white": t.state !== "future"
        }])
      }, [
        t.state !== "complete" && t.state !== "warn" ? (M(), Y("span", Y3, Ne(t.index), 1)) : G("", !0),
        t.state === "complete" || t.state === "warn" ? (M(), Me(p(ca), {
          key: 1,
          icon: t.state === "complete" ? "check" : "exclamation",
          class: "w-4 h-4 inline-flex"
        }, null, 8, ["icon"])) : G("", !0)
      ], 2),
      ie(t.$slots, "default")
    ], 2));
  }
}), _r = [
  "click",
  "mouseenter",
  "mouseleave",
  "mouseover",
  "mouseout",
  "mousemove"
].map((e) => ({
  nativeEventName: e,
  sectionEventName: `section-${e}`
})), Ho = [
  "#FF6384",
  "#36A2EB",
  "#FFCE56",
  "#F58231",
  "#46F0F0",
  "#D2F53C",
  "#911EB4",
  "#F032E6",
  "#3CB44B",
  "#FFE119",
  "#E6194B",
  "#FABEBE",
  "#008080",
  "#E6BEFF",
  "#0082C8",
  "#AA6E28",
  "#FFFAC8",
  "#800000",
  "#AAFFC3",
  "#808000",
  "#FFD8B1",
  "#000080",
  "#808080",
  "#000000"
], pl = "1em", B3 = "dodgerblue", en = {
  TOP: "top",
  RIGHT: "right",
  BOTTOM: "bottom",
  LEFT: "left"
}, j3 = {
  [en.TOP]: {
    container: { flexDirection: "column" },
    legend: { order: -1, margin: 0, marginBottom: pl }
  },
  [en.RIGHT]: {
    container: {},
    legend: {
      flexDirection: "column",
      margin: 0,
      marginLeft: pl
    }
  },
  [en.BOTTOM]: {
    container: { flexDirection: "column" },
    legend: {}
  },
  [en.LEFT]: {
    container: {},
    legend: {
      flexDirection: "column",
      order: -1,
      margin: 0,
      marginRight: pl
    }
  }
}, U3 = (e) => !!e && e.constructor === Object, W3 = [
  { type: "number", key: "value", required: !0 },
  { key: "label" },
  { key: "color" }
];
function q3(e) {
  return U3(e) ? W3.reduce((t, a) => {
    if (!t)
      return !1;
    let n = !0;
    return a.required && (n = n && Object.hasOwnProperty.call(e, a.key)), a.type && (n = n && typeof e[a.key] === a.type), t && n;
  }, !0) : !1;
}
const qu = (e, t) => {
  const a = e.__vccOpts || e;
  for (const [n, r] of t)
    a[n] = r;
  return a;
}, Fo = {
  LEFT: "cdc-section-left",
  RIGHT: "cdc-section-right"
}, X3 = {
  props: {
    startAngle: { type: Number, default: 0 },
    sections: { type: Array, default: () => [] }
  },
  emits: _r.map((e) => e.sectionEventName),
  computed: {
    containerStyles() {
      return {
        transform: `rotate(${this.startAngle}deg)`
      };
    },
    donutSections() {
      let t = 0, a = 0;
      return this.sections.map((r) => {
        let [i, l] = [Fo.RIGHT, -180];
        t >= 180 && ([i, l] = [Fo.LEFT, 180]);
        const o = l + r.degree, s = {
          backgroundColor: r.color || B3,
          transform: `rotate(${o}deg)`
        }, d = { transform: `rotate(${a}deg)` };
        t += r.degree, t === 180 ? a = 0 : a += r.degree;
        const u = _r.reduce((c, { nativeEventName: f, sectionEventName: v }) => ({
          ...c,
          [f]: (g) => this.emitEvent(v, r, g)
        }), {});
        return {
          label: r.label,
          className: i,
          fillerStyles: s,
          sectionStyles: d,
          listeners: u
        };
      });
    }
  },
  methods: {
    emitEvent(e, t, a) {
      t.value !== 0 && this.$emit(e, t.$section, a);
    }
  }
}, Q3 = ["title"];
function K3(e, t, a, n, r, i) {
  return M(), Y("div", {
    class: "cdc-sections",
    style: Ge(i.containerStyles)
  }, [
    (M(!0), Y(Ae, null, ze(i.donutSections, (l, o) => (M(), Y("div", Ue(Bo(l.listeners, !0), {
      key: o,
      class: ["cdc-section", l.className],
      style: l.sectionStyles
    }), [
      le("div", {
        class: "cdc-filler",
        style: Ge(l.fillerStyles),
        title: l.label
      }, null, 12, Q3)
    ], 16))), 128))
  ], 4);
}
const G3 = /* @__PURE__ */ qu(X3, [["render", K3]]), J3 = {
  name: "vc-donut",
  props: {
    // diameter of the donut
    size: { type: Number, default: 250, validator: (e) => e > 0 },
    // unit to use for `size`
    unit: { type: String, default: "px" },
    // percentage of donut ring's thickness
    thickness: {
      type: Number,
      default: 20,
      validator: (e) => e >= 0 && e <= 100
    },
    // text in the middle of the donut, this can also be passed using the default slot
    text: { type: String, default: null },
    autoAdjustTextSize: { type: Boolean, default: !0 },
    // color to use for the middle of the donut
    // set this to `transparent` or `thickness` to 100 to make a pie chart instead
    background: { type: String, default: "#ffffff" },
    // color to use for the empty ring areas
    foreground: { type: String, default: "#eeeeee" },
    // sections of the donut, must have a `value` property
    // other valid properties are `label` and `color` (default is `dodgerblue`)
    sections: {
      type: Array,
      default: () => [],
      validator(e) {
        for (let t = 0; t < e.length; ++t)
          if (!q3(e[t]))
            return !1;
        return !0;
      }
    },
    total: { type: Number, default: 100, validator: (e) => e > 0 },
    hasLegend: { type: Boolean, default: !1 },
    legendPlacement: {
      type: String,
      default: en.BOTTOM,
      validator: (e) => !!en[e.toUpperCase()]
    },
    // degree angle at which the first section begins
    startAngle: { type: Number, default: 0 }
  },
  emits: _r.map((e) => e.sectionEventName),
  watch: {
    autoAdjustTextSize(e) {
      e ? window.addEventListener("resize", this.resizeListener) : window.removeEventListener("resize", this.resizeListener), this.recalcFontSize();
    },
    size() {
      this.recalcFontSize();
    },
    unit() {
      this.recalcFontSize();
    }
  },
  data() {
    return {
      donutEl: null,
      fontSize: "1em",
      resizeListener: null
    };
  },
  computed: {
    donutSections() {
      let e = this.sections.reduce((l, o) => l + o.value, 0);
      if (typeof e != "number")
        return [];
      if (e = Number(e.toFixed(2)), e > this.total) {
        const l = `Sum of all the sections' values (${e}) should not exceed \`total\` (${this.total})`;
        throw new Error(l);
      }
      const t = 360, a = 180;
      let n = 0, r = 0;
      const i = [];
      return this.sections.forEach((l) => {
        const o = t * (l.value / this.total);
        let s = [o];
        o > a && (s = [a, o - a]);
        const d = l.color || Ho[r++];
        s.forEach((u) => {
          if (Number((n + u).toFixed(2)) > a) {
            const f = a - n;
            i.push(
              { ...l, degree: f, color: d, $section: l },
              { ...l, degree: u - f, color: d, $section: l }
            );
          } else
            i.push({ ...l, degree: u, color: d, $section: l });
          n += u, n >= a && (n -= a);
        });
      }), i;
    },
    legend() {
      if (!this.hasLegend)
        return null;
      let e = 0;
      return this.sections.map((t, a) => ({
        label: t.label || `Section ${a + 1}`,
        percent: `${t.value} (${t.value / this.total * 100}%)`,
        styles: {
          backgroundColor: t.color || Ho[e++]
        }
      }));
    },
    placementStyles() {
      return this.hasLegend ? j3[this.legendPlacement] : {};
    },
    donutStyles() {
      const e = `${this.size}${this.unit}`;
      return {
        width: e,
        paddingBottom: e,
        backgroundColor: this.foreground
      };
    },
    overlayStyles() {
      const t = 100 - this.thickness, a = `${t}%`, n = `calc(50% - ${t / 2}%)`;
      return {
        height: a,
        width: a,
        top: n,
        left: n,
        backgroundColor: this.background
      };
    },
    donutTextStyles() {
      const { fontSize: e } = this;
      return { fontSize: e };
    },
    sectionListeners() {
      return _r.reduce((e, { sectionEventName: t }) => ({
        ...e,
        [t]: (...a) => this.emitSectionEvent(t, ...a)
      }), {});
    }
  },
  methods: {
    recalcFontSize() {
      if (!this.autoAdjustTextSize) {
        this.fontSize = "1em";
        return;
      }
      const e = 0.08;
      let t = this.size;
      this.$nextTick(() => {
        this.unit !== "px" && (this.donutEl ? t = this.donutEl.clientWidth : t = null), this.fontSize = t ? `${(t * e).toFixed(2)}px` : "1em";
      });
    },
    emitSectionEvent(e, ...t) {
      this.$emit(e, ...t);
    }
  },
  created() {
    this.resizeListener = this.recalcFontSize.bind(this);
  },
  mounted() {
    this.donutEl = this.$refs.donut, this.autoAdjustTextSize && (this.recalcFontSize(), window.addEventListener("resize", this.resizeListener));
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  },
  components: { DonutSections: G3 }
}, Z3 = ["title"];
function eh(e, t, a, n, r, i) {
  const l = zl("donut-sections");
  return M(), Y("div", {
    class: "cdc-container",
    style: Ge(i.placementStyles.container)
  }, [
    le("div", {
      class: "cdc",
      ref: "donut",
      style: Ge(i.donutStyles)
    }, [
      Ee(l, Ue(Bo(i.sectionListeners), {
        sections: i.donutSections,
        "start-angle": a.startAngle
      }), null, 16, ["sections", "start-angle"]),
      le("div", {
        class: "cdc-overlay",
        style: Ge(i.overlayStyles)
      }, [
        le("div", {
          class: "cdc-text",
          style: Ge(i.donutTextStyles)
        }, [
          ie(e.$slots, "default", {}, () => [
            ut(Ne(a.text), 1)
          ])
        ], 4)
      ], 4)
    ], 4),
    ie(e.$slots, "legend", {}, () => [
      a.hasLegend ? (M(), Y("div", {
        key: 0,
        class: "cdc-legend",
        style: Ge(i.placementStyles.legend)
      }, [
        (M(!0), Y(Ae, null, ze(i.legend, (o, s) => (M(), Y("span", {
          class: "cdc-legend-item",
          key: s,
          title: o.percent
        }, [
          le("span", {
            class: "cdc-legend-item-color",
            style: Ge(o.styles)
          }, null, 4),
          le("span", null, Ne(o.label), 1)
        ], 8, Z3))), 128))
      ], 4)) : G("", !0)
    ])
  ], 4);
}
const th = /* @__PURE__ */ qu(J3, [["render", eh]]), ah = { "aria-label": "Progress" }, nh = {
  role: "list",
  class: "hidden md:flex items-center"
}, rh = { class: "flex md:hidden justify-between" }, lh = { class: "w-full flex" }, ih = { class: "pl-4" }, oh = { class: "px-2 py-2" }, sh = { class: "w-20 py-2" }, Th = /* @__PURE__ */ _e({
  __name: "UIStepList",
  props: {
    steps: {},
    currentStep: {},
    erroredSteps: { default: () => [] }
  },
  setup(e) {
    const t = e, a = $(
      () => t.steps.find((o) => o.key === t.currentStep)
    ), n = $(() => t.steps.length ?? 0), r = $(
      () => t.steps.findIndex((o) => o.key === t.currentStep)
    ), i = $(() => t.currentStep ? t.steps.slice(0, r.value).map((o) => o.key) : []), l = $(() => t.steps.map((o) => {
      var s;
      return {
        label: o.name,
        value: 100 / t.steps.length,
        color: o.key === t.currentStep ? "#433069" : (s = i.value) != null && s.includes(o.key) ? "#1da7a7" : "#979797"
      };
    }));
    return (o, s) => {
      const d = zl("router-link");
      return M(), Y("nav", ah, [
        le("ol", nh, [
          (M(!0), Y(Ae, null, ze(o.steps, (u, c) => (M(), Y("li", {
            key: u.key,
            class: "pr-8 last:pr-0 relative"
          }, [
            u.href ? (M(), Me(d, {
              key: 0,
              to: u.href,
              class: "cursor-pointer"
            }, {
              default: pe(() => [
                Ee(or, {
                  index: c + 1,
                  state: o.erroredSteps.includes(u.key) ? "warn" : u.key === o.currentStep ? "current" : i.value.includes(u.key) ? "complete" : "future"
                }, {
                  default: pe(() => [
                    ut(Ne(u.name), 1)
                  ]),
                  _: 2
                }, 1032, ["index", "state"])
              ]),
              _: 2
            }, 1032, ["to"])) : (M(), Me(or, {
              key: 1,
              index: c + 1,
              state: o.erroredSteps.includes(u.key) ? "warn" : u.key === o.currentStep ? "current" : i.value.includes(u.key) ? "complete" : "future"
            }, {
              default: pe(() => [
                ut(Ne(u.name), 1)
              ]),
              _: 2
            }, 1032, ["index", "state"]))
          ]))), 128))
        ]),
        le("div", rh, [
          Ee(p(A0), {
            as: "div",
            class: "relative inline-block text-left w-full"
          }, {
            default: pe(() => [
              Ee(p(T0), { class: "inline-flex w-full gap-x-1.5 px-3 py-2 text-base justify-between" }, {
                default: pe(({ open: u }) => {
                  var c;
                  return [
                    le("div", lh, [
                      Ee(th, {
                        size: 25,
                        background: "white",
                        foreground: "gainsboro",
                        sections: l.value,
                        thickness: 20
                      }, null, 8, ["sections"]),
                      le("span", ih, Ne((c = a.value) == null ? void 0 : c.name), 1)
                    ]),
                    Ee(p(ca), {
                      icon: u ? "caret-up" : "caret-down",
                      class: "block pt-1",
                      size: "sm"
                    }, null, 8, ["icon"])
                  ];
                }),
                _: 1
              }),
              Ee(Ca, {
                "enter-active-class": "transition ease-out duration-100",
                "enter-from-class": "transform opacity-0 scale-95",
                "enter-to-class": "transform opacity-100 scale-100",
                "leave-active-class": "transition ease-in duration-75",
                "leave-from-class": "transform opacity-100 scale-100",
                "leave-to-class": "transform opacity-0 scale-95"
              }, {
                default: pe(() => [
                  Ee(p(O0), { class: "absolute left-0 z-[9999] mt-2 w-56 origin-top-left focus:outline-none rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5" }, {
                    default: pe(() => [
                      le("div", oh, [
                        (M(!0), Y(Ae, null, ze(o.steps, (u, c) => (M(), Me(p(S0), {
                          key: u.key,
                          class: "py-2 relative"
                        }, {
                          default: pe(() => [
                            u.href ? (M(), Me(d, {
                              key: 0,
                              to: u.href,
                              class: "cursor-pointer flex"
                            }, {
                              default: pe(() => [
                                Ee(or, {
                                  index: c + 1,
                                  state: o.erroredSteps.includes(u.key) ? "warn" : u.key === o.currentStep ? "current" : i.value.includes(u.key) ? "complete" : "future"
                                }, {
                                  default: pe(() => [
                                    ut(Ne(u.name), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["index", "state"])
                              ]),
                              _: 2
                            }, 1032, ["to"])) : (M(), Me(or, {
                              key: 1,
                              index: c + 1,
                              state: o.erroredSteps.includes(u.key) ? "warn" : u.key === o.currentStep ? "current" : i.value.includes(u.key) ? "complete" : "future"
                            }, {
                              default: pe(() => [
                                ut(Ne(u.name), 1)
                              ]),
                              _: 2
                            }, 1032, ["index", "state"]))
                          ]),
                          _: 2
                        }, 1024))), 128))
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          le("div", sh, Ne(r.value + 1) + " of " + Ne(n.value), 1)
        ])
      ]);
    };
  }
});
export {
  Lo as UIButton,
  Mh as UICard,
  Ah as UICardContent,
  Ph as UICardHeading,
  yh as UICheckbox,
  _h as UIDateInput,
  hh as UIFieldError,
  vh as UIForm,
  ca as UIIcon,
  mh as UIIconLayers,
  fh as UILabel,
  kh as UINumberInput,
  gh as UIRadioButton,
  xh as UISelectInput,
  Th as UIStepList,
  wh as UISwitch,
  ph as UITextInput
};
