<template>
  <div>
    <form-group class="groups-form">
      <div class="group-setup-label">
        Search for students to add to the assignment
      </div>
      <div class="break"></div>
    </form-group>
    <div class="groups-container">
      <collapse-provider>
        <collapse-toggle>
          <div class="selected-students">
            <span v-if="model && model.length"
              >{{ selectedCount }} students selected: {{ studentsList }}</span
            >
            <span v-if="!model || !model.length">No students selected</span>
            <collapse-icon icon="caret" class="collapse-icon" />
          </div>
        </collapse-toggle>
        <collapse-content>
          <multiselect
            v-model="model"
            :options="rosterList"
            label="name"
            valueProp="_id"
            mode="multiple"
            placeholder="Type a student name to search the list"
            :searchable="true"
            :show-options="true"
            :close-on-select="false"
            :clear-on-select="true"
            :hide-selected="false"
            :filter-results="true"
            track-by="name"
            :classes="{
              container: 'select-container',
              search: 'select-search',
              dropdown: 'select-dropdown',
              dropdownHidden: 'select-dropdown',
              options: 'select-options',
              multipleLabel: 'd-none'
            }"
            @change="onUpdate"
          >
          </multiselect>
        </collapse-content>
      </collapse-provider>
    </div>
  </div>
</template>
<script type="text/javascript">
import Multiselect from '@vueform/multiselect'

export default {
  name: 'ActivitySubsets',
  inject: ['$modal'],
  components: { Multiselect },
  emits: ['update:modelValue'],
  props: {
    roster: {
      type: Array,
      required: true
    },
    modelValue: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    model: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    rosterList() {
      if (this.disabled) {
        return this.roster.map(r => ({
          ...r,
          disabled: this.disabled
        }))
      }
      return this.roster
    },
    studentsList() {
      return [...(this.model || [])]
        .map(s => this.roster.find(as => as._id === s))
        .filter(Boolean)
        .map(s => s.name)
        .join(', ')
    },
    selectedCount() {
      return this.model?.length ?? 0
    }
  },
  methods: {
    onUpdate(value) {
      this.model = value
    }
  }
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>

<style lang="scss" scoped>
.groups-form {
  flex-wrap: wrap;
  width: 370px;
  margin-top: 20px;
}
.group-setup-label {
  flex-grow: 3;
}
.group-setup-row {
  display: flex;
  flex-direction: row;
}
.group-setup-subgroup {
  margin-right: 30px;
  margin-bottom: 10px;
}
.break {
  flex-basis: 100%;
  height: 0;
}
.groups-container {
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 30px;
  margin-top: 10px;
  width: 370px;
}
.assigned-text {
  margin-bottom: 15px;
}
.collapse-toggle {
  width: 100%;
}
.selected-students {
  position: relative;
  padding: 10px 20px 10px 10px;
  min-height: 24px;
}
.collapse-icon {
  position: absolute;
  right: 10px;
  top: 14px;
}

.pl-0 {
  padding-left: 0;
}

.group-count {
  font-weight: bold;
  padding-top: 5px;
}
</style>
<style lang="scss">
.select-container {
  position: relative;
  margin: 0 auto;
  width: 100%;
  display: block;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  border-radius: 4px;
  background: #fff;
  font-size: 14px;
  min-height: 36px;

  .multiselect-placeholder {
    display: block;
    top: 12px;
    left: 5px;
  }

  .multiselect-clear {
    display: none;
  }
}

.d-none {
  display: none;
}

.select-search {
  position: relative;
  top: 5px;
  left: 5px;
  width: calc(100% - 10px);
  height: 36px;
  outline: none;
  box-sizing: border-box;
  border: 1px solid #ccc;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: inherit;
  font-family: inherit;
  background: #fff;
  border-radius: 4px;
}
.multiselect-caret {
  display: none;
}
.select-dropdown {
  display: block;
  max-height: 250px;
  overflow-y: scroll;
  position: relative;
  margin-top: 15px;
  width: 100%;
  background-color: #fff;
  color: #555555;
  border-radius: 4px;
}

.select-options {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  max-height: 250px;
  padding-left: 5px !important;
  .multiselect-option {
    font-size: 14px !important;
    &.is-selected {
      background-color: $teal;
    }
    &:hover,
    &.is-pointed {
      background-color: $teal;
    }
  }
}
</style>
