export enum ActivityProgressValue {
  Initialized = 'initialized',
  Unavailable = 'unavailable',
  Started = 'started',
  InProgress = 'in-progress',
  Submitted = 'submitted'
}

export enum ActivitySubmissionTypeValue {
  Automatic = 'automatic',
  Manual = 'manual'
}

export enum CollectionVariableColumnTypeValue {
  Number = 'number',
  Text = 'text',
  Image = 'image'
}

export enum VariablePrecisionTypeValue {
  Decimal = 'decimal',
  SigFigs = 'sigfigs'
}

export enum AssignedToValue {
  Everyone = 'everyone',
  Groups = 'groups',
  Individuals = 'individuals'
}

export enum AssignmentStudentFeedbackTimingValue {
  AfterQuestionSubmit = 'after-question-submit',
  AfterAssignmentSubmit = 'after-assignment-submit',
  AfterFinalGrade = 'after-final-grade',
  Never = 'never'
}

export enum AssignmentStatusValue {
  NotOpen = 'not-open',
  Open = 'open',
  Closed = 'closed'
}

export enum AssignmentScoreVisibilityValue {
  AfterQuestionSubmit = 'after-question-submit',
  AfterAssignmentSubmit = 'after-assignment-submit',
  AfterFinalGrade = 'after-final-grade',
  Never = 'never'
}

export enum AssignmentFeedbackVisibilityValue {
  AfterQuestionSubmit = 'after-question-submit',
  AfterAssignmentSubmit = 'after-assignment-submit',
  AfterFinalGrade = 'after-final-grade',
  Never = 'never'
}

export enum ClassLevelValue {
  HighSchool = 'high-school',
  College = 'college'
}

export enum ClassPaymentSourceValue {
  Student = 'student',
  Institution = 'institution'
}

export enum GradingProgressValue {
  NotReady = 'not-ready',
  Pending = 'pending',
  Graded = 'graded'
}

export enum ScoringTypeValue {
  PointValue = 'point-value',
  Custom = 'custom'
}

export enum ExternalRosterConnectionTypeValue {
  ClassLink = 'ClassLink',
  Clever = 'Clever'
}

export enum ActivityComponentGradingTypeValue {
  Auto = 'auto',
  Manual = 'manual',
  None = 'none'
}

export enum ActivityComponentTypeValue {
  GridGraphQuestion = 'GridGraphQuestion',
  GridQuestion = 'GridQuestion',
  OpenEndedQuestion = 'OpenEndedQuestion',
  MultipleChoiceQuestion = 'MultipleChoiceQuestion',
  EmbeddedInstance = 'EmbeddedInstance',
  StudentInstance = 'StudentInstance',
  InstructorInstance = 'InstructorInstance',
  Announcement = 'GeneralNote',
  NumberedInstruction = 'GeneralInstruction',
  EmbeddedWebContent = 'IFrame',
  LegacyPhetIOSimulation = 'PhetIOSim',
  PhetIOSimulation = 'PhetIOSimulation',
  NumericalQuestion = 'NumericalQuestion',
  DragDropQuestion = 'DragDropQuestion',
  SplitView = 'SplitView'
}

export enum AccuracyTypeValue {
  Percent = 'percent',
  Absolute = 'absolute',
  None = 'none'
}

export enum ActivityVariableTypeValue {
  Expression = 'expression',
  Random = 'random',
  StudentResponse = 'studentResponse',
  Collection = 'collection'
}

export enum LibraryPermissionsValue {
  Owner = 'owner',
  Admin = 'admin',
  Editor = 'editor',
  Viewer = 'viewer'
}

export enum LibraryStatusValue {
  Active = 'active',
  Archived = 'archived'
}

export enum LibraryTypeValue {
  Shared = 'shared',
  Community = 'community',
  Pivot = 'pivot'
}

export enum InstructorLicenseTypeValue {
  Site = 'site'
}

export enum LicenseActionTypeValue {
  Created = 'created',
  Upgraded = 'upgraded',
  Scheduled = 'scheduled',
  Cancelled = 'cancelled',
  DateChange = 'datechange',
  Expired = 'expired',
  Renewed = 'renewed',
  SeatsChanged = 'seatschanged',
  Archived = 'archived',
  Merged = 'merged'
}

export enum LicenseInvitationStatusValue {
  Pending = 'pending',
  Rejected = 'rejected'
}

export enum SchoolLevelValue {
  College = 'college',
  HighSchool = 'high-school'
}

export enum LicenseRenewalStatusValue {
  Applied = 'applied',
  Scheduled = 'scheduled',
  Cancelled = 'cancelled'
}

export enum LicenseRoleValue {
  Admin = 'admin',
  Member = 'member',
  Grader = 'grader'
}

export enum LicenseStatusValue {
  Active = 'active',
  Inactive = 'inactive',
  Expiring = 'expiring',
  Archived = 'archived'
}

export enum LicenseTypeValue {
  Institution = 'institution',
  Student = 'student',
  Trial = 'trial'
}

export enum LicenseVettingStatusValue {
  Vetted = 'vetted',
  Unvetted = 'unvetted',
  Revoked = 'revoked'
}

export enum OnboardingStatusValue {
  Invited = 'invited',
  AcceptedInvitation = 'accepted-invitation',
  CreatedClass = 'created-class',
  EnrolledStudents = 'enrolled-students',
  AssignedActivity = 'assigned-activity',
  Reset = 'reset'
}

export enum InstructorActionValue {
  CreatedClass = 'created-class',
  GradedAssignment = 'graded-assignment',
  AssignedActivity = 'assigned-activity',
  AcceptedInvitation = 'accepted-invitation',
  CreatedActivity = 'created-activity',
  AddedActivityToLibrary = 'added-activity-to-library',
  CreatedClassLtiConnection = 'created-class-lti-connection'
}

export enum BookstorePurchaseSeatStatusValue {
  Redeemed = 'redeemed',
  Unredeemed = 'unredeemed'
}

export enum ProductTypeValue {
  License = 'license',
  BookstoreCodes = 'bookstore-codes',
  AddedSeats = 'added-seats',
  Renewal = 'renewal',
  Upgrade = 'upgrade',
  Generic = 'generic'
}

export enum PurchaseActionTypeValue {
  Created = 'created',
  Modified = 'modified',
  Completed = 'completed',
  LineItemFulfilled = 'line-item-fulfilled'
}

export enum PurchaseLevelValue {
  College = 'college',
  HighSchool = 'high-school'
}

export enum PurchaseStatusValue {
  Quote = 'quote',
  Pending = 'pending',
  Paid = 'paid',
  Expired = 'expired',
  Fulfilled = 'fulfilled'
}

export enum TaxCodeValue {
  Nontaxable = 'nontaxable',
  Saas = 'saas'
}

export enum InvitationTypeValue {
  Purchase = 'purchase',
  Instructor = 'instructor',
  Grader = 'grader'
}

export enum UserVetStatusValue {
  Vetted = 'vetted',
  Unvetted = 'unvetted',
  Prevetted = 'prevetted',
  Rejected = 'rejected'
}

export enum UserRoleValue {
  Admin = 'admin',
  ContentDeveloper = 'content_developer',
  SalesAdmin = 'sales_admin',
  Instructor = 'teacher',
  Student = 'student'
}

export enum DisabledReasonValue {
  Abuse = 'abuse',
  Invalid = 'invalid',
  VettingRejected = 'vetting-rejected',
  Duplicate = 'duplicate'
}

export enum DragDropRenderTypeValue {
  RichText = 'rich-text',
  LaTeX = 'latex'
}

export enum DragDropConditionTargetTypeValue {
  Is = 'is',
  IsNot = 'is-not',
  Any = 'any'
}

export enum DroppedStudentActorValue {
  Instructor = 'Instructor',
  EndDate = 'End Date',
  RosteringService = 'Rostering Service',
  Offload = 'Offload',
  Expired = 'Expired'
}

export enum StudentRosteringSourceValue {
  LTI = 'LTI',
  Roster = 'Roster',
  Manual = 'Manual',
  Unknown = 'Unknown',
  Transfer = 'Transfer'
}

export enum ActivityRelationshipTypeValue {
  Replaces = 'replaces'
}

export enum LtiPlatformSigningKeyTypeValue {
  JWK = 'jwk',
  JwkUrl = 'jwk-url',
  PublicKey = 'public-key'
}

export enum LtiMessageClaimValue {
  Subject = 'sub',
  FamilyName = 'family_name',
  GivenName = 'given_name',
  Email = 'email',
  Nonce = 'nonce',
  Roles = 'https://purl.imsglobal.org/spec/lti/claim/roles',
  MessageType = 'https://purl.imsglobal.org/spec/lti/claim/message_type',
  Version = 'https://purl.imsglobal.org/spec/lti/claim/version',
  DeploymentId = 'https://purl.imsglobal.org/spec/lti/claim/deployment_id',
  Context = 'https://purl.imsglobal.org/spec/lti/claim/context',
  TargetLink = 'https://purl.imsglobal.org/spec/lti/claim/target_link_uri',
  Custom = 'https://purl.imsglobal.org/spec/lti/claim/custom',
  ResourceLink = 'https://purl.imsglobal.org/spec/lti/claim/resource_link',
  Lis = 'https://purl.imsglobal.org/spec/lti/claim/lis',
  Endpoint = 'https://purl.imsglobal.org/spec/lti-ags/claim/endpoint',
  Data = 'https://purl.imsglobal.org/spec/lti-dl/claim/data',
  DeepLinkSettings = 'https://purl.imsglobal.org/spec/lti-dl/claim/deep_linking_settings',
  ContentItems = 'https://purl.imsglobal.org/spec/lti-dl/claim/content_items',
  Message = 'https://purl.imsglobal.org/spec/lti-dl/claim/msg',
  Log = 'https://purl.imsglobal.org/spec/lti-dl/claim/log',
  ErrorMessage = 'https://purl.imsglobal.org/spec/lti-dl/claim/errormsg',
  ErrorLog = 'https://purl.imsglobal.org/spec/lti-dl/claim/errorlog'
}

export enum LtiMessageRoleValue {
  InstitutionFaculty = 'http://purl.imsglobal.org/vocab/lis/v2/institution/person#Faculty',
  InstitutionStaff = 'http://purl.imsglobal.org/vocab/lis/v2/institution/person#Staff',
  InstitutionInstructor = 'http://purl.imsglobal.org/vocab/lis/v2/institution/person#Instructor',
  InstitutionLearner = 'http://purl.imsglobal.org/vocab/lis/v2/institution/person#Learner',
  InstitutionStudent = 'http://purl.imsglobal.org/vocab/lis/v2/institution/person#Student',
  MembershipInstructor = 'http://purl.imsglobal.org/vocab/lis/v2/membership#Instructor',
  MembershipLearner = 'http://purl.imsglobal.org/vocab/lis/v2/membership#Learner',
  Instructor = 'Instructor',
  Learner = 'Learner'
}

export enum LtiMessageTypeValue {
  ResourceLinkRequest = 'LtiResourceLinkRequest',
  DeepLinkingRequest = 'LtiDeepLinkingRequest',
  DeepLinkingResponse = 'LtiDeepLinkingResponse'
}

export enum LtiMessageContextTypeValue {
  CourseTemplate = 'CourseTemplate',
  CourseOffering = 'CourseOffering',
  CourseSection = 'CourseSection',
  Group = 'Group',
  CourseTemplateURI = 'http://purl.imsglobal.org/vocab/lis/v2/course#CourseTemplate',
  CourseOfferingURI = 'http://purl.imsglobal.org/vocab/lis/v2/course#CourseOffering',
  CourseSectionURI = 'http://purl.imsglobal.org/vocab/lis/v2/course#CourseSection',
  GroupURI = 'http://purl.imsglobal.org/vocab/lis/v2/course#Group',
  CourseTemplateURN = 'urn:lti:context-type:ims/lis/CourseTemplate',
  CourseOfferingURN = 'urn:lti:context-type:ims/lis/CourseOffering',
  CourseSectionURN = 'urn:lti:context-type:ims/lis/CourseSection',
  GroupURN = 'urn:lti:context-type:ims/lis/Group'
}

export enum ClassInstructorRoleValue {
  Grader = 'Grader',
  Instructor = 'Instructor'
}
